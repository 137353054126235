import React, { useState, Dispatch, SetStateAction } from 'react';
import { Dialog, MenuItem, SelectField } from 'material-ui';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { TXT_RED } from 'theme';
import {
  OrderMassUpdateMap,
  OrderTags,
  OrderTraceType,
  SubscriptionSource,
  OrderPrivateDTO,
} from '@cvfm-front/tefps-types';
import { addTagsUpdate } from 'api/cvfm-core-subscription/order';

import OrderFilterService from './OrderFilterService';
import OrderMassUpdateProcess from './OrderMassUpdateProcess';

const { _t, _tg } = window.loadTranslations(__filename);

const ACTION_TYPE_ADD = 'ADD';
const ACTION_TYPE_REMOVE = 'REMOVE';

type OrderTagsModalProps = {
  isOpen: boolean;
  setIsOpenTagsModal: Dispatch<SetStateAction<boolean>>;
  selectedOrders: OrderMassUpdateMap;
  tags: string[];
  userInfo: InternalAgent | null | undefined;
};

const OrderTagsModal = ({
  isOpen,
  setIsOpenTagsModal,
  selectedOrders,
  tags,
  userInfo,
}: OrderTagsModalProps): JSX.Element => {
  const [tagsToAdd, setTagsToAdd] = useState<string[]>([]);
  const [tagsToRemove, setTagsToRemove] = useState<string[]>([]);

  const [isAllSettled, setIsAllSettled] = useState<boolean>(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState<number>(0);
  const [uploadErrorIds, setUploadErrorIds] = useState<
    { id: string; err: string }[]
  >([]);

  const onCloseModal = () => {
    setTagsToAdd([]);
    setTagsToRemove([]);
    setIsLoadingProgress(false);
    setUploadProgressState(0);
    setUploadErrorIds([]);
    setIsOpenTagsModal(false);
    if (isAllSettled) {
      OrderFilterService.loadOrders();
    }
    setIsAllSettled(false);
  };

  const getNewOrderTagsAfterAddAndRemove = (orderTags: string[]): string[] => {
    // Set ensure no duplicate tags
    const newOrderTags = new Set(orderTags);

    tagsToAdd.forEach(value => newOrderTags.add(value));
    tagsToRemove.forEach(value => newOrderTags.delete(value));

    return Array.from(newOrderTags);
  };

  const doSingleTagUpdate = async (
    orderId: string,
    tags: string[]
  ): Promise<OrderPrivateDTO | Error> => {
    return addTagsUpdate(orderId, {
      timestamp: new Date().toISOString(),
      traceType: OrderTraceType.TAGS,
      source: SubscriptionSource.BACK,
      agent: userInfo ? { ...userInfo } : null,
      tags: getNewOrderTagsAfterAddAndRemove(tags),
      subscriberId: null,
    } as OrderTags)
      .then(response => {
        return response;
      })
      .catch(err => {
        const error = err as Error;
        setUploadErrorIds(errors => [
          ...errors,
          { id: orderId, err: error.message },
        ]);
        return error;
      })
      .finally(() => {
        // Sert à incrementer la barre de progression
        setUploadProgressState(past => past + 1);
      });
  };

  async function onClickValidation() {
    setIsLoadingProgress(true);
    const tagsUpdatesPromises: Promise<
      OrderPrivateDTO | Error
    >[] = Object.entries(selectedOrders).map(([orderId, orderData]) => {
      return doSingleTagUpdate(orderId, orderData.tags);
    });
    await Promise.all(tagsUpdatesPromises);
    setIsAllSettled(true);
    setIsLoadingProgress(false);
  }

  const onChangeTagsByType = (
    newTags: Array<string>,
    type: typeof ACTION_TYPE_ADD | typeof ACTION_TYPE_REMOVE
  ) => {
    if (type === 'ADD') {
      setTagsToAdd(newTags);
    } else {
      setTagsToRemove(newTags);
    }
  };

  const getTagsInBothAddAndRemove = (): Set<string> => {
    const resultSet: Set<string> = new Set();
    tagsToAdd.forEach(tag => {
      if (tagsToRemove.includes(tag)) {
        resultSet.add(tag);
      }
    });
    tagsToRemove.forEach(tag => {
      if (tagsToAdd.includes(tag)) {
        resultSet.add(tag);
      }
    });
    return resultSet;
  };

  const tagsInBothAddAndRemove = getTagsInBothAddAndRemove();

  const hasTagsChanged = (): boolean => {
    return (
      (tagsToAdd.length > 0 || tagsToRemove.length > 0) &&
      tagsInBothAddAndRemove.size === 0
    );
  };

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-tags-cancel"
      style={{ marginRight: 10 }}
      onClick={onCloseModal}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="order-tags-confirm"
      primary
      style={{ marginRight: 10 }}
      disabled={!hasTagsChanged()}
      onClick={onClickValidation}
    />,
  ];

  <OrderMassUpdateProcess
    titleFail={_t('header.titleFail')}
    titleSuccess={_t('header.titleSuccess')}
    titleProgress={_t('header.titleProgress')}
    isOpen={isOpen}
    isAllSettled={isAllSettled}
    isLoadingProgress={isLoadingProgress}
    uploadProgressState={uploadProgressState}
    uploadErrorIds={uploadErrorIds}
    max={Object.keys(selectedOrders).length}
    onCloseModal={onCloseModal}
  />;

  return (
    <Dialog
      title={_t('title')}
      open={isOpen}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      <SelectField
        key="select-order-tags-to-add"
        floatingLabelFixed
        floatingLabelText={_t('field.selectTagsToAdd')}
        fullWidth
        multiple
        onChange={(_e, _i, v) => onChangeTagsByType(v, ACTION_TYPE_ADD)}
        value={tagsToAdd}
      >
        {tags.map(tag => (
          <MenuItem
            id={tag}
            key={tag}
            value={tag}
            primaryText={tag}
            insetChildren
          />
        ))}
      </SelectField>
      <br />
      <SelectField
        key="select-order-tags-to-remove"
        floatingLabelFixed
        floatingLabelText={_t('field.selectTagsToRemove')}
        fullWidth
        multiple
        onChange={(_e, _i, v) => onChangeTagsByType(v, ACTION_TYPE_REMOVE)}
        value={tagsToRemove}
      >
        {tags.map(tag => (
          <MenuItem
            id={tag}
            key={tag}
            value={tag}
            primaryText={tag}
            insetChildren
          />
        ))}
      </SelectField>
      <br />
      <br />
      <p style={{ color: TXT_RED }}>
        {tagsInBothAddAndRemove.size > 0 &&
          _t('error.badSelection', {
            tags: Array.from(tagsInBothAddAndRemove).join(', '),
          })}
      </p>
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderTagsModal);
