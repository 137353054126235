import React from 'react';
import Dialog from 'material-ui/Dialog';
import SuccessIcon from 'material-ui/svg-icons/navigation/check';

import BoButton from 'facade/BoButton';
import { BKG_GREEN } from 'theme';
import './OrderActions.css';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = { isOpen: boolean; title: string; closeModal: () => void };

function OrderMassUpdateSuccess({
  isOpen,
  title,
  closeModal,
}: Props): JSX.Element {
  const action = [
    <BoButton
      key="close"
      label={_tg('action.close')}
      primary
      onClick={closeModal}
    />,
  ];

  return (
    <Dialog
      title={title}
      actions={action}
      modal
      autoScrollBodyContent
      open={isOpen}
    >
      <div style={{ width: 100, height: 100, margin: 'auto' }}>
        <SuccessIcon color={BKG_GREEN} style={{ width: 100, height: 100 }} />
      </div>
    </Dialog>
  );
}

export default OrderMassUpdateSuccess;
