import React, { useState } from 'react';
import { connect } from 'react-redux';

import { MailTypeOrder, ProductPrivateDTO } from '@cvfm-front/tefps-types';
import MailTemplateEditor from 'commons/Mail/MailTemplateEditor/MailTemplateEditor';
import { getConfigState } from 'config/duck';

import '../ProductDetailPage.css';
import ProductDetailSeparator from '../commons/DetailSeparator';

import ProductSelectMailTemplate from './ProductSelectMailTemplate';

const { _t } = window.loadTranslations(__filename);

type ProductMailModalProps = {
  productId: string;
  product: ProductPrivateDTO;
  onChange: (field: string, newValue: unknown) => void;
  smsEnabled: boolean;
};

const ProductMailConfig = ({
  productId,
  product,
  onChange,
  smsEnabled,
}: ProductMailModalProps): JSX.Element => {
  const [selectedMailType, setSelectedMailType] = useState<MailTypeOrder>(
    MailTypeOrder.ORDER_CREATION
  );

  function updateMailType(newType: MailTypeOrder) {
    setSelectedMailType(newType);
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('separator')} />
      <ProductSelectMailTemplate
        selectedMailType={selectedMailType}
        updateSelectedMailType={updateMailType}
        product={product}
        onChange={onChange}
      />
      <MailTemplateEditor
        mailType={selectedMailType}
        resourceId={productId}
        useSubject
        useSms={smsEnabled}
        enableTest
        useHeaderFooter
      />
    </div>
  );
};

export default connect(state => ({
  smsEnabled: getConfigState(state).smsEnabled,
}))(ProductMailConfig);
