import * as React from 'react';

import TimelinePoint from 'commons/TimelinePoint';
import DataBoxComment from 'commons/DataBox/DataBoxComment';
import { ControlAgent } from 'api/commonTypes';
import { OrderTraceStatus } from '@cvfm-front/tefps-types/build/order/OrderTraceStatus';

import OrderDetailTimelineBox from './OrderDetailTimelineBox';

const { _t } = window.loadTranslations(__filename);

type Props = {
  isWarning?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  type: string;
  source: string;
  massAction?: boolean;
  date: string;
  comment?: string;
  message?: string;
  agent?: ControlAgent;
  status?: OrderTraceStatus;
  orderTraceElement: JSX.Element | null;
  downloadPDF?: () => void;
};

const TimelineBlock = ({
  isWarning,
  isFirst,
  isLast,
  type,
  source,
  massAction,
  date,
  comment,
  message,
  agent,
  status,
  downloadPDF,
  orderTraceElement,
}: Props): JSX.Element => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <TimelinePoint isFirst={isFirst} isLast={isLast} style={{ flex: 1 }} />
        <OrderDetailTimelineBox
          type={type}
          source={source}
          massAction={massAction}
          date={date}
          agent={agent}
          status={status}
          orderTraceElement={orderTraceElement}
          isWarning={isWarning}
          downloadPDF={downloadPDF}
        />
        <div style={{ flex: 1 }} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <TimelinePoint filler isLast={isLast} style={{ flex: 1 }} />
        {comment && (
          <DataBoxComment style={{ flex: 10, marginBottom: 60 }}>
            {comment.split('\n').map(function(item, index) {
              return (
                <span key={index}>
                  {item}
                  <br />
                </span>
              );
            })}
          </DataBoxComment>
        )}
        {message && (
          <DataBoxComment
            title={_t('fields.message')}
            style={{ flex: 10, marginBottom: 60 }}
          >
            {message.split('\n').map(function(item, index) {
              return (
                <span key={index}>
                  {item}
                  <br />
                </span>
              );
            })}
          </DataBoxComment>
        )}
        {!comment && !message && <div style={{ flex: 10, marginBottom: 60 }} />}
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

export default TimelineBlock;
