import React from 'react';
import { useParams } from 'react-router-dom';
import FlatButton from 'material-ui/FlatButton';

import { RecourseDecisionDTO } from 'api/recourse/types';
import { getResponseDownloadUrl } from 'api/recourse';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { openNewAuthentifiedTab } from 'api/helpers';

const { _tg } = window.loadTranslations(__filename);

type AutomaticRejectionProps = {
  decision: RecourseDecisionDTO;
  index: number;
};

const AutomaticRejection = ({
  decision,
  index,
}: AutomaticRejectionProps): JSX.Element => {
  const { recourseId } = useParams<{ recourseId: string }>();

  const openPdfUrl = () => {
    const url = getResponseDownloadUrl(recourseId, index);
    openNewAuthentifiedTab(url);
  };

  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.date')}
            value={<Date datetime={decision.datetime} withTime={false} />}
          />
        </DataBoxItem>
        <DataBoxItem>
          <FlatButton onClick={openPdfUrl} primary label={_tg('action.see')} />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default AutomaticRejection;
