import React, { useState, useEffect } from 'react';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';

import useRecourse from 'commons/hooks/useRecourse';
import { Flex } from '@cvfm-front/commons-ui';
import FormComponent from 'commons/FormComponent';
import { PatchObject, UploadFile } from 'api/commonTypes';
import ModalActions from 'tefps/RecoursesV2/commons/ModalActions';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { RecourseFileDTO, RecourseInformationDTO } from 'api/recourse/types';
import { uploadRapoFiles } from 'tefps/Fps/Detail/AddContents/AddRecourse/helpers';
import { getComplementRequestPdfUrl, patchRecourse } from 'api/recourse';
import Services from 'commons/services';
import { openNewAuthentifiedTab } from 'api/helpers';
import PdfButton from 'commons/PdfButton';
import { DatetimePickerCustom } from 'commons/FormComponent/Fields';
import {
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
} from 'tefps/Fps/List/AddFPS/common.styles';
import {
  validateFileSize,
  validateTotalFileSize,
  ACCEPTED_FORMATS,
} from 'tefps/RecoursesV2/utils/recourseFilesUtils';
import RecourseButton from 'facade/RecourseButton';
import { getConfigState } from 'config/duck';
import UploadFileCustomLegacy from 'commons/FormComponent/Fields/UploadFileCustomLegacy';

const { _t } = window.loadTranslations(__filename);

const CompleteRequestAction = ({
  watermarkText,
}: {
  watermarkText: string | null;
}): JSX.Element => {
  const recourseDetails = useRecourse();
  const setMessage = useSnackbar();

  /* Component state */
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [completionDate, setCompletionDate] = useState<Date>(new Date());
  const [completeRequestFiles, setCompleteRequestFiles] = useState<
    Record<string, UploadFile | null | undefined>
  >({});
  const [complementaryInfo, setComplementaryInfo] = useState<string>('');
  const [uploadErrors, setUploadErrors] = useState<{ [p: string]: string }>({});

  useEffect(() => {
    if (recourseDetails?.recourse.complementRequest) {
      const files: Record<string, UploadFile | null | undefined> = {};
      recourseDetails.recourse.complementRequest.files.forEach(file => {
        files[file] = null;
      });
      setCompleteRequestFiles(files);
    }
  }, [recourseDetails]);

  const openPdfUrl = (): void => {
    if (recourseDetails) {
      const url = getComplementRequestPdfUrl(recourseDetails.recourse.rapoId);
      openNewAuthentifiedTab(url);
    }
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const validateTotalSize = (
    file: UploadFile | null | undefined
  ): string | null | undefined => {
    const totalSize = Object.keys(completeRequestFiles)
      .map(value => completeRequestFiles[value]?.size ?? 0)
      .reduce((total, size) => total + size, 0);
    return validateTotalFileSize(file, totalSize);
  };

  const onChangeDate = (newDate: Date | null | undefined): void => {
    if (newDate) {
      setCompletionDate(newDate);
    }
  };

  const onValidate = async () => {
    if (!recourseDetails) {
      return;
    }
    try {
      setLoading(true);
      const value: RecourseInformationDTO = {
        completionDate: new Date(),
        complementComment: complementaryInfo,
      };
      const patches: Array<PatchObject<
        RecourseInformationDTO | RecourseFileDTO[]
      >> = [
        {
          path: '/information',
          op: 'replace',
          value,
        },
      ];

      // Init upload
      const recourseFiles: Array<RecourseFileDTO> = [];
      // Upload files (First param should be mapped before)
      const filesResponse = await uploadRapoFiles(
        Object.assign(
          {},
          ...Object.keys(completeRequestFiles).map(x => ({
            [x]: completeRequestFiles[x],
          }))
        ),
        recourseDetails.recourse.rapoId,
        watermarkText,
        recourseFiles
      );

      if (Object.keys(filesResponse).length > 0) {
        setUploadErrors(filesResponse);
        return;
      }

      /* Adding recourse files by agent */
      patches.push({
        path: '/files',
        op: 'add',
        value: recourseFiles,
      });
      await patchRecourse(recourseDetails.recourse.rapoId, patches);
      setMessage(_t('submit.success'));
      // Close modal
      handleModal();
      // Update recourse details
      await Services.recourse.init(recourseDetails.recourse.rapoId);
    } catch (e) {
      setMessage(_t('submit.error'));
    } finally {
      setLoading(false);
    }
  };

  const onChangeFile = (
    fileId: string,
    newValue: UploadFile | null | undefined
  ) => {
    completeRequestFiles[fileId] = newValue;
    setCompleteRequestFiles(completeRequestFiles);
  };

  return (
    <>
      <RecourseButton
        label={_t('button.title.completeRequest')}
        backgroundColor="var(--bg-color--pink)"
        labelColor="var(--white)"
        onClick={handleModal}
      />
      <Dialog
        open={open}
        title={_t('modal.title')}
        titleClassName="modal-form__title"
        bodyClassName="modal-form"
        actions={
          !loading ? ModalActions({ onValidate, onClose: handleModal }) : []
        }
        onRequestClose={handleModal}
      >
        <Flex flexDirection="column">
          <Flex justifyContent="space-between">
            <div className="modal-form__advice">
              {_t('modal.agentComment')}
              {recourseDetails?.recourse.complementRequest?.complementInfo}
            </div>
            <div>
              <PdfButton openPdfUrl={openPdfUrl} />
            </div>
          </Flex>
          <FormComponent>
            <DatetimePickerCustom
              id="completionDate"
              onChange={onChangeDate}
              name={_t('modal.completionDate')}
              hintDate={_t('modal.completionDate')}
              hintHour={_t('modal.completionTime')}
              defaultValue={completionDate}
              styleContainer={STYLE_INPUTS_CONTAINER}
              styleField={STYLE_FIELD}
              mandatory
            />
            <Flex flexWrap="wrap" overflow="hidden">
              {Object.keys(completeRequestFiles).map(file => {
                return (
                  <UploadFileCustomLegacy
                    key={file}
                    name={file}
                    hint={file}
                    value={completeRequestFiles[file]}
                    onChange={(newFile: UploadFile) => {
                      onChangeFile(file, newFile);
                    }}
                    validators={[validateFileSize, validateTotalSize]}
                    error={uploadErrors && uploadErrors[file]}
                    accept={ACCEPTED_FORMATS}
                  />
                );
              })}
            </Flex>
          </FormComponent>

          <TextField
            floatingLabelText={_t('modal.complementaryInfo.title')}
            className="form__comment"
            onChange={(_e, newValue) => setComplementaryInfo(newValue)}
            value={complementaryInfo}
            multiLine
            rows={5}
            rowsMax={5}
          />
        </Flex>
      </Dialog>
    </>
  );
};

export default connect(state => {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
})(CompleteRequestAction);
