import moment from 'moment/moment';

import { RecourseDTO, RecourseStatus } from 'api/recourse/types';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { Pager, SortParameters } from 'api/commonTypes';
import { RapoFiltersParam } from '@cvfm-front/tefps-types';

const disabledStatus: RecourseStatus[] = ['ACCEPTED', 'INCORRECT', 'REFUSED'];

const { _t } = window.loadTranslations(__filename);

export function isDisabledStatus(status: RecourseStatus): boolean {
  return disabledStatus.includes(status);
}

export function isProposalOpen(recourse: RecourseDTO): boolean {
  return (
    !disabledStatus.includes(recourse.status) &&
    recourse.proposals.length > 0 &&
    !recourse.proposals[recourse.proposals.length - 1].decision &&
    !(
      recourse.proposals[recourse.proposals.length - 1].proposal.waiting &&
      !recourse.proposals[recourse.proposals.length - 1].decision
    )
  );
}

export function isOnHold(recourse: RecourseDTO): boolean {
  return (
    recourse.proposals.length > 0 &&
    recourse.proposals[recourse.proposals.length - 1].proposal.waiting &&
    !recourse.proposals[recourse.proposals.length - 1].decision
  );
}

// The key should be the same as the id in RapoExportColumnsConfig in the back.
export const getExportCols = (
  sendingRecourseLetterEnabled: boolean
): { key: string; label: string; checked?: boolean }[] => {
  const cols = [
    { key: 'rapoId', label: _t('exportCols.rapoId'), checked: true },
    { key: 'rapoNumber', label: _t('exportCols.rapoNumber'), checked: true },
    {
      key: 'received',
      label: _t('exportCols.received'),
      checked: true,
    },
    { key: 'created', label: _t('exportCols.created') },
    { key: 'fpsId', label: _t('exportCols.fpsId'), checked: true },
    {
      key: 'reason',
      label: _t('exportCols.reason'),
      checked: true,
    },
    { key: 'fpsPrice', label: _t('exportCols.fpsPrice'), checked: true },
    {
      key: 'status',
      label: _t('exportCols.status'),
      checked: true,
    },
    { key: 'cityReason', label: _t('exportCols.cityReason') },
    {
      key: 'agentId',
      label: _t('exportCols.agentId'),
    },
    { key: 'agentName', label: _t('exportCols.agentName') },
    {
      key: 'comment',
      label: _t('exportCols.comment'),
    },
    { key: 'agentComment', label: _t('exportCols.agentComment') },
    {
      key: 'propositionDate',
      label: _t('exportCols.propositionDate'),
    },
    { key: 'zip', label: _t('exportCols.zip') },
    {
      key: 'agentIdValidate',
      label: _t('exportCols.agentIdValidate'),
    },
    {
      key: 'validatorAgentName',
      label: _t('exportCols.validatorAgentName'),
    },
    { key: 'validationDate', label: _t('exportCols.validationDate') },
    {
      key: 'cessionFpsId',
      label: _t('exportCols.cessionFpsId'),
    },
    { key: 'processingPeriod', label: _t('exportCols.processingPeriod') },
    { key: 'labels', label: _t('exportCols.labels') },
    { key: 'recourseHistory', label: _t('exportCols.recourseHistory') },
    {
      key: 'complementRequestCreationDate',
      label: _t('exportCols.complementRequestCreationDate'),
    },
    {
      key: 'complementRequestCompletionDate',
      label: _t('exportCols.complementRequestCompletionDate'),
    },
    {
      key: 'userFirstName',
      label: _t('exportCols.firstName'),
    },
    {
      key: 'userLastName',
      label: _t('exportCols.lastName'),
    },
    {
      key: 'plate',
      label: _t('exportCols.plate'),
    },
    {
      key: 'antaiNotificationDatetime',
      label: _t('exportCols.antaiNotificationDatetime'),
    },
  ];

  if (sendingRecourseLetterEnabled) {
    cols.splice(7, 0, {
      key: 'postalStatus',
      label: _t('exportCols.postalStatus'),
      checked: true,
    });
  }

  return cols;
};

export type RecourseSearchCriterias = {
  statuses: Set<string>;
  postalStatuses: Set<string>;
  reasons: Set<string>;
  cityReasons: Set<string>;
  tags: Set<string>;
  sources: Set<string>;
  creationDate: FilterDate;
  receptionDate: FilterDate;
  agentId: string | null | undefined;
  fpsId: string | null | undefined;
  rapoNumber: string | null | undefined;
  userName: string | null | undefined;
  comment: string | null | undefined;
  plate: string | null | undefined;
  agentComment: string | null | undefined;
  fpsStatementDatetime: FilterDate;
  decisionDatetime: FilterDate;
  antaiNotificationDatetime: FilterDate;
};

function getSortFieldText(sortField: number) {
  switch (sortField) {
    case 0:
      return 'rapoNumber';
    case 1:
      return 'fpsId';
    case 2:
      return 'plate';
    case 5:
      return 'fpsPrice';
    default:
      return 'endDecisionDate';
  }
}

export const filtersToRequest = (
  filters: RecourseSearchCriterias,
  sort?: SortParameters,
  pager?: Pager
): RapoFiltersParam => {
  const {
    statuses,
    postalStatuses,
    reasons,
    cityReasons,
    creationDate,
    receptionDate,
    agentId,
    fpsId,
    rapoNumber,
    userName,
    plate,
    comment,
    agentComment,
    tags,
    fpsStatementDatetime,
    antaiNotificationDatetime,
    sources,
    decisionDatetime,
  } = filters;
  const { page, maxRecords } = pager || { page: 0, maxRecords: 0 };
  const { from: creationFrom, to: creationTo } = creationDate || {
    from: undefined,
    to: undefined,
  };
  const { from: receptionFrom, to: receptionTo } = receptionDate || {
    from: undefined,
    to: undefined,
  };
  const { from: fpsFrom, to: fpsTo } = fpsStatementDatetime || {
    from: undefined,
    to: undefined,
  };
  const { from: decisionFrom, to: decisionTo } = decisionDatetime || {
    from: undefined,
    to: undefined,
  };
  const { from: antaiFrom, to: antaiTo } = antaiNotificationDatetime || {
    from: undefined,
    to: undefined,
  };

  return {
    statuses: Array.from(statuses),
    postalStatuses: Array.from(postalStatuses),
    reasons: Array.from(reasons),
    cityReasons: Array.from(cityReasons),
    tags: Array.from(tags),
    sources: Array.from(sources),
    startCreationDatetime: creationFrom ? moment(creationFrom).unix() : null,
    endCreationDatetime: creationTo ? moment(creationTo).unix() : null,
    startReceptionDatetime: receptionFrom ? moment(receptionFrom).unix() : null,
    endReceptionDatetime: receptionTo ? moment(receptionTo).unix() : null,
    startDecisionDatetime: decisionFrom ? moment(decisionFrom).unix() : null,
    endDecisionDatetime: decisionTo ? moment(decisionTo).unix() : null,
    agentId,
    fpsId,
    rapoNumber: rapoNumber ? parseInt(rapoNumber, 10) : null,
    userName,
    plate,
    comment,
    agentComment,
    startFpsStatementDatetime: fpsFrom ? moment(fpsFrom).unix() : null,
    endFpsStatementDatetime: fpsTo ? moment(fpsTo).unix() : null,
    startAntaiNotificationDatetime: antaiFrom ? moment(antaiFrom).unix() : null,
    endAntaiNotificationDatetime: antaiTo ? moment(antaiTo).unix() : null,
    page,
    maxRecords,
    sortOrder: sort?.increasingOrder ? 'ASC' : 'DESC',
    sortField: sort ? getSortFieldText(sort.sortField) : 'endDecisionDate',
  };
};

export const initialFilters = (plate?: string): RecourseSearchCriterias => ({
  statuses: new Set(),
  postalStatuses: new Set(),
  reasons: new Set(),
  cityReasons: new Set(),
  tags: new Set(),
  sources: new Set(),
  creationDate: {
    from: moment()
      .subtract(6, 'months')
      .startOf('day')
      .toDate(),
    to: undefined,
  },
  receptionDate: {
    from: moment()
      .subtract(6, 'months')
      .startOf('day')
      .toDate(),
    to: undefined,
  },
  agentId: null,
  fpsId: null,
  rapoNumber: null,
  userName: null,
  plate,
  comment: null,
  agentComment: null,
  fpsStatementDatetime: {
    from: undefined,
    to: undefined,
  },
  decisionDatetime: {
    from: undefined,
    to: undefined,
  },
  antaiNotificationDatetime: {
    from: undefined,
    to: undefined,
  },
});
