import React, { useState } from 'react';
import { IconButton } from 'material-ui';
import BackwardIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import ForwardIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';

import { FnmsMediaDTO } from 'api/fps/types';
import { Flex } from '@cvfm-front/commons-ui';

import './FpsSummaryPhotos.css';

type FpsSummaryPhotosProps = {
  images: FnmsMediaDTO[];
};

const { _t } = window.loadTranslations(__filename);

const FpsSummaryPhotos = ({ images }: FpsSummaryPhotosProps): JSX.Element => {
  const [contextImages] = useState<FnmsMediaDTO[]>(images);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const unique = contextImages.length <= 1;

  const handleLeftCarouselCLick = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(contextImages.length - 1);
    } else setSelectedIndex(selectedIndex - 1);
  };

  const handleRightCarouselCLick = () => {
    if (selectedIndex === contextImages.length - 1) {
      setSelectedIndex(0);
    } else setSelectedIndex(selectedIndex + 1);
  };

  const dataSource = contextImages[selectedIndex]
    ? contextImages[selectedIndex].contentUrl
    : null;

  return (
    <Flex alignItems="center" className="fps-photos">
      {!unique && (
        <IconButton
          onClick={handleLeftCarouselCLick}
          iconStyle={{ width: 50, height: 50 }}
        >
          <BackwardIcon />
        </IconButton>
      )}
      <Flex flexDirection="column">
        {dataSource && (
          <>
            <img src={dataSource} alt="fps" />
            <a
              href={dataSource}
              target="_blank"
              rel="noreferrer"
              className="link-photo"
            >
              {_t('zoom')}
            </a>
          </>
        )}
      </Flex>
      {!unique && (
        <IconButton
          onClick={handleRightCarouselCLick}
          iconStyle={{ width: 50, height: 50 }}
        >
          <ForwardIcon />
        </IconButton>
      )}
    </Flex>
  );
};

export default FpsSummaryPhotos;
