import React from 'react';

import { RecourseCommentDTO, RecourseDTO } from 'api/recourse/types';
import Date from 'commons/Date';
import Tag from 'commons/Tag';
import { getAgentCommentFiles } from 'tefps/RecoursesV2/utils/recourseFilesUtils';

import FilesContainer from '../FilesContainer';

import './Comment.css';

const { _t, Trans } = window.loadTranslations(__filename);

type CommentProps = {
  comment: RecourseCommentDTO;
  recourse: RecourseDTO;
};

const Comment = ({ comment, recourse }: CommentProps): JSX.Element => {
  const files = getAgentCommentFiles(recourse, comment);
  let translation = _t('tag');
  if (comment.text) {
    translation = _t('comment');
  }
  return (
    <div className="recourse-timeline__block">
      <div className="comment">
        {files.length > 0 ? _t('file') : translation}
        <Trans
          i18nKey="details"
          values={{ agent: comment.agent.name }}
          components={[
            <span style={{ fontWeight: 'bold' }} />,
            <Date
              datetime={comment.dateCreated}
              withTime={false}
              style={{ fontWeight: 'bold' }}
            />,
            <Date
              datetime={comment.dateCreated}
              withDate={false}
              style={{ fontWeight: 'bold' }}
            />,
          ]}
        />
      </div>
      <div className="comment__tags-container">
        {comment.addedTags.map((tag, i) => (
          <Tag key={i} tag={tag} />
        ))}
        <span className="comment__deleted-tags">
          {comment.removedTags.map((tag, i) => (
            <Tag key={i} tag={tag} />
          ))}
        </span>
      </div>
      {recourse && (
        <FilesContainer
          created={recourse.created}
          files={files}
          reason={recourse.reason}
          rapoId={recourse.rapoId}
        />
      )}
    </div>
  );
};

export default Comment;
