import * as React from 'react';
import { connect } from 'react-redux';

import { FpsEligibility, SivVehicleDetails } from '@cvfm-front/tefps-types';
import { BKG_LIGHT_BLUE } from 'theme';

import {
  getConfigState,
  ModulesConfiguration,
} from '../../../../../config/duck';

import ComputeFilterAndRedirect from './ComputeFilterAndRedirect';
import EasyParkEligibilitiesModal from './EasyParkEligibilitiesModal';
import STYLE from './style';

import './style.css';
import SivVehicleDetailsModal from './SivVehicleDetailsModal';

const { _tg } = window.loadTranslations(__filename);

const LinkBottomContent = ({
  plate,
  date,
  modulesConfiguration,
  fpsEligibilities,
  sivVehicle,
}: {
  plate: string;
  date: string;
  modulesConfiguration: ModulesConfiguration;
  fpsEligibilities?: Array<FpsEligibility>;
  sivVehicle?: SivVehicleDetails;
}) => {
  const [openEligibilitiesModal, setOpenEligibilitiesModal] = React.useState<
    boolean
  >(false);
  const [
    openSivVehicleDetailsModal,
    setOpenSivVehicleDetailsModal,
  ] = React.useState<boolean>(false);

  const fpsEnabled = modulesConfiguration.fps.enabled;
  const tvEnabled = modulesConfiguration.tv.enabled;
  // Paris only
  const fpsEligibilitiesEnabled =
    !tvEnabled && !!fpsEligibilities && !!fpsEligibilities?.length;

  const subscribersEnabled = modulesConfiguration.subscribers.enabled;

  return (
    <div style={STYLE.LINK_CONTAINER}>
      {'Voir pour cette immatriculation : '}
      {fpsEnabled && (
        <ComputeFilterAndRedirect
          type="FPS"
          label={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.fps'
          )}
          plate={plate}
          date={null}
        />
      )}
      {tvEnabled && (
        <ComputeFilterAndRedirect
          type="SUBSCRIPTION"
          label={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.eligibilities'
          )}
          plate={plate}
          date={null}
        />
      )}
      {subscribersEnabled && (
        <ComputeFilterAndRedirect
          type="AD_ELIGIBILITIES"
          label={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.subscription'
          )}
          plate={plate}
          date={null}
        />
      )}
      {fpsEligibilitiesEnabled && (
        <span
          onClick={() => setOpenEligibilitiesModal(true)}
          style={{
            fontSize: 12,
            fontWeight: 'normal',
            color: BKG_LIGHT_BLUE,
            cursor: 'pointer',
          }}
        >
          {_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.eligibilities'
          )}
        </span>
      )}
      {tvEnabled && (
        <ComputeFilterAndRedirect
          type="TV"
          label={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.parkingRights'
          )}
          plate={plate}
          date={date}
        />
      )}
      {!!sivVehicle && (
        <span
          onClick={() => setOpenSivVehicleDetailsModal(true)}
          style={{
            fontSize: 12,
            fontWeight: 'normal',
            color: BKG_LIGHT_BLUE,
            cursor: 'pointer',
          }}
        >
          {_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.sivDetails'
          )}
        </span>
      )}
      <ComputeFilterAndRedirect
        type="RAPO"
        label={_tg(
          'tefps.recourseV2.pages.Detail.FpsBlock.LinkBottomContent.recourse'
        )}
        plate={plate}
        date={null}
      />
      {openEligibilitiesModal && fpsEligibilitiesEnabled && (
        <EasyParkEligibilitiesModal
          open={openEligibilitiesModal}
          setOpen={setOpenEligibilitiesModal}
          fpsEligibilities={fpsEligibilities || []}
        />
      )}
      {openSivVehicleDetailsModal && !!sivVehicle && (
        <SivVehicleDetailsModal
          open={openSivVehicleDetailsModal}
          setOpen={setOpenSivVehicleDetailsModal}
          sivVehicle={sivVehicle}
        />
      )}
    </div>
  );
};

export default connect(state => {
  const { modulesConfiguration } = getConfigState(state);
  return {
    modulesConfiguration,
  };
})(LinkBottomContent);
