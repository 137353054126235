import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { IndexRange } from 'react-virtualized';
import { useRouteMatch } from 'react-router-dom';
import Checkbox from 'material-ui/Checkbox';

import BoButton from 'facade/BoButton';
import {
  OrderEsPrivateDTO,
  OrderMassUpdateMap,
  OrderStatus,
  OrderEsSearchQueryDTO,
} from '@cvfm-front/tefps-types';
import { OrderEsSubscriber } from '@cvfm-front/tefps-types/build/order/OrderEsSubscriber';
import SimpleTable from 'commons/SimpleTable';
import { renderPlates } from 'commons/Utils/plateUtils';
import { formatDatetime } from 'commons/Date';

import './OrderTablePage.css';

import { translateOrderStatus } from './OrderStatusTranslator';
import { AWAIT_DECISION_STATUSES } from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderTableProps = {
  pagedOrders: Array<unknown>;
  totalOrders: number;
  filters: OrderEsSearchQueryDTO;
  sortIndex: number;
  sortAscending: boolean;
  onSort: (colIndex: number, ascending: boolean, field: string) => void;
  onRange: (fromIndex: number) => Promise<void>;
  selectedOrder: OrderMassUpdateMap;
  setSelectedOrders: Dispatch<SetStateAction<OrderMassUpdateMap>>;
  multipleMode: boolean;
};

const OrderTable = ({
  pagedOrders: orders,
  totalOrders: ordersCount,
  filters,
  sortIndex,
  sortAscending,
  onSort,
  onRange,
  selectedOrder,
  setSelectedOrders,
  multipleMode,
}: OrderTableProps): JSX.Element => {
  const { url } = useRouteMatch();

  const handleTableSort = (
    colIndex: number,
    ascending?: boolean,
    sortField?: string
  ) => {
    if (ascending !== undefined && ascending !== null && sortField) {
      onSort(colIndex, ascending, sortField);
    }
  };

  const handleTableRange = (range: IndexRange): Promise<void> => {
    return onRange(range.startIndex);
  };

  const canShowNbDaysSinceLastModificationCol = (): boolean => {
    return !!filters.orderStatuses?.every(filteredStatus =>
      AWAIT_DECISION_STATUSES.includes(filteredStatus)
    );
  };

  const getHeaderCols = () => {
    const headerCols = [
      {
        label: _t('table.header.subscriberName'),
        width: 150,
        grow: 2,
        sortId: 'firstSubscriberName.raw',
        onSort: true,
      },
      {
        label: _t('table.header.status'),
        width: 50,
        grow: 1,
        sortId: 'orderStatus',
        onSort: true,
      },
      {
        label: _t('table.header.plates'),
        width: 140,
        grow: 1,
        sortId: 'firstRawPlate',
        onSort: true,
      },
      {
        label: _t('table.header.productName'),
        width: 105,
        grow: 1,
        sortId: 'productName',
        onSort: true,
      },
      {
        label: _t('table.header.periodStart'),
        width: 70,
        grow: 1,
        sortId: 'startOfValidity',
        onSort: true,
      },
      {
        label: _t('table.header.periodEnd'),
        width: 70,
        grow: 1,
        sortId: 'endOfValidity',
        onSort: true,
      },
    ];
    if (canShowNbDaysSinceLastModificationCol()) {
      headerCols.push({
        label: _t('table.header.nbDaysSinceLastModification'),
        width: 70,
        grow: 1,
        sortId: 'modificationDatetime',
        onSort: true,
      });
    }
    headerCols.push({
      label: '',
      width: 80,
      grow: 1,
      onSort: false,
      sortId: '',
    });
    if (multipleMode) {
      headerCols.unshift({
        label: _t('table.header.selection'),
        width: 70,
        grow: 1,
        sortId: 'selected',
        onSort: false,
      });
    }
    return headerCols;
  };

  const onCheck = (event: React.ChangeEvent<any>, checked: boolean): void => {
    const {
      rowOrderId,
      rowOrderStartOfValidity,
      rowOrderEndOfValidity,
      rowOrderTags,
      rowOrderStatus,
      rowOrderSubscribersIds,
    } = event.target.dataset;

    setSelectedOrders(selected => {
      if (checked) {
        selected[rowOrderId as string] = {
          startOfValidity: rowOrderStartOfValidity as string,
          endOfValidity: rowOrderEndOfValidity as string,
          tags: rowOrderTags as string[],
          orderStatus: rowOrderStatus as OrderStatus,
          subscribersIds: rowOrderSubscribersIds as string[],
        };
      } else {
        delete selected[rowOrderId as string];
      }
      return { ...selected };
    });
  };

  const renderSubscriberNameCell = (order: OrderEsPrivateDTO): JSX.Element => {
    return (
      <>
        <a
          id={`${order.orderId}-id`}
          className="order-table-row_id"
          href={`#${url}/${order.orderId}`}
        >
          {order.subscribers
            .map(
              (subscriber: OrderEsSubscriber) =>
                `${subscriber.subscriberLastName} ${subscriber.subscriberFirstName}`
            )
            .join(', ')}
        </a>
      </>
    );
  };

  const renderStatusCell = (order: OrderEsPrivateDTO): JSX.Element => {
    return (
      <span id={`${order.orderId}-status`} className="order-table-row">
        {translateOrderStatus(
          order.orderStatus,
          order.startOfValidity,
          order.endOfValidity
        )}
      </span>
    );
  };

  const renderDatetimeFieldCell = (
    order: OrderEsPrivateDTO,
    field: string
  ): JSX.Element => {
    const isoDatetimeStrToRender = order[field] as string;

    return (
      <span id={`${order.orderId}-${field}`} className="order-table-row">
        {formatDatetime({
          datetime: isoDatetimeStrToRender,
          withTime: false,
        })}
      </span>
    );
  };

  const renderTextFieldCell = (
    order: OrderEsPrivateDTO,
    field: string
  ): JSX.Element => {
    return (
      <span
        id={`${order.orderId}-${field}`}
        className="order-table-row"
        title={order[field]}
        style={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {order[field] as string}
      </span>
    );
  };

  const renderTextArrayFieldCell = (
    order: OrderEsPrivateDTO,
    field: string
  ): JSX.Element => {
    const textArraytoRender = order[field] as Array<string>;
    return (
      <span
        id={`${order.orderId}-${field}`}
        className="order-table-row"
        title={renderPlates(textArraytoRender)}
      >
        {renderPlates(textArraytoRender, 3)}
      </span>
    );
  };

  const renderDetailCell = (order: OrderEsPrivateDTO): JSX.Element => {
    return (
      <span id={`${order.orderId}-detail`} className="order-table-row_delete">
        <BoButton
          primary
          label={_tg('field.detail')}
          data-id={order.orderId}
          href={`#${url}/${order.orderId}`}
        />
      </span>
    );
  };

  const renderSelectionCell = (order: OrderEsPrivateDTO): JSX.Element => {
    return (
      <Checkbox
        checked={!!selectedOrder[order.orderId]}
        onCheck={onCheck}
        data-row-order-id={order.orderId}
        data-row-order-start-of-validity={order.startOfValidity}
        data-row-order-end-of-validity={order.endOfValidity}
        data-row-order-tags={order.tags}
        data-row-order-status={order.orderStatus}
        data-row-order-subscribers-ids={order.subscribers.map(subscriber => [
          1,
        ])}
      />
    );
  };

  const renderRow = (order: OrderEsPrivateDTO) => {
    const rows = [
      renderSubscriberNameCell(order),
      renderStatusCell(order),
      renderTextArrayFieldCell(order, 'rawPlates'),
      renderTextFieldCell(order, 'productName'),
      renderDatetimeFieldCell(order, 'startOfValidity'),
      renderDatetimeFieldCell(order, 'endOfValidity'),
    ];
    if (canShowNbDaysSinceLastModificationCol()) {
      rows.push(renderTextFieldCell(order, 'nbDaysSinceLastModification'));
    }
    rows.push(renderDetailCell(order));
    if (multipleMode) {
      rows.unshift(renderSelectionCell(order));
    }
    return rows;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={orders}
      remoteRowCount={ordersCount}
      loadMoreRows={handleTableRange}
      onSort={handleTableSort}
      colSorted={sortIndex}
      sortOrder={sortAscending}
    />
  );
};

export default OrderTable;
