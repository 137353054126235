import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';
import {
  DateRangeDTO,
  OrderEsPrivateDTO,
  OrderEsSearchQueryDTO,
  OrderEsSearchResultDTO,
  OrderMassUpdateMap,
  OrderSortParameters,
  OrderStatus,
  ProductPrivateDTO,
  ProductSearchQueryDTO,
  ProductType,
  SortOrder,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { CustomFieldDTO } from 'api/pricing/types';
import { fetchZoning } from 'api/pricing';
import { searchProducts } from 'api/cvfm-core-subscription/product';
import { fetchOrderCustomFields } from 'api/cvfm-core-subscription/order';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import loadQueryParamFilters from 'commons/helpers/loadQueryParamFilters';
import { fetchTags, TagsTypes } from 'api/tags';

import OrderTableSidebar from './OrderTableSidebar';
import SubscriptionNavigation from './SubscriptionNavigation';
import OrderTable from './OrderTable';
import './OrderTablePage.css';
import OrderExport from './OrderExport';
import OrderImport from './OrderImport';
import OrderActions from './OrderActions';
import OrderFilterService from './OrderFilterService';

type OrderTablePageProps = {
  userInfo: InternalAgent | null | undefined;
  productType: ProductType;
  statuses: Array<OrderStatus>;
  validityEnd: DateRangeDTO;
  pageSort?: OrderSortParameters;
  canMultipleActions: boolean;
};

const OrderTablePage = ({
  userInfo,
  productType,
  validityEnd,
  statuses,
  pageSort,
  canMultipleActions,
}: OrderTablePageProps): JSX.Element => {
  const location = useLocation();
  const locationState: OrderEsSearchQueryDTO = location.state as OrderEsSearchQueryDTO;

  const defaultQuery: OrderEsSearchQueryDTO = {
    productTypes: [productType],
    orderStatuses: locationState?.orderStatuses
      ? [...locationState.orderStatuses]
      : [...statuses],
    validityEnd: locationState?.validityEnd
      ? { ...locationState.validityEnd }
      : { ...validityEnd },
  };

  const initialQuery: OrderEsSearchQueryDTO = defaultQuery;

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(true);
  const [zones, setZones] = useState<Array<ZoneDTO>>();
  const [tags, setTags] = useState<Array<string>>([]);
  const [customFields, setCustomFields] = useState<Array<CustomFieldDTO>>([]);

  const [products, setProducts] = useState<Array<ProductPrivateDTO>>();
  const { searchPageMaxRecords } = OrderFilterService;
  const [sortParameters, setSortParameters] = useState<OrderSortParameters>(
    pageSort || {
      page: 0,
      index: 0,
      sortOrder: SortOrder.ASC,
      field: undefined,
    }
  );
  const [searchResults, setSearchResults] = useState<OrderEsSearchResultDTO>();
  const [orders, setOrders] = useState<Array<OrderEsPrivateDTO>>([]);
  const [selectedOrders, setSelectedOrders] = useState<OrderMassUpdateMap>({});
  const [multipleMode, setMultipleMode] = useState<boolean>(false);

  async function loadPage() {
    try {
      setLoading(true);

      await OrderFilterService.initAgentTeamSubscriptionConfiguration(userInfo);

      loadQueryParamFilters(location.search, (filters: { plate: string }) => {
        const { plate } = filters;
        const setupFilters = { ...initialQuery };
        if (plate !== undefined) {
          setupFilters.plates = [plate];
        }
        OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
          // combination of prevFilters, setupFilters, agentTeamsFilters
          return OrderFilterService.addAgentTeamFilters({
            ...prevFilters,
            ...setupFilters,
          });
        });
      });
      OrderFilterService.updateSortParameters(
        oldParams => oldParams || sortParameters
      );
      const loadedZones = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
      setZones(loadedZones.zones);

      const loadedTags = await fetchTags(
        TagsTypes.order,
        userInfo?.worksFor?.organizationId,
        false
      );
      setTags(loadedTags);

      const customFields = await fetchOrderCustomFields();
      setCustomFields(customFields);

      const loadedProducts = await searchProducts({
        productType,
        activeOnly: true,
      } as ProductSearchQueryDTO);
      setProducts(loadedProducts);
    } catch (err) {
      setError(err as Error);
    }
    setLoading(false);
    setSearching(false);
  }

  function moreOrders(searchPage: number): void {
    try {
      setSearching(true);
      OrderFilterService.moreOrders(searchPage);
    } catch (err) {
      setError(err as Error);
    } finally {
      setSearching(false);
    }
  }

  function onTableSort(index: number, ascending: boolean, field: string) {
    const sortOrder = ascending ? SortOrder.ASC : SortOrder.DESC;
    try {
      setSearching(true);
      OrderFilterService.updateSortParameters(
        (prevFilters: OrderSortParameters) => {
          return { ...prevFilters, index, sortOrder, field };
        }
      );
    } catch (err) {
      setError(err as Error);
    } finally {
      setSearching(false);
    }
  }

  async function onTableRange(fromIndex: number): Promise<void> {
    const nextPage = Math.floor(fromIndex / searchPageMaxRecords);
    moreOrders(nextPage);
    return Promise.resolve();
  }

  useEffect(() => {
    // Load page once
    void loadPage();
    return OrderFilterService.watchResults(setSearchResults);
  }, []);

  useEffect(() => {
    return OrderFilterService.watchSortParameter(setSortParameters);
  }, []);

  useEffect(() => {
    return OrderFilterService.watchOrders(setOrders);
  }, []);

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock message="Error: " error={error} />
      </FlexCenter>
    );
  }

  return (
    <div className="order-page_container">
      <OrderTableSidebar
        defaultFilters={defaultQuery}
        searchResults={searchResults}
        zones={zones || []}
        tags={tags || []}
        customFields={customFields}
        products={products || []}
        productType={productType}
      />
      <div className="order-page_content">
        <SubscriptionNavigation />
        <div className="order-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '90%',
            }}
          >
            <ListBody loading={loading || searching}>
              <OrderTable
                pagedOrders={orders}
                totalOrders={searchResults?.totalHits || 0}
                filters={OrderFilterService.getFilters()}
                onSort={onTableSort}
                sortIndex={sortParameters.index}
                sortAscending={sortParameters.sortOrder === SortOrder.ASC}
                onRange={onTableRange}
                selectedOrder={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                multipleMode={multipleMode}
              />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              <OrderExport
                disabled={!orders || orders.length === 0}
                filters={OrderFilterService.getFilters()}
                productType={productType}
              />
              <OrderImport style={{ marginLeft: 10 }} />
              {canMultipleActions && (
                <OrderActions
                  multipleMode={multipleMode}
                  setMultipleMode={setMultipleMode}
                  selectedOrders={selectedOrders}
                  setSelectedOrders={setSelectedOrders}
                  totalOrders={searchResults?.totalHits || 0}
                  tags={tags}
                  filters={OrderFilterService.getFilters()}
                />
              )}
            </ListBottom>
          </ListWrapper>
        </div>
      </div>
    </div>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
    canMultipleActions:
      !!userInfo && userInfo.rights.includes('ORDER_MASS_ACTION'),
  };
})(OrderTablePage);
