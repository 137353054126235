import * as React from 'react';
import { connect } from 'react-redux';

import { OrderEsSearchQueryDTO, ProductType } from '@cvfm-front/tefps-types';
import Exporter from 'commons/Exporter';
import { MAX_RECORDS } from 'commons/const';
import { getApiState, InternalApiState } from 'api/duck';
import { getOrderExport } from 'api/cvfm-core-subscription/order';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';

import './OrderTablePage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderExportReduxProps = {
  canExport: boolean;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  langEnabled: boolean;
};

type OrderExportProps = {
  disabled: boolean;
  filters: OrderEsSearchQueryDTO;
  productType: ProductType;
} & OrderExportReduxProps;

const OrderExport = ({
  canExport,
  filters,
  disabled,
  productType,
  authorizedVehicleCategories,
  langEnabled,
}: OrderExportProps): JSX.Element => {
  const EXPORT_COL: Array<{
    key: string;
    label: string;
    checked: boolean;
  }> = [
    {
      key: 'id',
      label:
        productType === ProductType.BUNDLE
          ? _t('field.subscriptionId.bundle')
          : _t('field.subscriptionId.eligibility'),
      checked: true,
    },
    { key: 'subscriberId', label: _t('subscriberId'), checked: true },
    {
      key: 'plate',
      label: _tg('field.vehicle.licensePlate_maybe_plural'),
      checked: true,
    },
    {
      key: 'startOfValidity',
      label: _tg('field.date.startValidity'),
      checked: true,
    },
    {
      key: 'endOfValidity',
      label: _tg('field.date.endValidity'),
      checked: true,
    },
    {
      key: 'productId',
      label: _tg('field.subscription.productId'),
      checked: true,
    },
    {
      key: 'productOptionId',
      label: _tg('field.subscription.productOptionId'),
      checked: true,
    },
    {
      key: 'validityAreas',
      label: _tg('field.address.validityZones'),
      checked: true,
    },
    { key: 'status', label: _tg('field.status'), checked: true },
    {
      key: 'phoneNumber',
      label: _tg('field.human.phoneNumber_short'),
      checked: false,
    },
    { key: 'address', label: _tg('field.address.address'), checked: false },
    { key: 'firstName', label: _tg('field.human.firstname'), checked: false },
    { key: 'name', label: _tg('field.human.lastname_short'), checked: false },
    { key: 'email', label: _tg('field.human.email'), checked: false },
    { key: 'customFields', label: _tg('field.customFields'), checked: false },
    ...(authorizedVehicleCategories.length > 0
      ? [
          {
            key: 'vehicleType',
            label: _tg('field.vehicle.type'),
            checked: false,
          },
        ]
      : []),
    { key: 'dependanceId', label: _t('dependanceId'), checked: true },
    { key: 'tags', label: _tg('field.tags'), checked: false },
    {
      key: 'massActionsTypes',
      label: _tg('field.massActionsTypes'),
      checked: false,
    },
    {
      key: 'decisionSource',
      label: _tg('field.decisionSource'),
      checked: false,
    },
    {
      key: 'creationDatetime',
      label: _tg('field.creationDatetime'),
      checked: false,
    },
    ...(langEnabled
      ? [
          {
            key: 'lang',
            label: _tg('commons.lang'),
            checked: false,
          },
        ]
      : []),
  ];

  return (
    <>
      {canExport && (
        <Exporter
          disabled={disabled}
          type={
            productType === ProductType.BUNDLE
              ? _t('page.bundle')
              : _t('page.eligibility')
          }
          columns={EXPORT_COL}
          filters={{
            ...filters,
            page: 0,
            maxRecords: MAX_RECORDS,
          }}
          fileExport={getOrderExport}
        />
      )}
    </>
  );
};
function mapStateToProps(state: InternalApiState): OrderExportReduxProps {
  const { userInfo } = getApiState(state);
  const {
    authorizedVehicleCategories,
    subscriptionConfigurationDTO,
  } = getConfigState(state);
  return {
    canExport:
      !!userInfo && userInfo.rights.includes('BACKOFFICE_ORDERS_EXPORT'),
    authorizedVehicleCategories,
    langEnabled: subscriptionConfigurationDTO.langEnabled,
  };
}

export default connect(mapStateToProps)(OrderExport);
