import * as React from 'react';

import { DataBox, DataBoxContent, DataBoxHeader } from 'commons/DataBox';
import { TimelineItem } from 'tefps/RecoursesV2/utils/timelineUtils';

import TimelinePoint from './TimelinePoint';
import Comments from './Comments';

import './TimelineBlock.css';

type Props = {
  item: TimelineItem;
};

const TimelineBlock = ({ item }: Props): JSX.Element => {
  return (
    <div className="timeline__block__container">
      <div className="timeline__block__content">
        <TimelinePoint
          style={{
            '--timeline-point-color':
              item.pointColor ?? ' var(--bg-color--pink)',
          }}
        />
        <DataBox>
          <DataBoxHeader color={item.title.color}>
            {item.title && item.title.left}
          </DataBoxHeader>
          <DataBoxContent>{item.contentElement}</DataBoxContent>
        </DataBox>
      </div>
      <div className="timeline__block__footer">
        <TimelinePoint filler />
        <Comments comment={item.comment} />
      </div>
    </div>
  );
};

export default TimelineBlock;
