import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog } from 'material-ui';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  OrderEsPrivateDTO,
  OrderPrivateDTO,
  OrderStatus,
  OrderTraceType,
  OrderZones,
  ProductPrivateDTO,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import {
  addZonesUpdate,
  searchEsOrderPaged,
} from 'api/cvfm-core-subscription/order';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import './OrderDetailPage.css';
import OrderCreateZones from './OrderCreateZones';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalZonesProps = {
  open: boolean;
  product: ProductPrivateDTO;
  order: OrderPrivateDTO;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalZones = ({
  open,
  product,
  order,
  userInfo,
  onCancel,
  onConfirm,
}: OrderDetailModalZonesProps): JSX.Element => {
  const [zones, setZones] = useState<Array<string>>(
    order.zoneHistory[order.zoneHistory.length - 1].zoneIds
  );
  const [activeRights, setActiveRights] = useState<Array<OrderEsPrivateDTO>>(
    []
  );
  const [activeRight, setActiveRight] = useState<OrderEsPrivateDTO>();
  const [loading, setLoading] = useState<boolean>(false);

  const setMessage = useSnackbar();

  function onCancelAction() {
    setZones([]);
    onCancel();
  }

  function onConfirmAction() {
    if (zones.length > 0) {
      const updateZones = {
        timestamp: new Date().toISOString(),
        traceType: OrderTraceType.ZONES,
        source: SubscriptionSource.BACK,
        agent: userInfo ? { ...userInfo } : null,
        zoneIds: [...zones],
        subscriberId: null,
      } as OrderZones;
      const updatePromise = addZonesUpdate(order.orderId, updateZones);
      onConfirm(updatePromise);
    }
  }

  useEffect(() => {
    if (order.activeRightUsed) {
      setLoading(true);
      searchEsOrderPaged({
        page: 0,
        maxRecords: 50,
        query: {
          subscriberIds:
            order.subscribersHistory[order.subscribersHistory.length - 1]
              .subscriberIds,
          orderStatuses: [OrderStatus.FULFILLED],
          validityEnd: {
            from: new Date(),
          },
        },
      })
        .then(activeOrders => {
          setActiveRight(
            activeOrders.results.find(
              (o: OrderEsPrivateDTO) =>
                o.orderId === order.activeRightUsed?.rightId
            )
          );
          setActiveRights(activeOrders.results);
          setLoading(false);
        })
        .catch(err => {
          setMessage((err as Error).message);
        });
    }
  }, [order.orderId]);

  function onChange(newZones: Array<string>) {
    setZones(newZones);
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-zones-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="order-zones-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
    />,
  ];

  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      {loading && (
        <div className="order-detail_row">
          <CircularProgress />
        </div>
      )}
      <OrderCreateZones
        product={product}
        activeRights={activeRights}
        selectedEligibilityId={
          activeRight?.productType === 'ELIGIBILITY'
            ? activeRight.orderId
            : null
        }
        selectedBundleId={
          activeRight?.productType === 'BUNDLE' ? activeRight.orderId : null
        }
        selectedZones={zones}
        onChange={onChange}
      />
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalZones);
