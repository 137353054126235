import { ProductPrivateDTO } from '@cvfm-front/tefps-types';
import { PatchObject } from 'api/commonTypes';

function makePatch(path: string, value: unknown): PatchObject<unknown> {
  return {
    op: 'replace',
    path,
    value,
  };
}

export function computeProductDiffPatchList(
  src: ProductPrivateDTO,
  dest: ProductPrivateDTO
): Array<PatchObject<unknown>> {
  const patches = [];

  if (src.name !== dest.name) {
    patches.push(makePatch('/name', dest.name));
  }
  if (src.organizationId !== dest.organizationId) {
    patches.push(makePatch('/organizationId', dest.organizationId));
  }
  if (src.privateOrdersEnabled !== dest.privateOrdersEnabled) {
    patches.push(makePatch('/privateOrdersEnabled', dest.privateOrdersEnabled));
  }
  if (src.privateDescription !== dest.privateDescription) {
    patches.push(makePatch('/privateDescription', dest.privateDescription));
  }
  if (src.publicDescription !== dest.publicDescription) {
    patches.push(makePatch('/publicDescription', dest.publicDescription));
  }
  if (src.publicOrdersEnabled !== dest.publicOrdersEnabled) {
    patches.push(makePatch('/publicOrdersEnabled', dest.publicOrdersEnabled));
  }
  if (src.paymentInvoiceEnabled !== dest.paymentInvoiceEnabled) {
    patches.push(
      makePatch('/paymentInvoiceEnabled', dest.paymentInvoiceEnabled)
    );
  }
  if (src.supportingDocumentEnabled !== dest.supportingDocumentEnabled) {
    patches.push(
      makePatch('/supportingDocumentEnabled', dest.supportingDocumentEnabled)
    );
  }
  if (src.supportingDocumentName !== dest.supportingDocumentName) {
    patches.push(
      makePatch('/supportingDocumentName', dest.supportingDocumentName)
    );
  }
  if (src.organizationName !== dest.organizationName) {
    patches.push(makePatch('/organizationName', dest.organizationName));
  }
  if (src.organizationAddress !== dest.organizationAddress) {
    patches.push(makePatch('/organizationAddress', dest.organizationAddress));
  }
  if (src.options !== dest.options) {
    patches.push(makePatch('/options', dest.options));
  }
  if (src.multipleOrderAllowed !== dest.multipleOrderAllowed) {
    patches.push(makePatch('/multipleOrderAllowed', dest.multipleOrderAllowed));
  }
  if (src.orderShareEnabled !== dest.orderShareEnabled) {
    patches.push(makePatch('/orderShareEnabled', dest.orderShareEnabled));
  }
  if (src.computeExactHourlyEnd !== dest.computeExactHourlyEnd) {
    patches.push(
      makePatch('/computeExactHourlyEnd', dest.computeExactHourlyEnd)
    );
  }
  if (
    src.remindAwaitingPaymentAndPurge !== dest.remindAwaitingPaymentAndPurge
  ) {
    patches.push(
      makePatch(
        '/remindAwaitingPaymentAndPurge',
        dest.remindAwaitingPaymentAndPurge
      )
    );
  }
  if (src.pricingConfiguration !== dest.pricingConfiguration) {
    patches.push(makePatch('/pricingConfiguration', dest.pricingConfiguration));
  }
  if (src.automaticAcceptConfiguration !== dest.automaticAcceptConfiguration) {
    patches.push(
      makePatch(
        '/automaticAcceptConfiguration',
        dest.automaticAcceptConfiguration
      )
    );
  }
  if (src.zones !== dest.zones) {
    patches.push(makePatch('/zones', dest.zones));
  }
  if (src.conditions !== dest.conditions) {
    patches.push(makePatch('/conditions', dest.conditions));
  }
  if (src.restrictions !== dest.restrictions) {
    patches.push(makePatch('/restrictions', dest.restrictions));
  }
  if (src.automaticOrders !== dest.automaticOrders) {
    patches.push(makePatch('/automaticOrders', dest.automaticOrders));
  }
  // 2 arrays of string difference
  if (
    src.tags.length !== dest.tags.length ||
    src.tags.some(x => !new Set(dest.tags).has(x)) ||
    dest.tags.some(x => !new Set(src.tags).has(x))
  ) {
    patches.push(makePatch('/tags', dest.tags));
  }
  if (src.reminders !== dest.reminders) {
    patches.push(makePatch('/reminders', dest.reminders));
  }
  if (src.enabledNotifications !== dest.enabledNotifications) {
    patches.push(makePatch('/enabledNotifications', dest.enabledNotifications));
  }
  if (src.applicationProcedure !== dest.applicationProcedure) {
    patches.push(makePatch('/applicationProcedure', dest.applicationProcedure));
  }
  if (src.fpsPreMajorationReminders !== dest.fpsPreMajorationReminders) {
    patches.push(
      makePatch('/fpsPreMajorationReminders', dest.fpsPreMajorationReminders)
    );
  }
  return patches;
}
