import React from 'react';
import { connect } from 'react-redux';

import { parsePeriod } from '@cvfm-front/commons-utils';
import { BKG_DARK } from 'theme';
import {
  ApplicationProcedureDTO,
  CoreAddress,
  OrderCustomField,
  OrderPrivateDTO,
  OrderStatus,
  ProductPrivateDTO,
  ProductType,
  SubscriberAccountType,
  SubscriberDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import './OrderDetailPage.css';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import { getLast } from 'commons/Utils/arrayUtils';
import { OrderTraceStatus } from '@cvfm-front/tefps-types/build/order/OrderTraceStatus';
import { formatDatetime } from 'commons/Date';
import SeparatorHorizontal from 'commons/SeparatorHorizontal';
import { coreAddressRenderer } from 'commons/Address/SimpleAddressRenderer';
import CopyValueButton from 'commons/CopyValueButton';
import { formatCtsPrice } from 'commons/Price';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';
import {
  getLabelFromVehicleType,
  getOrderVehiclesCategories,
} from 'commons/Utils/vehicleTypeUtils';
import Tag from 'commons/Tag';

import { translateOrderStatus } from './OrderStatusTranslator';
import OrderDetailFiles, {
  computeEvidences,
  computeProfileSubscriberEvidences,
} from './OrderDetailFiles';
import OrderDetailRestrictionWarnings from './OrderDetailRestrictionWarnings';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailSummaryProps = {
  order: OrderPrivateDTO;
  orderProduct: ProductPrivateDTO;
  orderSubscribers: Array<SubscriberDTO>;
  zones: Array<ZoneDTO>;
  refreshOrder: () => void;
};

type CityConfigReduxProps = {
  adV3Enabled: boolean;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  langEnabled: boolean;
};

const OrderDetailSummary = ({
  order,
  orderProduct,
  orderSubscribers,
  zones,
  refreshOrder,
  adV3Enabled,
  authorizedVehicleCategories,
  langEnabled,
}: OrderDetailSummaryProps & CityConfigReduxProps): JSX.Element => {
  const orderValidityPeriod =
    order.validityPeriodHistory[order.validityPeriodHistory.length - 1];

  function getTitleTranslation(): string {
    if (orderProduct.productType === ProductType.BUNDLE) {
      return _t('title.bundle');
    }

    return _t('title.eligibility');
  }

  function getTitleIdTranslation(): string {
    if (orderProduct.productType === ProductType.BUNDLE) {
      return _t('title.id.bundle');
    }

    return _t('title.id.eligibility');
  }

  function renderZones(zoneIds: Array<string>): string {
    return zoneIds
      .map(zoneId => zones.find(z => z.id === zoneId))
      .filter(z => !!z)
      .map(z => z?.name)
      .join(', ');
  }

  // we assume the array to not be empty
  function renderCustomFields(
    customFields: Array<OrderCustomField>,
    applicationProcedure: ApplicationProcedureDTO | undefined
  ): JSX.Element {
    // as this is a history, we might have the same key multiple time => take the last one by constructing a hashmap <key, value>
    const customFieldsMapped = customFields.reduce(
      (dictionnary, customField) => {
        dictionnary[customField.key] = customField.value;
        return dictionnary;
      },
      {}
    );
    // we transform them into a list of JSX.Element
    const customFieldsElements = Object.keys(customFieldsMapped).reduce(
      (acc: JSX.Element[], key: keyof typeof customFieldsMapped) => {
        // the key is an UUID, we need to translate it from the applicationProcedure
        const label = applicationProcedure?.customFields?.find(
          customField => customField.key === key
        )?.label;
        if (label) {
          acc.push(
            <FakeInputBlock
              labelStyle={{ textAlign: 'center' }}
              title={label}
              value={customFieldsMapped[key]}
            />
          );
        }
        return acc;
      },
      []
    );
    // and now we will split them into 3 sections
    // if there are 1 or 2 elements, we put them into lone sections
    if (customFieldsElements.length === 1) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {customFieldsElements[0]}
        </div>
      );
    }
    if (customFieldsElements.length === 2) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            {customFieldsElements[0]}
          </div>
          <Separator />
          <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            {customFieldsElements[1]}
          </div>
        </div>
      );
    }
    // we put the extra elements in the first 2 sections if necessary
    const numberPerSection = Math.floor(customFieldsElements.length / 3);
    const extraNumber = customFieldsElements.length % 3;
    const numberFirstSection = numberPerSection + (extraNumber >= 1 ? 1 : 0);
    const numberSecondSection = numberPerSection + (extraNumber >= 2 ? 1 : 0);
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          {customFieldsElements.slice(0, numberFirstSection)}
        </div>
        <Separator />
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          {customFieldsElements.slice(
            numberFirstSection,
            numberFirstSection + numberSecondSection
          )}
        </div>
        <Separator />
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          {customFieldsElements.slice(numberFirstSection + numberSecondSection)}
        </div>
      </div>
    );
  }

  const { productScope } = orderProduct.conditions;

  const displayAddressAndZone =
    orderProduct.productType === ProductType.ELIGIBILITY &&
    order.status !== OrderStatus.FULFILLED;
  const displayCustomFields =
    orderProduct.productType === ProductType.ELIGIBILITY &&
    order.providedCustomFieldHistory.length > 0;
  const displayUserComment =
    orderProduct.productType === ProductType.ELIGIBILITY &&
    order.status !== OrderStatus.FULFILLED &&
    !!order.creationRecord?.comment;
  const displayFiles =
    (orderProduct.productType === ProductType.ELIGIBILITY &&
      computeEvidences(order).length > 0) ||
    computeProfileSubscriberEvidences(orderSubscribers, productScope).length >
      0;

  const reminderInTheFuture = order.reminders
    .filter(reminder => new Date(reminder.executionTime).getTime() > Date.now())
    .sort(
      (a, b) =>
        new Date(a.executionTime).getTime() -
        new Date(b.executionTime).getTime()
    );

  let reminderText;
  if (reminderInTheFuture.length === 0) {
    reminderText = _t('field.noSubscriberReminder');
  } else if (order.orderRenewed) {
    reminderText = _t('field.orderRenewedReminder');
  } else {
    reminderText = formatDatetime({
      datetime: reminderInTheFuture[0].executionTime,
      withTime: true,
    });
  }

  const lastPlateChangeRequest =
    order.plateChangeRequestHistory.length > 0 &&
    order.plateChangeRequestHistory[order.plateChangeRequestHistory.length - 1];

  const getSubscriberAddress = (
    orderSubscriber: SubscriberDTO
  ): CoreAddress | undefined | null => {
    const isPro = productScope === SubscriberAccountType.PROFESSIONAL;
    if (adV3Enabled) {
      return isPro
        ? orderSubscriber.professionalProfile?.location?.address
        : orderSubscriber.personalProfile?.location?.address;
    }
    return isPro
      ? orderSubscriber.professionalAddress
      : orderSubscriber.address;
  };

  const orderVehiclesCategories = adV3Enabled
    ? getOrderVehiclesCategories(order, orderSubscribers)
    : new Set(orderProduct.conditions.vehiclesCategories);

  return (
    <DataBox panel style={{ width: '95%' }}>
      <DataBoxHeader>
        <div className="order-detail-page_header_left">
          {getTitleTranslation()}
        </div>
        <div className="order-detail-page_header_right">
          {getTitleIdTranslation()}:
          <div className="order-detail-page_header_right-identifiant">
            {order.orderId}
            <CopyValueButton
              value={order.orderId}
              iconStyle={{ height: 15, color: 'white' }}
              spanStyle={{ marginLeft: 5 }}
            />
          </div>
        </div>
      </DataBoxHeader>
      <DataBoxContent>
        <DataBoxItemWrapper>
          <DataBoxItem>
            {orderSubscribers.map((subscriber, index) => (
              <>
                {index > 0 && <SeparatorHorizontal />}
                <FakeInputBlock
                  title={_t('field.subscriberName')}
                  value={`${subscriber.lastName} ${subscriber.firstName}`}
                />
                <FakeInputBlock
                  title={_t('field.subscriberPhone')}
                  value={subscriber.phoneNumber}
                />
                <FakeInputBlock
                  title={_t('field.subscriberEmail')}
                  value={subscriber.email}
                />
              </>
            ))}
            {langEnabled && (
              <FakeInputBlock
                title={_tg('commons.userLang')}
                value={_tg(`commons.langs.${order.lang}`)}
              />
            )}
            <FakeInputBlock
              title={_t('field.subscriberReminder')}
              value={reminderText}
            />
            {order.mandateUser && (
              <FakeInputBlock
                title={_t('field.mandateName')}
                inputStyle={{
                  color: BKG_DARK,
                  border: `1px solid ${BKG_DARK}`,
                }}
                value={`${order.mandateUser.mandateLastName} ${order.mandateUser.mandateFirstName}`}
              />
            )}
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            <FakeInputBlock
              title={_tg('field.status')}
              value={translateOrderStatus(
                order.status,
                getLast(order.validityPeriodHistory)?.startOfValidity,
                getLast(order.validityPeriodHistory)?.endOfValidity
              )}
            />
            <FakeInputBlock
              title={_t('field.productName')}
              value={orderProduct.name}
            />
            <FakeInputBlock
              title={_t('field.productOptionDuration')}
              value={parsePeriod(order.orderDuration)}
            />
            <FakeInputBlock
              title={_t('field.productOptionPrice')}
              value={formatCtsPrice(order.orderPrice)}
            />
          </DataBoxItem>
          <Separator />
          <DataBoxItem>
            {lastPlateChangeRequest &&
              lastPlateChangeRequest.status === OrderTraceStatus.WAITING && (
                <>
                  {lastPlateChangeRequest.orderPlateChange.changeType ===
                    'MODIFICATION' && (
                    <FakeInputBlock
                      title={_t(`field.plateModification`)}
                      value={`${lastPlateChangeRequest.orderPlateChange
                        .oldPlate || ''} ${_tg(
                        'field.towards'
                      )} ${lastPlateChangeRequest.orderPlateChange.newPlate ||
                        ''}`}
                    />
                  )}
                  {lastPlateChangeRequest.orderPlateChange.changeType ===
                    'ADDITION' && (
                    <FakeInputBlock
                      title={_t(`field.plateAddition`)}
                      value={lastPlateChangeRequest.orderPlateChange.newPlate}
                    />
                  )}
                </>
              )}
            <FakeInputBlock
              title={_tg('field.vehicle.plates')}
              value={order.plateHistory[
                order.plateHistory.length - 1
              ].plates.join(', ')}
            />
            {authorizedVehicleCategories.length > 0 &&
              orderSubscribers.length > 0 &&
              orderVehiclesCategories.size > 0 && (
                <FakeInputBlock
                  title={_tg('field.vehicle.type')}
                  value={Array.from(orderVehiclesCategories)
                    .map(vehicleCategory =>
                      getLabelFromVehicleType(vehicleCategory)
                    )
                    .join(', ')}
                />
              )}
            <FakeInputBlock
              title={_t('field.validityStart')}
              value={formatDatetime({
                datetime: orderValidityPeriod.startOfValidity,
                withTime:
                  orderProduct.computeExactHourlyEnd ||
                  orderProduct.pricingConfiguration.useTariffEngine,
              })}
            />
            <FakeInputBlock
              title={_t('field.validityEnd')}
              value={formatDatetime({
                datetime: orderValidityPeriod.endOfValidity,
                withTime:
                  orderProduct.computeExactHourlyEnd ||
                  orderProduct.pricingConfiguration.useTariffEngine,
              })}
            />
            <FakeInputBlock
              title={_tg('field.address.validityZones')}
              value={renderZones(
                order.zoneHistory[order.zoneHistory.length - 1].zoneIds
              )}
            />
          </DataBoxItem>
        </DataBoxItemWrapper>
        {(displayAddressAndZone ||
          displayCustomFields ||
          displayUserComment ||
          displayFiles) && <SeparatorHorizontal />}
        {displayAddressAndZone && (
          <DataBoxItemWrapper>
            {orderSubscribers.map(orderSubscriber => (
              <>
                <DataBoxItem>
                  <FakeInputBlock
                    title={_t('field.subscriberAddress')}
                    value={coreAddressRenderer(
                      getSubscriberAddress(orderSubscriber)
                    )}
                  />
                </DataBoxItem>
                <Separator />
                <DataBoxItem>
                  <FakeInputBlock
                    title={_t('field.subscriberZone')}
                    value={
                      (productScope === SubscriberAccountType.PROFESSIONAL &&
                        orderSubscriber.professionalZoneId &&
                        renderZones([orderSubscriber.professionalZoneId])) ||
                      (orderSubscriber.zoneId &&
                        renderZones([orderSubscriber.zoneId]))
                    }
                  />
                </DataBoxItem>
              </>
            ))}
          </DataBoxItemWrapper>
        )}
        {order.tags.length > 0 && (
          <>
            <SeparatorHorizontal />
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                margin: '0.5rem',
              }}
            >
              {order.tags.map((tag, i) => (
                <Tag key={i} tag={tag} />
              ))}
            </div>
          </>
        )}
        {order.comment && (
          <>
            <SeparatorHorizontal />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FakeInputBlock
                labelStyle={{ textAlign: 'center' }}
                title={_tg('field.address.comment')}
                value={order.comment}
              />
            </div>
          </>
        )}
        {displayCustomFields && (
          <DataBoxItemWrapper style={{ margin: '15px 0' }}>
            <DataBoxItem style={{ maxWidth: '' }}>
              {renderCustomFields(
                order.providedCustomFieldHistory,
                orderProduct.applicationProcedure
              )}
            </DataBoxItem>
          </DataBoxItemWrapper>
        )}
        {displayUserComment && (
          <DataBoxItemWrapper>
            <FakeInputBlock
              title={_t('field.subscriberComment')}
              containerStyle={{ width: '100%' }}
              inputStyle={{ flex: '3' }}
              value={order.creationRecord?.comment || ''}
            />
          </DataBoxItemWrapper>
        )}
        {displayFiles && (
          <OrderDetailFiles
            order={order}
            orderProduct={orderProduct}
            orderSubscribers={orderSubscribers}
            canDelete
            refreshOrder={refreshOrder}
          />
        )}
        <OrderDetailRestrictionWarnings // includes a SeparatorHorizontal
          order={order}
          orderProduct={orderProduct}
          refreshOrder={refreshOrder}
        />
      </DataBoxContent>
    </DataBox>
  );
};

function mapStateToProps(state: InternalApiState): CityConfigReduxProps {
  const {
    subscriptionConfigurationDTO,
    authorizedVehicleCategories,
  } = getConfigState(state);
  return {
    adV3Enabled: subscriptionConfigurationDTO.adV3Enabled,
    authorizedVehicleCategories,
    langEnabled: subscriptionConfigurationDTO.langEnabled,
  };
}

export default connect(mapStateToProps)(OrderDetailSummary);
