import React, { useEffect, useState } from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { fetchMatchingAgents } from 'api/accounts';
import { AgentIdAndNameDTO } from '@cvfm-front/tefps-types';
import useCityConfig from 'commons/hooks/useCityConfig';
import useRecourseFilters from 'commons/hooks/useRecourseFilters';
import useRecourseSearchResult from 'commons/hooks/useRecourseSearchResult';
import { initialFilters } from 'tefps/RecoursesV2/utils/recourseUtils';
import {
  POSTAL_STATUSES_FILTER_OPTIONS,
  RECOURSE_STATUSES_FILTER_OPTIONS,
  REQUEST_REASONS_FILTER_OPTIONS,
  RESPONSE_REASONS_FILTER_OPTIONS,
  RECOURSE_SOURCES_FILTER_OPTIONS,
} from 'tefps/RecoursesV2/utils/translationUtils';
import useMandatoryTags from 'commons/hooks/useMandatoryTags';

const { _t } = window.loadTranslations(__filename);

const RecourseFilters = (): JSX.Element => {
  /* Custom hooks */
  const cityConfig = useCityConfig();
  const mandatoryTags = useMandatoryTags();
  const recourseSearchResult = useRecourseSearchResult();
  const [filters, setFilters] = useRecourseFilters();

  /* Component state */
  const [agentSearchOptions, setAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);

  /* Rendering & component variables */
  const totalHits = recourseSearchResult ? recourseSearchResult.totalHits : 0;
  const facetings = recourseSearchResult?.checkboxFaceting;

  /* Functions */
  const fetchAgentsAndGenerateOptions = async (
    agentFilter: string | null | undefined
  ): Promise<void> => {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const agentSearchOptionsResult = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    setAgentSearchOptions(agentSearchOptionsResult);
  };

  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
  }, []);

  const onChange = (id: string, value: any): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    setFilters({ ...filters, [id]: value });
  };

  const onChangeRapoNumber = (id: string, value: any): void => {
    // prevent search on numbers bigger than Long.MAX_VALUE
    if (/^\d+$/.test(value) && value.length < 19) {
      onChange(id, value);
    }
  };

  const resetFilters = (): void => {
    setFilters({ ...initialFilters(), agentId: undefined });
  };

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Dates
        id="creationDate"
        title={_t('element.filters.creationDate.title')}
        dates={filters.creationDate}
        onChange={onChange}
      />
      <Dates
        id="receptionDate"
        title={_t('element.filters.receptionDate.title')}
        dates={filters.receptionDate}
        onChange={onChange}
      />
      <Dates
        id="decisionDatetime"
        title={_t('element.filters.decisionDatetime.title')}
        dates={filters.decisionDatetime}
        onChange={onChange}
      />
      <Autocomplete
        id="agentId"
        title={_t('element.filters.agentId.title')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentId}
        blockStyle={{ marginTop: 20 }}
      />
      <Input
        id="rapoNumber"
        title="Numéro de RAPO"
        placeholder="Rechercher par numéro de RAPO"
        onChange={onChangeRapoNumber}
        value={filters.rapoNumber?.toString() || ''}
      />
      <Input
        id="fpsId"
        title={_t('element.filters.fpsId.title')}
        placeholder={_t('element.filters.fpsId.placeholder')}
        onChange={onChange}
        value={filters.fpsId || ''}
      />
      <Input
        id="plate"
        title={_t('element.filters.plate.title')}
        placeholder={_t('element.filters.plate.placeholder')}
        onChange={onChange}
        value={filters.plate || ''}
      />
      <Input
        id="userName"
        title={_t('element.filters.userName.title')}
        placeholder={_t('element.filters.userName.placeholder')}
        onChange={onChange}
        value={filters.userName || ''}
      />
      <Checkboxes
        id="statuses"
        title={_t('element.filters.statuses.title')}
        options={RECOURSE_STATUSES_FILTER_OPTIONS}
        filters={filters.statuses}
        onChange={onChange}
        faceting={facetings ? facetings.statuses : null}
      />
      {!!cityConfig?.sendingRecourseLetterEnabled && (
        <Checkboxes
          id="postalStatuses"
          title={_t('element.filters.postalStatuses.title')}
          options={POSTAL_STATUSES_FILTER_OPTIONS}
          filters={filters.postalStatuses}
          onChange={onChange}
          faceting={facetings ? facetings.postalStatuses : null}
        />
      )}
      <Checkboxes
        id="sources"
        title={_t('element.filters.sources.title')}
        options={RECOURSE_SOURCES_FILTER_OPTIONS}
        filters={filters.sources}
        onChange={onChange}
        faceting={facetings ? facetings.sources : null}
      />
      <Checkboxes
        id="tags"
        title={_t('element.filters.tags.title')}
        options={mandatoryTags.map(tag => ({ value: tag, label: tag }))}
        filters={filters.tags}
        onChange={onChange}
        faceting={facetings ? facetings.tags : null}
      />
      <Checkboxes
        id="reasons"
        title={_t('element.filters.reasons.title')}
        options={REQUEST_REASONS_FILTER_OPTIONS}
        filters={filters.reasons}
        onChange={onChange}
        faceting={facetings ? facetings.reasons : null}
      />
      <Checkboxes
        id="cityReasons"
        title={_t('element.filters.cityReasons.title')}
        options={RESPONSE_REASONS_FILTER_OPTIONS}
        filters={filters.cityReasons}
        onChange={onChange}
        faceting={facetings ? facetings.cityReasons : null}
      />
      <Input
        id="comment"
        title={_t('element.filters.comment.title')}
        placeholder={_t('element.filters.comment.placeholder')}
        onChange={onChange}
        value={filters.comment || ''}
      />
      <Input
        id="agentComment"
        title={_t('element.filters.agentComment.title')}
        placeholder={_t('element.filters.agentComment.placeholder')}
        onChange={onChange}
        value={filters.agentComment || ''}
      />
      <Dates
        id="fpsStatementDatetime"
        title={_t('element.filters.fpsStatementDatetime.title')}
        dates={filters.fpsStatementDatetime}
        onChange={onChange}
      />
      <Dates
        id="antaiNotificationDatetime"
        title={_t('element.filters.antaiNotificationDatetime.title')}
        dates={filters.antaiNotificationDatetime}
        onChange={onChange}
      />
    </Sidebar>
  );
};

export default RecourseFilters;
