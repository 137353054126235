import React from 'react';
import { useParams } from 'react-router-dom';

import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { ComplementRequestDTO } from 'api/recourse/types';
import PdfButton from 'commons/PdfButton';
import { getComplementRequestPdfUrl } from 'api/recourse';
import { openNewAuthentifiedTab } from 'api/helpers';

import './ComplementRequest.css';

const { _t } = window.loadTranslations(__filename);

type ComplementRequestProps = {
  complementRequest: ComplementRequestDTO;
};

const ComplementRequest = ({
  complementRequest,
}: ComplementRequestProps): JSX.Element => {
  const { recourseId } = useParams<{ recourseId: string }>();

  const openPdfUrl = (): void => {
    const url = getComplementRequestPdfUrl(recourseId);
    openNewAuthentifiedTab(url);
  };

  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_t('field.agent')}
            value={complementRequest.controlAgent?.name || ''}
          />
          <FakeInputBlock
            title={_t('field.creationDate')}
            value={
              <Date
                datetime={complementRequest.creationDate}
                withTime={false}
              />
            }
          />
          <FakeInputBlock
            title={_t('field.sendDate')}
            value={
              <Date datetime={complementRequest.sentDate} withTime={false} />
            }
          />
          <FakeInputBlock
            title={_t('field.limitDate')}
            value={
              <Date datetime={complementRequest.endDate} withTime={false} />
            }
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <div className="complement-request__field">
            <span className="complement-request__label">
              {_t('field.requestMail')}
            </span>
            <PdfButton openPdfUrl={openPdfUrl} />
          </div>
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default ComplementRequest;
