import React, { useEffect, useState } from 'react';
import { TextField, SelectField, MenuItem, Checkbox } from 'material-ui';

import VehicleDetails from 'tefps/Subscription/commons/VehicleDetails';
import {
  OrderSimpleDTO,
  ProductPrivateDTO,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';
import { PlatesService } from '@cvfm-front/commons-services';
import useWatcher from 'commons/hooks/useWatcher';
import { normalizePlate } from '@cvfm-front/commons-utils/';

const { _tg, _t } = window.loadTranslations(__filename);

type OrderCreatePlatesSelectVehicleProps = {
  canSelectAnyPlate: boolean;
  typedPlates: string[];
  subscriberVehicles: SubscriberVehicle[];
  handleSelectChange: (index: number, menuItemValue: string) => void;
  handleChange: (e: React.ChangeEvent<HTMLDataElement>, value: string) => void;
  ordersInduced: OrderSimpleDTO[] | undefined;
  product?: ProductPrivateDTO;
};

const OrderCreatePlatesSelectVehicle = ({
  canSelectAnyPlate,
  typedPlates,
  subscriberVehicles,
  handleSelectChange,
  handleChange,
  ordersInduced,
  product,
}: OrderCreatePlatesSelectVehicleProps): JSX.Element => {
  const [customPlates, setCustomPlates] = useState<boolean[]>(
    new Array(typedPlates.length).fill(false)
  );

  const usedPlates = useWatcher(PlatesService.watchUsedPlates, {});

  useEffect(() => {
    const plateRequested = typedPlates[0];
    if (
      !product ||
      !product.restrictions.showOrderPlateLimit ||
      !plateRequested ||
      normalizePlate(plateRequested) ===
        normalizePlate(PlatesService.getRequestPlate() || '')
    ) {
      return undefined;
    }
    const handler = setTimeout(() => {
      PlatesService.setRequestPlate(plateRequested || null);
    }, 1000);

    // si plate change avant que le timeout n'expire, on annule le timeout
    return (): void => {
      clearTimeout(handler);
    };
  }, [typedPlates, usedPlates]);

  function getPlateColor(plate: string): string {
    if (ordersInduced?.some(o => o.plates.includes(plate))) {
      return 'red';
    }
    return 'black';
  }

  function handleCheck(index: number, checked: boolean) {
    const newCustomPLates = [...customPlates];
    newCustomPLates[index] = checked;
    setCustomPlates(newCustomPLates);
    // Clean selection
    handleSelectChange(index, '');
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {typedPlates.map((plate, index) => (
        <div key={`select-container-${index}`}>
          {customPlates[index] ? (
            <TextField
              inputStyle={{ color: getPlateColor(plate) }}
              value={plate}
              onChange={handleChange}
              data-field={index}
              floatingLabelText={`${_t('vehicle.plate')} ${index + 1}`}
            />
          ) : (
            <SelectField
              key={index}
              menuItemStyle={{ color: getPlateColor(plate) }}
              floatingLabelText={`${_tg('field.vehicle.plate')} ${index + 1}`}
              onChange={(e, _index, menuItemValue) =>
                handleSelectChange(index, menuItemValue)
              }
              value={plate}
              fullWidth
            >
              {subscriberVehicles.map(vehicle => (
                <MenuItem
                  key={vehicle.plate}
                  checked={plate === vehicle.plate}
                  value={vehicle.plate}
                  primaryText={
                    <p className="text text--intermediate text--no-wrap">
                      {vehicle.name}
                    </p>
                  }
                  secondaryText={
                    <VehicleDetails
                      plate={vehicle.plate}
                      vehicles={subscriberVehicles}
                      showPlate
                    />
                  }
                  insetChildren
                />
              ))}
            </SelectField>
          )}
          {canSelectAnyPlate && (
            <Checkbox
              key={`other-${index}`}
              label={_t('vehicle.other')}
              checked={customPlates[index]}
              onCheck={(_e, checked) => handleCheck(index, checked)}
            />
          )}
          {product?.restrictions.showOrderPlateLimit && plate && (
            <div style={{ color: '#694c14', marginTop: '8px' }}>
              {_t('vehicle.remainingPlates', {
                plate,
                plateUsedAmount: PlatesService.getNumberOfPlateUsage(plate),
                plateUseLimit: product.restrictions.orderPlateLimit,
              })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderCreatePlatesSelectVehicle;
