import React, { useState, useEffect } from 'react';
import { TextField as TextFieldV0 } from 'material-ui';

import { OrderCustomFieldComplete } from './OrderCreateTypes';

type OrderCreateCustomFieldsProps = {
  customFields: OrderCustomFieldComplete[];
  setCustomFields: React.Dispatch<
    React.SetStateAction<OrderCustomFieldComplete[]>
  >;
};

const OrderCreateCustomFields = ({
  customFields,
  setCustomFields,
}: OrderCreateCustomFieldsProps): JSX.Element => {
  const handleCustomFieldsChange = (key: string, value: string): void => {
    const newCustomFields = customFields.map(customField => {
      if (customField.key === key) return { ...customField, value };
      return customField;
    });
    setCustomFields(newCustomFields);
  };

  return (
    <>
      <div className="order-detail-row" style={{ marginTop: '40px' }}>
        {customFields.map(customField => (
          <div>
            <span>{customField.label} : </span>
            <TextFieldV0
              value={customField.value}
              onChange={(_e: React.FormEvent<Element>, value: string) =>
                handleCustomFieldsChange(customField.key, value)
              }
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default OrderCreateCustomFields;
