import React from 'react';

import { OrderPayment } from '@cvfm-front/tefps-types';
import {
  formatCentsToCurrency,
  translatePaymentChannel,
  translatePaymentMode,
} from 'commons/Utils/paymentUtil';

import './OrderDetailPage.css';
import FakeInputBlock from 'commons/FakeInputBlock';
import { DataBoxItem } from 'commons/DataBox';

type OrderDetailHistoryPaymentProps = {
  orderTrace: OrderPayment;
};

const { _tg } = window.loadTranslations(__filename);

const OrderDetailHistoryPayment = ({
  orderTrace,
}: OrderDetailHistoryPaymentProps): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_tg('field.payment.amount')}
        value={formatCentsToCurrency(orderTrace.payment.paymentAmount, 'EUR')}
      />
      <FakeInputBlock
        title={_tg('field.payment.origin')}
        value={translatePaymentChannel()[orderTrace.payment.paymentChannel]}
      />
      <FakeInputBlock
        title={_tg('field.payment.mode')}
        value={translatePaymentMode()[orderTrace.payment.paymentMode]}
      />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryPayment;
