import React from 'react';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_LIGHT } from 'theme';

import './ProductDetailPage.css';

type Props = {
  title: string;
};
export const ProductDetailSecondarySeparator = ({
  title,
}: Props): JSX.Element => {
  return (
    <div className="product-detail_section-separator">
      <div className="product-detail_section-separator-content">
        <SeparatorWithTitle title={title} color={BKG_LIGHT} titleSize={20} />
      </div>
    </div>
  );
};

export default ProductDetailSecondarySeparator;
