import React, { useMemo, useState } from 'react';
import { Dialog, MenuItem, SelectField, TextField } from 'material-ui';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  OrderCustomField,
  OrderPrivateDTO,
  OrderTraceType,
  ProductPrivateDTO,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import { addCustomFieldUpdate } from 'api/cvfm-core-subscription/order';
import { ItemIdName } from 'api/commonTypes';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalCustomFieldProps = {
  open: boolean;
  product: ProductPrivateDTO;
  order: OrderPrivateDTO;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalCustomField = ({
  open,
  product,
  order,
  userInfo,
  onCancel,
  onConfirm,
}: OrderDetailModalCustomFieldProps): JSX.Element => {
  const [customFieldChoices, setCustomFieldChoices] = useState<
    Array<ItemIdName>
  >([]);
  const [customFieldKey, setCustomFieldKey] = useState<string>();
  const [customFieldValue, setCustomFieldValue] = useState<string>();
  const [comment, setComment] = useState<string>();

  function onCancelAction() {
    setComment(undefined);
    setCustomFieldKey(undefined);
    setCustomFieldValue(undefined);
    onCancel();
  }

  function onConfirmAction() {
    if (customFieldKey && customFieldValue) {
      const updateCustomField = {
        timestamp: new Date().toISOString(),
        traceType: OrderTraceType.CUSTOM_FIELD,
        source: SubscriptionSource.BACK,
        agent: userInfo ? { ...userInfo } : null,
        comment,
        key: customFieldKey,
        value: customFieldValue,
        subscriberId: null,
      } as OrderCustomField;
      const updatePromise = addCustomFieldUpdate(
        order.orderId,
        updateCustomField
      );
      onConfirm(updatePromise);
    }
  }

  function handleSelectCustomFieldKey(e: never, index: never, key: string) {
    setCustomFieldKey(key);
  }

  function handleChangeCustomFieldValue(e: never, text: string) {
    if (text !== '') {
      setCustomFieldValue(text);
    } else {
      setCustomFieldValue(undefined);
    }
  }

  function handleChangeComment(e: never, text: string) {
    setComment(text);
  }

  useMemo(() => {
    if (
      product.applicationProcedure &&
      product.applicationProcedure.customFields
    ) {
      const productChoices = product.applicationProcedure.customFields
        .filter(pcf => !pcf.deleted)
        .map(
          pcf =>
            ({
              id: pcf.key,
              name: pcf.label,
            } as ItemIdName)
        );
      setCustomFieldChoices(productChoices);
    }
  }, [product]);

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-period-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-period-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
      disabled={!customFieldKey || !customFieldValue}
    />,
  ];

  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      <div className="order-detail_row">
        <span className="order-detail_cell-100">
          <SelectField
            key="custom-field-key-select"
            value={customFieldKey}
            fullWidth
            floatingLabelFixed
            floatingLabelText={_t('field.key')}
            onChange={handleSelectCustomFieldKey}
          >
            {customFieldChoices.map(choice => (
              <MenuItem
                id={choice.id}
                key={choice.id}
                value={choice.id}
                primaryText={choice.name}
              />
            ))}
          </SelectField>
        </span>
      </div>
      <div className="order-detail_row">
        <span className="order-detail_cell-100">
          <TextField
            fullWidth
            floatingLabelFixed
            floatingLabelText={_t('field.value')}
            value={customFieldValue || ''}
            onChange={handleChangeCustomFieldValue}
          />
        </span>
      </div>
      <div className="order-detail_row">
        <span className="order-detail_cell-100">
          <TextField
            multiLine
            rows={5}
            fullWidth
            floatingLabelFixed
            floatingLabelText={_tg('field.comment')}
            value={comment || ''}
            onChange={handleChangeComment}
          />
        </span>
      </div>
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalCustomField);
