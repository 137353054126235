import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, MenuItem, SelectField } from 'material-ui';

import BoButton from 'facade/BoButton';
import { ReminderConfiguration } from '@cvfm-front/tefps-types';
import PeriodPicker, { localPeriodRenderer } from 'commons/PeriodPicker';

import './ProductDetailPage.css';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

import ProductDetailSeparator from './commons/DetailSeparator';
import ProductDetailSecondarySeparator from './ProductDetailSecondarySeparator';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailRemindersProps = {
  reminders: Array<ReminderConfiguration>;
  fpsPreMajorationReminders: Array<ReminderConfiguration>;
  canEdit: boolean;
  retroactiveReminderModification: boolean;
  setRetroactiveReminderModification: (newValue: boolean) => void;
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailReminders = ({
  reminders,
  fpsPreMajorationReminders,
  canEdit,
  retroactiveReminderModification,
  setRetroactiveReminderModification,
  onChange,
}: ProductDetailRemindersProps): JSX.Element => {
  const setMessage = useSnackbar();

  function handleCheckRetroactive(
    _event: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) {
    setRetroactiveReminderModification(checked);
  }

  function handleClickAdd(
    originalList: Array<ReminderConfiguration>,
    defaultReminder: ReminderConfiguration,
    field: string
  ): () => void {
    return () => {
      const newReminders = [...originalList, defaultReminder];
      onChange(field, newReminders);
    };
  }

  function handleClickRemove(
    originalList: Array<ReminderConfiguration>,
    field: string
  ) {
    return (e: React.MouseEvent<HTMLDataElement>): void => {
      const { index } = e.currentTarget.dataset;
      if (index && parseInt(index, 10) !== undefined) {
        const newReminders = [...originalList];
        newReminders.splice(parseInt(index, 10), 1);
        onChange(field, newReminders);
      }
    };
  }

  function handleChangePeriodWrapper(
    originalList: Array<ReminderConfiguration>,
    index: number,
    field: string
  ) {
    return (event: React.MouseEvent<HTMLDataElement>, period: string) => {
      const reminder = { ...originalList[index] };
      reminder.period = period;

      const newReminders = [...originalList];
      newReminders[index] = reminder;
      onChange(field, newReminders);
    };
  }

  function handleCheckSms(
    originalList: Array<ReminderConfiguration>,
    field: string
  ) {
    return (event: React.MouseEvent<HTMLDataElement>, checked: boolean) => {
      const { index } = event.currentTarget.dataset;
      if (index && parseInt(index, 10) !== undefined) {
        const reminderIndex = parseInt(index, 10);
        const reminder = { ...originalList[reminderIndex] };
        reminder.sms = checked;

        const newReminders = [...originalList];
        newReminders[reminderIndex] = reminder;
        onChange(field, newReminders);
      }
    };
  }

  function handleCheckMail(
    originalList: Array<ReminderConfiguration>,
    field: string
  ) {
    return (event: React.MouseEvent<HTMLDataElement>, checked: boolean) => {
      const { index } = event.currentTarget.dataset;
      if (index && parseInt(index, 10) !== undefined) {
        const reminderIndex = parseInt(index, 10);
        const reminder = { ...originalList[reminderIndex] };
        reminder.mail = checked;

        const newReminders = [...originalList];
        newReminders[reminderIndex] = reminder;
        onChange(field, newReminders);
      }
    };
  }

  function handleSelectPost(index: number, value: number) {
    const reminder = { ...reminders[index] };
    reminder.post = value === 1;

    const newReminders = [...reminders];
    newReminders[index] = reminder;
    onChange('reminders', newReminders);
  }

  function renderReminder(
    remindersList: Array<ReminderConfiguration>,
    productField: string,
    isFps?: boolean
  ): JSX.Element {
    return (
      <>
        {remindersList.map((reminder, index) => (
          <div
            className="product-detail_section-content-row"
            style={{ alignItems: 'center' }}
          >
            <span className="product-detail_cell-25">
              <PeriodPicker
                hint={_t('element.periodChange.hint', { index: index + 1 })}
                value={reminder.period}
                style={{ width: 'auto' }}
                idx={index}
                onChange={handleChangePeriodWrapper(
                  remindersList,
                  index,
                  productField
                )}
                periodRender={value =>
                  value === 'P0D'
                    ? _t('element.periodChange.emptyDuration')
                    : localPeriodRenderer(value) || ''
                }
                width="100%"
                disabled={!canEdit}
              />
            </span>
            <span className="product-detail_cell-25">
              <SelectField
                key={`reminder-${index}`}
                data-index={index}
                floatingLabelFixed
                floatingLabelText={_t('field.label.option')}
                disabled={!canEdit || isFps}
                fullWidth
                onChange={(_e, _i, v) => handleSelectPost(index, v)}
                value={reminder.post && !isFps ? 1 : 0}
              >
                <MenuItem
                  key={0}
                  value={0}
                  primaryText={_t('select.pre')}
                  insetChildren
                />
                <MenuItem
                  key={1}
                  value={1}
                  primaryText={_t('select.post')}
                  insetChildren
                />
              </SelectField>
            </span>
            <span className="product-detail_cell-12">
              <Checkbox
                data-index={index}
                label={_t('checkbox.sms')}
                disabled={!canEdit}
                checked={reminder.sms}
                onCheck={handleCheckSms(remindersList, productField)}
              />
            </span>
            <span className="product-detail_cell-12">
              <Checkbox
                data-index={index}
                label={_t('checkbox.mail')}
                disabled={!canEdit}
                checked={reminder.mail}
                onCheck={handleCheckMail(remindersList, productField)}
              />
            </span>
            <span className="product-detail_cell-25">
              <BoButton
                data-index={index}
                label={_tg('action.delete')}
                secondary
                fullWidth
                disabled={!canEdit}
                onClick={handleClickRemove(remindersList, productField)}
              />
            </span>
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />
      <div className="product-detail_section-content">
        {renderReminder(reminders, 'reminders')}
        <div className="product-detail_section-content-row">
          <span
            className="product-detail_cell-12"
            title={_t('action.add.title')}
          >
            <BoButton
              label={_tg('action.add')}
              onClick={handleClickAdd(
                reminders,
                {
                  id: uuidv4(),
                  period: 'P0D',
                  reminderType: 'ORDER_EXPIRATION',
                  post: false,
                  mail: false,
                  sms: false,
                },
                'reminders'
              )}
              fullWidth
              disabled={!canEdit}
              primary
            />
          </span>
          <span className="product-detail_cell-50" />
          <span className="product-detail_cell-25">
            <Checkbox
              label={_t('checkbox.retroactive')}
              disabled={!canEdit}
              checked={retroactiveReminderModification}
              onCheck={handleCheckRetroactive}
            />
          </span>
        </div>
      </div>
      <ProductDetailSecondarySeparator title={_t('section.fpsTitle')} />
      <div className="product-detail_section-content">
        {renderReminder(
          fpsPreMajorationReminders,
          'fpsPreMajorationReminders',
          true
        )}
        <div className="product-detail_section-content-row">
          <span
            className="product-detail_cell-12"
            title={_t('action.add.title')}
          >
            <BoButton
              label={_tg('action.add')}
              onClick={handleClickAdd(
                fpsPreMajorationReminders,
                {
                  id: uuidv4(),
                  mail: true,
                  sms: false,
                  reminderType: 'FPS_MAJORATION',
                  period: 'P0M',
                  post: false,
                },
                'fpsPreMajorationReminders'
              )}
              fullWidth
              disabled={!canEdit}
              primary
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailReminders;
