import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import {
  DateRangeDTO,
  OrderEsSearchQueryDTO,
  OrderEsSearchResultDTO,
  OrderMassActionsTypes,
  OrderStatus,
  ProductPrivateDTO,
  ProductType,
  SubscriptionSource,
  VehicleCategory,
  ZoneDTO,
  LANGS,
  TeamDTO,
} from '@cvfm-front/tefps-types';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Input from 'commons/SidebarV2/Components/Input';
import Select from 'commons/SidebarV2/Components/Select';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { CustomFieldDTO } from 'api/pricing/types';
import { CheckboxOption } from 'commons/SidebarV2/types';
import { getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';
import { getLabelFromVehicleType } from 'commons/Utils/vehicleTypeUtils';

import OrderFilterService from './OrderFilterService';
import { translateOrderStatus } from './OrderStatusTranslator';
import OrderTableSidebarAgentTeamFilters from './OrderTableSidebarAgentTeamFilters';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderTableReduxProps = {
  authorizedVehicleCategories: Array<VehicleCategory>;
  claimEnabled: boolean;
  langEnabled: boolean;
};

type OrderTableSidebarProps = {
  defaultFilters: OrderEsSearchQueryDTO;
  searchResults?: OrderEsSearchResultDTO;
  zones: Array<ZoneDTO>;
  tags: Array<string>;
  customFields: Array<CustomFieldDTO>;
  products: Array<ProductPrivateDTO>;
  productType: ProductType;
} & OrderTableReduxProps;

const OrderTableSidebar = ({
  defaultFilters,
  searchResults,
  zones,
  tags,
  customFields,
  products,
  productType,
  authorizedVehicleCategories,
  claimEnabled,
  langEnabled,
}: OrderTableSidebarProps): JSX.Element => {
  const [filters, setFilters] = useState<OrderEsSearchQueryDTO>(
    OrderFilterService.getFilters()
  );
  const [agentTeamFiltersEnabled, setAgentTeamFiltersEnabled] = useState<
    boolean
  >(true);

  const [zoneItems, setZoneItems] = useState<Array<CheckboxOption>>([]);
  const [tagsItems, setTagsItems] = useState<Array<CheckboxOption>>([]);
  const [productItems, setProductItems] = useState<Array<CheckboxOption>>([]);
  const [customFieldItems, setCustomFieldItems] = useState<
    Array<CheckboxOption>
  >([]);

  const orderStatusesOptions = useMemo(() => {
    return (
      Object.values(OrderStatus)
        .filter(status => {
          // remove it from the filter as it can't be in the EsOrder
          const statusesToIgnore = [
            OrderStatus.AWAIT_EVIDENCE,
            OrderStatus.AWAIT_CLAIM_EVIDENCES,
          ];

          if (!claimEnabled) {
            statusesToIgnore.push(
              ...[
                OrderStatus.REJECTED_CLAIM,
                OrderStatus.AWAIT_CLAIM_DECISION,
                OrderStatus.AWAIT_CLAIM_EVIDENCES,
                OrderStatus.AWAIT_CLAIM_COMPLETION_USER,
                OrderStatus.AWAIT_CLAIM_TREATMENT_COMPLETUDE,
              ]
            );
          }

          return !statusesToIgnore.includes(status);
        })
        .map(status => ({
          key: status,
          label: translateOrderStatus(status, undefined, undefined, true),
        })) || []
    );
  }, [claimEnabled]);

  const massActionsTypesOptions =
    Object.values(OrderMassActionsTypes).map(status => ({
      value: status,
      label: _t(`massActionsTypes.${status}`),
    })) || [];

  const decisionsSourcesOptions =
    Object.values([SubscriptionSource.BACK, SubscriptionSource.API]).map(
      status => ({
        value: status,
        label: _t(`decisionSource.${status}`),
      })
    ) || [];

  const langsOptions = langEnabled
    ? [...LANGS].map(lang => ({
        value: lang,
        label: _tg(`commons.langs.${lang}`),
      })) || []
    : [];

  function dateRangeToFilterDate(dateRange?: DateRangeDTO): FilterDate {
    return {
      from: dateRange?.from,
      to: dateRange?.to,
    };
  }

  function resetFilters() {
    const updateFilters = OrderFilterService.addAgentTeamFilters({
      ...defaultFilters,
    });
    setFilters(updateFilters);
    setAgentTeamFiltersEnabled(true);
    OrderFilterService.setFilters(() => {
      return updateFilters;
    });
  }

  function onChangeStatus(id: string, value: Array<OrderStatus>) {
    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return { ...prevFilters, [id]: value };
    });
  }

  function onChangeArrayText(id: string, value: string) {
    const updateValue = value ? [value] : undefined;
    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return { ...prevFilters, [id]: updateValue };
    });
  }

  function onChangeCheckBox(id: string, updateValue: Set<string>) {
    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return { ...prevFilters, [id]: [...updateValue] };
    });
  }

  function onChange(id: string, updateValue: string) {
    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return { ...prevFilters, [id]: updateValue };
    });
  }

  function onChangeDate(id: string, newDates: FilterDate) {
    const rangeDto: DateRangeDTO = {
      from: newDates.from,
      to: newDates.to,
    };
    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return { ...prevFilters, [id]: rangeDto };
    });
  }

  useMemo(() => {
    const items = zones.map(zone => ({
      value: zone.id,
      label: zone.name,
    }));

    setZoneItems(items);
  }, [zones]);

  useMemo(() => {
    const items = tags.map(tag => ({
      value: tag,
      label: tag,
    }));

    setTagsItems(items);
  }, [tags]);

  useMemo(() => {
    const items = customFields.map(customField => ({
      value: customField.key,
      label: customField.label,
    }));
    setCustomFieldItems(items);
  }, [customFields]);

  useMemo(() => {
    const items = products.map(product => ({
      value: product.productId,
      label: product.name,
    }));
    setProductItems(items);
  }, [products]);

  useEffect(() => {
    return OrderFilterService.watchFilters(setFilters);
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      {OrderFilterService.isAgentInTeam() && (
        <OrderTableSidebarAgentTeamFilters
          agentTeamFiltersEnabled={agentTeamFiltersEnabled}
          setAgentTeamFiltersEnabled={setAgentTeamFiltersEnabled}
        />
      )}
      <HitsCounter hits={searchResults?.totalHits || 0} />
      <Dates
        id="requestDatetime"
        title={_t('requestDatetime')}
        onChange={onChangeDate}
        dates={dateRangeToFilterDate(filters.requestDatetime)}
      />
      <Dates
        id="creation"
        title={_t('creation')}
        onChange={onChangeDate}
        dates={dateRangeToFilterDate(filters.creation)}
      />
      <Dates
        id="modification"
        title={_t('modification')}
        onChange={onChangeDate}
        dates={dateRangeToFilterDate(filters.modification)}
      />
      <Input
        id="subscriberIds"
        title={_t('subscriber')}
        placeholder="subscriberIds"
        onChange={onChangeArrayText}
        value={filters.subscriberIds ? filters.subscriberIds[0] : ''}
      />
      <Select
        id="orderStatuses"
        title={_t('orderStatus')}
        multiple
        options={orderStatusesOptions}
        selected={filters.orderStatuses || []}
        onChange={onChangeStatus}
      />
      <Input
        id="plates"
        title={_t('plate')}
        placeholder="plates"
        onChange={onChangeArrayText}
        value={filters.plates ? filters.plates[0] : ''}
      />
      <Dates
        id="validityStart"
        title={_t('validityStart')}
        onChange={onChangeDate}
        dates={dateRangeToFilterDate(filters.validityStart)}
      />
      <Dates
        id="validityEnd"
        title={_t('validityEnd')}
        onChange={onChangeDate}
        dates={dateRangeToFilterDate(filters.validityEnd)}
      />
      <Input
        id="orderIds"
        title={
          productType === ProductType.BUNDLE
            ? _t('orderId.bundle')
            : _t('orderId.eligibility')
        }
        placeholder="orderIds"
        onChange={onChangeArrayText}
        value={filters.orderIds ? filters.orderIds[0] : ''}
      />
      <Checkboxes
        title={_t('decisionSource.header')}
        id="decisionsSources"
        options={decisionsSourcesOptions}
        filters={new Set(filters.decisionsSources)}
        onChange={onChangeCheckBox}
        faceting={searchResults?.checkboxFaceting.decisionSource}
      />
      <Checkboxes
        title={_t('offers')}
        id="productIds"
        options={productItems}
        filters={new Set(filters.productIds)}
        onChange={onChangeCheckBox}
        faceting={searchResults?.checkboxFaceting.productId}
      />
      <Checkboxes
        title={_t('zones')}
        id="zoneIds"
        options={zoneItems}
        filters={new Set(filters.zoneIds)}
        onChange={onChangeCheckBox}
        faceting={searchResults?.checkboxFaceting.validityZoneIds}
      />
      <Checkboxes
        title={_t('tags')}
        id="tags"
        options={tagsItems}
        filters={new Set(filters.tags)}
        onChange={onChangeCheckBox}
        faceting={searchResults?.checkboxFaceting.tags}
      />
      {authorizedVehicleCategories.length > 0 && (
        <Checkboxes
          title={_tg('field.vehicle.categories')}
          id="vehiclesCategories"
          options={authorizedVehicleCategories.map(vc => {
            return { value: vc, label: getLabelFromVehicleType(vc) };
          })}
          filters={new Set(filters.vehiclesCategories)}
          onChange={onChangeCheckBox}
          faceting={searchResults?.checkboxFaceting.vehiclesCategories}
        />
      )}
      <Checkboxes
        title={_t('massActionsTypes.header')}
        id="massActionsTypes"
        options={massActionsTypesOptions}
        filters={new Set(filters.massActionsTypes)}
        onChange={onChangeCheckBox}
        faceting={searchResults?.checkboxFaceting.massActionsTypes}
      />
      <Input
        id="customFieldValue"
        title={_t('customFieldInput')}
        placeholder={_t('customFieldInput')}
        onChange={onChange}
        value={filters.customFieldValue || ''}
      />
      {customFieldItems.length !== 0 && (
        <Checkboxes
          title={_t('customFieldsCheckbox')}
          id="customFields"
          options={customFieldItems}
          filters={new Set(filters.customFields)}
          onChange={onChangeCheckBox}
          faceting={searchResults?.checkboxFaceting.customFields}
        />
      )}
      {langEnabled && (
        <Checkboxes
          title={_tg('commons.lang')}
          id="langs"
          options={langsOptions}
          filters={new Set(filters.langs)}
          onChange={onChangeCheckBox}
          faceting={searchResults?.checkboxFaceting.lang}
        />
      )}
    </Sidebar>
  );
};

function mapStateToProps(state: InternalApiState): OrderTableReduxProps {
  const {
    authorizedVehicleCategories,
    subscriptionConfigurationDTO: { claimEnabled, langEnabled },
  } = getConfigState(state);

  return {
    authorizedVehicleCategories: authorizedVehicleCategories.map(
      vc => vc.vehicleCategory
    ),
    claimEnabled,
    langEnabled,
  };
}

export default connect(mapStateToProps)(OrderTableSidebar);
