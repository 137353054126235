import React, { useEffect, useState } from 'react';
import { MenuItem, SelectField } from 'material-ui';

import {
  ProductOptionReference,
  ProductPrivateDTO,
} from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import { localPeriodRenderer } from 'commons/PeriodPicker';

import './ProductDetailPage.css';

type ProductDetailConditionsOptionsProps = {
  productLabel: string;
  productHintLabel: string;
  optionsLabel: string;
  optionsHintLabel: string;
  options: Array<ProductOptionReference>;
  otherProducts: Array<ProductPrivateDTO>;
  canEdit: boolean;
  onChange: (updateOptions: Array<ProductOptionReference>) => void;
};

const ProductDetailConditionsOptions = ({
  productLabel,
  productHintLabel,
  optionsLabel,
  optionsHintLabel,
  options,
  otherProducts,
  canEdit,
  onChange,
}: ProductDetailConditionsOptionsProps): JSX.Element => {
  // SelectField items auto computed by useState
  const [productItems, setProductItems] = useState<Array<ItemIdName>>([]);
  const [optionsItems, setOptionsItems] = useState<Array<ItemIdName>>([]);

  // Ids computed once at loading, then kept dirty so it's smooth to use
  const [productSelectedIds, setProductSelectedIds] = useState<Array<string>>(
    []
  );
  const [productSelectDirty, setProductSelectDirty] = useState<boolean>(false);
  const [optionsSelectedIds, setOptionsSelectedIds] = useState<Array<string>>(
    []
  );
  // Use this state to trigger updates only when a user has clicked
  const [canTriggerUpdate, setCanTriggerUpdate] = useState<boolean>(false);

  function computeSelectedProductIds(): Array<string> {
    const selectedIdsSet = new Set<string>();
    options.forEach(optRef => {
      if (otherProducts.find(bp => bp.productId === optRef.productId)) {
        selectedIdsSet.add(optRef.productId);
      }
    });
    if (productSelectDirty) {
      productSelectedIds.forEach(id => selectedIdsSet.add(id));
    }
    return [...selectedIdsSet];
  }

  function computeSelectedOptionsIds(
    selectedProductIds: Array<string>
  ): Array<string> {
    const selectedIdsSet = new Set<string>();
    options.forEach(optRef => {
      if (selectedProductIds.includes(optRef.productId)) {
        const optId = `${optRef.productId}|${optRef.productOptionId}`;
        selectedIdsSet.add(optId);
      }
    });
    return [...selectedIdsSet];
  }

  function onOptionsChange() {
    // Recompute OptionReference list
    const newOptions: Array<ProductOptionReference> = [];
    optionsSelectedIds.forEach(combinedOpt => {
      const combinedSplit = combinedOpt.split('|');
      const optionRef = {
        productId: combinedSplit[0],
        productOptionId: combinedSplit[1],
      } as ProductOptionReference;
      newOptions.push(optionRef);
    });
    // Trigger managed product change
    onChange(newOptions);
  }

  function handleProductSelect(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    newProductIds: Array<string>
  ): void {
    setProductSelectDirty(true);
    setCanTriggerUpdate(true);
    setProductSelectedIds(newProductIds);

    // Handle remove product gracefully
    if (newProductIds.length < productSelectedIds.length) {
      setOptionsSelectedIds(computeSelectedOptionsIds(newProductIds));
    }
  }

  function handleOptionSelect(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    newOptionsIds: Array<string>
  ): void {
    setProductSelectDirty(true);
    setCanTriggerUpdate(true);
    setOptionsSelectedIds(newOptionsIds);
  }

  function renderMultiSelect(
    label: string,
    hint: string,
    value: Array<string>,
    items: Array<ItemIdName>,
    onChangeCallback: (
      e: React.SyntheticEvent,
      i: number,
      v: Array<string>
    ) => void
  ): JSX.Element {
    return (
      <SelectField
        floatingLabelFixed
        floatingLabelText={label}
        disabled={!canEdit}
        hintText={hint}
        fullWidth
        multiple
        onChange={onChangeCallback}
        value={value}
      >
        {items.map(item => (
          <MenuItem
            id={item.id}
            key={item.id}
            value={item.id}
            primaryText={item.name}
            checked={value.includes(item.id)}
            insetChildren
          />
        ))}
      </SelectField>
    );
  }

  // Compute product items only once when prop updates
  useEffect(() => {
    const items = otherProducts.map(b => {
      return {
        id: b.productId,
        name: b.name,
      };
    });
    setProductItems(items);
  }, [otherProducts]);

  // Recompute combined options items on product select
  useEffect(() => {
    const optItems: Array<ItemIdName> = [];
    otherProducts.forEach(p => {
      if (productSelectedIds.includes(p.productId)) {
        p.options.forEach(opt => {
          const ftmDuration = localPeriodRenderer(opt.duration) || '';
          const item = {
            id: `${p.productId}|${opt.id}`,
            name: `${p.name} - ${ftmDuration}`,
          };
          optItems.push(item);
        });
      }
    });
    setOptionsItems(optItems);
  }, [productSelectedIds]);

  // Update managed product on option select update
  useEffect(() => {
    if (canTriggerUpdate) {
      setCanTriggerUpdate(false);
      onOptionsChange();
    }
  }, [optionsSelectedIds]);

  // Recompute selections once on Props update
  useEffect(() => {
    setCanTriggerUpdate(false);

    const pIds = computeSelectedProductIds();
    const optsIds = computeSelectedOptionsIds(pIds);
    setProductSelectedIds(pIds);
    setOptionsSelectedIds(optsIds);
  }, [options, otherProducts]);

  return (
    <div className="product-detail_section-content-row">
      <span className="product-detail_cell-50">
        {renderMultiSelect(
          productLabel,
          productHintLabel,
          productSelectedIds,
          productItems,
          handleProductSelect
        )}
      </span>
      <span className="product-detail_cell-50">
        {renderMultiSelect(
          optionsLabel,
          optionsHintLabel,
          optionsSelectedIds,
          optionsItems,
          handleOptionSelect
        )}
      </span>
    </div>
  );
};

export default ProductDetailConditionsOptions;
