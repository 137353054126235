import React from 'react';
import { Card } from 'material-ui';

import { Tabs, Tab } from '@cvfm-front/commons-ui';
import useControl from 'commons/hooks/useControl';
import useZoning from 'commons/hooks/useZoning';
import useFps from 'commons/hooks/useFps';

import ControlMap from './ControlMap';
import ControlDetail from './ControlDetail';

const { _t } = window.loadTranslations(__filename);

const ControlSummary = (): JSX.Element => {
  const control = useControl();
  const zoning = useZoning();
  const fps = useFps();

  return (
    <Card className="recourse-card">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('controlSummary.title')} </div>
      </div>
      <div className="recourse-card__content text--small">
        <Tabs
          defaultActiveTabIndex={0}
          tabsClassName="text-bold"
          contentClassName="margin-y--s"
        >
          <Tab title={_t('controlSummary.informations')}>
            <div>
              <ControlDetail control={control} zoning={zoning} />
            </div>
          </Tab>
          <Tab title={_t('controlSummary.position')}>
            <div>
              <ControlMap control={control} fps={fps} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </Card>
  );
};

export default ControlSummary;
