import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { RecourseDecisionDTO } from 'api/recourse/types';
import Separator from 'commons/Separator';

const { _t, _tg } = window.loadTranslations(__filename);

type ProposalDecisionProps = {
  decision: RecourseDecisionDTO;
};

const ProposalDecision = ({ decision }: ProposalDecisionProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.date')}
            value={<Date datetime={decision.datetime} withTime={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.agent.id')}
            value={decision.agent?.agentId ?? _t('field.noAgent')}
          />
          <FakeInputBlock
            title={_tg('field.agent.agent')}
            value={decision.agent?.name ?? _t('field.noAgent')}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default ProposalDecision;
