import React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { Lang, LANGS } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  lang: Lang;
  setLang: (lang: Lang) => void;
};

const OrderCreateSubscriberLang = ({ lang, setLang }: Props): JSX.Element => {
  return (
    <div className="order-detail_row">
      <span className="order-detail_cell-25">{_tg('commons.userLang')}</span>
      <span className="order-detail_cell-75">
        <SelectField
          value={lang}
          onChange={(
            _: React.ChangeEvent<HTMLInputElement>,
            __: number,
            lang: Lang
          ) => setLang(lang)}
        >
          {[...LANGS]
            .filter(langOpt => langOpt !== Lang.unknown)
            .map(langOpt => (
              <MenuItem
                key={`menu-item-key-${langOpt}`}
                value={langOpt}
                primaryText={_tg(`commons.langs.${langOpt}`)}
              />
            ))}
        </SelectField>
      </span>
    </div>
  );
};

export default OrderCreateSubscriberLang;
