import React, { useEffect, useRef, useState } from 'react';
import { MenuItem } from 'material-ui';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import CancelIcon from 'material-ui/svg-icons/navigation/cancel';
import DoneIcon from 'material-ui/svg-icons/action/done-all';
import MailIcon from 'material-ui/svg-icons/content/mail';
import PaymentIcon from 'material-ui/svg-icons/action/payment';
import moment from 'moment';
import DropDownMenu from 'material-ui/DropDownMenu';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  OrderPrivateDTO,
  OrderSimpleDTO,
  OrderStatus,
  OrderTraceType,
  ProductOption,
  ProductPrivateDTO,
  ProductType,
  SubscriberAccountType,
  SubscriberDTO,
  SubscriberVehicle,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import './OrderDetailPage.css';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_CYAN, BKG_DARK_BLUE, BKG_PINK } from 'theme';
import {
  fetchInducedOrders,
  getOrderWithInstantPrice,
  TakeDecisionUpdateParams,
  hasInvoice,
} from 'api/cvfm-core-subscription/order';
import 'commons/css/buttonGroup.css';
import { getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';

import FormComponent from '../../commons/FormComponent';

import {
  CancelFormType,
  COMPLEMENT_STATUSES,
  AWAIT_DECISION_STATUSES,
  downloadInvoicePDF,
  downloadPaymentPDF,
  filterSubscriberVehiclesFromProductScope,
  generateRenewalURI,
  shouldRenewOrder,
} from './helpers';
import OrderDetailSummary from './OrderDetailSummary';
import OrderDetailHistory from './OrderDetailHistory';
import OrderDetailModalValidityPeriod from './OrderDetailModalValidityPeriod';
import OrderDetailModalEvidenceRequest from './OrderDetailModalEvidenceRequest';
import OrderDetailModalEvidence from './OrderDetailModalEvidence';
import OrderDetailModalCustomField from './OrderDetailModalCustomField';
import OrderDetailModalDecision from './OrderDetailModalDecision';
import OrderDetailModalPlates from './OrderDetailModalPlates';
import OrderDetailModalPayment from './OrderDetailModalPayment';
import OrderInduced from './OrderInduced';
import OrderDetailModalCancel from './OrderDetailModalCancel';
import OrderReminders from './OrderReminders';
import OrderDetailModalZones from './OrderDetailModalZones';
import OrderDetailModalTransfer from './OrderDetailModalTransfer';
import OrderDetailModalSubscribers from './OrderDetailModalSubscribers';
import OrderDetailModalTags from './OrderDetailModalTags';
import OrderDetailModalMakeClaim from './OrderDetailModalMakeClaim';

const { _t, _tg } = window.loadTranslations(__filename);

const RAISED_BUTTON_CONTAINER_STYLE = {
  borderRadius: 0,
  flex: 1,
  margin: '0px 25px',
  maxWidth: '30%',
};

interface OrderDetailReduxProps {
  claimEnabled: boolean;
}

type OrderDetailProps = {
  order: OrderPrivateDTO;
  zones: Array<ZoneDTO>;
  orderProduct: ProductPrivateDTO;
  orderSubscribers: Array<SubscriberDTO>;
  canEdit: boolean;
  canAccept: boolean;
  onUpdateAction: (
    update: Promise<OrderPrivateDTO>,
    params?: TakeDecisionUpdateParams
  ) => void;
  onCancelAction: (orderId: string, formEntries: CancelFormType) => void;
  refreshOrder: () => void;
  accessToSubscriber: () => void;
  accessToActiveRightUsed: () => void;
  accessToOrderInduced: (orderInduced: OrderSimpleDTO) => void;
} & OrderDetailReduxProps;

const Label = ({
  title,
  icon,
}: {
  title: string;
  icon: JSX.Element;
}): React.ReactElement<any> => (
  <div style={{ display: 'inline-block' }}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {icon}
      {title}
    </span>
  </div>
);

const OrderDetail = ({
  order,
  zones,
  orderProduct,
  orderSubscribers,
  canEdit,
  canAccept,
  onUpdateAction,
  onCancelAction,
  refreshOrder,
  accessToSubscriber,
  accessToActiveRightUsed,
  accessToOrderInduced,
  claimEnabled,
}: OrderDetailProps): JSX.Element => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState<OrderTraceType>();
  const [modalDecisionAccept, setModalDecisionAccept] = useState<boolean>(true);
  const [invoiceExists, setInvoiceExists] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [ordersInduced, setOrdersInduced] = useState<
    Array<OrderSimpleDTO> | undefined
  >(); // undefined : not yet fetched

  const cancelForm = useRef<FormComponent | null>(null);

  useEffect(() => {
    void fetchInducedOrders(order.orderId).then(fetchedOrders =>
      setOrdersInduced(fetchedOrders)
    );
    hasInvoice(order.orderId)
      .then(res => {
        setInvoiceExists(res);
      })
      .catch(err => console.error(err));
  }, [order]);

  function openCancelModal() {
    setCancelModal(true);
  }

  function closeCancelModal() {
    setCancelModal(false);
  }

  function handleModalCancel() {
    setOpenModal(undefined);
  }

  function handleModalUpdate(
    update: Promise<OrderPrivateDTO>,
    params?: TakeDecisionUpdateParams
  ) {
    setOpenModal(undefined);
    onUpdateAction(update, params);
  }

  const handleCancelAction = () => {
    if (cancelForm.current && cancelForm.current?.isValid()) {
      const entries = cancelForm.current.getFormEntries() as CancelFormType;
      onCancelAction(order.orderId, entries);
      closeCancelModal();
    }
  };

  function handleModifyChange(_e: any, _number: any, value: string) {
    if (value === 'PLATES') {
      setOpenModal(OrderTraceType.PLATES);
    } else if (value === 'VALIDITY_PERIOD') {
      setOpenModal(OrderTraceType.VALIDITY_PERIOD);
    } else if (value === 'ZONES') {
      setOpenModal(OrderTraceType.ZONES);
    } else if (value === 'TAGS') {
      setOpenModal(OrderTraceType.TAGS);
    } else if (value === 'CUSTOM_FIELD') {
      setOpenModal(OrderTraceType.CUSTOM_FIELD);
    } else if (value === 'EVIDENCE') {
      setOpenModal(OrderTraceType.EVIDENCE);
    } else if (value === 'CLAIM_EVIDENCE') {
      setOpenModal(OrderTraceType.CLAIM_EVIDENCE);
    } else if (value === 'EVIDENCE_REQUEST') {
      setOpenModal(OrderTraceType.EVIDENCE_REQUEST);
    } else if (value === 'TRANSFER') {
      setOpenModal(OrderTraceType.TRANSFER);
    } else if (value === 'DECISION') {
      setOpenModal(OrderTraceType.DECISION);
    } else if (value === 'RENEW') {
      history.push(generateRenewalURI(order, orderProduct));
    } else if (value === 'PAYMENT') {
      setOpenModal(OrderTraceType.PAYMENT);
    } else if (value === 'REFUND') {
      setOpenModal(OrderTraceType.REFUND);
    } else if (value === 'CANCELLATION') {
      setCancelModal(true);
    } else if (value === 'SUBSCRIBERS') {
      setOpenModal(OrderTraceType.SUBSCRIBERS);
    } else if (value === 'MAKE_CLAIM') {
      setOpenModal(OrderTraceType.MAKE_CLAIM);
    }
  }

  const displayEvidenceProvide =
    order.status === OrderStatus.FULFILLED ||
    order.status === OrderStatus.AWAIT_EVIDENCE ||
    order.status === OrderStatus.AWAIT_COMPLETION_USER ||
    order.status === OrderStatus.AWAIT_CLAIM_EVIDENCES ||
    order.status === OrderStatus.AWAIT_CLAIM_COMPLETION_USER;
  const displayEvidenceRequest = COMPLEMENT_STATUSES.includes(order.status);
  const displayCustomField =
    orderProduct.applicationProcedure &&
    orderProduct.applicationProcedure.customFields &&
    orderProduct.applicationProcedure.customFields.length > 0;
  const displayDecision = AWAIT_DECISION_STATUSES.includes(order.status);
  const displayCancel =
    (order.status === OrderStatus.FULFILLED && canEdit) ||
    order.status === OrderStatus.AWAIT_PAYMENT;
  const displayPayment = order.status === OrderStatus.AWAIT_PAYMENT;
  const displayRenewal =
    orderProduct.restrictions.renewable &&
    orderProduct.privateOrdersEnabled &&
    shouldRenewOrder(order, orderProduct);
  const displayRefund = !!(
    order.status === OrderStatus.CANCELED && order?.amountPaid
  );
  const displayMakeClaim =
    claimEnabled &&
    orderProduct.applicationProcedure?.authorizeClaims &&
    order.status === OrderStatus.REJECTED;

  const subscribersVehicles: Array<SubscriberVehicle> = orderSubscribers
    .map(s => filterSubscriberVehiclesFromProductScope(orderProduct, s))
    .reduce((acc, val) => acc.concat(val), []);

  const getOrderActivePlateVehicles = () => {
    const activePlates = order.plateHistory[order.plateHistory.length - 1];
    return activePlates
      ? subscribersVehicles.filter(vehicle =>
          activePlates.plates.includes(vehicle.plate)
        )
      : [];
  };

  const pay = async () => {
    if (orderProduct.pricingConfiguration.usePricingCalculator) {
      await getOrderWithInstantPrice(order.orderId).then(res => {
        order.orderPrice = res.orderPrice;
      });
    }
    setOpenModal(OrderTraceType.PAYMENT);
  };

  return (
    <>
      <OrderDetailSummary
        order={order}
        zones={zones}
        orderProduct={orderProduct}
        orderSubscribers={orderSubscribers}
        refreshOrder={refreshOrder}
      />
      {canEdit && (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <div
            style={{
              marginTop: 50,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              padding: '0 2.5%',
            }}
          >
            {displayPayment && (
              <BoButton
                backgroundColor={BKG_PINK}
                buttonStyle={{ borderRadius: '5px 5px 5px 5px' }}
                style={RAISED_BUTTON_CONTAINER_STYLE}
                label={
                  <Label
                    title={_tg('field.payment.payment')}
                    icon={
                      <PaymentIcon style={{ marginRight: 5 }} color="#ffffff" />
                    }
                  />
                }
                onClick={pay}
                labelColor="#ffffff"
              />
            )}
            {displayDecision && (
              <BoButton
                backgroundColor={BKG_CYAN}
                buttonStyle={{ borderRadius: '5px 5px 5px 5px' }}
                style={RAISED_BUTTON_CONTAINER_STYLE}
                label={
                  <Label
                    title={_t('action.accept')}
                    icon={
                      <DoneIcon style={{ marginRight: 5 }} color="#ffffff" />
                    }
                  />
                }
                onClick={() => {
                  setOpenModal(OrderTraceType.DECISION);
                  setModalDecisionAccept(true);
                }}
                labelColor="#ffffff"
                disabled={
                  order.productType === ProductType.ELIGIBILITY && !canAccept
                }
              />
            )}
            {displayEvidenceRequest &&
              orderProduct.productType === ProductType.ELIGIBILITY && (
                <BoButton
                  backgroundColor={BKG_DARK_BLUE}
                  buttonStyle={{ borderRadius: '5px 5px 5px 5px' }}
                  style={RAISED_BUTTON_CONTAINER_STYLE}
                  label={
                    <Label
                      title={_t('action.complement')}
                      icon={
                        <MailIcon style={{ marginRight: 5 }} color="#ffffff" />
                      }
                    />
                  }
                  onClick={() =>
                    setOpenModal(
                      order.claim
                        ? OrderTraceType.CLAIM_EVIDENCE_REQUEST
                        : OrderTraceType.EVIDENCE_REQUEST
                    )
                  }
                  labelColor="#ffffff"
                  disabled={order.status === OrderStatus.AWAIT_PLATE_DECISION}
                />
              )}
            {displayDecision && (
              <BoButton
                backgroundColor={BKG_PINK}
                buttonStyle={{ borderRadius: '5px 5px 5px 5px' }}
                style={RAISED_BUTTON_CONTAINER_STYLE}
                label={
                  <Label
                    title={_t('action.reject')}
                    icon={
                      <CancelIcon style={{ marginRight: 5 }} color="#ffffff" />
                    }
                  />
                }
                onClick={() => {
                  setOpenModal(OrderTraceType.DECISION);
                  setModalDecisionAccept(false);
                }}
                labelColor="#ffffff"
              />
            )}
            <DropDownMenu
              value={1}
              onChange={handleModifyChange}
              style={{
                ...RAISED_BUTTON_CONTAINER_STYLE,
                borderRadius: '5px 5px 5px 5px',
                backgroundColor: BKG_DARK_BLUE,
                height: 36,
              }}
              iconButton=""
              underlineStyle={{ borderTop: 0 }}
              labelStyle={{
                color: '#ffffff',
                textTransform: 'uppercase',
                fontWeight: 500,
                fontSize: 14,
                textAlign: 'center',
                lineHeight: '36px',
                height: 36,
                padding: 0,
              }}
              menuStyle={{ width: '100%' }}
            >
              <MenuItem
                value={1}
                primaryText={
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <EditIcon style={{ marginRight: 5 }} color="#ffffff" />{' '}
                    <span>{_tg('action.modify')}</span>
                  </span>
                }
                style={{ display: 'none' }}
              />
              {order.status !== OrderStatus.AWAIT_PLATE_DECISION && (
                <MenuItem value="PLATES" primaryText={_t('action.plates')} />
              )}
              <MenuItem
                value="VALIDITY_PERIOD"
                primaryText={_t('action.validityPeriod')}
              />
              <MenuItem value="ZONES" primaryText={_t('action.zones')} />
              <MenuItem value="TAGS" primaryText={_t('action.tags')} />
              {displayCustomField && (
                <MenuItem
                  value="CUSTOM_FIELD"
                  primaryText={_t('action.customField')}
                />
              )}
              {orderProduct.orderShareEnabled && (
                <MenuItem
                  value="SUBSCRIBERS"
                  primaryText={_t('action.subscribers')}
                />
              )}
              {displayEvidenceProvide && (
                <MenuItem
                  value={
                    order.claim ? OrderTraceType.CLAIM_EVIDENCE : 'EVIDENCE'
                  }
                  primaryText={_t('action.evidenceProvide')}
                />
              )}
              <MenuItem value="TRANSFER" primaryText={_t('action.transfer')} />
              {displayRenewal && (
                <MenuItem value="RENEW" primaryText={_t('action.renew')} />
              )}
              {displayRefund && (
                <MenuItem
                  value="REFUND"
                  primaryText={_tg('field.payment.refund')}
                />
              )}
              {displayMakeClaim && (
                <MenuItem
                  value="MAKE_CLAIM"
                  primaryText={_t('action.makeClaim')}
                />
              )}
            </DropDownMenu>

            {displayCancel && (
              <BoButton
                backgroundColor={BKG_PINK}
                buttonStyle={{ borderRadius: '5px 5px 5px 5px' }}
                style={{
                  ...RAISED_BUTTON_CONTAINER_STYLE,
                  borderRadius: '5px 5px 5px 5px',
                }}
                label={
                  <Label
                    title={_tg('action.cancel')}
                    icon={
                      <CancelIcon style={{ marginRight: 5 }} color="#ffffff" />
                    }
                  />
                }
                onClick={openCancelModal}
                labelColor="#ffffff"
                disabled={ordersInduced?.some(
                  orderInduced =>
                    orderInduced.status === OrderStatus.FULFILLED &&
                    orderInduced.validityPeriod.endOfValidity &&
                    moment(orderInduced.validityPeriod.endOfValidity).isAfter(
                      moment()
                    )
                )}
              />
            )}
          </div>
        </div>
      )}
      <div className="button-group full-width order-detail-button_container">
        <BoButton
          className="order-detail-button"
          label={_t('action.go_to_subscriber')}
          onClick={accessToSubscriber}
        />
        {order.fullyPaid && order.orderPrice === 0 && (
          <BoButton
            className="order-detail-button"
            label={_t('action.download_proof')}
            onClick={() => downloadPaymentPDF(order.orderId)}
          />
        )}
        {order.fullyPaid &&
          order.orderPrice !== 0 &&
          orderProduct.paymentInvoiceEnabled &&
          invoiceExists && (
            <BoButton
              className="order-detail-button"
              label={_t('action.download_payment_invoice')}
              onClick={() => downloadInvoicePDF(order.orderId)}
            />
          )}
        {order.activeRightUsed?.rightId && (
          <BoButton
            className="order-detail-button"
            label={_t('action.go_to_active_right_used')}
            onClick={accessToActiveRightUsed}
          />
        )}
      </div>
      <div className="full-width">
        {orderProduct.productType === ProductType.ELIGIBILITY && (
          <OrderInduced
            ordersInduced={ordersInduced}
            accessToOrderInduced={accessToOrderInduced}
          />
        )}
      </div>
      <div className="full-width no_margin">
        <OrderReminders order={order} />
      </div>
      <SeparatorWithTitle
        className="order-detail_separator"
        title={_tg('commons.history')}
        color={BKG_PINK}
        titleSize={20}
      />
      <OrderDetailHistory
        order={order}
        orderProduct={orderProduct}
        orderSubscribers={orderSubscribers}
      />
      {openModal && (
        <>
          <OrderDetailModalValidityPeriod
            open={openModal === OrderTraceType.VALIDITY_PERIOD}
            product={orderProduct}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalPlates
            open={openModal === OrderTraceType.PLATES}
            product={orderProduct}
            order={order}
            ordersInduced={ordersInduced}
            subscriberVehicles={subscribersVehicles}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalZones
            open={openModal === OrderTraceType.ZONES}
            product={orderProduct}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalTags
            open={openModal === OrderTraceType.TAGS}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalEvidenceRequest
            open={
              openModal === OrderTraceType.EVIDENCE_REQUEST ||
              openModal === OrderTraceType.CLAIM_EVIDENCE_REQUEST
            }
            order={order}
            supportingEvidences={
              orderProduct.applicationProcedure?.supportingEvidences
            }
            subscribersVehicles={getOrderActivePlateVehicles()}
            professional={
              orderProduct.conditions.productScope ===
              SubscriberAccountType.PROFESSIONAL
            }
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalEvidence
            open={
              openModal === OrderTraceType.EVIDENCE ||
              openModal === OrderTraceType.CLAIM_EVIDENCE
            }
            product={orderProduct}
            order={order}
            subscribers={orderSubscribers}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalCustomField
            open={openModal === OrderTraceType.CUSTOM_FIELD}
            product={orderProduct}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalTransfer
            open={openModal === OrderTraceType.TRANSFER}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
            subscribers={orderSubscribers}
          />
          <OrderDetailModalDecision
            isOpen={
              openModal === OrderTraceType.DECISION ||
              openModal === OrderTraceType.CLAIM_DECISION
            }
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
            initialDecisionAccept={modalDecisionAccept}
          />
          <OrderDetailModalPayment
            open={
              openModal === OrderTraceType.PAYMENT ||
              openModal === OrderTraceType.REFUND
            }
            traceType={openModal}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
          <OrderDetailModalSubscribers
            open={openModal === OrderTraceType.SUBSCRIBERS}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
            order={order}
            orderSubscribers={orderSubscribers}
          />
          <OrderDetailModalMakeClaim
            open={openModal === OrderTraceType.MAKE_CLAIM}
            order={order}
            onConfirm={handleModalUpdate}
            onCancel={handleModalCancel}
          />
        </>
      )}
      <OrderDetailModalCancel
        isOpen={cancelModal}
        cancelForm={cancelForm}
        onConfirm={handleCancelAction}
        onClose={closeCancelModal}
      />
    </>
  );
};

function mapStateToProps(state: InternalApiState): OrderDetailReduxProps {
  const {
    subscriptionConfigurationDTO: { claimEnabled },
  } = getConfigState(state);

  return {
    claimEnabled,
  };
}

export default connect(mapStateToProps)(OrderDetail);
