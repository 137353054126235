import React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';

import './OrderActions.css';

const { _t, _tg } = window.loadTranslations(__filename);

const TABLE_COLS = [
  { label: '', width: 30 },
  { label: _t('table.id'), width: 300 },
  { label: _t('table.comment'), width: 400 },
];

type Props = {
  title: string;
  isOpen: boolean;
  orderErrors: { id: string; err: string }[];
  closeModal: () => void;
};

function OrderMassUpdateFailedInformation({
  title,
  isOpen,
  orderErrors,
  closeModal,
}: Props): JSX.Element {
  function renderRow(order: { id: string; err: string }) {
    return ['', order.id, order.err];
  }

  const action = [
    <BoButton
      key="close"
      label={_tg('action.close')}
      primary
      onClick={closeModal}
    />,
  ];

  return (
    <Dialog
      titleClassName="order-prolongation_modal_title-error"
      title={title}
      actions={action}
      modal
      autoScrollBodyContent
      open={isOpen}
    >
      <SimpleTable
        maxHeight={300}
        cols={TABLE_COLS}
        rowHeight={50}
        header
        itemsRenderer={renderRow}
        style={{ marginTop: 10 }}
        items={orderErrors}
      />
    </Dialog>
  );
}

export default OrderMassUpdateFailedInformation;
