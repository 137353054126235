import React from 'react';

import OrderMassUpdateProgressBar from './OrderMassUpdateProgressBar';
import OrderMassUpdateSuccess from './OrderMassUpdateSuccess';
import OrderMassUpdateFailedInformation from './OrderMassUpdateFailedInformation';

type OrderMassUpdateProcessProps = {
  titleFail: string;
  titleSuccess: string;
  titleProgress: string;
  isOpen: boolean;
  isAllSettled: boolean;
  isLoadingProgress: boolean;
  uploadProgressState: number;
  uploadErrorIds: { id: string; err: string }[];
  max: number;
  onCloseModal: () => void;
};

const OrderMassUpdateProcess = ({
  titleFail,
  titleSuccess,
  titleProgress,
  isOpen,
  isAllSettled,
  isLoadingProgress,
  uploadProgressState,
  uploadErrorIds,
  max,
  onCloseModal,
}: OrderMassUpdateProcessProps): JSX.Element => {
  if (uploadErrorIds.length > 0 && !isLoadingProgress && isAllSettled) {
    return (
      <OrderMassUpdateFailedInformation
        title={titleFail}
        isOpen={isOpen}
        orderErrors={uploadErrorIds}
        closeModal={onCloseModal}
      />
    );
  }

  if (!isLoadingProgress && isAllSettled) {
    return (
      <OrderMassUpdateSuccess
        title={titleSuccess}
        isOpen={isOpen}
        closeModal={onCloseModal}
      />
    );
  }

  if (isLoadingProgress) {
    return (
      <OrderMassUpdateProgressBar
        title={titleProgress}
        isOpen={isOpen}
        max={max}
        progress={uploadProgressState}
      />
    );
  }

  return <></>;
};

export default OrderMassUpdateProcess;
