import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Toggle } from 'material-ui';
import moment from 'moment';

import BoButton from 'facade/BoButton';
import { ProductPrivateDTO } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import { localPeriodRenderer } from 'commons/PeriodPicker';
import { formatCentsToCurrency } from 'commons/Utils/paymentUtil';
import ConfirmAction from 'commons/ConfirmAction';

import './ProductTablePage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductTableProps = {
  canEdit: boolean;
  canDelete: boolean;
  products: Array<ProductPrivateDTO>;
  onToggle: (productId: string, field: string, toggled: boolean) => void;
  onDelete: (productId: string) => void;
};

const ProductTable = ({
  canEdit,
  canDelete,
  products,
  onToggle,
  onDelete,
}: ProductTableProps): JSX.Element => {
  const { url } = useRouteMatch();

  function handleDelete(productId: string) {
    onDelete(productId);
  }

  function handleToggle(
    e: React.MouseEvent<HTMLDataElement>,
    toggled: boolean
  ) {
    const productId = e.currentTarget.dataset.id;
    const { field } = e.currentTarget.dataset;

    if (productId && field) {
      onToggle(productId, field, toggled);
    }
  }

  const getHeaderCols = () => [
    { label: _t('table.header.name'), width: 200, grow: 2 },
    { label: _t('table.header.options'), width: 80, grow: 1 },
    { label: _t('table.header.price.min'), width: 80, grow: 1 },
    { label: _t('table.header.duration.min'), width: 80, grow: 1 },
    { label: _t('table.header.price.max'), width: 80, grow: 1 },
    { label: _t('table.header.duration.max'), width: 80, grow: 1 },
    { label: _t('table.header.open.users'), width: 80, grow: 1 },
    { label: _t('table.header.open.agents'), width: 80, grow: 1 },
    { label: '', width: 80, grow: 1 },
  ];

  const renderNameCell = (product: ProductPrivateDTO): JSX.Element => {
    return (
      <a
        id={`${product.productId}-name`}
        className="product-table-row_name"
        href={`#${url}/${product.productId}`}
      >
        {product.name}
      </a>
    );
  };

  const renderOptionsCountCell = (product: ProductPrivateDTO): JSX.Element => {
    return (
      <span
        id={`${product.productId}-options-count`}
        className="product-table-row_options-count"
      >
        {product.options.reduce(
          (accumulator, option) =>
            option.deleted ? accumulator : accumulator + 1,
          0
        )}
      </span>
    );
  };

  const renderMinPriceCell = (product: ProductPrivateDTO): JSX.Element => {
    let minPrice = 10e6;
    product.options
      .filter(option => !option.deleted)
      .forEach(opt => {
        minPrice = Math.min(minPrice, opt.price);
      });
    return (
      <span
        id={`${product.productId}-min-price`}
        className="product-table-row_min-price"
      >
        {formatCentsToCurrency(minPrice, 'EUR')}
      </span>
    );
  };

  const renderMinDurationCell = (product: ProductPrivateDTO): JSX.Element => {
    let min = 10e12;
    let duration = 'P0D';
    product.options
      .filter(option => !option.deleted)
      .forEach(opt => {
        const cmp = moment.duration(opt.duration).asHours();
        if (min > cmp) {
          min = cmp;
          duration = opt.duration;
        }
      });
    return (
      <span
        id={`${product.productId}-min-duration`}
        className="product-table-row_min-duration"
      >
        {localPeriodRenderer(duration)}
      </span>
    );
  };

  const renderMaxPriceCell = (product: ProductPrivateDTO): JSX.Element => {
    let maxPrice = 0.0;
    product.options
      .filter(option => !option.deleted)
      .forEach(opt => {
        maxPrice = Math.max(maxPrice, opt.price);
      });
    return (
      <span
        id={`${product.productId}-max-price`}
        className="product-table-row_max-price"
      >
        {formatCentsToCurrency(maxPrice, 'EUR')}
      </span>
    );
  };

  const renderMaxDurationCell = (product: ProductPrivateDTO): JSX.Element => {
    let max = 0;
    let duration = 'P0D';
    product.options
      .filter(option => !option.deleted)
      .forEach(opt => {
        const cmp = moment.duration(opt.duration).asHours();
        if (max < cmp) {
          max = cmp;
          duration = opt.duration;
        }
      });
    return (
      <span
        id={`${product.productId}-max-duration`}
        className="product-table-row_max-duration"
      >
        {localPeriodRenderer(duration)}
      </span>
    );
  };

  const renderPublicOrderCell = (product: ProductPrivateDTO): JSX.Element => {
    return (
      <span
        id={`${product.productId}-public-orders`}
        className="product-table-row_toggle-orders"
      >
        <Toggle
          data-id={product.productId}
          data-field="publicOrdersEnabled"
          toggled={product.publicOrdersEnabled}
          onToggle={handleToggle}
          disabled={!canEdit}
        />
      </span>
    );
  };

  const renderPrivateOrderCell = (product: ProductPrivateDTO): JSX.Element => {
    return (
      <span
        id={`${product.productId}-private-orders`}
        className="product-table-row_toggle-orders"
      >
        <Toggle
          data-id={product.productId}
          data-field="privateOrdersEnabled"
          toggled={product.privateOrdersEnabled}
          onToggle={handleToggle}
          disabled={!canEdit}
        />
      </span>
    );
  };

  const renderDeleteCell = (product: ProductPrivateDTO): JSX.Element => {
    return (
      <span
        id={`${product.productId}-delete`}
        className="product-table-row_delete"
      >
        <ConfirmAction
          enabled
          message={_tg('action.confirmDelete')}
          action={() => handleDelete(product.productId)}
        >
          <BoButton
            primary
            label={_tg('action.delete')}
            disabled={!canDelete}
          />
        </ConfirmAction>
      </span>
    );
  };

  const renderRow = (product: ProductPrivateDTO) => {
    const row = [
      renderNameCell(product),
      renderOptionsCountCell(product),
      renderMinPriceCell(product),
      renderMinDurationCell(product),
      renderMaxPriceCell(product),
      renderMaxDurationCell(product),
      renderPublicOrderCell(product),
      renderPrivateOrderCell(product),
      renderDeleteCell(product),
    ];
    return row;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={products}
    />
  );
};

export default ProductTable;
