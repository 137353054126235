import React from 'react';

import {
  OrderPrivateDTO,
  ProductPrivateDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import './OrderDetailPage.css';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { formatDatetime } from 'commons/Date';

const { _tg } = window.loadTranslations(__filename);

type OrderDetailHistoryCreationProps = {
  orderProduct: ProductPrivateDTO;
  order: OrderPrivateDTO;
  zones: Array<ZoneDTO>;
};

const OrderDetailHistoryCreation = ({
  orderProduct,
  order,
  zones,
}: OrderDetailHistoryCreationProps): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_tg('field.vehicle.licensePlate_maybe_plural')}
        value={order.plateHistory[0].plates.join(', ')}
      />
      <FakeInputBlock
        title={_tg('field.address.validityZones')}
        value={order.zoneHistory[0].zoneIds
          .map(zId => zones?.find(zone => zone.id === zId)?.name)
          .join(', ')}
      />
      <FakeInputBlock
        title={_tg('field.date.beginning')}
        value={formatDatetime({
          datetime: order.validityPeriodHistory[0].startOfValidity,
          withTime: orderProduct.computeExactHourlyEnd,
        })}
      />
      <FakeInputBlock
        title={_tg('field.date.end')}
        value={formatDatetime({
          datetime: order.validityPeriodHistory[0].endOfValidity,
          withTime: orderProduct.computeExactHourlyEnd,
        })}
      />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryCreation;
