import React from 'react';
import FileIcon from 'material-ui/svg-icons/content/content-paste';

import {
  OrderPlateChangeRequest,
  ProductPrivateDTO,
  SubscriberAccountType,
  SubscriberDTO,
  SubscriberMediaDTO,
  SubscriberPersonalProfile,
  SubscriberProfessionalProfile,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';
import './OrderDetailPage.css';
import FakeInputBlock from 'commons/FakeInputBlock';
import { DataBoxItem } from 'commons/DataBox';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailHistoryPlateChangeRequestProps = {
  subscribers: Array<SubscriberDTO>;
  orderTrace: OrderPlateChangeRequest;
  orderProduct: ProductPrivateDTO;
};

const OrderDetailHistoryPlateChangeRequest = ({
  subscribers,
  orderTrace,
  orderProduct,
}: OrderDetailHistoryPlateChangeRequestProps): JSX.Element => {
  const subscriberEvidences = subscribers.reduce(
    (acc, sub) => acc.concat(sub.evidences),
    [] as Array<SubscriberMediaDTO>
  );

  const registrationDocument =
    orderTrace.registrationDocumentSubscriberMediaId &&
    subscriberEvidences.find(
      evidence =>
        evidence.id === orderTrace.registrationDocumentSubscriberMediaId
    );

  const otherDocument =
    orderTrace.otherProofSubscriberMediaId &&
    subscriberEvidences.find(
      evidence => evidence.id === orderTrace.otherProofSubscriberMediaId
    );

  const getMatchingVehicle = (
    profile:
      | SubscriberPersonalProfile
      | SubscriberProfessionalProfile
      | undefined
  ): SubscriberVehicle | undefined => {
    if (!profile || !profile.vehicles) {
      return undefined;
    }
    return profile.vehicles.find(
      vehicle =>
        vehicle.registrationDocuments &&
        vehicle.plate === orderTrace.orderPlateChange.newPlate
    );
  };

  const vehicleMatchingPlate = subscribers.reduce((vehicle, sub) => {
    if (vehicle) {
      return vehicle;
    }
    if (
      orderProduct.conditions.productScope === SubscriberAccountType.PERSONAL
    ) {
      return getMatchingVehicle(sub.personalProfile);
    }
    if (
      orderProduct.conditions.productScope ===
      SubscriberAccountType.PROFESSIONAL
    ) {
      return getMatchingVehicle(sub.professionalProfile);
    }
    return (
      getMatchingVehicle(sub.personalProfile) ||
      getMatchingVehicle(sub.professionalProfile)
    );
  }, undefined as SubscriberVehicle | undefined);

  function renderEvidenceLink(doc: SubscriberMediaDTO): JSX.Element {
    return (
      <a
        href={doc.contentUrl}
        target="_blank"
        rel="noopener noreferrer"
        title={_tg('action.downloadAttachment')}
      >
        <div className="order-detail_row">
          <span className="order-detail_cell-25">
            <FileIcon />
          </span>
          <div>{doc.name}</div>
        </div>
      </a>
    );
  }

  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_t('field.newPlate')}
        value={orderTrace.orderPlateChange.newPlate}
      />
      {orderTrace.orderPlateChange.oldPlate && (
        <FakeInputBlock
          title={_t('field.oldPlate')}
          value={orderTrace.orderPlateChange.oldPlate}
        />
      )}
      {registrationDocument && (
        <FakeInputBlock
          title={_t('field.greyCard')}
          value={renderEvidenceLink(registrationDocument)}
        />
      )}
      {otherDocument && (
        <FakeInputBlock
          title={_t('field.otherDocument')}
          value={renderEvidenceLink(otherDocument)}
        />
      )}
      {vehicleMatchingPlate &&
        vehicleMatchingPlate?.registrationDocuments?.documents &&
        vehicleMatchingPlate?.registrationDocuments?.documents.map(doc => (
          <FakeInputBlock
            title={_t('field.document')}
            value={renderEvidenceLink(doc)}
          />
        ))}
    </DataBoxItem>
  );
};

export default OrderDetailHistoryPlateChangeRequest;
