import React from 'react';
import TextField from 'material-ui/TextField';
import Dialog from 'material-ui/Dialog';
import { Checkbox, DialogProps } from 'material-ui';

import { Flex } from '@cvfm-front/commons-ui';
import CheckboxList from 'tefps/RecoursesV2/commons/CheckboxList';
import { getRecourseRequiredFiles } from 'tefps/RecoursesV2/utils/recourseFilesUtils';
import useRecourse from 'commons/hooks/useRecourse';
import useComplementRequest from 'commons/hooks/useComplementRequest';
import useOtherFile from 'tefps/RecoursesV2/hooks/useOtherFile';

import './ComplementRequestActionModal.css';

const { _tg, _t } = window.loadTranslations(__filename);

const ComplementRequestActionModal = ({
  actions,
  open,
  onRequestClose,
}: DialogProps): JSX.Element => {
  const recourseDetails = useRecourse();
  const [complementRequest, setComplementRequest] = useComplementRequest();
  const [otherFile, setOtherFile] = useOtherFile();

  const recourseRequiredFiles = recourseDetails
    ? getRecourseRequiredFiles(recourseDetails.recourse.reason)
    : null;

  const onComplementInfoChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    setComplementRequest({ ...complementRequest, complementInfo: newValue });
  };

  const onChangeOtherText = (
    _e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setOtherFile({ ...otherFile, name });
  };

  const onChangeOtherChecked = (
    _e: React.MouseEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setOtherFile({ ...otherFile, checked });
  };

  /* Either add or remove file from request list */
  const handleCheck = (
    _e: React.MouseEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    let updatedList = [...complementRequest.files];
    if (checked) {
      updatedList = [...complementRequest.files, _e.currentTarget.value];
    } else {
      updatedList.splice(
        complementRequest.files.indexOf(_e.currentTarget.value),
        1
      );
    }
    setComplementRequest({ ...complementRequest, files: updatedList });
  };

  return (
    <Dialog
      open={open}
      title={_t('modal.title')}
      titleClassName="modal-form__title"
      bodyClassName="modal-form"
      actions={actions}
      onRequestClose={onRequestClose}
    >
      <div className="modal-form__advice">{_t('modal.advice')}</div>
      <Flex>
        <div className="modal-form__checkbox-container">
          <CheckboxList
            title={_t('modal.fileList.main')}
            itemList={recourseRequiredFiles?.main ?? []}
            onCheck={handleCheck}
          />
          {/* Custom file */}
          <div key="other">
            <Checkbox
              label={_tg('field.other')}
              onCheck={onChangeOtherChecked}
            />
            <TextField
              className="modal-form__checkbox-container__input margin-y--xs"
              value={otherFile.name}
              onChange={onChangeOtherText}
              hintText={_tg('field.specify')}
              underlineShow={false}
              multiLine
              rows={3}
              rowsMax={3}
              errorText={
                otherFile.checked && !otherFile.name.trim()
                  ? _tg('feedback.error.mandatoryField')
                  : null
              }
              errorStyle={{ bottom: -12 }}
            />
          </div>
        </div>

        <div className="modal-form__checkbox-container">
          <CheckboxList
            title={_t('modal.fileList.others')}
            itemList={recourseRequiredFiles?.others ?? []}
            onCheck={handleCheck}
          />
        </div>
      </Flex>

      <Flex flexDirection="column">
        <div className="modal-form__complementary-info__title margin-y--s ">
          {_t('modal.complementaryInfo.title')}
        </div>
        <TextField
          value={complementRequest.complementInfo}
          onChange={onComplementInfoChange}
          hintText={_t('modal.complementaryInfo.hint')}
          underlineShow={false}
          multiLine
          rows={5}
          rowsMax={5}
          className="modal-form__complementary-info__input"
        />
      </Flex>
    </Dialog>
  );
};

export default ComplementRequestActionModal;
