import React, { useEffect, useState } from 'react';
import './Pagination.css';

const { _t } = window.loadTranslations(__filename);

type Props = {
  items: Array<unknown>;
  elementsPerPage: number;
  displayElement: (element: unknown) => JSX.Element;
};

const Pagination = ({
  items,
  elementsPerPage,
  displayElement,
}: Props): JSX.Element => {
  const [elements, setElements] = useState<Array<unknown>>(items);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedElements, setDisplayedElements] = useState<Array<unknown>>(
    elements.slice(currentPage, currentPage + elementsPerPage)
  );

  const maxPage = Math.floor((elements.length - 1) / elementsPerPage);

  useEffect(() => {
    setDisplayedElements(
      items.slice(
        currentPage * elementsPerPage,
        (currentPage + 1) * elementsPerPage
      )
    );
  }, [currentPage]);

  useEffect(() => {
    setElements(items);
    setDisplayedElements(
      items.slice(currentPage, currentPage + elementsPerPage)
    );
  }, [items]);

  const goLeft = (): void => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goRight = (): void => {
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <ul className="element-list">
        {displayedElements.map(el => displayElement(el))}
      </ul>
      <div className="element-list__history-arrows">
        <span
          onClick={goLeft}
          className={
            currentPage === 0
              ? 'element-list__history-arrows__change-page_deactivated'
              : 'element-list__history-arrows__change-page'
          }
        >
          {_t('previous')}
        </span>
        <span
          onClick={goRight}
          className={
            currentPage === maxPage
              ? 'element-list__history-arrows__change-page_deactivated'
              : 'element-list__history-arrows__change-page'
          }
        >
          {_t('next')}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
