import React, { useMemo, useState } from 'react';
import _cloneDeep from 'lodash.clonedeep';
import { v4 as uuidv4 } from 'uuid';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  MenuItem,
  SelectField,
  TextField,
} from 'material-ui';
import { connect } from 'react-redux';

import {
  EvidenceProperties,
  OrderEvidence,
  OrderPrivateDTO,
  OrderStatus,
  OrderTraceType,
  ProductPrivateDTO,
  SubscriberDTO,
  SubscriberMediaDTO,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import {
  addSubscriberMedia,
  getEvidenceDirectUploadLink,
} from 'api/cvfm-core-subscription/subscriber';
import { ItemIdName } from 'api/commonTypes';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import { addEvidencesUpdate } from 'api/cvfm-core-subscription/order';
import './OrderDetailPage.css';
import BoButton from 'facade/BoButton';
import { Files } from '@cvfm-front/commons-ui';
import { uploadFileV2 } from 'api/mediaupload';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';

const { _t, _tg } = window.loadTranslations(__filename);

type SimpleOrderEvidence = {
  evidenceSubscriberMediaId: string;
  comment: string;
  manuallyChecked: boolean;
  evidenceItem: EvidenceProperties;
  mandatory: boolean;
};

type OrderDetailModalEvidenceProps = {
  open: boolean;
  order: OrderPrivateDTO;
  product: ProductPrivateDTO;
  subscribers: Array<SubscriberDTO>;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
  watermarkText: string | null;
};

const OrderDetailModalEvidence = ({
  open,
  order,
  product,
  subscribers: subscribersImmutable,
  userInfo,
  onCancel,
  onConfirm,
  watermarkText,
}: OrderDetailModalEvidenceProps): JSX.Element => {
  const [subscribers, setSubscribers] = useState<Array<SubscriberDTO>>([
    ...subscribersImmutable,
  ]);
  const [subscriberUploading, setSubscriberUploading] = useState<SubscriberDTO>(
    subscribers[0]
  );
  const [mediaItems, setMediaItems] = useState<Array<ItemIdName>>([]);
  const [evidencesSelected, setEvidencesSelected] = useState<
    Array<SimpleOrderEvidence>
  >([]);

  const [uploadingMedia, setUploadingMedia] = useState<boolean>(false);
  const [registeringMedia, setRegisteringMedia] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);

  const setMessage = useSnackbar();

  function initOrderEvidences(): void {
    // Create items from requested evidences
    const items = order.complementEvidenceHistory.map(
      e => e.evidenceProperties
    );
    // Also add evidences items from application procedure
    if (
      product.applicationProcedure &&
      product.applicationProcedure.supportingEvidences
    ) {
      product.applicationProcedure.supportingEvidences.forEach(se => {
        items.push(se);
      });
    }
    const orderEvidences = items.map(evidenceItem => ({
      evidenceSubscriberMediaId: '',
      comment: '',
      manuallyChecked: false,
      evidenceItem,
      mandatory: evidenceItem.mandatory,
      id: evidenceItem.id,
    }));

    // Handle already pushed files
    orderEvidences.forEach(ev => {
      const file = order.providedEvidenceHistory.find(
        e => e.evidencePropertiesId === ev.id
      );
      ev.evidenceSubscriberMediaId = file?.evidenceSubscriberMediaId || '';
    });

    setEvidencesSelected(orderEvidences);
  }

  function onChangeSubscriberUploading(
    _e: never,
    _i: number,
    subscriberId: string
  ) {
    const subscriber = subscribers.find(sub => sub.id === subscriberId);
    if (subscriber) {
      setSubscriberUploading(subscriber);
    }
  }

  function checkReady(): void {
    setReady(
      evidencesSelected.every(
        evidence =>
          evidence.evidenceSubscriberMediaId !== '' ||
          evidence.manuallyChecked ||
          !evidence.mandatory
      )
    );
  }

  function onCancelAction() {
    setUploadingMedia(false);
    setRegisteringMedia(false);
    initOrderEvidences();
    onCancel();
  }

  function onConfirmAction() {
    if (ready) {
      const updateRequest = evidencesSelected.map(evidence => ({
        timestamp: new Date().toISOString(),
        traceType:
          order.status === OrderStatus.AWAIT_CLAIM_COMPLETION_USER
            ? OrderTraceType.CLAIM_EVIDENCE
            : OrderTraceType.EVIDENCE,
        source: SubscriptionSource.BACK,
        agent: userInfo ? { ...userInfo } : null,
        evidencePropertiesId: evidence.evidenceItem.id,
        evidenceSubscriberMediaId: evidence.manuallyChecked
          ? ''
          : evidence.evidenceSubscriberMediaId,
        manuallyChecked: evidence.manuallyChecked,
        comment: evidence.comment,
        subscriberId: subscriberUploading.subscriberId,
      })) as Array<OrderEvidence>;
      const updatePromise = addEvidencesUpdate(order.orderId, updateRequest);
      onConfirm(updatePromise);
    }
  }

  const onChangeFile = async (file: File): Promise<void> => {
    const directUploadDTO = await getEvidenceDirectUploadLink(
      subscriberUploading.subscriberId,
      file.type
    );

    let success = true;
    try {
      await uploadFileV2(
        directUploadDTO.signedUploadUrl,
        file,
        watermarkText,
        undefined,
        _err => {
          setMessage(_tg('commons.error'));
          success = false;
        }
      );
    } catch (err) {
      setMessage((err as Error)?.message || _tg('commons.error'));
      success = false;
    }
    if (!success) {
      return new Promise((_resolve, reject) => reject());
    }

    const newDocument: SubscriberMediaDTO = {
      id: uuidv4(),
      name: file.name,
      url: directUploadDTO.fileUrn,
      dateCreated: new Date().toISOString(),
    };
    const updatedSubscriber = await addSubscriberMedia(
      subscriberUploading.subscriberId,
      newDocument
    );
    setSubscribers(
      [...subscribers].map(sub =>
        sub.subscriberId === updatedSubscriber.subscriberId
          ? updatedSubscriber
          : sub
      )
    ); // we only replace the subscriber who has uploaded the file
    setSubscriberUploading({ ...updatedSubscriber });
    return new Promise(resolve => resolve());
  };

  useMemo(() => {
    const evidences = subscribers.reduce(
      (acc, subs) => acc.concat(subs.evidences),
      [] as Array<SubscriberMediaDTO>
    );
    const items = evidences.map(e => {
      return {
        id: e.id,
        name: e.name || e.id,
      } as ItemIdName;
    });
    setMediaItems(items);
  }, [subscribers]);

  useMemo(checkReady, [evidencesSelected]);

  useMemo(() => {
    initOrderEvidences();
  }, [order, product]);

  function handleSelectSubscriberMediaId(
    selectedMediaId: string,
    evidencePropertiesId: string
  ): void {
    const newEvidences = _cloneDeep(evidencesSelected);
    newEvidences
      .filter(evidence => evidence.evidenceItem.id === evidencePropertiesId)
      .forEach(evidence => {
        evidence.evidenceSubscriberMediaId =
          evidence.evidenceSubscriberMediaId === selectedMediaId
            ? ''
            : selectedMediaId;
      });
    setEvidencesSelected(newEvidences);
  }

  function handleCheck(evidencePropertiesId: string): void {
    const newEvidences = _cloneDeep(evidencesSelected);
    newEvidences
      .filter(evidence => evidence.evidenceItem.id === evidencePropertiesId)
      .forEach(evidence => {
        evidence.manuallyChecked = !evidence.manuallyChecked;
        evidence.evidenceSubscriberMediaId = '';
      });
    setEvidencesSelected(newEvidences);
  }

  function handleChangeComment(
    text: string,
    evidencePropertiesId: string
  ): void {
    const newEvidences = _cloneDeep(evidencesSelected);
    newEvidences
      .filter(evidence => evidence.evidenceItem.id === evidencePropertiesId)
      .forEach(evidence => {
        evidence.comment = text;
      });
    setEvidencesSelected(newEvidences);
  }

  function renderSelect(
    domId: string,
    items: Array<ItemIdName>,
    value: string | undefined,
    onSelect: (e: never, index: number, selected: string) => void
  ): JSX.Element {
    return (
      <SelectField
        id={domId}
        onChange={onSelect}
        value={value}
        hintText={_t('hint')}
        fullWidth
      >
        {items.map(item => (
          <MenuItem
            id={item.id}
            key={item.id}
            value={item.id}
            primaryText={item.name}
            insetChildren
          />
        ))}
      </SelectField>
    );
  }

  function renderLine(evidence: SimpleOrderEvidence): JSX.Element {
    return (
      <div className="order-detail-evidence_container">
        <div className="order-detail_row">
          <span className="order-detail_cell-50">
            {evidence.evidenceItem.name}
            {evidence.evidenceItem.mandatory && '*'}
          </span>
          <div className="order-detail_cell-50 order-detail-evidence_container">
            <span style={{ width: '100%' }}>
              {!(registeringMedia || uploadingMedia) && (
                <div>
                  {renderSelect(
                    'evidence-media-select',
                    mediaItems,
                    evidence.evidenceSubscriberMediaId,
                    (_e: never, _index: never, selectedMediaId: string): void =>
                      handleSelectSubscriberMediaId(
                        selectedMediaId,
                        evidence.evidenceItem.id
                      )
                  )}
                </div>
              )}
            </span>
            {!evidence.evidenceItem.uploadRequired && (
              <span style={{ marginBottom: '20px' }}>{_t('or')}</span>
            )}
            {!evidence.evidenceItem.uploadRequired && (
              <Checkbox
                style={{ marginLeft: '10px' }}
                label={_t('manuallyChecked')}
                checked={evidence.manuallyChecked}
                onCheck={() => handleCheck(evidence.evidenceItem.id)}
              />
            )}
          </div>
        </div>
        <div className="order-detail_row">
          <TextField
            multiLine
            rows={3}
            fullWidth
            name="evidence-comment"
            floatingLabelText={_tg('field.comment')}
            onChange={(_e: never, text: string) =>
              handleChangeComment(text, evidence.evidenceItem.id)
            }
            value={evidence.comment || ''}
          />
        </div>
      </div>
    );
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-period-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-period-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
      disabled={!ready}
    />,
  ];

  return (
    <Dialog
      title={_t(order.claim ? 'title.claim' : 'title.order')}
      open={open}
      actions={actions}
      bodyClassName="order-detail-modal_body"
      titleClassName="order-detail-modal_title"
    >
      {subscribers.length > 1 && (
        <SelectField
          onChange={onChangeSubscriberUploading}
          value={subscriberUploading.id}
        >
          {subscribers.map(subscriber => (
            <MenuItem
              value={subscriber.id}
              primaryText={`${subscriber.firstName} ${subscriber.firstName}`}
            />
          ))}
        </SelectField>
      )}
      <div style={{ marginTop: '10px' }}>
        <Files
          selfControlled={false} // we don't want the files to appear
          label={_tg('action.addFiles')}
          fileIcon="/static/vehicle-card.svg"
          closeIcon="/static/close.svg"
          withDropzone={false}
          maxSize={2000000}
          maxFiles={4}
          onChange={onChangeFile}
          onError={err => setMessage(err)}
        />
      </div>
      {(registeringMedia || uploadingMedia) && (
        <div className="order-detail-evidence_container">
          <CircularProgress />
        </div>
      )}
      {evidencesSelected.map(evidence => renderLine(evidence))}
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { watermarkText } = getConfigState(state);
  return {
    userInfo,
    watermarkText,
  };
})(OrderDetailModalEvidence);
