import React, { useMemo, useEffect, useState } from 'react';
import { MenuItem, SelectField } from 'material-ui';

import { NotificationService } from '@cvfm-front/commons-services';
import { fetchTags, TagsTypes } from 'api/tags';

import ProductDetailSeparator from './commons/DetailSeparator';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailTagsProps = {
  organizationId: string | null | undefined;
  configTags: string[];
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailTags = ({
  organizationId,
  configTags,
  onChange,
}: ProductDetailTagsProps): JSX.Element => {
  const [allChoosableTags, setAllChoosableTags] = useState<string[]>([]);

  const loadAllChoosableTagsList = async (): Promise<void> => {
    try {
      const tags = await fetchTags(TagsTypes.order, organizationId, false);
      setAllChoosableTags(tags);
    } catch (e) {
      NotificationService.pushNotification({
        id: 'product-detail',
        type: 'error',
        message:
          _tg('Configuration.commons.AdminTags.index.feedback.error.load') ||
          e?.json?.message,
      });
    }
  };

  useEffect(() => {
    void loadAllChoosableTagsList();
  }, []);

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <SelectField
            key="select-tags"
            floatingLabelFixed
            floatingLabelText={_t('field.selectTags')}
            fullWidth
            multiple
            onChange={(_e, _i, v) => onChange('tags', v)}
            value={configTags}
          >
            {allChoosableTags.map(tag => (
              <MenuItem
                id={tag}
                key={tag}
                value={tag}
                primaryText={tag}
                insetChildren
              />
            ))}
          </SelectField>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailTags;
