import React from 'react';
import { Card } from 'material-ui';

import { Tabs, Tab } from '@cvfm-front/commons-ui';
import useParkingRights from 'commons/hooks/useParkingRights';
import useEligibilities from 'commons/hooks/useEligibilities';
import useZoning from 'commons/hooks/useZoning';

import TicketList from './TicketList';
import EligibilityList from './EligibilityList';

const { _t } = window.loadTranslations(__filename);

type Props = {
  recoursePlate: string;
};

const TicketSummary = ({ recoursePlate }: Props): JSX.Element => {
  const eligibilities = useEligibilities();
  const zoning = useZoning();

  return (
    <Card className="recourse-card">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('ticketSummary.title')}</div>
      </div>
      <div className="recourse-card__content text--small">
        <Tabs
          defaultActiveTabIndex={0}
          tabsClassName="text-bold"
          contentClassName="margin-y--s"
        >
          <Tab title={_t('ticketSummary.ticketsTab')}>
            <div>
              <TicketList recoursePlate={recoursePlate} />
            </div>
          </Tab>
          <Tab title={_t('ticketSummary.eligibilitiesTab')}>
            <div>
              <EligibilityList
                recoursePlate={recoursePlate}
                eligibilities={eligibilities}
                zoning={zoning}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </Card>
  );
};

export default TicketSummary;
