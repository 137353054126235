import React, { CSSProperties, useEffect, useState } from 'react';
import moment from 'moment';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { Dialog } from 'material-ui';
import { connect } from 'react-redux';

import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import {
  VehicleCategory,
  SivConfiguration,
  LoginType,
  OrderEvidence,
  OrderPrivateDTO,
  OrderStatus,
  OrderTraceStatus,
  ProductPrivateDTO,
  SubscriberAccountType,
  SubscriberDTO,
  SubscriberMediaDTO,
  SubscriberPersonalProfile,
  SubscriberProfessionalProfile,
  CritAir,
  CritAirSource,
  CritAirEnum,
} from '@cvfm-front/tefps-types';

import './OrderDetailPage.css';
import { Flex } from '@cvfm-front/commons-ui';
import SimpleTable from 'commons/SimpleTable';
import notDisplayableStatuses from 'commons/Critair/CritairNotDisplayableStatus';
import { BKG_CYAN, ICON_BLACK, TEXT_COLOR_LIGHT, TXT_BLACK } from 'theme';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import DateComponent from 'commons/Date';
import { deleteEvidenceLink, getRemovableEvidences } from 'api/subscribers';
import BoButton from 'facade/BoButton';
import { CRITAIR_COLORS } from '@cvfm-front/tefps-ui';

import ThirdPartyValidatedElement from '../../commons/ThirdPartyValidatedElement';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  width: 40,
  height: 35,
};

type OrderDetailFilesReduxProps = {
  sivConfig: SivConfiguration;
};

export const computeEvidences = (
  order: OrderPrivateDTO
): Array<OrderEvidence> => {
  const mappedEvidence = order.providedEvidenceHistory
    .sort((a, b) => {
      const aDate = new Date(a.timestamp);
      const bDate = new Date(b.timestamp);
      return bDate.getTime() - aDate.getTime();
    })
    .reduce((result, evidence) => {
      if (!result[evidence.evidencePropertiesId]) {
        result[evidence.evidencePropertiesId] = evidence;
      }
      return result;
    }, new Map<string, OrderEvidence>());

  return Object.values(mappedEvidence) as Array<OrderEvidence>;
};

export const computeProfileSubscriberEvidences = (
  orderSubscribers: Array<SubscriberDTO>,
  productScope: SubscriberAccountType
): Array<{
  name: string;
  subscriber: SubscriberDTO;
  documents: Array<SubscriberMediaDTO> | null;
  manuallyChecked?: boolean;
  isDgFipVerified?: boolean;
}> => {
  const computeProfessionalEvidences = () =>
    orderSubscribers.reduce((acc, sub) => {
      if (!sub.professionalProfile) {
        return acc;
      }
      if (sub.identity) {
        acc.push({
          name: _tg('field.evidenceType.proofOfId'),
          subscriber: sub,
          documents: sub.identity.documents,
          manuallyChecked: sub.identity.manuallyChecked,
        });
      }
      if (sub.professionalProfile.company.proofDocuments) {
        acc.push({
          name: _tg('field.evidenceType.professionalProofOfActivity'),
          subscriber: sub,
          documents: sub.professionalProfile.company.proofDocuments.documents,
          manuallyChecked:
            sub.professionalProfile.company.proofDocuments.manuallyChecked,
        });
      }
      if (sub.professionalProfile.location.proofOfAddress) {
        acc.push({
          name: _tg('field.evidenceType.professionalProofOfAddress'),
          subscriber: sub,
          documents: sub.professionalProfile.location.proofOfAddress.documents,
          manuallyChecked:
            sub.professionalProfile.location.proofOfAddress.manuallyChecked,
        });
      }
      return acc;
    }, [] as Array<{ name: string; subscriber: SubscriberDTO; manuallyChecked?: boolean; isDgFipVerified?: boolean; documents: Array<SubscriberMediaDTO> | null }>);

  const computePersonalEvidences = () =>
    orderSubscribers.reduce((acc, sub) => {
      if (!sub.personalProfile) {
        return acc;
      }
      if (sub.identity) {
        acc.push({
          name: _tg('field.evidenceType.proofOfId'),
          subscriber: sub,
          documents: sub.identity.documents,
          manuallyChecked: sub.identity.manuallyChecked,
        });
      }
      if (
        sub.personalProfile.location.proofOfAddress ||
        sub.personalProfile.location.dgFipAddressVerified
      ) {
        acc.push({
          name: _tg('field.evidenceType.personalProofOfAddress'),
          subscriber: sub,
          documents:
            sub.personalProfile.location.proofOfAddress?.documents || [],
          isDgFipVerified: sub.personalProfile.location.dgFipAddressVerified,
          manuallyChecked: !!sub.personalProfile.location.proofOfAddress
            ?.manuallyChecked,
        });
      }
      return acc;
    }, [] as Array<{ name: string; subscriber: SubscriberDTO; documents: Array<SubscriberMediaDTO> | null; isDgFipVerified?: boolean; manuallyChecked?: boolean }>);

  if (productScope === SubscriberAccountType.PERSONAL) {
    return computePersonalEvidences();
  }
  if (productScope === SubscriberAccountType.PROFESSIONAL) {
    return computeProfessionalEvidences();
  }
  // Scope is BOTH
  return computePersonalEvidences().concat(computeProfessionalEvidences());
};

type OrderDetailSummaryProps = {
  order: OrderPrivateDTO;
  orderProduct: ProductPrivateDTO;
  orderSubscribers: Array<SubscriberDTO>;
  canDelete: boolean;
  refreshOrder: () => void;
} & OrderDetailFilesReduxProps;

type VehicleEvidences = {
  vehicleName: string;
  owner: string;
  plate: string;
  documents: Array<SubscriberMediaDTO> | null | undefined;
  manuallyChecked?: boolean;
  critAir: CritAir;
  critAirSource?: CritAirSource;
  vehicleCategory: VehicleCategory;
};

type TypeOfDocuments =
  | 'subscriberEvidence'
  | 'profileEvidence'
  | 'vehicleEvidences'
  | 'vehicleEvidence';

type DisplayType = {
  type: TypeOfDocuments;
  medias: Array<SubscriberMediaDTO>;
  canDelete?: boolean;
  manuallyChecked?: boolean;
  isDgFipVerified?: boolean;
  productEvidenceName?: string;
  productEvidenceCritair?: CritAir;
  productEvidenceCritairSource?: CritAirSource;
  productEvidenceVehicleCategory?: VehicleCategory;
};

const getDisplayableVehiclesFromOrder = ({
  order,
  orderProduct,
  orderSubscribers,
}: OrderDetailSummaryProps): Array<VehicleEvidences> => {
  const getDisplayableVehiclePlates = () => {
    const validPlates = [
      ...order.plateHistory[order.plateHistory.length - 1].plates,
    ];
    const lastPlateChangeRequest =
      order.plateChangeRequestHistory[
        order.plateChangeRequestHistory.length - 1
      ];
    if (
      lastPlateChangeRequest &&
      lastPlateChangeRequest.status === OrderTraceStatus.WAITING &&
      lastPlateChangeRequest.orderPlateChange.newPlate
    ) {
      validPlates.push(lastPlateChangeRequest.orderPlateChange.newPlate);
    }
    return validPlates;
  };

  const displayablePlates = getDisplayableVehiclePlates();

  const findSubscriberVehiclesByPlates = (
    subscriber: SubscriberDTO,
    profile: SubscriberPersonalProfile | SubscriberProfessionalProfile
  ): Array<{
    vehicleName: string;
    owner: string;
    plate: string;
    documents: Array<SubscriberMediaDTO> | null | undefined;
    manuallyChecked?: boolean;
    critAir: CritAir;
    critAirSource?: CritAirSource;
    vehicleCategory: VehicleCategory;
  }> => {
    return profile.vehicles.reduce((acc, vehicle) => {
      if (displayablePlates.includes(vehicle.plate)) {
        acc.push({
          vehicleName: vehicle.name,
          owner: `${subscriber.lastName}${subscriber.firstName}`,
          plate: vehicle.plate,
          documents: vehicle.registrationDocuments?.documents,
          manuallyChecked: vehicle.registrationDocuments?.manuallyChecked,
          critAir: vehicle.vehicleCritair,
          critAirSource: vehicle.vehicleCritairSource,
          vehicleCategory: vehicle.vehicleCategory,
        });
      }
      return acc;
    }, [] as Array<{ vehicleName: string; owner: string; plate: string; documents: Array<SubscriberMediaDTO> | null | undefined; manuallyChecked?: boolean; critAir: CritAir; critAirSource: CritAirSource; vehicleCategory: VehicleCategory }>);
  };

  const vehicleList = orderSubscribers.reduce((vehicles, sub) => {
    if (
      orderProduct.conditions.productScope === SubscriberAccountType.PERSONAL &&
      sub.personalProfile
    ) {
      return vehicles.concat(
        findSubscriberVehiclesByPlates(sub, sub.personalProfile)
      );
    }
    if (
      orderProduct.conditions.productScope ===
        SubscriberAccountType.PROFESSIONAL &&
      sub.professionalProfile
    ) {
      return vehicles.concat(
        findSubscriberVehiclesByPlates(sub, sub.professionalProfile)
      );
    }
    // If we land here, it means that the product scope is BOTH
    if (sub.professionalProfile) {
      vehicles.push(
        ...findSubscriberVehiclesByPlates(sub, sub.professionalProfile)
      );
    }
    if (sub.personalProfile) {
      vehicles.push(
        ...findSubscriberVehiclesByPlates(sub, sub.personalProfile)
      );
    }
    return vehicles;
  }, [] as Array<VehicleEvidences>);
  return vehicleList;
};

const OrderDetailFiles = ({
  order,
  orderSubscribers,
  orderProduct,
  canDelete,
  refreshOrder,
  sivConfig,
}: OrderDetailSummaryProps): JSX.Element => {
  const [evidences, setEvidences] = useState<OrderEvidence[]>([]);
  const [evidenceIdToRemove, setEvidenceIdToRemove] = useState<string | null>(
    null
  );
  const [
    displayableVehicleEvidences,
    setDisplayableVehiculesEvidences,
  ] = useState<VehicleEvidences[]>([]);
  useEffect(() => {
    setEvidences(computeEvidences(order));
    setEvidenceIdToRemove(null);
    setDisplayableVehiculesEvidences(
      getDisplayableVehiclesFromOrder({
        order,
        orderSubscribers,
        orderProduct,
        canDelete,
        refreshOrder,
        sivConfig,
      })
    );
  }, [order]);
  const subscriberProfileEvidences = computeProfileSubscriberEvidences(
    orderSubscribers,
    orderProduct.conditions.productScope
  );
  const setMessage = useSnackbar();

  const translateCols = (): Array<{
    label: string;
    width: number;
    grow?: number;
    headerStyle?: React.CSSProperties;
  }> => {
    const cols = [
      { label: _t('header.name'), width: 180 },
      {
        label: 'Documents',
        width: sivConfig.enableSaveCritairSiv ? 240 : 320,
        grow: 1,
        headerStyle: { justifyContent: 'center' },
      },
      { label: _t('header.createdAt'), width: 80 },
      {
        label: _t('header.delete'),
        width: 50,
        headerStyle: { justifyContent: 'flex-end' },
      },
    ];

    // if Crit'air option is activated in BDO we want to show the column
    if (sivConfig.enableSaveCritairSiv) {
      cols.splice(2, 0, {
        label: "Crit'Air",
        width: 80,
      });
    }

    if (!canDelete) {
      cols.pop();
    }
    return cols;
  };

  const handleClickDelete = (evidenceId: string) => {
    setEvidenceIdToRemove(evidenceId);
  };

  const confirmDeleteEvidence = async () => {
    if (evidenceIdToRemove !== null) {
      try {
        await deleteEvidenceLink(order.orderId, evidenceIdToRemove);
      } catch (err) {
        const error = err as { message: string; name: string };
        if (error.name === 'EvidenceInUse') {
          setMessage(_t('error.evidenceInUse'));
        } else {
          setMessage(_t('error.message'));
        }
      }
      refreshOrder();
    }
  };

  const confirmDialogActions = [
    <BoButton
      secondary
      label={_tg('action.cancel')}
      onClick={() => setEvidenceIdToRemove(null)}
    />,
    <BoButton
      primary
      label={_tg('action.confirm')}
      onClick={confirmDeleteEvidence}
      style={{ marginLeft: 20 }}
    />,
  ];

  const subscriberEvidences = orderSubscribers.reduce((acc, sub) => {
    return acc.concat(sub.evidences);
  }, [] as Array<SubscriberMediaDTO>);

  function getAllEvidences(
    evidences: OrderEvidence[],
    subscriberEvidences: SubscriberMediaDTO[]
  ): Array<DisplayType> {
    const allProductEvidences: Array<DisplayType> = [];
    evidences
      .filter(
        evidence =>
          (order.status !== OrderStatus.AWAIT_PLATE_DECISION &&
            evidence.status === OrderTraceStatus.VALIDATED) ||
          order.status === OrderStatus.AWAIT_PLATE_DECISION
      )
      .forEach(evidence => {
        const productEvidenceName = orderProduct.applicationProcedure?.supportingEvidences?.find(
          productEvidence =>
            productEvidence.id === evidence.evidencePropertiesId
        )?.name;
        const subscriberEvidence = subscriberEvidences.filter(
          sE => evidence.evidenceSubscriberMediaId === sE.id
        );
        if (subscriberEvidence.length !== 0) {
          allProductEvidences.push({
            type: 'subscriberEvidence',
            medias: subscriberEvidence,
            canDelete: true,
            manuallyChecked: evidence.manuallyChecked,
            productEvidenceName,
          });
        } else {
          allProductEvidences.push({
            type: 'subscriberEvidence',
            medias: [],
            productEvidenceName,
            manuallyChecked: evidence.manuallyChecked,
          });
        }
      });
    subscriberProfileEvidences.forEach(profileEvidence => {
      profileEvidence.documents?.sort((a, b) =>
        b.dateCreated.localeCompare(a.dateCreated)
      );
      if (
        (profileEvidence.documents &&
          profileEvidence.documents?.length !== 0) ||
        profileEvidence.isDgFipVerified
      ) {
        if (
          profileEvidence.name === 'Justificatif de domicile' &&
          orderProduct.applicationProcedure?.requiresProofOfAddress
        ) {
          allProductEvidences.push({
            type: 'profileEvidence',
            medias: profileEvidence.documents || [],
            manuallyChecked: profileEvidence.manuallyChecked,
            productEvidenceName: profileEvidence.name,
            isDgFipVerified: profileEvidence.isDgFipVerified,
          });
        } else if (
          profileEvidence.name === "Titre d'identité" &&
          orderProduct.applicationProcedure?.requiresProofOfId
        ) {
          allProductEvidences.push({
            type: 'profileEvidence',
            medias: profileEvidence.documents || [],
            manuallyChecked: profileEvidence.manuallyChecked,
            productEvidenceName: profileEvidence.name,
            isDgFipVerified: profileEvidence.isDgFipVerified,
          });
        } else if (
          profileEvidence.name === 'Kbis' &&
          orderProduct.applicationProcedure?.requiresProofOfActivity
        ) {
          allProductEvidences.push({
            type: 'profileEvidence',
            medias: profileEvidence.documents || [],
            manuallyChecked: profileEvidence.manuallyChecked,
            productEvidenceName: profileEvidence.name,
            isDgFipVerified: profileEvidence.isDgFipVerified,
          });
        } else if (
          profileEvidence.name !== 'Kbis' &&
          profileEvidence.name !== "Titre d'identité" &&
          profileEvidence.name !== 'Justificatif de domicile'
        ) {
          allProductEvidences.push({
            type: 'profileEvidence',
            medias: profileEvidence.documents || [],
            manuallyChecked: profileEvidence.manuallyChecked,
            productEvidenceName: profileEvidence.name,
            isDgFipVerified: profileEvidence.isDgFipVerified,
          });
        }
      }
    });
    displayableVehicleEvidences.forEach(vehiculeEvidence => {
      vehiculeEvidence.documents?.sort((a, b) =>
        b.dateCreated.localeCompare(a.dateCreated)
      );
      if (vehiculeEvidence.documents?.length) {
        allProductEvidences.push({
          type: 'vehicleEvidences',
          medias: vehiculeEvidence.documents,
          manuallyChecked: vehiculeEvidence.manuallyChecked,
          productEvidenceName: vehiculeEvidence.vehicleName,
          productEvidenceCritair: vehiculeEvidence.critAir,
          productEvidenceCritairSource: vehiculeEvidence.critAirSource,
          productEvidenceVehicleCategory: vehiculeEvidence.vehicleCategory,
        });
      } else {
        allProductEvidences.push({
          type: 'vehicleEvidences',
          medias: [],
          manuallyChecked: vehiculeEvidence.manuallyChecked,
          productEvidenceName: vehiculeEvidence.vehicleName,
          productEvidenceCritair: vehiculeEvidence.critAir,
          productEvidenceCritairSource: vehiculeEvidence.critAirSource,
          productEvidenceVehicleCategory: vehiculeEvidence.vehicleCategory,
        });
      }
    });
    return allProductEvidences;
  }

  if (evidences.length === 0 && subscriberProfileEvidences.length === 0) {
    return <></>;
  }

  const isFcVerified =
    orderSubscribers[0].openIds.some(
      openId => openId.loginType === LoginType.FRANCE_CONNECT
    ) && !orderSubscribers[0].identity?.documents?.length;

  const isPersonalAddressVerifiedByDgFip = !!orderSubscribers[0].personalProfile
    ?.location.dgFipAddressVerified;

  return (
    <Flex flexDirection="column" gap={16} width100>
      {isFcVerified ? (
        <ThirdPartyValidatedElement
          title={_tg('commons.franceConnect.validated')}
        />
      ) : (
        <></>
      )}
      {isPersonalAddressVerifiedByDgFip && (
        <ThirdPartyValidatedElement title={_tg('commons.dgFip.validated')} />
      )}
      <SimpleTable
        maxHeight={400}
        cols={translateCols()}
        rowHeight={50}
        header
        itemsRenderer={(document: DisplayType, index) => {
          const isAddressFieldAndVerifiedByDgFip =
            document.isDgFipVerified &&
            _tg('field.evidenceType.personalProofOfAddress') ===
              document.productEvidenceName;
          const items = [
            <span
              style={{
                width: '100%',
                alignItems: 'flex',
                display: 'flex',
                height: '2em',
                lineHeight: '2em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              title={document.productEvidenceName}
            >
              {document.productEvidenceName}
            </span>,
            document.medias.length === 0 &&
            (document.manuallyChecked || isAddressFieldAndVerifiedByDgFip) ? (
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                {isAddressFieldAndVerifiedByDgFip
                  ? _tg('commons.dgFip.validated')
                  : _t('checked.manuallyChecked')}
              </span>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {document.medias.map(media => {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={media.contentUrl}
                      title={_tg('action.downloadAttachment')}
                      className="text--wrap"
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {document.type === 'vehicleEvidences' ||
                        document.type === 'vehicleEvidence' ? (
                          <img
                            src={`/static/img/${document.productEvidenceVehicleCategory}_parking_colored.svg`}
                            style={{ width: '42px', height: '42px' }}
                          />
                        ) : (
                          <FileIcon style={STYLE_ICON} color={BKG_CYAN} />
                        )}
                      </div>
                    </a>
                  );
                })}
              </div>
            ),
            sivConfig.enableSaveCritairSiv ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                {document.productEvidenceCritair &&
                document.productEvidenceCritair &&
                !notDisplayableStatuses.includes(
                  document.productEvidenceCritair
                ) ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {document.productEvidenceCritair ===
                    CritAirEnum.UNRANKED ? (
                      <span style={{ fontWeight: 'bold', marginTop: 3 }}>
                        {CRITAIR_COLORS.UNRANKED.value}
                      </span>
                    ) : (
                      <img
                        src={`/static/img/critair/critair_${document.productEvidenceCritair}.svg`}
                        alt={`Crit’Air ${document.productEvidenceCritair}`}
                      />
                    )}

                    <span style={{ marginLeft: 8, marginTop: 3 }}>
                      {`${document.productEvidenceCritairSource || ''}`}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : null,
            <DateComponent
              datetime={document.medias[0]?.dateCreated}
              style={{ display: 'flex', justifyContent: 'center' }}
              withTime
            />,
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {document.canDelete ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <DeleteIcon
                    color={ICON_BLACK}
                    onClick={() =>
                      handleClickDelete(document.medias[0]?.id || '')
                    }
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                    hoverColor={BKG_CYAN}
                    className="action-icon"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>,
          ];
          return items.filter(elt => elt !== null);
        }}
        items={getAllEvidences(evidences, subscriberEvidences)}
      />
      <Dialog
        open={evidenceIdToRemove !== null}
        actions={confirmDialogActions}
        title={_t('dialog.title')}
      />
    </Flex>
  );
};

function mapStateToProps(state: InternalApiState): OrderDetailFilesReduxProps {
  const config = getConfigState(state);
  return {
    sivConfig: config.sivConfiguration,
  };
}

export default connect(mapStateToProps)(OrderDetailFiles);
