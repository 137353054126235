import { CSSProperties } from 'react';

import {
  BKG_CYAN,
  BKG_FLASHY,
  BKG_LIGHT_BLUE,
  BRD_GREY,
  TXT_BLACK,
} from '../../../../../theme';

const STYLE: {
  [k: string]: CSSProperties;
} = {
  CONTAINER: {
    margin: '30px 0 50px 0',
    fontFamily: 'Roboto',
  },

  LINK_CONTAINER: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: '20px 0 0 0',
    textAlign: 'center',
    color: TXT_BLACK,
  },

  HEADER: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    backgroundColor: BKG_CYAN,
    borderRadius: '5px 5px 0 0',
    color: 'white',
    fontSize: 20,
    padding: '0 20px',
  },

  CONTENT: {
    border: `1px solid ${BRD_GREY}`,
    borderTop: 'none',
    borderRadius: '0 0 5px 5px',
    padding: 20,
  },

  BOTTOM_BLOCK: {
    width: '20%',
    margin: '0 10px',
    justifyContent: 'center',
  },

  WARNING: {
    width: '100%',
    textAlign: 'center',
    color: BKG_FLASHY,
    marginBottom: 10,
  },

  FPS_DETAIL_BUTTON: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: BKG_CYAN,
    color: 'white',
    borderRadius: '5px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%) translateY(20%)',
    height: 30,
    fontSize: 17,
    padding: '0px 40px',
  },

  LINK: {
    fontSize: 12,
    fontWeight: 'normal',
    color: BKG_LIGHT_BLUE,
    cursor: 'pointer',
  },
};

export default STYLE;
