import React, { Dispatch, SetStateAction, useState } from 'react';
import moment from 'moment';

import {
  OrderPrivateDTO,
  OrderMassUpdateMap,
  OrderTraceType,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';

import { addValidityPeriodUpdate } from '../../api/cvfm-core-subscription/order';

import OrderProlongationInfoModal from './OrderProlongationInfoModal';
import OrderFilterService from './OrderFilterService';
import OrderMassUpdateProcess from './OrderMassUpdateProcess';

const { _t } = window.loadTranslations(__filename);

type OrderProlongationModalsProps = {
  isOpen: boolean;
  setIsOpenProlongationModal: Dispatch<SetStateAction<boolean>>;
  selectedOrders: OrderMassUpdateMap;
};

function OrderProlongationModals({
  isOpen,
  setIsOpenProlongationModal,
  selectedOrders,
}: OrderProlongationModalsProps): JSX.Element {
  const [prolongationPeriod, setProlongationPeriod] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [isAllSettled, setIsAllSettled] = useState<boolean>(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState<number>(0);
  const [uploadErrorIds, setUploadErrorIds] = useState<
    { id: string; err: string }[]
  >([]);

  const onChangePeriod = (event: React.FormEvent<unknown>, value: string) => {
    setProlongationPeriod(value);
  };

  const onChangeComment = (event: React.FormEvent<unknown>, value: string) => {
    setComment(value);
  };

  const closeModal = () => {
    setComment('');
    setIsLoadingProgress(false);
    setProlongationPeriod('');
    setUploadProgressState(0);
    setUploadErrorIds([]);
    setIsOpenProlongationModal(false);
    if (isAllSettled) {
      OrderFilterService.loadOrders();
    }
    setIsAllSettled(false);
  };

  const doSingleProlongation = async (
    orderId: string,
    startOfValidity: string,
    endOfValidity: string
  ): Promise<OrderPrivateDTO | Error> => {
    return addValidityPeriodUpdate(orderId, {
      startOfValidity,
      endOfValidity,
      comment,
      traceType: OrderTraceType.VALIDITY_PERIOD,
      source: SubscriptionSource.BACK,
      timestamp: new Date().toISOString(),
      subscriberId: null,
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        const error = err as Error;
        setUploadErrorIds(errors => [
          ...errors,
          { id: orderId, err: error.message },
        ]);
        return error;
      })
      .finally(() => {
        // Sert à incrementer la barre de progression
        setUploadProgressState(past => past + 1);
      });
  };

  async function onClickValidation() {
    setIsLoadingProgress(true);
    const prolongationsPromises: Promise<
      OrderPrivateDTO | Error
    >[] = Object.entries(selectedOrders).map(([orderId, dates]) => {
      return doSingleProlongation(
        orderId,
        dates.startOfValidity,
        moment(dates.endOfValidity)
          .add(prolongationPeriod)
          .toISOString()
      );
    });
    await Promise.all(prolongationsPromises);
    setIsAllSettled(true);
    setIsLoadingProgress(false);
  }

  <OrderMassUpdateProcess
    titleFail={_t('header.titleFail')}
    titleSuccess={_t('header.titleSuccess')}
    titleProgress={_t('header.titleProgress')}
    isOpen={isOpen}
    isAllSettled={isAllSettled}
    isLoadingProgress={isLoadingProgress}
    uploadProgressState={uploadProgressState}
    uploadErrorIds={uploadErrorIds}
    max={Object.keys(selectedOrders).length}
    onCloseModal={closeModal}
  />;

  return (
    <OrderProlongationInfoModal
      isOpen={isOpen}
      closeModal={closeModal}
      clickValidation={onClickValidation}
      comment={comment}
      onChangeComment={onChangeComment}
      prolongationPeriod={prolongationPeriod}
      onChangePeriod={onChangePeriod}
    />
  );
}

export default OrderProlongationModals;
