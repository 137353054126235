import React, { useState } from 'react';

import { Button, Flex, Input } from '@cvfm-front/commons-ui';
import { searchEsOrderScroll } from 'api/cvfm-core-subscription/order';
import {
  OrderEsPrivateDTO,
  OrderEsSearchResultDTO,
  ProductType,
  ZoningDTO,
} from '@cvfm-front/tefps-types';
import { filtersToScrollRequest } from 'tefps/Subscription/helpers';
import Services from 'commons/services';
import Date from 'commons/Date';
import { translateOrderStatus } from 'tefps/Subscription/OrderStatusTranslator';

import Pagination from './Pagination';
import './EligibilityList.css';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  recoursePlate: string;
  eligibilities: OrderEsSearchResultDTO | null;
  zoning: ZoningDTO | null;
};

const EligibilityList = ({
  recoursePlate,
  eligibilities,
  zoning,
}: Props): JSX.Element => {
  const [plate, setPlate] = useState<string>(recoursePlate);
  const [searchedPlate, setSearchedPlate] = useState<string>(recoursePlate);

  const getZone = (eligibility: OrderEsPrivateDTO): string => {
    const zoneNames = new Array<string>();
    if (zoning) {
      eligibility.validityZoneIds.forEach(eligibilityZone => {
        const zone = zoning.zones.find(
          zoneDTO => zoneDTO.id === eligibilityZone
        );
        if (zone) {
          zoneNames.push(zone.name);
        }
      });
    }
    return zoneNames.join(', ');
  };

  const displayEligibility = (eligibility: OrderEsPrivateDTO) => {
    return (
      <li
        className="eligibility-list__eligibility-detail"
        key={eligibility.orderId}
      >
        <span className="text-bold text-black">{_t('eligibility')} : </span>
        <span>
          <a
            href={`/#/subscription/eligibility/product/${eligibility.productId}`}
            target="_blank"
            rel="noreferrer"
          >
            {eligibility.productName}
          </a>
        </span>
        <ul className="eligibility-list__eligibility-detail__information">
          <li className="eligibility-list__eligibility-detail__field">
            {_t('startOfValidityDatetime')} :{' '}
            <Date datetime={eligibility.startOfValidity} withTime={false} />
          </li>
          <li className="eligibility-list__eligibility-detail__field">
            {_t('endOfValidityDatetime')} :{' '}
            <Date datetime={eligibility.endOfValidity} withTime={false} />
          </li>
          <li className="eligibility-list__eligibility-detail__field">
            {_t('status')}: {translateOrderStatus(eligibility.orderStatus)}
          </li>
          <li className="eligibility-list__eligibility-detail__field">
            {_t('zones')} : {getZone(eligibility)}
          </li>
        </ul>
      </li>
    );
  };

  const handleSearch = async () => {
    const searchPlate = plate === '' ? recoursePlate : plate;
    const filters = {
      plates: [searchPlate],
      productTypes: [ProductType.ELIGIBILITY],
    };
    const filterParam = filtersToScrollRequest(filters);
    const newEligibilities = await searchEsOrderScroll(filterParam);
    Services.eligibilities.applyEligibilitiesChanges(newEligibilities);
    setSearchedPlate(searchPlate);
  };

  if (!eligibilities || eligibilities.totalHits === 0) {
    return (
      <Flex flexDirection="column" className="eligibilities">
        <span className="card-title text-bold text-black">{_t('title')}</span>
        <div className="ticket-list__plates">
          <span>{_tg('tefps.recourseV2.proposal.eligibilityList.plate')}</span>
          <span>{searchedPlate}</span>
        </div>
        <p className="text-black">{_t('noResult')}</p>
        <div className="eligibility-list__search">
          <Input
            label={_t('search')}
            placeholder={_t('placeholder')}
            onChange={newPlate => setPlate(newPlate)}
            value={searchedPlate === recoursePlate ? '' : searchedPlate}
          />
          <Button onClick={handleSearch}>{_t('searchPlate')}</Button>
        </div>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" className="eligibilities">
      <span className="card-title text-bold text-black">{_t('title')}</span>
      <div className="ticket-list__plates">
        <span>{_tg('tefps.recourseV2.proposal.eligibilityList.plate')}</span>
        <span>{searchedPlate}</span>
      </div>
      <div className="eligibility-list">
        <Pagination
          items={eligibilities?.results || []}
          elementsPerPage={3}
          displayElement={displayEligibility}
        />
      </div>
      <div className="eligibility-list__search">
        <Input
          label={_t('search')}
          placeholder={_t('placeholder')}
          onChange={newPlate => setPlate(newPlate)}
          value={searchedPlate === recoursePlate ? '' : searchedPlate}
        />
        <Button onClick={handleSearch}>{_t('searchPlate')}</Button>
      </div>
    </Flex>
  );
};

export default EligibilityList;
