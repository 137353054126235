import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UploadFile } from 'api/commonTypes';
import useEvidenceFiles from 'commons/hooks/useEvidenceFiles';
import { Flex } from '@cvfm-front/commons-ui';
import {
  validateFileSize,
  validateTotalFileSize,
  ACCEPTED_FORMATS,
} from 'tefps/RecoursesV2/utils/recourseFilesUtils';
import { EvidenceFile } from 'commons/services/EvidenceFilesService';
import { UploadFileCustom } from 'commons/FormComponent/Fields';

const { _t } = window.loadTranslations(__filename);

type AddFileActionUploadFilesProps = {
  name: string;
  errors?: {
    [key: string]: string;
  };
};

const AddFileActionUploadFiles = ({
  name,
  errors,
}: AddFileActionUploadFilesProps): JSX.Element => {
  const [values, setValues] = useEvidenceFiles();

  const onChangeFile = (newValues: UploadFile[]) => {
    const newFiles: EvidenceFile[] = [];
    newValues.forEach(newValue =>
      newFiles.push({ id: uuidv4(), file: newValue })
    );
    setValues(newFiles);
  };

  const validateTotalSize = (
    file: UploadFile | null | undefined
  ): string | null | undefined => {
    const totalSize = values
      .map(value => (value.file ? value.file.size : 0))
      .reduce((total, size) => total + size, 0);
    return validateTotalFileSize(file, totalSize);
  };

  useEffect(() => {
    setValues([]);
  }, []);

  return (
    <Flex flexWrap="wrap">
      {values.map((value, pos) => {
        return (
          <UploadFileCustom
            key={uuidv4()}
            name={`${name}.${value.id}`}
            value={value.file}
            allValues={values.map(v => v.file)}
            onChange={(newFiles: UploadFile[]) => {
              onChangeFile(newFiles);
            }}
            validators={[validateFileSize, validateTotalSize]}
            error={errors && errors[value.id]}
            accept={ACCEPTED_FORMATS}
            hint={`${_t('form.document')} ${pos + 1}`}
            multipleFiles
          />
        );
      })}
      <UploadFileCustom
        key={uuidv4()}
        name={`${name}`}
        onChange={(newFiles: UploadFile[]) => {
          onChangeFile(newFiles);
        }}
        allValues={values.map(v => v.file)}
        validators={[validateFileSize, validateTotalSize]}
        hint={_t('form.addFile')}
        accept={ACCEPTED_FORMATS}
        multipleFiles
      />
    </Flex>
  );
};

export default AddFileActionUploadFiles;
