import React from 'react';
import { Card } from 'material-ui';

import { Tabs, Tab } from '@cvfm-front/commons-ui';
import useRecourse from 'commons/hooks/useRecourse';
import useFps from 'commons/hooks/useFps';
import FpsSummaryInfo from 'tefps/RecoursesV2/pages/Proposal/FpsSummaryInfo';
import FpsSummaryPhotos from 'tefps/RecoursesV2/pages/Proposal/FpsSummaryPhotos';

const { _t } = window.loadTranslations(__filename);

const FpsSummary = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const latestFps = useFps();

  return latestFps && recourseDetails ? (
    <Card className="recourses-card">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('title')}</div>
      </div>
      <div className="recourse-card__content text--small">
        <Tabs
          defaultActiveTabIndex={0}
          tabsClassName="text-bold"
          contentClassName="margin-y--s"
        >
          <Tab title={_t('fpsSummary.fpsInfo')}>
            <FpsSummaryInfo
              recourseDetails={recourseDetails}
              latestFps={latestFps}
            />
          </Tab>
          <Tab title={_t('fpsSummary.fpsPhotos')}>
            <FpsSummaryPhotos images={recourseDetails.fps.medias} />
          </Tab>
        </Tabs>
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default FpsSummary;
