import React from 'react';
import { FlatButton } from 'material-ui';
import ContinueIcon from 'material-ui/svg-icons/navigation/arrow-forward';

import { Flex } from '@cvfm-front/commons-ui';
import SectionTitle from 'commons/SectionTitle';
import MailEditor from 'commons/Mail/MailTemplateEditor/MailEditor/MailEditor';
import MailConfiguration from 'commons/Mail/MailTemplateEditor/MailConfiguration/MailConfiguration';
import { getHintList } from 'commons/Mail/utils';
import {
  MailConfigurationDTO,
  MailTemplateDTO,
  MailTypeRAPO,
} from '@cvfm-front/tefps-types';
import { translateFile } from 'tefps/RecoursesV2/utils/recourseFilesUtils';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';
import useCityConfig from 'commons/hooks/useCityConfig';
import useMailTemplate from 'commons/hooks/useMailTemplate';
import useComplementRequest from 'commons/hooks/useComplementRequest';

import './ComplementRequestEditor.css';
import BoButton from 'facade/BoButton';

const { _t } = window.loadTranslations(__filename);

type ComplementRequestEditor = {
  onSubmit: () => void;
  onPreview: () => void;
};

const ComplementRequestEditor = ({
  onSubmit,
  onPreview,
}: ComplementRequestEditor): JSX.Element => {
  const config = useCityConfig();
  const [complementRequest] = useComplementRequest();
  const [mailTemplate, setMailTemplate] = useMailTemplate();

  const onContentChange = (newTemplate: MailTemplateDTO) => {
    if (mailTemplate) {
      setMailTemplate({ ...mailTemplate, mail: newTemplate.mail });
    }
  };

  const onConfigurationChange = (mailConfiguration?: MailConfigurationDTO) => {
    if (mailTemplate) {
      setMailTemplate({ ...mailTemplate, mailConfiguration });
    }
  };

  return (
    <div className="complement-request">
      <Flex className="navigation-bar">
        <ReturnButton />
        <FlatButton
          className="align-right"
          label={_t('complementRequest.validate')}
          labelPosition="before"
          onClick={onSubmit}
          icon={<ContinueIcon />}
        />
      </Flex>
      <SectionTitle title={_t('complementRequest.title')} />
      <div className="margin--s">
        {_t('complementRequest.list')}
        <ul className="complement-request__list">
          {complementRequest.files.map(file => (
            <li key={file}>{translateFile(file)}</li>
          ))}
        </ul>
      </div>
      <MailEditor
        mailTemplate={{
          mail: mailTemplate?.mail,
          mailConfiguration: mailTemplate?.mailConfiguration,
          type: MailTypeRAPO.RAPO_COMPLEMENT,
        }}
        onContentChange={onContentChange}
        hintList={
          config ? getHintList(config, MailTypeRAPO.RAPO_COMPLEMENT) : []
        }
      />

      <Flex>
        <BoButton
          label={_t('complementRequest.preview')}
          primary
          className="align-right"
          onClick={onPreview}
        />
      </Flex>

      {mailTemplate?.mailType !== MailTypeRAPO.RAPO_COMPLEMENT_NOTIFICATION && (
        <>
          <SectionTitle title={_t('complementRequest.mailConfiguration')} />
          <MailConfiguration
            mailConfiguration={mailTemplate?.mailConfiguration}
            onChange={onConfigurationChange}
            editable={false}
            mailType={MailTypeRAPO.RAPO_COMPLEMENT}
          />
        </>
      )}
    </div>
  );
};

export default ComplementRequestEditor;
