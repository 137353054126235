import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, TextField } from 'material-ui';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';

import BoButton from 'facade/BoButton';
import {
  MailTypeOrder,
  ProductPrivateDTO,
  ProductType,
} from '@cvfm-front/tefps-types';
import { createNewDefaultProduct } from '@cvfm-front/tefps-utils';
import { createProduct } from 'api/cvfm-core-subscription/product';
import { getApiState, InternalApiState } from 'api/duck';
import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductCreateModalReduxProps = {
  cityId: string;
  orgId: string;
};

type ProductCreateModalProps = {
  open: boolean;
  isDuplicate?: boolean;
  productType: ProductType;
  onCancel: () => void;
  onConfirm: (
    created: Promise<ProductPrivateDTO>,
    productId: string,
    isDuplicate: boolean
  ) => void;
  productToDuplicate?: ProductPrivateDTO;
} & ProductCreateModalReduxProps;

const ProductCreateModal = ({
  open,
  isDuplicate = false,
  cityId,
  orgId,
  productType,
  onCancel,
  onConfirm,
  productToDuplicate,
}: ProductCreateModalProps): JSX.Element => {
  const [product, setProduct] = useState<ProductPrivateDTO>();

  function onCancelAction() {
    setProduct(undefined);
    onCancel();
  }

  function onConfirmAction() {
    if (product) {
      product.enabledNotifications = [
        MailTypeOrder.ORDER_CREATION,
        MailTypeOrder.ORDER_ACCEPTED,
        MailTypeOrder.ORDER_REJECTED,
        MailTypeOrder.ORDER_AWAITING_PAYMENT,
        MailTypeOrder.ORDER_AWAITING_PAYMENT_REMINDER,
        MailTypeOrder.ORDER_TO_BE_RENEWED,
        MailTypeOrder.ORDER_POST_BE_RENEWED,
        MailTypeOrder.ORDER_DISCARDED,
        MailTypeOrder.ORDER_EDIT_PLATE,
        MailTypeOrder.ORDER_EDIT_PLATE_REJECTED,
        MailTypeOrder.ORDER_EVIDENCE_REQUEST,
        MailTypeOrder.FPS_MAJORATION,
      ];
      const createPromise = createProduct(product);
      onConfirm(createPromise, product.productId, isDuplicate);
    }
  }

  function handleChangeName(e: never, text: string): void {
    if (product) {
      setProduct({ ...product, name: text });
    }
  }

  function handleChangeId(e: never, text: string): void {
    if (product) {
      const newProduct = { ...product, productId: text };
      newProduct.options[0].productId = text;
      newProduct.restrictions.nextProductId = text;
      setProduct(newProduct);
    }
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    if (productToDuplicate) {
      const clonedProductToDuplicate = cloneDeep(productToDuplicate);
      clonedProductToDuplicate.options = clonedProductToDuplicate.options.map(
        option => {
          return { ...option, id: uuidv4() };
        }
      );

      setProduct(clonedProductToDuplicate);
      return;
    }

    setProduct(createNewDefaultProduct(cityId, orgId, productType, ''));
  }, [open]);

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="new-product-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="new-product-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
      disabled={!product?.productId || product.productId.includes(' ')}
    />,
  ];

  return (
    <Dialog title={_tg('action.create')} open={open} actions={actions}>
      <div className="product-page_content">
        {product && (
          <>
            <TextField
              fullWidth
              name="new-product-id"
              floatingLabelText={_tg('field.id')}
              onChange={handleChangeId}
              value={product.productId}
              errorText={product.productId.includes(' ') && _t('error')}
            />
            <TextField
              fullWidth
              name="new-product-name"
              floatingLabelText={_tg('field.name')}
              onChange={handleChangeName}
              value={product.name}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};

function mapStateToProps(
  state: InternalApiState
): ProductCreateModalReduxProps {
  const { cityId, userInfo } = getApiState(state);
  return {
    cityId: cityId || '',
    orgId: userInfo?.worksFor?.organizationId || '',
  };
}

export default connect(mapStateToProps)(ProductCreateModal);
