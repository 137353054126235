import React from 'react';
import FileIcon from 'material-ui/svg-icons/content/content-paste';

import {
  EvidenceProperties,
  OrderEvidence,
  OrderPrivateDTO,
  ProductPrivateDTO,
  SubscriberDTO,
  SubscriberMediaDTO,
} from '@cvfm-front/tefps-types';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';

import './OrderDetailPage.css';

const { _tg } = window.loadTranslations(__filename);

type OrderDetailEvidenceHistoryProps = {
  subscribers: Array<SubscriberDTO>;
  orderTrace: OrderEvidence;
  orderProduct: ProductPrivateDTO;
  order: OrderPrivateDTO;
};

const OrderDetailEvidenceHistory = ({
  subscribers,
  orderTrace,
  orderProduct,
  order,
}: OrderDetailEvidenceHistoryProps): JSX.Element => {
  function computeEvidenceProperties(): EvidenceProperties | undefined {
    let evidenceProperties = orderProduct.applicationProcedure?.supportingEvidences?.find(
      e => e.id === orderTrace.evidencePropertiesId
    );

    if (!evidenceProperties) {
      evidenceProperties = order.complementEvidenceHistory.find(
        e => e.evidenceProperties.id === orderTrace.evidencePropertiesId
      )?.evidenceProperties;
    }
    return evidenceProperties;
  }

  function computeEvidenceMedia(
    evidenceProperties: EvidenceProperties | undefined
  ): SubscriberMediaDTO[] | undefined | null {
    if (!evidenceProperties) {
      return undefined;
    }

    let subscriberEvidences: Array<SubscriberMediaDTO>;
    switch (evidenceProperties.type) {
      case 'PROOF_OF_ID': {
        subscriberEvidences = subscribers[0].identity?.documents ?? [];
        break;
      }
      case 'PERSONAL_PROOF_OF_ADDRESS': {
        subscriberEvidences =
          subscribers[0].personalProfile?.location.proofOfAddress?.documents ??
          [];
        break;
      }
      case 'PROFESSIONAL_PROOF_OF_ACTIVITY': {
        subscriberEvidences =
          subscribers[0].professionalProfile?.company.proofDocuments
            ?.documents ?? [];
        break;
      }
      case 'PROFESSIONAL_PROOF_OF_ADDRESS': {
        subscriberEvidences =
          subscribers[0].professionalProfile?.location.proofOfAddress
            ?.documents ?? [];
        break;
      }
      default:
        subscriberEvidences = subscribers.reduce(
          (acc, sub) => acc.concat(sub.evidences),
          [] as Array<SubscriberMediaDTO>
        );
    }

    const evidence = subscriberEvidences.find(
      evidenceMedia => evidenceMedia.id === orderTrace.evidenceSubscriberMediaId
    );

    return evidence ? [evidence] : undefined;
  }

  function renderEvidenceLink(evidenceMedia: SubscriberMediaDTO): JSX.Element {
    if (evidenceMedia === undefined) {
      return <a>{orderTrace.evidenceSubscriberMediaId}</a>;
    }

    return (
      <a
        href={evidenceMedia.contentUrl}
        target="_blank"
        rel="noopener noreferrer"
        title={_tg('action.downloadAttachment')}
      >
        <div className="order-detail_row">
          <span className="order-detail_cell-25">
            <FileIcon />
          </span>
          <div>{evidenceMedia.name}</div>
        </div>
      </a>
    );
  }

  const evidenceProperties = computeEvidenceProperties();
  const evidenceMedias = computeEvidenceMedia(evidenceProperties);

  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_tg('field.evidence.evidenceShort')}
        value={evidenceProperties?.name}
      />
      {evidenceMedias &&
        evidenceMedias.map(e => (
          <FakeInputBlock
            title={_tg('field.evidence.file')}
            value={renderEvidenceLink(e)}
          />
        ))}
    </DataBoxItem>
  );
};

export default OrderDetailEvidenceHistory;
