import React, { useMemo, useState } from 'react';
import { Checkbox, MenuItem, SelectField, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import {
  ProductOption,
  ProductOptionReference,
  ProductPrivateDTO,
  ProductAutomaticOrder,
} from '@cvfm-front/tefps-types';

import './ProductDetailPage.css';
import ProductDetailSeparator from './commons/DetailSeparator';
import ProductDetailAddAutomaticOrder from './ProductDetailAddAutomaticOrder';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailAutomaticOrderProps = {
  automaticOrders: Array<ProductAutomaticOrder>;
  otherProducts: Array<ProductPrivateDTO>;
  canEdit: boolean;
  onChange: (field: string, newValue: unknown) => void;
};

function listAvailableProductOptionReference(
  options: Array<ProductPrivateDTO>,
  alreadyUsedReferences: Array<ProductAutomaticOrder>
): { [key: string]: Array<ProductOption> } {
  return options.reduce((acc, product) => {
    const optList = product.options.filter(
      option =>
        !alreadyUsedReferences.find(
          reference =>
            product.productId === reference.productToOrder.productId &&
            option.id === reference.productToOrder.productOptionId
        )
    );
    if (optList.length) {
      acc[product.productId] = optList;
    }
    return acc;
  }, {} as { [key: string]: Array<ProductOption> });
}

const ProductDetailAutomaticOrder = ({
  automaticOrders,
  otherProducts,
  canEdit,
  onChange,
}: ProductDetailAutomaticOrderProps): JSX.Element => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);

  const availableChoices = useMemo(
    () => listAvailableProductOptionReference(otherProducts, automaticOrders),
    [otherProducts, automaticOrders]
  );

  function handleSelectProduct(index: number, value: string): void {
    const newAutomaticOrders = [...automaticOrders];
    const newAutomaticOrder = { ...automaticOrders[index] };
    const newProductToOrder = { ...newAutomaticOrder.productToOrder };

    newProductToOrder.productId = value;
    newAutomaticOrder.productToOrder = newProductToOrder;
    newAutomaticOrders[index] = newAutomaticOrder;
    onChange('automaticOrders', newAutomaticOrders);
  }

  function handleSelectProductOption(index: number, value: string): void {
    const newAutomaticOrders = [...automaticOrders];
    const newAutomaticOrder = { ...automaticOrders[index] };
    const newProductToOrder = { ...newAutomaticOrder.productToOrder };

    newProductToOrder.productOptionId = value;
    newAutomaticOrder.productToOrder = newProductToOrder;
    newAutomaticOrders[index] = newAutomaticOrder;
    onChange('automaticOrders', newAutomaticOrders);
  }

  const addAutomaticOrder = (optRef: ProductOptionReference) => {
    onChange('automaticOrders', [
      ...automaticOrders,
      { productToOrder: optRef, bypassApplicationProcedure: false },
    ]);
    setOpenAddModal(false);
  };

  function handleClickAdd(): void {
    setOpenAddModal(true);
  }

  function handleClickRemove(e: React.MouseEvent<HTMLDataElement>): void {
    const { index } = e.currentTarget.dataset;
    if (index && parseInt(index, 10) !== undefined) {
      const newAutomaticOrders = [...automaticOrders];
      newAutomaticOrders.splice(parseInt(index, 10), 1);
      onChange('automaticOrders', newAutomaticOrders);
    }
  }

  function handleCheck(
    event: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) {
    const { index } = event.currentTarget.dataset;
    if (index && parseInt(index, 10) !== undefined) {
      const autoOrderIndex = parseInt(index, 10);
      const checkedAutoOrder = { ...automaticOrders[autoOrderIndex] };
      checkedAutoOrder.bypassApplicationProcedure = checked;

      const newAutomaticOrders = [...automaticOrders];
      newAutomaticOrders[autoOrderIndex] = checkedAutoOrder;
      onChange('automaticOrders', newAutomaticOrders);
    }
  }

  function renderSelects(
    { productToOrder, bypassApplicationProcedure }: ProductAutomaticOrder,
    index: number
  ): JSX.Element {
    const otherProduct = otherProducts.find(
      p => p.productId === productToOrder.productId
    );

    return (
      <div className="product-detail_section-content-row">
        <span className="product-detail_cell-25">
          <SelectField
            key={`product-${index}`}
            data-index={index}
            floatingLabelFixed
            floatingLabelText={_t('field.label.product')}
            disabled={!canEdit}
            fullWidth
            onChange={(_e, _i, v) => handleSelectProduct(index, v)}
            value={productToOrder.productId}
          >
            {otherProducts
              .filter(
                other =>
                  other.productId === productToOrder.productId ||
                  (availableChoices[other.productId] &&
                    availableChoices[other.productId].length)
              )
              .map(other => (
                <MenuItem
                  id={other.productId}
                  key={other.productId}
                  value={other.productId}
                  primaryText={other.name}
                  insetChildren
                />
              ))}
          </SelectField>
        </span>
        <span className="product-detail_cell-25">
          {otherProduct?.options ? (
            <SelectField
              key={`option-${index}`}
              data-index={index}
              floatingLabelFixed
              floatingLabelText={_t('field.label.option')}
              disabled={!canEdit}
              fullWidth
              onChange={(_e, _i, v) => handleSelectProductOption(index, v)}
              value={productToOrder.productOptionId}
            >
              {otherProduct.options
                .filter(
                  option =>
                    option.id === productToOrder.productOptionId ||
                    availableChoices[otherProduct.productId]?.find(
                      otherOpt => otherOpt.id === option.id
                    )
                )
                .map(otherOptions => (
                  <MenuItem
                    id={otherOptions.id}
                    key={otherOptions.id}
                    value={otherOptions.id}
                    primaryText={otherOptions.name}
                    insetChildren
                  />
                ))}
            </SelectField>
          ) : (
            <TextField disabled value={_t('text.no_option')} />
          )}
        </span>

        <span className="product-detail_cell-75">
          <Checkbox
            data-index={index}
            label={_t('checkbox.bypass')}
            disabled={!canEdit}
            checked={bypassApplicationProcedure}
            onCheck={handleCheck}
          />
        </span>
        <span className="product-detail_cell-12">
          <BoButton
            data-index={index}
            label={_tg('action.delete')}
            secondary
            fullWidth
            disabled={!canEdit}
            onClick={handleClickRemove}
          />
        </span>
      </div>
    );
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />
      <div className="product-detail_section-content">
        {automaticOrders.map((order, index) => renderSelects(order, index))}
        <div className="product-detail_section-content-row">
          <span
            className="product-detail_cell-12"
            title={
              Object.keys(availableChoices).length
                ? _t('button.hint.add')
                : _t('button.hint.not_available')
            }
          >
            <BoButton
              label={_tg('action.add')}
              onClick={handleClickAdd}
              fullWidth
              disabled={!canEdit || !Object.keys(availableChoices).length}
              primary
            />
          </span>
        </div>
      </div>
      <ProductDetailAddAutomaticOrder
        availableChoices={availableChoices}
        opened={openAddModal}
        onClose={() => setOpenAddModal(false)}
        otherProducts={otherProducts}
        onSave={addAutomaticOrder}
      />
    </div>
  );
};

export default ProductDetailAutomaticOrder;
