import React, { useState } from 'react';

import { Button, Flex, Input } from '@cvfm-front/commons-ui';
import { ParkingRightOverviewDTO } from 'api/tickets/types';
import Services from 'commons/services';
import Date from 'commons/Date';
import useParkingRights from 'commons/hooks/useParkingRights';
import useRecourse from 'commons/hooks/useRecourse';

import Pagination from './Pagination';
import TicketDetail from './TicketDetail';

import './TicketList.css';

const { _t } = window.loadTranslations(__filename);

type Props = {
  recoursePlate: string;
};

const TicketList = ({ recoursePlate }: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [plate, setPlate] = useState<string>(recoursePlate);
  const parkingRights = useParkingRights();
  const recourseDetails = useRecourse();
  const [searchedPlate, setSearchedPlate] = useState<string>(recoursePlate);
  const [ticketSelected, setTicketSelected] = useState<
    ParkingRightOverviewDTO | undefined
  >(undefined);

  if (!recourseDetails) {
    return <></>;
  }

  const handleModal = ({
    currentTarget,
  }: React.MouseEvent<HTMLSpanElement>): void => {
    if (parkingRights) {
      setTicketSelected(
        parkingRights.parkingRights.find(
          pr => pr.ticketId === currentTarget.dataset.ticketId
        )
      );
      setOpen(!open);
    }
  };

  const onClose = () => {
    setOpen(false);
    setTicketSelected(undefined);
  };

  const displayTicket = (pr: ParkingRightOverviewDTO): JSX.Element => {
    return (
      <li
        className="ticket-list__ticket-history__ticket text-black"
        key={pr.ticketId}
      >
        <span
          onClick={handleModal}
          className={pr.ticketId ? 'ticket-list__ticket-history-hover' : ''}
          data-ticket-id={pr.ticketId}
        >
          {_t('ticketHistory.the')}{' '}
          <Date datetime={pr.startDatetime} withDate withTime={false} />{' '}
          {_t('ticketHistory.from')}{' '}
          <Date datetime={pr.startDatetime} withDate={false} withTime />{' '}
          {_t('ticketHistory.to')}{' '}
          <Date datetime={pr.endDatetime} withDate withTime={false} />{' '}
          {_t('ticketHistory.at')}{' '}
          <Date datetime={pr.endDatetime} withDate={false} withTime />
        </span>
      </li>
    );
  };

  const handleSearch = async () => {
    const searchPlate = plate === '' ? recoursePlate : plate;
    await Services.parkingRights.init(
      searchPlate,
      recourseDetails?.fps.statementDatetime
    );
    setSearchedPlate(searchPlate);
  };

  if (!parkingRights || parkingRights.parkingRights.length === 0) {
    return (
      <Flex flexDirection="column" className="ticket-list text-black">
        <span className="ticket-list__title">{_t('title')}</span>
        <div className="ticket-list__plates text-black">
          <span className="text-bold">{_t('ticketHistory.plate')} : </span>
          <span>{searchedPlate}</span>
        </div>
        <p className="no-ticket__message">{_t('noTicketsFound')}</p>
        <div className="ticket-list__search-plate">
          <p>{_t('ticketHistory.search')}</p>
          <div className="ticket-list__search-plate__search text-black">
            <Input
              label=""
              placeholder={_t('ticketHistory.placeholder')}
              onChange={newPlate => setPlate(newPlate)}
            />
            <Button onClick={handleSearch}>{_t('searchPlate')}</Button>
          </div>
        </div>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" className="ticket-list">
      <span className="ticket-list__title">{_t('title')}</span>
      <div className="ticket-list__plates text-black">
        <span className="text-bold">{_t('ticketHistory.plate')} : </span>
        <span>{searchedPlate}</span>
      </div>
      <div className="ticket-list__ticket-history">
        <p className="ticket-list__ticket-history__title text-black text-bold">
          {_t('ticketHistory.lastTickets')}
        </p>
        <Pagination
          items={parkingRights.parkingRights}
          elementsPerPage={5}
          displayElement={displayTicket}
        />
      </div>
      <div className="ticket-list__search-plate text-black">
        <p>{_t('ticketHistory.search')}</p>
        <div className="ticket-list__search-plate__search">
          <Input
            label=""
            placeholder={_t('ticketHistory.placeholder')}
            onChange={newPlate => setPlate(newPlate)}
            value={searchedPlate === recoursePlate ? '' : searchedPlate}
          />
          <Button onClick={handleSearch}>{_t('searchPlate')}</Button>
        </div>
      </div>
      {ticketSelected && (
        <TicketDetail
          parkingRight={ticketSelected}
          open={open}
          onRequestClose={onClose}
        />
      )}
    </Flex>
  );
};

export default TicketList;
