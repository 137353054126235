import React from 'react';
import { MenuItem, SelectField } from 'material-ui';

import {
  OrderEsPrivateDTO,
  ProductOptionReference,
} from '@cvfm-front/tefps-types';
// TODO
import './OrderDetailPage.css';
import { ItemIdName } from 'api/commonTypes';
import { formatDateFromString } from 'commons/Utils/dateUtil';

const { _tg } = window.loadTranslations(__filename);

type OrderCreateProductRequiredProps = {
  productItems: Array<ItemIdName>;
  activeProductsRequired: Array<ProductOptionReference>;
  selectedRightId: string | null | undefined;
  activeRights: Array<OrderEsPrivateDTO>;
  label: string;
  onChange: (selectedRightId: string | undefined) => void;
};

function computeItems(
  activeProductsRequired: Array<ProductOptionReference>,
  activeRights: Array<OrderEsPrivateDTO>,
  productItems: Array<ItemIdName>
): Array<ItemIdName> {
  return activeRights
    .filter(r => activeProductsRequired.some(p => p.productId === r.productId))
    .map(r => {
      const productName = productItems.find(i => i.id === r.productId)?.name;
      return {
        id: r.orderId,
        name: `${productName || ''} - ${r.rawPlates.join(', ') || ''} - ${_tg(
          'field.date.fromAlternative'
        )} ${formatDateFromString(r.startOfValidity)} ${_tg(
          'field.date.toAlternative'
        )} ${formatDateFromString(r.endOfValidity)}`,
      };
    });
}

const OrderCreateProductRequired = ({
  productItems,
  activeProductsRequired,
  selectedRightId,
  activeRights,
  label,
  onChange,
}: OrderCreateProductRequiredProps): JSX.Element => {
  function handleChange(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: string
  ): void {
    onChange(value);
  }

  const items = computeItems(
    activeProductsRequired,
    activeRights,
    productItems
  );

  if (!items.some(value => value.id === selectedRightId)) {
    onChange(undefined);
  }

  return (
    <div className="order-detail_row">
      <span className="order-detail_cell-25">{label}</span>
      <span className="order-detail_cell-75">
        <SelectField
          fullWidth
          id="order-zone-selector"
          onChange={handleChange}
          value={selectedRightId}
        >
          {items.map(item => (
            <MenuItem
              id={item.id}
              key={item.id}
              value={item.id}
              primaryText={item.name}
              insetChildren
            />
          ))}
        </SelectField>
      </span>
    </div>
  );
};

export default OrderCreateProductRequired;
