import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useMailTemplate from 'commons/hooks/useMailTemplate';
import useComplementRequest from 'commons/hooks/useComplementRequest';
import Services from 'commons/services';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import Content from 'commons/Content';
import {
  createComplementRequest,
  previewComplementRequest,
} from 'api/recourse';
import { ComplementRequestCreationDTO } from 'api/recourse/types';
import Loading from 'tefps/RecoursesV2/commons/Loading';

import ComplementRequestEditor from './ComplementRequestEditor';

const { _t } = window.loadTranslations(__filename);

const ComplementRequest = (): JSX.Element => {
  /* Service related hooks */
  const [mailTemplate] = useMailTemplate();
  const [complementRequest] = useComplementRequest();

  /* Component related hooks */
  const [loading, setLoading] = useState<boolean>(false);
  const setMessage = useSnackbar();
  const history = useHistory();
  const { recourseId } = useParams<{ recourseId: string }>();

  const initMailContent = async () => {
    setLoading(true);
    try {
      await Services.mailTemplate.init();
    } catch (e) {
      setMessage(_t('error.mailType'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void initMailContent();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await createComplementRequest(recourseId, {
        ...mailTemplate,
        ...(complementRequest as ComplementRequestCreationDTO),
      });
      setMessage(_t('success'));
      history.push(`/recourses/detail/${recourseId}`);
    } catch (e) {
      setMessage(_t('error.complementRequest'));
    } finally {
      setLoading(false);
    }
  };

  const onPreview = () => {
    setLoading(true);
    previewComplementRequest(recourseId, {
      ...mailTemplate,
      ...(complementRequest as ComplementRequestCreationDTO),
    });
    setLoading(false);
  };

  return (
    <Content>
      {!loading && mailTemplate && (
        <ComplementRequestEditor onSubmit={onSubmit} onPreview={onPreview} />
      )}
      {loading && <Loading />}
    </Content>
  );
};

export default ComplementRequest;
