import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { ComplementRequestDTO } from 'api/recourse/types';

const { _tg } = window.loadTranslations(__filename);

type ComplementRequestExpirationProps = {
  complementRequest: ComplementRequestDTO;
};

const ComplementRequestExpiration = ({
  complementRequest,
}: ComplementRequestExpirationProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.endValidityDate')}
            value={
              <Date datetime={complementRequest.endDate} withTime={false} />
            }
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default ComplementRequestExpiration;
