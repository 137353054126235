import React, { useEffect, useState } from 'react';

import BoButton from 'facade/BoButton';
import { OrderRestrictionsCount } from '@cvfm-front/tefps-types/build/order/OrderRestrictionsCount';
import {
  OrderPrivateDTO,
  OrderStatus,
  ProductPrivateDTO,
  ProductType,
} from '@cvfm-front/tefps-types';
import { getRestrictionCountByOrderId } from 'api/cvfm-core-subscription/order';
import { BKG_BLUE, BKG_GREEN, TXT_BLACK, TXT_ERROR_RED } from 'theme';
import './OrderDetailPage.css';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import SeparatorHorizontal from 'commons/SeparatorHorizontal';

import OrderDetailCustomLimitCounterModal from './OrderDetailCustomLimitCounterModal';

const { _t } = window.loadTranslations(__filename);

type OrderDetailSummaryProps = {
  order: OrderPrivateDTO;
  orderProduct: ProductPrivateDTO;
  refreshOrder: () => void;
};

const tradsByKeysBundle: {
  [key: string]: (current: number, limit: number, fieldName: string) => string;
} = {
  countByCivilYear(current: number, limit: number, fieldName: string) {
    return _t('bundle.countByCivilYear', { current, limit, fieldName });
  },
  countByEligibility(current: number, limit: number, fieldName: string) {
    return _t('bundle.countByEligibility', { current, limit, fieldName });
  },
  countByPlate(current: number, limit: number, fieldName: string) {
    return _t('bundle.countByPlate', { current, limit, fieldName });
  },
  countByCustomField(current: number, limit: number, fieldName: string) {
    return _t('bundle.countByCustomField', { current, limit, fieldName });
  },
  countByCustomFieldFulfilled(
    current: number,
    limit: number,
    fieldName: string
  ) {
    return _t('bundle.countByCustomFieldFulfilled', {
      current,
      limit,
      fieldName,
    });
  },
  countBySubscriber(current: number, limit: number, fieldName: string) {
    return _t('bundle.countBySubscriber', { current, limit, fieldName });
  },
};

const tradsByKeysEligibility: {
  [key: string]: (current: number, limit: number, fieldName: string) => string;
} = {
  countByCivilYear(current: number, limit: number, fieldName: string) {
    return _t('eligibility.countByCivilYear', { current, limit, fieldName });
  },
  countByEligibility(current: number, limit: number, fieldName: string) {
    return _t('eligibility.countByEligibility', { current, limit, fieldName });
  },
  countByPlate(current: number, limit: number, fieldName: string) {
    return _t('eligibility.countByPlate', { current, limit, fieldName });
  },
  countByCustomField(current: number, limit: number, fieldName: string) {
    return _t('eligibility.countByCustomField', { current, limit, fieldName });
  },
  countByCustomFieldFulfilled(
    current: number,
    limit: number,
    fieldName: string
  ) {
    return _t('eligibility.countByCustomFieldFulfilled', {
      current,
      limit,
      fieldName,
    });
  },
  countBySubscriber(current: number, limit: number, fieldName: string) {
    return _t('eligibility.countBySubscriber', { current, limit, fieldName });
  },
  countByRightOnPlate(current: number, limit: number, fieldName: string) {
    return _t('eligibility.countByPlateAlreadyUse', {
      current,
      limit,
      fieldName,
    });
  },
};

const OrderDetailRestrictionWarnings = ({
  order,
  orderProduct: { applicationProcedure, productType, restrictions },
  refreshOrder,
}: OrderDetailSummaryProps): JSX.Element => {
  const [
    orderRestrictions,
    setOrderRestriction,
  ] = useState<OrderRestrictionsCount | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  function openCustomModal() {
    setOpenModal(true);
  }

  function handleModalCancel() {
    setOpenModal(false);
  }

  function handleModalUpdate() {
    refreshOrder();
    setOpenModal(false);
  }

  useEffect(() => {
    async function fetchRestrictions() {
      const restrictionsCount = await getRestrictionCountByOrderId(
        order.orderId
      );
      setOrderRestriction(restrictionsCount);
    }
    void fetchRestrictions();
  }, [order]);

  if (
    !orderRestrictions ||
    !(
      // at least one restriction is required
      // TODO add plate limite
      (
        restrictions.orderLimitCounter > 0 ||
        restrictions.restrictionCount > 0 ||
        restrictions.subscriberLimit > 0 ||
        orderRestrictions.restrictionCountDependencies.length > 0 ||
        orderRestrictions.plateAlreadyInUse.length > 0
      )
    )
  ) {
    return <></>;
  }

  const ifOrderIsWaitingShouldIncrementCounterToSimulateTheCountAfterAcceptation =
    order.status === 'AWAIT_DECISION' ||
    order.status === 'AWAIT_EVIDENCE' ||
    order.status === 'AWAIT_PAYMENT'
      ? 1
      : 0;

  const restrictedField = applicationProcedure?.customFields?.find(
    field => field.key === restrictions.restrictionKey
  );

  function renderRestrictionWarning(
    translation: string,
    productLimit: number,
    orderCount: number,
    color?: string
  ): JSX.Element {
    const colorTextGreenOrRed =
      orderCount > productLimit ? TXT_ERROR_RED : BKG_GREEN;
    return (
      <div className="order-detail_row">
        <div
          className="order-detail-cell-75"
          style={{
            color: color || colorTextGreenOrRed,
          }}
        >
          {translation}
        </div>
        {restrictions.orderLimitAppliesDuringEligibilityPeriod && (
          <div className="order-detail-cell-25" style={{ paddingLeft: '25%' }}>
            <BoButton label={_t('add_custom')} onClick={openCustomModal} />
          </div>
        )}
      </div>
    );
  }

  function renderRestrictionWarningWithTrad(
    field: string,
    productLimit: number,
    orderCount: number
  ): JSX.Element {
    return renderRestrictionWarning(
      productType === ProductType.BUNDLE
        ? tradsByKeysBundle[field](
            orderCount,
            productLimit,
            restrictedField?.label || ''
          )
        : tradsByKeysEligibility[field](
            orderCount,
            productLimit,
            restrictedField?.label || ''
          ),
      productLimit,
      orderCount
    );
  }

  return (
    <>
      <SeparatorHorizontal />
      <DataBoxItemWrapper style={{ marginBottom: 20, width: '90%' }}>
        <DataBoxItem
          style={{
            flex: '',
            marginRight: '25px',
            color: TXT_BLACK,
            fontWeight: 'bold',
          }}
        >
          {_t('alerts')}
        </DataBoxItem>
        <DataBoxItem style={{ maxWidth: '100%' }}>
          {restrictions.subscriberLimit > 0 && (
            <>
              {renderRestrictionWarningWithTrad(
                'countBySubscriber',
                restrictions.subscriberLimit,
                orderRestrictions.subscriberCount
              )}
            </>
          )}
          {(restrictions.orderLimitAppliesDuringEligibilityPeriod ||
            restrictions.orderLimitAppliesDuringCivilYear) &&
            restrictions.orderLimitCounter > 0 && (
              <>
                {renderRestrictionWarningWithTrad(
                  restrictions.orderLimitAppliesDuringEligibilityPeriod
                    ? 'countByEligibility'
                    : 'countByCivilYear',
                  orderRestrictions.orderCountLimit,
                  orderRestrictions.orderCount
                )}
              </>
            )}
          {orderRestrictions.restrictionCountDependencies.map(dep => {
            let label = '';
            if (productType === ProductType.BUNDLE) {
              label = _t('bundle.countByEligibilityOnDependProduct', {
                productName: dep.productName,
                current: dep.orderCount,
                limit: dep.orderCountLimit,
              });
            } else if (dep.orderLimitAppliesDuringCivilYear) {
              label = _t(
                'eligibility.countByEligibilityOnDependProductByCivilYear',
                {
                  productName: dep.productName,
                  current: dep.orderCount,
                  limit: dep.orderCountLimit,
                }
              );
            } else {
              label = _t('eligibility.countByEligibilityOnDependProduct', {
                productName: dep.productName,
                current: dep.orderCount,
                limit: dep.orderCountLimit,
              });
            }
            return renderRestrictionWarning(
              label,
              dep.orderCountLimit,
              dep.orderCount
            );
          })}
          {restrictions.restrictionCount > 0 && (
            <>
              {renderRestrictionWarningWithTrad(
                ifOrderIsWaitingShouldIncrementCounterToSimulateTheCountAfterAcceptation
                  ? 'countByCustomField'
                  : 'countByCustomFieldFulfilled',
                orderRestrictions.restrictionCountLimit,
                orderRestrictions.restrictionCount
              )}
            </>
          )}
          {![OrderStatus.CANCELED, OrderStatus.REJECTED].includes(
            order.status
          ) &&
            order.productType === ProductType.ELIGIBILITY &&
            orderRestrictions.plateAlreadyInUse.map(plate => {
              const label = _t('eligibility.countByPlateAlreadyUse', {
                current: '',
                limit: 1,
                plate,
              });
              return renderRestrictionWarning(label, 1, 2, BKG_BLUE); // we just need to make a fake limit lower to the count to display it
            })}
        </DataBoxItem>
      </DataBoxItemWrapper>
      {orderRestrictions && (
        <OrderDetailCustomLimitCounterModal
          open={openModal}
          order={order}
          orderCountLimit={orderRestrictions.orderCountLimit}
          onConfirm={handleModalUpdate}
          onCancel={handleModalCancel}
        />
      )}
    </>
  );
};

export default OrderDetailRestrictionWarnings;
