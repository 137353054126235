import { FnmsFpsDTO, FpsAddressDTO } from '../../../../api/fps/types';
import { Address } from '../../../../api/commonTypes';
import {
  RecourseDecisionRequestDTO,
  RecourseDTO,
  RecourseStatus,
  RecourseUpdateStatus,
} from '../../../../api/recourse/types';

export const buildDecision = (
  accepted: boolean,
  emailEnabled: boolean,
  letterEnabled: boolean
): RecourseDecisionRequestDTO => ({
  accepted,
  emailEnabled,
  letterEnabled,
});

export const convertToFpsAddress = (address: Address): FpsAddressDTO => ({
  streetNumber: address.streetNumber,
  streetNumberBis: address.streetNumberBis,
  streetType: address.streetType,
  streetName: address.streetName,
  specialPlace: address.specialPlace,
  postOfficeBoxNumber: address.postOfficeBoxNumber,
  postalCode: address.postalCode,
  subRegion: address.addressSubRegion,
  region: address.addressRegion,
  locality: address.addressLocality,
  country: address.addressCountry,
});

export const RAPO_REASONS = {
  PAYE: "J'estime avoir payé",
  CESSION: "J'ai cédé / vendu le véhicule concerné",
  DISPENSE:
    'Je ne dois pas avoir à payer (handicap, autorisation particulière)',
  DESTRUCTION: 'Mon véhicule a été détruit',
  VOL: 'Mon véhicule a été volé',
  USURPATION: 'Mes plaques ont été usurpées',
  AVANCE:
    "L'avis de paiement a été délivré avant l'extinction du ticket de stationnement ou d'un Forfait de Post Stationnement précédent",
  AUTRE: 'Autre (merci de préciser dans votre demande)',
};

export const UPDATE_MESSAGE = {
  ACCEPTED: 'Le RAPO a bien été accepté',
  REFUSED: 'Le RAPO a bien été refusé',
  INCORRECT: 'Le RAPO a bien été marqué comme irrecevable',
  IS_WAITING: 'Le RAPO a été mis en attente',
  ONGOING_VALIDATION:
    "Le RAPO est bien mis à jour et en attente de validation d'une personne accréditée.",
  ERROR_PROPOSITION: "Une erreur s'est produite lors de la proposition",
  ERROR_VALIDATION: "Une erreur s'est produite lors de la validation",
};

export const VALIDATION_MESSAGE: { [key in RecourseUpdateStatus]?: string } = {
  ACCEPTED: UPDATE_MESSAGE.ACCEPTED,
  REFUSED: UPDATE_MESSAGE.REFUSED,
  INCORRECT: UPDATE_MESSAGE.INCORRECT,
  IS_WAITING: UPDATE_MESSAGE.IS_WAITING,
};

export const CHOICES_DECISION = [
  {
    value: 'ACCEPTED',
    label: 'Accepter',
  },
  {
    value: 'REFUSED',
    label: 'Rejeter',
  },
  {
    value: 'INCORRECT',
    label: 'Déclarer irrecevable',
  },
];

export const getStatusLabel = (status: RecourseStatus): string => {
  const wrapper = CHOICES_DECISION.find(w => w.value === status);
  return wrapper ? wrapper.label : '';
};

const ACTIVE_RECOURSE_STATUS: Set<RecourseStatus> = new Set<RecourseStatus>([
  'SUBMITTED',
  'IS_WAITING',
  'IS_WAITING_COMPLEMENT',
  'ONGOING_VALIDATION',
]);

export const isActiveRecourse = (status: RecourseStatus): boolean => {
  return ACTIVE_RECOURSE_STATUS.has(status);
};

export const shouldShowFpsWarning = (
  rapo: RecourseDTO,
  latestFpsVersion: string
): boolean => {
  return latestFpsVersion !== rapo.lastFpsId && isActiveRecourse(rapo.status);
};

export const computeTicketPrice = (fps: FnmsFpsDTO): number => {
  if (!fps.significantRights || fps.significantRights.length === 0) {
    return 0;
  }

  return fps.significantRights.reduce((prev, current) =>
    prev.startDatetime >= current.startDatetime ? prev : current
  ).rightPrice;
};
