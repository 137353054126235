import React from 'react';
import moment, { Moment } from 'moment/moment';

import { RapoDetailDTO } from 'api/recourse/types';
import { InternalAgent } from 'api/auth/types';
import InitialFps from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/InitialFps';
import RecourseCreation from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/RecourseCreation';
import UserInformationTitle from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/UserInformationTitle';
import UserInformation from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/UserInformation';
import ComplementRequest from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/ComplementRequest';
import ComplementRequestReception from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/ComplementRequestReception';
import ComplementRequestExpiration from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/ComplementRequestExpiration';
import ProposalIssue from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/ProposalIssue';
import PutOnHold from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/PutOnHold';
import ProposalDecision from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/ProposalDecision';
import AutomaticRejection from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/AutomaticRejection';
import ProceedWithRecourse from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/ProceedWithRecourse';
import { getAgentCommentFiles } from 'tefps/RecoursesV2/utils/recourseFilesUtils';
import Comment from 'tefps/RecoursesV2/pages/Detail/RecourseTimeLine/Comment';

const { _t, _tg } = window.loadTranslations(__filename);

export type TimelineItem = {
  date: Moment;
  title: {
    color?: string;
    left: string | React.ReactNode;
  };
  contentElement: React.ReactNode;
  pointColor?: string;
  comment?: string;
};

export function buildTimelineItems(
  recourse: RapoDetailDTO | null,
  userInfo: InternalAgent | null
): TimelineItem[] {
  const result: TimelineItem[] = [];
  if (recourse === null || userInfo === null) {
    return result;
  }

  const canModifyRecourse =
    userInfo.rights.includes('RAPO_ACCEPT') ||
    (userInfo.rights.includes('RAPO_UPDATE') &&
      recourse.recourse.createdByAgent);

  /* FPS info */
  result.push({
    date: moment(recourse.fps.statementDatetime),
    title: {
      color: 'var(--bg-color--cyan)',
      left: _tg('commons.fps'),
    },
    pointColor: 'var(--bg-color--cyan)',
    contentElement: InitialFps({ fps: recourse.fps }),
  });

  /* Recourse creation */
  result.push({
    date: moment(recourse.recourse.created),
    title: {
      color: 'var(--bg-color--cyan)',
      left: _t('timeline.recourseCreation'),
    },
    pointColor: 'var(--bg-color--cyan)',
    comment: recourse.recourse.comment ?? undefined,
    contentElement: RecourseCreation({ recourse: recourse.recourse }),
  });

  /* User info */
  result.push({
    date: moment(recourse.recourse.created),
    title: {
      color: 'var(--bg-color--cyan)',
      left: UserInformationTitle({
        title: _t('timeline.userInformation'),
        userType: 'user',
        recourse: recourse.recourse,
        disabled: !canModifyRecourse,
      }),
    },
    pointColor: 'var(--bg-color--cyan)',
    contentElement: UserInformation({ user: recourse.recourse.user }),
  });

  /* Representative info */
  if (recourse.recourse.representative) {
    result.push({
      date: moment(recourse.recourse.created),
      title: {
        color: 'var(--bg-color--cyan)',
        left: UserInformationTitle({
          title: _t('timeline.representativeInformation'),
          userType: 'representative',
          recourse: recourse.recourse,
          disabled: !canModifyRecourse,
        }),
      },
      pointColor: 'var(--bg-color--cyan)',
      contentElement: UserInformation({
        user: recourse.recourse.representative,
      }),
    });
  }

  /* For vehicle transfer recourse */
  if (
    recourse.recourse.reason === 'CESSION' &&
    recourse.recourse.transferUser
  ) {
    result.push({
      date: moment(recourse.recourse.created),
      title: {
        color: 'var(--bg-color--light-blue)',
        left: UserInformationTitle({
          title: _t('timeline.transferUserInformation'),
          userType: 'transferUser',
          recourse: recourse.recourse,
          disabled: !canModifyRecourse,
        }),
      },
      pointColor: 'var(--bg-color--light-blue)',
      contentElement: UserInformation({ user: recourse.recourse.transferUser }),
    });
  }

  /* Complement request */
  if (recourse.recourse.complementRequest) {
    result.push({
      date: moment(recourse.recourse.complementRequest.creationDate),
      title: {
        color: 'var(--bg-color--pink)',
        left: _t('timeline.complementRequest'),
      },
      contentElement: ComplementRequest({
        complementRequest: recourse.recourse.complementRequest,
      }),
      comment: recourse.recourse.complementRequest.complementInfo,
    });

    /* Complement sent */
    if (recourse.recourse.complementRequest.completionDate) {
      result.push({
        date: moment(recourse.recourse.complementRequest.completionDate),
        title: {
          color: 'var(--bg-color--pink)',
          left: _t('timeline.complementRequestReception'),
        },
        contentElement: ComplementRequestReception({
          complementRequest: recourse.recourse.complementRequest,
          recourse: recourse.recourse,
        }),
        comment: recourse.recourse.complementComment,
      });
    } else if (
      moment(recourse.recourse.complementRequest.endDate).isBefore(moment())
    ) {
      /* Expired complement request */
      result.push({
        date: moment(recourse.recourse.complementRequest.endDate),
        title: {
          color: 'var(--bg-color--pink)',
          left: _t('timeline.complementRequestExpiration'),
        },
        contentElement: ComplementRequestExpiration({
          complementRequest: recourse.recourse.complementRequest,
        }),
      });
    }
  }
  if (recourse.recourse.proposals) {
    recourse.recourse.proposals.forEach((currentValue, index) => {
      /* Proposal details or put on hold */
      switch (currentValue.proposal.status) {
        case 'ACCEPTED':
        case 'REFUSED':
        case 'INCORRECT':
          result.push({
            date: moment(currentValue.proposal.datetime),
            title: {
              color: 'var(--bg-color--cyan)',
              left: _t('timeline.proposalIssue'),
            },
            pointColor: 'var(--bg-color--cyan)',
            contentElement: ProposalIssue({
              proposal: currentValue.proposal,
              index,
            }),
          });
          break;
        case 'IS_WAITING':
          result.push({
            date: moment(currentValue.proposal.datetime),
            title: {
              color: 'var(--bg-color--dark-blue)',
              left: _t('timeline.putOnHold'),
            },
            comment: currentValue.proposal.agentComment ?? undefined,
            pointColor: 'var(--bg-color--dark-blue)',
            contentElement: PutOnHold({
              proposal: currentValue.proposal,
            }),
          });
          break;
        default:
        // NOOP
      }

      /* Decision taken */
      if (currentValue.decision) {
        /* Accepted proposal in detail */
        if (currentValue.decision.accepted) {
          result.push({
            date: moment(currentValue.decision.datetime),
            title: {
              color: 'var(--bg-color--cyan)',
              left: _t('timeline.proposalAccepted'),
            },
            pointColor: 'var(--bg-color--cyan)',
            contentElement: ProposalIssue({
              proposal: currentValue.proposal,
              index,
            }),
          });
          /* Recourse decision */
          switch (currentValue.proposal.status) {
            case 'ACCEPTED':
              result.push({
                date: moment(currentValue.decision.datetime),
                title: {
                  color: 'var(--bg-color--green)',
                  left: _t('timeline.validation'),
                },
                pointColor: 'var(--bg-color--green)',
                contentElement: ProposalDecision({
                  decision: currentValue.decision,
                }),
              });
              break;
            case 'REFUSED':
              result.push({
                date: moment(currentValue.decision.datetime),
                title: {
                  color: 'var(--bg-color--pink)',
                  left: _t('timeline.refusal'),
                },
                contentElement: ProposalDecision({
                  decision: currentValue.decision,
                }),
              });
              break;
            case 'INCORRECT':
              result.push({
                date: moment(currentValue.decision.datetime),
                title: {
                  color: 'var(--bg-color--dark)',
                  left: _t('timeline.incorrect'),
                },
                pointColor: 'var(--bg-color--dark)',
                contentElement: ProposalDecision({
                  decision: currentValue.decision,
                }),
              });
              break;
            default:
            // NOOP
          }

          /* Automatic refusal for recourse */
          if (recourse.recourse.autoRefused) {
            result.push({
              date: moment(currentValue.decision.datetime),
              title: {
                color: 'var(--bg-color--pink)',
                left: _t('timeline.autoRefused'),
              },
              contentElement: AutomaticRejection({
                decision: currentValue.decision,
                index,
              }),
            });
          }
        } else if (currentValue.proposal.waiting) {
          /* Proceed with on hold recourse */
          result.push({
            date: moment(currentValue.decision.datetime),
            title: {
              color: 'var(--bg-color--light-blue)',
              left: _t('timeline.proceedWithRecourse'),
            },
            pointColor: 'var(--bg-color--light-blue)',
            contentElement: ProceedWithRecourse({
              decision: currentValue.decision,
            }),
          });
        } else {
          /* Proposal was rejected */
          result.push({
            date: moment(currentValue.decision.datetime),
            title: {
              color: 'var(--bg-color--pink)',
              left: _t('timeline.proposalRejected'),
            },
            contentElement: ProposalDecision({
              decision: currentValue.decision,
            }),
          });
        }
      }
    });
  }

  /* Format all agent comments */
  if (recourse.recourse.comments) {
    recourse.recourse.comments.forEach(currentValue => {
      // To be consistent with the fact that files can be uploaded without comments
      let eventTitle = _t('timeline.tag');
      const files = getAgentCommentFiles(recourse.recourse, currentValue);
      if (files.length > 0) {
        eventTitle = _t('timeline.file');
      } else if (currentValue.text) {
        eventTitle = _t('timeline.comment');
      }

      result.push({
        date: moment(currentValue.dateCreated),
        title: {
          color: 'var(--bg-color--dark-blue)',
          left: eventTitle,
        },
        pointColor: 'var(--bg-color--dark-blue)',
        comment: currentValue.text ?? undefined,
        contentElement: Comment({
          comment: currentValue,
          recourse: recourse.recourse,
        }),
      });
    });
  }

  /* Sort from early date to latest */
  return result.sort((a, b) => {
    if (a.date.isBefore(b.date)) {
      return -1;
    }
    if (a.date.isAfter(b.date)) {
      return 1;
    }
    return 0;
  });
}
