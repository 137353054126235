import React from 'react';
import FileFileUpload from 'material-ui/svg-icons/file/file-upload';

import { openNewAuthentifiedTab } from 'api/helpers';
import BoButton from 'facade/BoButton';
import { Flex } from '@cvfm-front/commons-ui';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';
import useRecourse from 'commons/hooks/useRecourse';
import { getRecoursePdfUrl } from 'api/recourse';
import Content from 'commons/Content';
import Watchings from 'commons/Watchings';

import OverviewBlock from './OverviewBlock';
import ActionButtons from './ActionButtons';
import RecourseTimeLine from './RecourseTimeLine';

const { _tg, _t } = window.loadTranslations(__filename);

const RecoursePage = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const onExport = () => {
    if (!recourseDetails) return;
    openNewAuthentifiedTab(getRecoursePdfUrl(recourseDetails.recourse.rapoId));
  };

  return (
    <Content>
      <Flex className="navigation-bar">
        <ReturnButton
          href="#/recourses/list"
          label={_tg('action.backToList')}
        />
        <div className="align-right">
          <BoButton
            label={_tg('action.export')}
            onClick={onExport}
            icon={<FileFileUpload />}
            backgroundColor="var(--bg-color--dark-blue)"
            labelColor="var(--white)"
          />
        </div>
      </Flex>
      <div className="container">
        {/* FPS and recourse summary */}
        <OverviewBlock />
        {/* Recourse delay is over */}
        {recourseDetails?.recourse.recoursePeriodOver && (
          <Flex justifyContent="center">
            <div className="text-error margin-y--xs margin-x--xl">
              {_t('warning.recourseDelayOver')}
            </div>
          </Flex>
        )}
        {/* User has no address */}
        {recourseDetails?.recourse.user.illegibleAddress && (
          <Flex justifyContent="center">
            <div className="text-error margin-y--xs">
              {_tg('feedback.error.illegibleAddress')}
            </div>
          </Flex>
        )}
        {/* Possible actions on current recourse */}
        <ActionButtons />
        {/* Timeline... */}
        <RecourseTimeLine />
      </div>
      {recourseDetails?.recourse && (
        <Watchings
          resourceType="RAPO"
          resourceId={recourseDetails?.recourse.rapoId}
        />
      )}
    </Content>
  );
};

export default RecoursePage;
