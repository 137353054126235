import React from 'react';

import TimelineBlock from 'tefps/RecoursesV2/commons/TimelineBlock';
import useRecourse from 'commons/hooks/useRecourse';
import useUserInfo from 'commons/hooks/useUserInfo';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import {
  buildTimelineItems,
  TimelineItem,
} from 'tefps/RecoursesV2/utils/timelineUtils';

import './RecourseTimeLine.css';

const { _tg } = window.loadTranslations(__filename);

const RecourseTimeLine = (): JSX.Element => {
  const recourse = useRecourse();
  const userInfo = useUserInfo();
  const items = buildTimelineItems(recourse, userInfo);

  return (
    <>
      <SeparatorWithTitle
        className="recourse-timeline__title"
        title={_tg('commons.history')}
        color="var(--bg-color--pink)"
        titleSize={20}
      />
      <div>
        {items.map((item: TimelineItem, index: number) => (
          <TimelineBlock key={index} item={item} />
        ))}
      </div>
    </>
  );
};

export default RecourseTimeLine;
