import { RapoDetailDTO } from 'api/recourse/types';
import { patchRecourse } from 'api/recourse';
import Services from 'commons/services';

const { _tg } = window.loadTranslations(__filename);

async function submitTags(
  newTags: string[],
  recourse: RapoDetailDTO,
  setLoading: (value: boolean) => void,
  setMessage: Function
): Promise<boolean> {
  const newModif =
    newTags.some(tag => !recourse?.recourse.tags.includes(tag)) ||
    recourse?.recourse.tags.some(tag => !newTags.includes(tag));
  if (!newModif) {
    return true;
  }
  setLoading(true);
  const addedTags = newTags.filter(
    tag => !recourse.recourse.tags.includes(tag)
  );
  const removedTags = recourse.recourse.tags.filter(
    tag => !newTags.includes(tag)
  );
  try {
    // Init upload
    const patches = [];

    /* Adding comment */
    patches.push({
      path: '/comments',
      op: 'add',
      value: { addedTags, removedTags, text: recourse.recourse.comment },
    });
    await patchRecourse(recourse.recourse.rapoId, patches);
    // Clean input
    setMessage(_tg('commons.tagSuccess'));

    // Update recourse details
    await Services.recourse.init(recourse.recourse.rapoId);
    return true;
  } catch (e) {
    setMessage(_tg('commons.tagError'));
    return false;
  } finally {
    // If error, re-enable submit
    setLoading(false);
  }
}

export default submitTags;
