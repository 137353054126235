import React from 'react';

import { OrderDecision } from '@cvfm-front/tefps-types';
import FakeInputBlock from 'commons/FakeInputBlock';
import { DataBoxItem } from 'commons/DataBox';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailHistoryDecisionProps = {
  orderTrace: OrderDecision;
};

const OrderDetailHistoryDecision = ({
  orderTrace,
}: OrderDetailHistoryDecisionProps): JSX.Element => {
  const decisionText = orderTrace.accepted ? _t('accept') : _t('reject');
  return (
    <DataBoxItem>
      <FakeInputBlock title={_tg('commons.decision')} value={decisionText} />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryDecision;
