import React, { useEffect, useState } from 'react';
import { MenuItem, SelectField, TextField } from 'material-ui';

import {
  OrderSimpleDTO,
  ProductPrivateDTO,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';
import { Text } from '@cvfm-front/commons-ui';
import VehicleDetails from 'tefps/Subscription/commons/VehicleDetails';
import OrderCreatePlatesSelectVehicle from 'tefps/Subscription/OrderCreatePlatesSelectVehicle';

const { _t } = window.loadTranslations(__filename);

function computeDefaultPlates(
  maxAmountOfPlates: number,
  plates: Array<string>
): Array<string> {
  const platesInputs = Array<string>(maxAmountOfPlates).fill('');

  plates.forEach((p, index) => {
    platesInputs[index] = p;
  });

  return platesInputs;
}

type OrderCreatePlatesRequiredProps = {
  canSelectAnyPlate: boolean;
  choosablePlates: Array<string>;
  plates: Array<string>;
  ordersInduced?: Array<OrderSimpleDTO>;
  maxAmountOfPlates: number;
  onChange: (plates: Array<string>) => void;
  mustOrderWithPlate: boolean;
  mustUseActiveEligibilityPlate: boolean;
  subscriberVehicles?: Array<SubscriberVehicle>;
  product?: ProductPrivateDTO;
};

const OrderCreatePlates = ({
  canSelectAnyPlate,
  mustOrderWithPlate,
  mustUseActiveEligibilityPlate,
  choosablePlates,
  plates,
  ordersInduced,
  maxAmountOfPlates,
  onChange,
  subscriberVehicles,
  product,
}: OrderCreatePlatesRequiredProps): JSX.Element => {
  const [typedPlates, setTypedPlates] = useState<Array<string>>(
    computeDefaultPlates(maxAmountOfPlates, plates)
  ); // The plates the user can type in

  function handleChangeMultiple(
    _e: React.ChangeEvent<HTMLDataElement>,
    _index: number,
    value: Array<string>
  ): void {
    if (value.length <= maxAmountOfPlates) {
      onChange(value);
    }
  }

  function getPlateColor(plate: string): string {
    if (ordersInduced?.some(o => o.plates.includes(plate))) {
      return 'red';
    }

    return 'black';
  }

  function handleChange(
    e: React.ChangeEvent<HTMLDataElement>,
    value: string
  ): void {
    const indexString = e.target.dataset.field;
    if (indexString) {
      const index = parseInt(indexString, 10);
      const newTypedPlates = [...typedPlates];
      newTypedPlates[index] = value;
      setTypedPlates(newTypedPlates);
      onChange(newTypedPlates.filter(plate => !!plate)); // removes the '' plates
    }
  }

  function handleSelectChange(index: number, value: string): void {
    const newTypedPlates = [...typedPlates];
    if (typedPlates[index] === value) {
      // Deselect value
      newTypedPlates[index] = '';
    } else if (!typedPlates.includes(value)) {
      // Select value if not selected yet
      newTypedPlates[index] = value;
    }
    setTypedPlates(newTypedPlates);
    onChange(newTypedPlates.filter(plate => !!plate)); // removes the '' plates
  }

  useEffect(() => {
    if (maxAmountOfPlates > typedPlates.length) {
      const platesInputs = [
        ...typedPlates,
        ...Array<string>(maxAmountOfPlates - typedPlates.length).fill(''),
      ];

      plates.forEach((p, index) => {
        platesInputs[index] = p;
      });

      setTypedPlates(platesInputs);
    } else if (maxAmountOfPlates < typedPlates.length) {
      const newTypedPlates = [...typedPlates];
      newTypedPlates.length = maxAmountOfPlates;
      setTypedPlates(newTypedPlates);
    }
  }, [maxAmountOfPlates]);

  if (mustOrderWithPlate && mustUseActiveEligibilityPlate) {
    // multiselect
    return (
      <SelectField
        onChange={handleChangeMultiple}
        value={plates}
        multiple
        fullWidth
      >
        {choosablePlates.map(plate => (
          <MenuItem
            key={plate}
            checked={plates.includes(plate)}
            value={plate}
            primaryText={plate}
            insetChildren
          />
        ))}
      </SelectField>
    );
  }

  if (mustOrderWithPlate && !mustUseActiveEligibilityPlate) {
    // X lines of textfield or select input, X being "maximum amount of plates"
    return subscriberVehicles && subscriberVehicles?.length > 0 ? (
      <OrderCreatePlatesSelectVehicle
        canSelectAnyPlate={canSelectAnyPlate}
        typedPlates={typedPlates}
        subscriberVehicles={subscriberVehicles}
        handleSelectChange={handleSelectChange}
        handleChange={handleChange}
        ordersInduced={ordersInduced}
        product={product}
      />
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {typedPlates.map((plate, index) => (
          <TextField
            inputStyle={{ color: getPlateColor(plate) }}
            value={plate}
            onChange={handleChange}
            data-field={index}
            floatingLabelText={_t('plates.floatingLabelText', {
              index: index + 1,
            })}
          />
        ))}
      </div>
    );
  }

  if (!mustOrderWithPlate && mustUseActiveEligibilityPlate) {
    // User doesn't choose, we display every plate of the chosen active right
    return (
      <>
        {choosablePlates.map(plate => (
          <div className="margin-y--s">
            <p className="text text--intermediate text--no-wrap">
              <VehicleDetails plate={plate} vehicles={subscriberVehicles} />
              <div className="margin-y--xs">
                <Text className="text--intermediate">{plate}</Text>
              </div>
            </p>
          </div>
        ))}
      </>
    );
  }

  // The following if is commented as it is always true but using it makes the linter complain about return type
  // if (!mustOrderWithPlate && !mustUseActiveEligibilityPlate) {}
  // Usecase "Visiteur", not yet implemented => display warning for user
  return <div>{_t('visitorUsecase')}</div>;
};

export default OrderCreatePlates;
