import React, { useEffect, useState } from 'react';
import { Checkbox, MenuItem, SelectField } from 'material-ui';

import { ProductZones, ZoneDTO } from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';

import ProductDetailSeparator from './commons/DetailSeparator';
import './ProductDetailPage.css';

const { _t } = window.loadTranslations(__filename);

type ProductDetailZonesProps = {
  productZones: ProductZones;
  canEdit: boolean;
  zones: Array<ZoneDTO>;
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailZones = ({
  productZones,
  canEdit,
  onChange,
  zones,
}: ProductDetailZonesProps): JSX.Element => {
  const [zoneItems, setZoneItems] = useState<Array<ItemIdName>>([]);

  function handleZoneChange(
    _e: React.SyntheticEvent<HTMLDataElement>,
    _index: number,
    value: Array<string>
  ): void {
    const newProductZones = { ...productZones, zoneIds: value };
    onChange('zones', newProductZones);
  }

  function handleAgentZoneChange(
    _e: React.SyntheticEvent<HTMLDataElement>,
    _index: number,
    value: Array<string>
  ): void {
    const newProductZones = { ...productZones, agentZoneIds: value };
    onChange('zones', newProductZones);
  }

  function handleCheck(e: React.MouseEvent<HTMLDataElement>, checked: boolean) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      const newProductZones = { ...productZones, [field]: checked };
      onChange('zones', newProductZones);
    }
  }

  function renderMultiSelect(
    label: string,
    value: Array<string>,
    items: Array<ItemIdName>,
    onChangeCallback: (
      e: React.SyntheticEvent,
      i: number,
      v: Array<string>
    ) => void
  ): JSX.Element {
    return (
      <SelectField
        floatingLabelFixed
        floatingLabelText={label}
        disabled={!canEdit}
        hintText={_t('field.hintZones')}
        fullWidth
        multiple
        onChange={onChangeCallback}
        value={value}
      >
        {items.map(item => (
          <MenuItem
            id={item.id}
            key={item.id}
            value={item.id}
            primaryText={item.name}
            checked={value.includes(item.id)}
            insetChildren
          />
        ))}
      </SelectField>
    );
  }

  // Compute zone items only once with prop updates
  useEffect(() => {
    const items = zones.map(z => ({
      id: z.id,
      name: z.name,
    }));
    setZoneItems(items);
  }, [zones]);

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-75">
            {renderMultiSelect(
              _t('field.zoneIds'),
              productZones.zoneIds,
              zoneItems,
              handleZoneChange
            )}
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-75">
            {renderMultiSelect(
              _t('field.agentZoneIds'),
              productZones.agentZoneIds,
              zoneItems,
              handleAgentZoneChange
            )}
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustPickOne')}
              checked={productZones.mustPickOne}
              data-field="mustPickOne"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.restrictFromSubscriberResidence')}
              checked={productZones.restrictFromSubscriberResidence}
              data-field="restrictFromSubscriberResidence"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.restrictFromEligibility')}
              checked={productZones.restrictFromEligibility}
              data-field="restrictFromEligibility"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.restrictFromBundle')}
              checked={productZones.restrictFromBundle}
              data-field="restrictFromBundle"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.showZoneMap')}
              checked={productZones.showZoneMap}
              data-field="showZoneMap"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailZones;
