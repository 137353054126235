import React from 'react';

import {
  getComplementRequestFiles,
  getRecourseFiles,
} from 'tefps/RecoursesV2/utils/recourseFilesUtils';
import { RapoDetailDTO } from 'api/recourse/types';

import FileBlock from '../Detail/FileBlock';

import './RecourseSummaryAttachedFiles.css';

const { _t } = window.loadTranslations(__filename);

type RecourseSummaryAttachedFilesProps = {
  recourseDetails: RapoDetailDTO;
};

const RecourseSummaryAttachedFiles = ({
  recourseDetails,
}: RecourseSummaryAttachedFilesProps): JSX.Element => {
  return (
    <div className="attached-files">
      <div className="attached-files__header">
        <div className="text-bold">{_t('title')}</div>
      </div>
      <div className="attached-files__content text--small">
        <div className="attached-files__content__row">
          <div className="text-bold">{_t('attachedFiles.recourse')}</div>
        </div>
        <div className="attached-files__content__row">
          {getRecourseFiles(recourseDetails.recourse).map((file, index) => (
            <div key={index} className="margin-y--xs">
              <FileBlock
                created={recourseDetails.recourse.created}
                file={file}
                reason={recourseDetails.recourse.reason}
                rapoId={recourseDetails.recourse.rapoId}
              />
            </div>
          ))}
        </div>
        <div className="attached-files__content__row">
          <div className="text-bold">
            {_t('attachedFiles.complementRequest')}
          </div>
        </div>
        <div className="attached-files__content__row">
          {getComplementRequestFiles(recourseDetails.recourse).map(
            (file, index) => (
              <div key={index} className="margin-y">
                <FileBlock
                  created={recourseDetails.recourse.created}
                  file={file}
                  reason={recourseDetails.recourse.reason}
                  rapoId={recourseDetails.recourse.rapoId}
                />
              </div>
            )
          )}
        </div>
        <div className="attached-files__content__row text-bold">
          {_t('attachedFiles.text')}
        </div>
        <div className="attached-files__content__row">
          <span>{recourseDetails.recourse.complementComment}</span>
        </div>
      </div>
    </div>
  );
};

export default RecourseSummaryAttachedFiles;
