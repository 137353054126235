import React, { useEffect, useState } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { Flex } from '@cvfm-front/commons-ui';
import { debounceFactory, parsePeriod } from '@cvfm-front/commons-utils';
import {
  OrderEsPrivateDTO,
  PricingPolicyStepDTO,
  ProductPrivateDTO,
} from '@cvfm-front/tefps-types';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';

const { _tg } = window.loadTranslations(__filename);

const debounceService = debounceFactory(500);

type Props = {
  product: ProductPrivateDTO;
  plate: string;
  zone: string;
  startOfValidity: string;
  usedRight?: OrderEsPrivateDTO;
  setOrderFieldsFromStep: (step?: PricingPolicyStepDTO) => void;
};

const OrderCreateTariffEngineSelect = ({
  product,
  plate,
  zone,
  startOfValidity,
  usedRight,
  setOrderFieldsFromStep,
}: Props): JSX.Element => {
  const [selectedDuration, setSelectedDuration] = useState<string>();

  const parkingDetail = useWatcher(
    services.parkingDetailService.watchParkingDetail,
    null
  );

  const handleStepChange = (
    e: unknown,
    idx: unknown,
    duration: string
  ): void => {
    setSelectedDuration(duration);
    const step:
      | PricingPolicyStepDTO
      | undefined = parkingDetail?.currentPricingPolicies[0]?.policySteps.find(
      s => s.duration === duration
    );
    setOrderFieldsFromStep(step);
  };

  useEffect(() => {
    debounceService(() => {
      const steps = services.parkingDetailService.fetchStepsForProduct(
        usedRight?.productId || product.productId,
        plate,
        startOfValidity,
        zone
      );

      void steps.then(resp =>
        setOrderFieldsFromStep(
          resp.currentPricingPolicies[0].policySteps.find(
            step => step.duration === selectedDuration
          ) || resp.currentPricingPolicies[0].policySteps[0]
        )
      );
    });
  }, [zone, startOfValidity, plate]);

  return (
    <div className="order-detail_row">
      <span className="order-detail_cell-25">
        {_tg(
          'tefps.Subscription.OrderCreateProductOptionChoice.choice.productOption'
        )}
      </span>
      <Flex className="order-detail_cell-75" flexDirection="column" gap={10}>
        <SelectField
          fullWidth
          id="order-option-selector"
          value={selectedDuration}
          onChange={handleStepChange}
        >
          {parkingDetail?.currentPricingPolicies[0].policySteps.map(item => (
            <MenuItem
              id={item.duration}
              key={item.duration}
              value={item.duration}
              primaryText={parsePeriod(item.duration)}
            />
          ))}
        </SelectField>
      </Flex>
    </div>
  );
};

export default OrderCreateTariffEngineSelect;
