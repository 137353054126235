import React from 'react';

import SimpleTable from 'commons/SimpleTable';
import Price from 'commons/Price';
import FpsNumber from 'commons/FpsNumber';
import { RapoOverviewDTO } from 'api/recourse/types';
import useRecourseSearchResult from 'commons/hooks/useRecourseSearchResult';
import useRecourseSort from 'commons/hooks/useRecourseSort';
import services from 'commons/services';
import {
  RECOURSE_STATUSES_FILTER_OPTIONS,
  REQUEST_REASONS_FILTER_OPTIONS,
} from 'tefps/RecoursesV2/utils/translationUtils';

import './RecourseTable.css';

const { _t } = window.loadTranslations(__filename);

const RecourseTable = (): JSX.Element => {
  /* Custom hooks */
  const recourseSearchResult = useRecourseSearchResult();
  const [sort, setSort] = useRecourseSort();

  /* Rendering & component variables */
  const items = recourseSearchResult ? recourseSearchResult.recourses : [];
  const rowCount = recourseSearchResult ? recourseSearchResult.totalHits : 0;
  const tableCols = [
    { label: _t('tableCols.rapoNumber'), width: 80, onSort: true },
    { label: _t('tableCols.fpsId'), width: 330, grow: 3, onSort: true },
    {
      label: _t('tableCols.plate'),
      width: 120,
      onSort: true,
    },
    { label: _t('tableCols.processingPeriod'), width: 200, onSort: true },
    {
      label: _t('tableCols.reason'),
      width: 200,
    },
    { label: _t('tableCols.fpsPrice'), width: 200, onSort: true },
    { label: _t('tableCols.status'), width: 150 },
  ];

  /* Functions */
  const onUpdateSort = async (
    colNumber: number,
    increasingOrder: boolean
  ): Promise<void> => {
    setSort({ sortField: colNumber, increasingOrder });
    return Promise.resolve();
  };

  const itemRenderer = (recourse: RapoOverviewDTO): JSX.Element[] => {
    const reason = REQUEST_REASONS_FILTER_OPTIONS.find(
      r => r.value === recourse.reason
    );
    const status = RECOURSE_STATUSES_FILTER_OPTIONS.find(
      s => s.value === recourse.status
    );

    return [
      <a
        className="recourse-table__item__link"
        href={location.hash.replace(/[^/]*$/, `detail/${recourse.rapoId}`)}
      >
        <span>{recourse.rapoNumber}</span>
      </a>,
      <a
        className="recourse-table__item__link"
        href={location.hash.replace(/[^/]*$/, `detail/${recourse.rapoId}`)}
      >
        <FpsNumber fpsId={recourse.fpsId} />
      </a>,
      <span>{recourse.plate}</span>,
      <span>{recourse.processingPeriod}</span>,
      <span>{reason ? reason.label : ''}</span>,
      <Price price={recourse.price} />,
      <span>{status ? status.label : ''}</span>,
    ];
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={tableCols}
      rowHeight={50}
      header
      onSort={onUpdateSort}
      colSorted={sort.sortField}
      sortOrder={sort.increasingOrder}
      loadMoreRows={services.recourseListService.loadMoreRows}
      remoteRowCount={rowCount}
      itemsRenderer={itemRenderer}
      items={items}
    />
  );
};

export default RecourseTable;
