import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  MenuItem,
  RadioButton,
  RadioButtonGroup,
  SelectField,
} from 'material-ui';
import { connect } from 'react-redux';

import {
  CritAir,
  CritAirEnum,
  ProductConditions,
  ProductOptionReference,
  ProductPrivateDTO,
  ProductType,
  SivConfiguration,
  SubscriberAccountType,
  VehicleCategory,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import { ProductConditionType } from '@cvfm-front/tefps-types/build/product/ProductConditionType';
import { getLabelFromVehicleType } from 'commons/Utils/vehicleTypeUtils';

import ProductDetailSeparator from './commons/DetailSeparator';
import ProductDetailConditionsOptions from './ProductDetailConditionsOptions';

import './ProductDetailPage.css';
import critairOptions from './commons/CritairOptions';

const { _t } = window.loadTranslations(__filename);

type ProductDetailConditionsReduxProps = {
  authorizedVehicleCategories: Array<VehicleCategory>;
  sivConfig: SivConfiguration;
};

type ProductDetailConditionsProps = {
  product: ProductPrivateDTO;
  conditions: ProductConditions;
  canEdit: boolean;
  zones: Array<ZoneDTO>;
  bundleProducts: Array<ProductPrivateDTO>;
  eligibilityProducts: Array<ProductPrivateDTO>;
  onChange: (field: string, newValue: unknown) => void;
  productType: ProductType;
} & ProductDetailConditionsReduxProps;

const ProductDetailConditions = ({
  product,
  conditions,
  canEdit,
  onChange,
  zones,
  bundleProducts,
  eligibilityProducts,
  productType,
  authorizedVehicleCategories,
  sivConfig,
}: ProductDetailConditionsProps): JSX.Element => {
  const [zoneItems, setZoneItems] = useState<Array<ItemIdName>>([]);
  const [critairItems, setCritairItems] = useState<Array<ItemIdName>>([]);
  const [requiredBundles, setRequiredBundles] = useState<
    Array<ProductOptionReference>
  >([]);
  const [requiredEligibilities, setRequiredEligiblities] = useState<
    Array<ProductOptionReference>
  >([]);
  const [forbiddenBundles, setForbiddenBundles] = useState<
    Array<ProductOptionReference>
  >([]);
  const [forbiddenEligibilities, setForbiddenEligibilities] = useState<
    Array<ProductOptionReference>
  >([]);

  function handleZoneChange(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: Array<string>
  ): void {
    const newConditions = { ...conditions, residenceZoneIds: value };
    onChange('conditions', newConditions);
  }

  function handleCritairChange(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: Array<CritAir>
  ): void {
    const newConditions = { ...conditions, allowedCritair: value };
    onChange('conditions', newConditions);
  }

  function handleProductsRequiredChange(
    bundleOpts: Array<ProductOptionReference>,
    eligibilityOpts: Array<ProductOptionReference>
  ): void {
    const optRefs = [...bundleOpts, ...eligibilityOpts];
    const newConditions = {
      ...conditions,
      activeProductsRequired: optRefs,
    };
    onChange('conditions', newConditions);
  }

  function handleProductsForbiddenChange(
    bundleOpts: Array<ProductOptionReference>,
    eligibilityOpts: Array<ProductOptionReference>
  ): void {
    const optRefs = [...bundleOpts, ...eligibilityOpts];
    const newConditions = {
      ...conditions,
      activeProductsForbidden: optRefs,
    };
    onChange('conditions', newConditions);
  }

  function handleBundleRequiredChange(
    optRefs: Array<ProductOptionReference>
  ): void {
    setRequiredBundles(optRefs);
    handleProductsRequiredChange(optRefs, requiredEligibilities);
  }

  function handleEligibilityRequiredChange(
    optRefs: Array<ProductOptionReference>
  ): void {
    setRequiredEligiblities(optRefs);
    handleProductsRequiredChange(requiredBundles, optRefs);
  }

  function handleBundleForbiddenChange(
    optRefs: Array<ProductOptionReference>
  ): void {
    setForbiddenBundles(optRefs);
    handleProductsForbiddenChange(optRefs, forbiddenEligibilities);
  }

  function handleEligibilityForbiddenChange(
    optRefs: Array<ProductOptionReference>
  ): void {
    setForbiddenEligibilities(optRefs);
    handleProductsForbiddenChange(forbiddenBundles, optRefs);
  }

  function handleProductConditionTypeChange(
    _e: React.FormEvent<HTMLDataElement>,
    selected: string
  ): void {
    const newConditions = {
      ...conditions,
      productConditionType: selected,
    };
    onChange('conditions', newConditions);
  }

  function handleChangeRequiredScope(
    e: SyntheticEvent,
    idx: number,
    newValue: string
  ): void {
    const newConditions = {
      ...conditions,
      productScope: SubscriberAccountType[newValue],
    };
    onChange('conditions', newConditions);
  }
  function handleChangeVehiclesCategories(
    e: SyntheticEvent,
    idx: number,
    newValue: VehicleCategory[]
  ): void {
    const newConditions = {
      ...conditions,
      vehiclesCategories: newValue,
    } as ProductConditions;
    onChange('conditions', newConditions);
  }

  function getScopeLabel(accountType: string): string {
    switch (SubscriberAccountType[accountType]) {
      case SubscriberAccountType.PERSONAL:
      default:
        return _t('field.scopeType.personal');
      case SubscriberAccountType.PROFESSIONAL:
        return _t('field.scopeType.professional');
      case SubscriberAccountType.BOTH:
        return _t('field.scopeType.both');
    }
  }

  const isChecked = (
    value: string[] | CritAir[],
    itemId: string | CritAirEnum
  ) => {
    if (value.length > 0) {
      if (typeof value[0] === 'string') {
        return (value as string[]).includes(itemId);
      }
      return (value as CritAirEnum[]).includes(itemId as CritAirEnum);
    }
    return false;
  };

  function renderMultiSelect(
    label: string,
    value: Array<CritAir> | Array<string> | null,
    items: Array<ItemIdName>,
    onChangeCallback: (
      e: React.SyntheticEvent,
      i: number,
      v: Array<string> | Array<CritAir>
    ) => void
  ): JSX.Element {
    return value !== null ? (
      <SelectField
        floatingLabelFixed
        floatingLabelText={label}
        disabled={!canEdit}
        hintText={_t('field.hintOptions')}
        fullWidth
        multiple
        onChange={onChangeCallback}
        value={value}
      >
        {items.map(item => (
          <MenuItem
            id={item.id}
            key={item.id}
            value={item.id}
            primaryText={item.name}
            checked={isChecked(value, item.id)}
            insetChildren
          />
        ))}
      </SelectField>
    ) : (
      <></>
    );
  }

  // Compute zone items only once with prop updates
  useEffect(() => {
    setCritairItems(critairOptions);

    const items = zones.map(z => ({
      id: z.id,
      name: z.name,
    }));

    setZoneItems(items);
  }, [zones]);

  // Compute split lists of options ref only once
  useEffect(() => {
    const reqBundle: Array<ProductOptionReference> = [];
    const reqEligiblity: Array<ProductOptionReference> = [];
    const forbidBundle: Array<ProductOptionReference> = [];
    const forbidEligiblity: Array<ProductOptionReference> = [];

    conditions.activeProductsRequired.forEach(optRef => {
      if (bundleProducts.find(bp => bp.productId === optRef.productId)) {
        reqBundle.push(optRef);
      } else {
        reqEligiblity.push(optRef);
      }
    });
    conditions.activeProductsForbidden.forEach(optRef => {
      if (bundleProducts.find(bp => bp.productId === optRef.productId)) {
        forbidBundle.push(optRef);
      } else {
        forbidEligiblity.push(optRef);
      }
    });

    setRequiredBundles(reqBundle);
    setRequiredEligiblities(reqEligiblity);
    setForbiddenBundles(forbidBundle);
    setForbiddenEligibilities(forbidEligiblity);
  }, [conditions]);

  const conditionTypeDisabled = conditions.activeProductsRequired.length === 0;

  if (
    conditionTypeDisabled &&
    conditions.productConditionType !== ProductConditionType.PER_USER
  ) {
    onChange('conditions', {
      ...conditions,
      productConditionType: ProductConditionType.PER_USER,
    });
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />

      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-100">
            <SelectField
              floatingLabelText={_t('field.scopeDescription')}
              floatingLabelFixed
              fullWidth
              value={SubscriberAccountType[conditions.productScope]}
              onChange={handleChangeRequiredScope}
            >
              {Object.keys(SubscriberAccountType)
                .filter(key => isNaN(Number(key)))
                .map((scope, index) => (
                  <MenuItem
                    id={`${scope} ${index}`}
                    data-field="productScope"
                    key={`${scope} ${index}`}
                    value={SubscriberAccountType[scope]}
                    primaryText={getScopeLabel(scope)}
                  />
                ))}
            </SelectField>
          </span>
        </div>
        {authorizedVehicleCategories.length > 0 && (
          <div className="product-detail_section-content-row">
            <span className="product-detail_cell-100">
              <SelectField
                floatingLabelText={_t('field.vehiclesCategories')}
                floatingLabelFixed
                fullWidth
                multiple
                value={conditions.vehiclesCategories}
                onChange={handleChangeVehiclesCategories}
              >
                {authorizedVehicleCategories.map((vehicleType, index) => (
                  <MenuItem
                    id={`${vehicleType}-${index}`}
                    data-field="productVehicleType"
                    key={`${vehicleType}-${index}`}
                    value={vehicleType}
                    primaryText={getLabelFromVehicleType(vehicleType)}
                  />
                ))}
              </SelectField>
            </span>
          </div>
        )}
        {sivConfig.enableSaveCritairSiv && (
          <div className="product-detail_section-content-row">
            <span className="product-detail_cell-100">
              {renderMultiSelect(
                _t('field.critair'),
                conditions.allowedCritair ?? [],
                critairItems,
                handleCritairChange
              )}
            </span>
          </div>
        )}
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-100">
            {renderMultiSelect(
              _t('field.residenceZoneIds'),
              conditions.residenceZoneIds,
              zoneItems,
              handleZoneChange
            )}
          </span>
        </div>
        <ProductDetailConditionsOptions
          productLabel={_t('field.activeEligibilityProductsRequired')}
          productHintLabel={_t('field.hintProduct')}
          optionsLabel={_t('field.activeOptionsRequired')}
          optionsHintLabel={_t('field.hintOptions')}
          options={requiredEligibilities}
          otherProducts={eligibilityProducts}
          canEdit={canEdit}
          onChange={handleEligibilityRequiredChange}
        />
        <ProductDetailConditionsOptions
          productLabel={_t('field.activeBundleProductsRequired')}
          productHintLabel={_t('field.hintProduct')}
          optionsLabel={_t('field.activeOptionsRequired')}
          optionsHintLabel={_t('field.hintOptions')}
          options={requiredBundles}
          otherProducts={bundleProducts}
          canEdit={canEdit}
          onChange={handleBundleRequiredChange}
        />
        <ProductDetailConditionsOptions
          productLabel={_t('field.activeEligibilityProductsForbidden')}
          productHintLabel={_t('field.hintProduct')}
          optionsLabel={_t('field.activeOptionsRequired')}
          optionsHintLabel={_t('field.hintOptions')}
          options={forbiddenEligibilities}
          otherProducts={eligibilityProducts}
          canEdit={canEdit}
          onChange={handleEligibilityForbiddenChange}
        />
        <ProductDetailConditionsOptions
          productLabel={_t('field.activeBundleProductsForbidden')}
          productHintLabel={_t('field.hintProduct')}
          optionsLabel={_t('field.activeOptionsRequired')}
          optionsHintLabel={_t('field.hintOptions')}
          options={forbiddenBundles}
          otherProducts={bundleProducts}
          canEdit={canEdit}
          onChange={handleBundleForbiddenChange}
        />
        {productType === ProductType.BUNDLE && (
          <>
            <p>{_t('field.conditionType.description')}</p>
            <RadioButtonGroup
              name="group"
              onChange={handleProductConditionTypeChange}
              valueSelected={conditions.productConditionType}
              className="options-horizontal__list"
            >
              <RadioButton
                name="PER_USER"
                value={ProductConditionType.PER_USER}
                label={_t('field.conditionType.restrictionByUser')}
                disabled={conditionTypeDisabled}
              />
              <RadioButton
                name="PER_USER_AND_RIGHT"
                value={ProductConditionType.PER_USER_AND_RIGHT}
                label={_t('field.conditionType.restrictionByUserAndByRight')}
                disabled={conditionTypeDisabled}
              />
              <RadioButton
                name="PER_RIGHT"
                value={ProductConditionType.PER_RIGHT}
                label={_t('field.conditionType.restrictionByRight')}
                disabled={conditionTypeDisabled}
              />
            </RadioButtonGroup>
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(
  state: InternalApiState
): ProductDetailConditionsReduxProps {
  const config = getConfigState(state);
  return {
    authorizedVehicleCategories: config.authorizedVehicleCategories.map(
      vc => vc.vehicleCategory
    ),
    sivConfig: config.sivConfiguration,
  };
}

export default connect(mapStateToProps)(ProductDetailConditions);
