import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DropDownMenu, MenuItem } from 'material-ui';
import ActionLabel from 'material-ui/svg-icons/action/label';
import Cancel from 'material-ui/svg-icons/navigation/cancel';
import ThumbDown from 'material-ui/svg-icons/action/thumb-down';
import ThumbUp from 'material-ui/svg-icons/action/thumb-up';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import AlarmAdd from 'material-ui/svg-icons/action/alarm-add';
import CommunicationEmail from 'material-ui/svg-icons/communication/email';

import { getApiState } from 'api/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import BoButton from 'facade/BoButton';
import {
  OrderMassUpdateMap,
  OrderEsSearchResultDTO,
  OrderEsSearchQueryDTO,
} from '@cvfm-front/tefps-types';
import {
  BKG_DARK_BLUE,
  BKG_PINK,
  BKG_DARK,
  BKG_LIGHT_BLUE,
  BKG_GREEN,
  BKG_ORANGE,
} from 'theme';

import OrderFilterService from './OrderFilterService';
import OrderProlongationModals from './OrderProlongationModals';
import OrderTagsModal from './OrderTagsModal';
import './OrderActions.css';
import OrderDetailModalCancel from './OrderDetailModalCancel';
import OrderDetailModalCommunication from './OrderDetailModalCommunication';
import OrderDetailModalDecision from './OrderDetailModalDecision';
import { CANCELABLE_STATUSES, AWAIT_DECISION_STATUSES } from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

const ACTIONS_ITEMS_STYLE: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '7px',
  color: '#ffffff',
  textTransform: 'uppercase',
};

type Props = {
  canUpdateOrder: boolean;
  canTakeOrderDecision: boolean;
  canCancelOrder: boolean;
  multipleMode: boolean;
  setMultipleMode: Dispatch<SetStateAction<boolean>>;
  selectedOrders: OrderMassUpdateMap;
  setSelectedOrders: Dispatch<SetStateAction<OrderMassUpdateMap>>;
  totalOrders: number;
  tags: string[];
  filters: OrderEsSearchQueryDTO;
};

function OrderActions({
  canUpdateOrder,
  canTakeOrderDecision,
  canCancelOrder,
  multipleMode,
  setMultipleMode,
  selectedOrders,
  setSelectedOrders,
  totalOrders,
  tags,
  filters,
}: Props): JSX.Element {
  const [isOpenProlongationModal, setIsOpenProlongationModal] = useState(false);
  const [isOpenTagsModal, setIsOpenTagsModal] = useState(false);
  const [isOpenDecisionModal, setIsOpenDecisionModal] = useState(false);
  const [initialDecisionAccept, setInitialDecisionAccept] = useState<boolean>(
    false
  );
  const [isOpenCancellationModal, setIsOpenCancellationModal] = useState(false);
  const [isOpenCommunicationModal, setIsOpenCommunicationModal] = useState(
    false
  );

  const setMessage = useSnackbar();

  const searchAllRecords = 9999;

  const selectedOrdersSize = Object.keys(selectedOrders).length;

  const canShowAcceptOrRejectActions = (): boolean => {
    if (totalOrders === selectedOrdersSize) {
      // CASE ALL SELECTED, the filtered statuses must :
      // contain at least one of the statuses allowing a decision (accept or reject)
      // AND not contain any of the statuses not allowing a decision (accept or reject)
      return (
        !!filters.orderStatuses?.some(filterStatus =>
          AWAIT_DECISION_STATUSES.includes(filterStatus)
        ) &&
        !filters.orderStatuses?.some(
          filterStatus => !AWAIT_DECISION_STATUSES.includes(filterStatus)
        )
      );
    }

    // CASE SOME SELECTED, all must have a decidable status
    return Object.keys(selectedOrders).every(selectedOrderId =>
      AWAIT_DECISION_STATUSES.includes(
        selectedOrders[selectedOrderId].orderStatus
      )
    );
  };

  const canShowCancelAction = (): boolean => {
    if (totalOrders === selectedOrdersSize) {
      // CASE ALL SELECTED, the filtered statuses must :
      // contain at least one of the statuses allowing a cancel
      // AND not contain any of the statuses not allowing a cancel
      return (
        !!filters.orderStatuses?.some(filterStatus =>
          CANCELABLE_STATUSES.includes(filterStatus)
        ) &&
        !filters.orderStatuses?.some(
          filterStatus => !CANCELABLE_STATUSES.includes(filterStatus)
        )
      );
    }

    // CASE SOME SELECTED, all must have a cancelable status
    return Object.keys(selectedOrders).every(selectedOrderId =>
      CANCELABLE_STATUSES.includes(selectedOrders[selectedOrderId].orderStatus)
    );
  };

  const switchMode = (): void => {
    if (multipleMode) {
      setSelectedOrders({});
    }
    setMultipleMode(() => !multipleMode);
  };

  const selectAll = async (): Promise<void> => {
    const filteredOrders: OrderEsSearchResultDTO = await OrderFilterService.loadOrders(
      searchAllRecords,
      0
    );

    const orderMap: OrderMassUpdateMap = filteredOrders.results.reduce(
      (acc: OrderMassUpdateMap, item): OrderMassUpdateMap => {
        acc[item.orderId] = {
          startOfValidity: item.startOfValidity,
          endOfValidity: item.endOfValidity,
          tags: item.tags,
          orderStatus: item.orderStatus,
          subscribersIds: item.subscribers.map(
            subscriber => subscriber.subscriberId
          ),
        };
        return acc;
      },
      {}
    );
    setSelectedOrders(orderMap);
  };

  const unSelectAll = () => {
    setSelectedOrders({});
  };

  useEffect(() => {
    unSelectAll();
  }, [filters]);

  const openProlongationModal = () => {
    setIsOpenProlongationModal(true);
  };

  const openTagsModal = () => {
    setIsOpenTagsModal(true);
  };

  const openDecisionModal = (isAccept: boolean) => {
    if (canShowAcceptOrRejectActions()) {
      if (isAccept) {
        setInitialDecisionAccept(true);
        setIsOpenDecisionModal(true);
      } else {
        setInitialDecisionAccept(false);
        setIsOpenDecisionModal(true);
      }
    } else if (isAccept) {
      setMessage(_t('action.message.accept'));
    } else {
      setMessage(_t('action.message.reject'));
    }
  };

  const openCancelModal = () => {
    if (canShowCancelAction()) {
      setIsOpenCancellationModal(true);
    } else {
      setMessage(_t('action.message.cancel'));
    }
  };

  const openCommunicationModal = () => {
    setIsOpenCommunicationModal(true);
  };

  const resetDecisionAction = () => {
    setInitialDecisionAccept(false);
    setIsOpenDecisionModal(false);
  };

  const resetCancelAction = () => {
    setIsOpenCancellationModal(false);
  };

  const resetCommunicationAction = () => {
    setIsOpenCommunicationModal(false);
  };

  const buttons = multipleMode ? (
    [
      <BoButton
        key="cancel"
        label={_tg('action.cancel')}
        onClick={switchMode}
      />,
      <BoButton
        className="order-actions_buttons-wrapper_middle"
        key="selectAll"
        label={
          selectedOrdersSize === totalOrders
            ? _tg('action.unselect_all')
            : _tg('action.select_all')
        }
        onClick={selectedOrdersSize === totalOrders ? unSelectAll : selectAll}
      />,
      <div
        style={{
          display: 'inline-block',
          verticalAlign: 'bottom',
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <DropDownMenu
            value="selectAll"
            style={{
              borderRadius: 0,
              flex: 1,
              backgroundColor: 'rgb(255, 64, 129)',
              height: '36px',
            }}
            iconButton=""
            underlineStyle={{ borderTop: 0 }}
            labelStyle={{
              color: '#ffffff',
              textTransform: 'uppercase',
              fontWeight: 500,
              fontSize: 14,
              textAlign: 'left',
              lineHeight: '36px',
              height: '36px',
              padding: 0,
            }}
            disabled={selectedOrdersSize === 0}
          >
            <MenuItem
              value="selectAll"
              primaryText={
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '7px',
                    paddingRight: '15px',
                  }}
                >
                  <ArrowDropDown style={{ marginRight: 5 }} color="#ffffff" />{' '}
                  <span>{_t('action.select')}</span>
                </span>
              }
              style={{ display: 'none' }}
            />
            {canUpdateOrder && (
              <MenuItem
                value="prolongation"
                style={{
                  backgroundColor: BKG_LIGHT_BLUE,
                  fontWeight: 'bold',
                }}
                primaryText={
                  <span style={{ ...ACTIONS_ITEMS_STYLE }}>
                    <AlarmAdd style={{ marginRight: 5 }} color="#ffffff" />{' '}
                    <span>{_t('action.prolongation')}</span>
                  </span>
                }
                onClick={openProlongationModal}
              />
            )}
            {canUpdateOrder && (
              <MenuItem
                value="tags"
                style={{
                  backgroundColor: BKG_DARK_BLUE,
                  fontWeight: 'bold',
                }}
                primaryText={
                  <span style={{ ...ACTIONS_ITEMS_STYLE }}>
                    <ActionLabel style={{ marginRight: 5 }} color="#ffffff" />{' '}
                    <span>{_t('action.tags')}</span>
                  </span>
                }
                onClick={openTagsModal}
              />
            )}
            {canTakeOrderDecision && (
              <MenuItem
                value="accept"
                style={{
                  backgroundColor: BKG_GREEN,
                  fontWeight: 'bold',
                }}
                primaryText={
                  <span style={{ ...ACTIONS_ITEMS_STYLE }}>
                    <ThumbUp style={{ marginRight: 5 }} color="#ffffff" />{' '}
                    <span>{_t('action.accept')}</span>
                  </span>
                }
                onClick={() => openDecisionModal(true)}
              />
            )}
            {canTakeOrderDecision && (
              <MenuItem
                value="refuse"
                style={{
                  backgroundColor: BKG_PINK,
                  fontWeight: 'bold',
                }}
                primaryText={
                  <span style={{ ...ACTIONS_ITEMS_STYLE }}>
                    <ThumbDown style={{ marginRight: 5 }} color="#ffffff" />{' '}
                    <span>{_t('action.refuse')}</span>
                  </span>
                }
                onClick={() => openDecisionModal(false)}
              />
            )}
            {canCancelOrder && (
              <MenuItem
                value="cancel"
                style={{
                  backgroundColor: BKG_DARK,
                  fontWeight: 'bold',
                }}
                primaryText={
                  <span style={{ ...ACTIONS_ITEMS_STYLE }}>
                    <Cancel style={{ marginRight: 5 }} color="#ffffff" />{' '}
                    <span>{_t('action.cancel')}</span>
                  </span>
                }
                onClick={openCancelModal}
              />
            )}
            <MenuItem
              value="communication"
              style={{
                backgroundColor: BKG_ORANGE,
                fontWeight: 'bold',
              }}
              primaryText={
                <span style={{ ...ACTIONS_ITEMS_STYLE }}>
                  <CommunicationEmail
                    style={{ marginRight: 5 }}
                    color="#ffffff"
                  />{' '}
                  <span>{_t('action.communication')}</span>
                </span>
              }
              onClick={openCommunicationModal}
            />
          </DropDownMenu>
        </div>
      </div>,
    ]
  ) : (
    <BoButton
      label={_t('action.multi_action')}
      secondary
      onClick={switchMode}
    />
  );

  return (
    <>
      <div className="order-actions_buttons-wrapper">{buttons}</div>
      {canUpdateOrder && (
        <>
          <OrderProlongationModals
            isOpen={isOpenProlongationModal}
            setIsOpenProlongationModal={setIsOpenProlongationModal}
            selectedOrders={selectedOrders}
          />
          <OrderTagsModal
            isOpen={isOpenTagsModal}
            setIsOpenTagsModal={setIsOpenTagsModal}
            selectedOrders={selectedOrders}
            tags={tags}
          />
        </>
      )}
      {canTakeOrderDecision && (
        <OrderDetailModalDecision
          isOpen={isOpenDecisionModal}
          initialDecisionAccept={initialDecisionAccept}
          selectedOrders={selectedOrders}
          onCancel={resetDecisionAction}
        />
      )}
      {canCancelOrder && (
        <OrderDetailModalCancel
          isOpen={isOpenCancellationModal}
          selectedOrders={selectedOrders}
          onClose={resetCancelAction}
        />
      )}
      <OrderDetailModalCommunication
        isOpen={isOpenCommunicationModal}
        selectedOrders={selectedOrders}
        setIsOpenCommunicationModal={setIsOpenCommunicationModal}
        onClose={resetCommunicationAction}
      />
    </>
  );
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canUpdateOrder: !!userInfo && userInfo.rights.includes('ORDER_WRITE'),
    canTakeOrderDecision:
      !!userInfo && userInfo.rights.includes('ORDER_ACCEPT'),
    canCancelOrder: !!userInfo && userInfo.rights.includes('ORDER_DELETE'),
  };
})(OrderActions);
