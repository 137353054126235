import React from 'react';

import { OrderValidityPeriod } from '@cvfm-front/tefps-types';
import './OrderDetailPage.css';
import FakeInputBlock from 'commons/FakeInputBlock';
import { DataBoxItem } from 'commons/DataBox';

const { _t } = window.loadTranslations(__filename);

type OrderDetailHistoryValidityPeriodProps = {
  orderTrace: OrderValidityPeriod;
};

const OrderDetailHistoryValidityPeriod = ({
  orderTrace,
}: OrderDetailHistoryValidityPeriodProps): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_t('startOfValidity')}
        value={new Date(orderTrace.startOfValidity).toLocaleDateString()}
      />
      <FakeInputBlock
        title={_t('endOfValidity')}
        value={new Date(orderTrace.endOfValidity).toLocaleDateString()}
      />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryValidityPeriod;
