import React, { useState, useEffect } from 'react';
import _cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MenuItem, SelectField, Checkbox } from 'material-ui';

import {
  addSubscriberMedia,
  getEvidenceDirectUploadLink,
} from 'api/cvfm-core-subscription/subscriber';
import { uploadFileV2 } from 'api/mediaupload';
import { getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { SubscriberDTO, SubscriberMediaDTO } from '@cvfm-front/tefps-types';
import { Files } from '@cvfm-front/commons-ui';

import { SimpleOrderEvidence } from './OrderCreateTypes';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderCreateProductEvidencesReduxProps = {
  watermarkText: string | null;
};

type OrderCreateProductEvidencesProps = {
  subscriber: SubscriberDTO;
  setSubscriber: React.Dispatch<
    React.SetStateAction<SubscriberDTO | undefined>
  >;
  productEvidences: SimpleOrderEvidence[];
  setProductEvidences: React.Dispatch<
    React.SetStateAction<SimpleOrderEvidence[]>
  >;
} & OrderCreateProductEvidencesReduxProps;

const OrderCreateProductEvidences = ({
  subscriber,
  setSubscriber,
  productEvidences,
  setProductEvidences,
  watermarkText,
}: OrderCreateProductEvidencesProps): JSX.Element => {
  const [
    evidencePerApplicationProcedureMap,
    setEvidencePerApplicationProcedureMap,
  ] = useState<Map<string, string>>(new Map());

  const [evidencesManuallyChecked, setEvidencesManuallyChecked] = useState<
    Map<string, boolean>
  >(new Map());

  const setMessage = useSnackbar();

  useEffect(() => {
    subscriber.evidences.forEach(evidence => {
      setEvidencesManuallyChecked(
        new Map(evidencesManuallyChecked.set(evidence.id, false))
      );
    });
  }, [subscriber]);

  const handleSelectSubscriberMediaId = (
    selectedMediaId: string,
    evidencePropertiesId: string
  ): void => {
    const newEvidences = _cloneDeep(productEvidences);
    newEvidences
      .filter(evidence => evidence.evidenceItem.id === evidencePropertiesId)
      .forEach(evidence => {
        evidence.evidenceSubscriberMediaId =
          evidence.evidenceSubscriberMediaId === selectedMediaId
            ? ''
            : selectedMediaId;
      });
    setEvidencePerApplicationProcedureMap(
      new Map(
        evidencePerApplicationProcedureMap.set(
          evidencePropertiesId,
          selectedMediaId
        )
      )
    );
    setProductEvidences(newEvidences);
  };

  const onChangeFile = (evidenceId: string) => async (
    file: File
  ): Promise<void> => {
    const directUploadDTO = await getEvidenceDirectUploadLink(
      subscriber.subscriberId,
      file.type
    );

    let success = true;
    try {
      await uploadFileV2(
        directUploadDTO.signedUploadUrl,
        file,
        watermarkText,
        undefined,
        _err => {
          setMessage(_tg('commons.error'));
          success = false;
        }
      );
    } catch (err) {
      setMessage((err as Error)?.message || _tg('commons.error'));
      success = false;
    }
    if (!success) {
      return new Promise((_resolve, reject) => reject());
    }

    const newSubscriberMedia: SubscriberMediaDTO = {
      id: uuidv4(),
      name: file.name,
      url: directUploadDTO.fileUrn,
      dateCreated: new Date().toISOString(),
    };
    try {
      const updatedSubscriber = await addSubscriberMedia(
        subscriber.subscriberId,
        newSubscriberMedia
      );
      setSubscriber({ ...updatedSubscriber });
      handleSelectSubscriberMediaId(newSubscriberMedia.id, evidenceId);
    } catch (err) {
      setMessage((err as Error).message);
      return new Promise((_resolve, reject) => reject());
    }
    return new Promise(resolve => resolve());
  };

  const renderSelect = (
    domId: string,
    items: Array<SubscriberMediaDTO>,
    evidencePropertiesId: string,
    value: string,
    evidencePerApplicationProcedureMapArg: Map<string, string>,
    onSelect: (e: never, index: number, selected: string) => void
  ): JSX.Element => {
    return (
      <SelectField
        id={domId}
        onChange={onSelect}
        value={
          evidencePerApplicationProcedureMapArg.has(evidencePropertiesId)
            ? evidencePerApplicationProcedureMapArg.get(evidencePropertiesId)
            : value
        }
        hintText={_t('hint')}
        disabled={evidencesManuallyChecked.get(evidencePropertiesId)}
        style={{
          flex: 1,
          marginRight: '40 px',
          paddingRight: '40px',
          marginTop: '-15px',
        }}
        iconStyle={{
          marginRight: '-50px',
        }}
      >
        <MenuItem value="" primaryText="" />
        {items.map(evidence => (
          <MenuItem
            id={evidence.id}
            key={evidence.id}
            value={evidence.id}
            primaryText={evidence.name}
            insetChildren
          />
        ))}
      </SelectField>
    );
  };

  const handleCheck = (evidencePropertiesId: string): void => {
    const newEvidences = _cloneDeep(productEvidences);
    newEvidences
      .filter(evidence => evidence.evidenceItem.id === evidencePropertiesId)
      .forEach(evidence => {
        evidence.manuallyChecked = !evidence.manuallyChecked;
        evidence.evidenceSubscriberMediaId = uuidv4();
      });
    setProductEvidences(newEvidences);
    const evidenceChecked = newEvidences.find(
      evidence => evidence.evidenceItem.id === evidencePropertiesId
    );
    if (evidenceChecked) {
      setEvidencesManuallyChecked(
        new Map(
          evidencesManuallyChecked.set(
            evidenceChecked.evidenceItem.id,
            evidenceChecked.manuallyChecked
          )
        )
      );
    }
  };

  return (
    <>
      <div className="order-detail-row" style={{ marginTop: '40px' }}>
        {productEvidences.map(evidence => (
          <div
            key={`evidence-line-${evidence.evidenceItem.id}`}
            className="order-create-page-evidence_line"
          >
            <span style={{ minWidth: '15%' }}>
              {evidence.evidenceItem.name} {evidence.mandatory ? ' * ' : ' '}
            </span>
            <div style={{ flexGrow: 1 }}>
              <Files
                selfControlled={false} // we don't want the files to appear
                label={_tg('action.addNewFiles')}
                fileIcon="/static/vehicle-card.svg"
                closeIcon="/static/close.svg"
                withDropzone={false}
                maxSize={2000000}
                maxFiles={4}
                onChange={onChangeFile(evidence.evidenceItem.id)}
                onError={err => setMessage(err)}
              />
            </div>
            {subscriber ? (
              <>
                <div>
                  {renderSelect(
                    'evidence-media-select',
                    subscriber.evidences,
                    evidence.evidenceItem.id,
                    evidence.evidenceSubscriberMediaId,
                    evidencePerApplicationProcedureMap,
                    (_e: never, _index: never, selectedMediaId: string): void =>
                      handleSelectSubscriberMediaId(
                        selectedMediaId,
                        evidence.evidenceItem.id
                      )
                  )}
                </div>
                <div className="order-create-page-checkbox">
                  {!evidence.evidenceItem.uploadRequired ? (
                    <Checkbox
                      label={_tg('tefps.subscription.checkedByAgent')}
                      id={evidence.evidenceItem.id}
                      checked={evidence.manuallyChecked}
                      onCheck={() => handleCheck(evidence.evidenceItem.id)}
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

function mapStateToProps(
  state: InternalApiState
): OrderCreateProductEvidencesReduxProps {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
}

export default connect(mapStateToProps)(OrderCreateProductEvidences);
