import React from 'react';

import RapoAddressRenderer from 'commons/Address/RapoAddressRenderer';
import { RECOURSE_REASONS } from 'tefps/RecoursesV2/utils/translationUtils';
import { RapoDetailDTO } from 'api/recourse/types';
import Date from 'commons/Date';

const { _t, _tg } = window.loadTranslations(__filename);

type RecourseSummaryUserInfoProps = {
  recourseDetails: RapoDetailDTO;
};

const RecourseSummaryUserInfo = ({
  recourseDetails,
}: RecourseSummaryUserInfoProps): JSX.Element => {
  const agentComments = recourseDetails.recourse.comments.filter(
    comment => comment?.text
  );
  return (
    <div className="recourse-card">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('title')}</div>
      </div>
      <div className="recourse-card__content text--small">
        <div className="recourse-card__content__row">
          <span className="text-bold">{_tg('field.human.lastname_short')}</span>
          <span>{recourseDetails.recourse.user.lastName}</span>
        </div>
        <div className="recourse-card__content__row">
          <span className="text-bold">{_tg('field.human.firstname')}</span>
          <span>{recourseDetails.recourse.user.firstName}</span>
        </div>
        <div className="recourse-card__content__row">
          <span className="text-bold">{_tg('field.human.email')}</span>
          <span>{recourseDetails.recourse.user.email}</span>
        </div>
        <div className="recourse-card__content__row">
          <span className="text-bold">{_tg('field.address.address')}</span>
          <span>
            <RapoAddressRenderer user={recourseDetails.recourse.user} />
          </span>
        </div>
        <div className="recourse-card__content__row">
          <span className="text-bold">{_tg('field.human.phone')}</span>
          <span>{recourseDetails.recourse.user.phoneNumber}</span>
        </div>
        <div className="recourse-card__content__row">
          <span className="text-bold">{_t('userInfo.reason')}</span>
          <span>{RECOURSE_REASONS[recourseDetails.recourse.reason]}</span>
        </div>
        <div className="margin-y--s" />
        <div className="recourse-card__content__row text-bold">
          <span>{_t('userInfo.userComment')}</span>
        </div>
        <div className="recourse-card__content__row">
          <span>
            {recourseDetails.recourse.comment ?? _t('userInfo.noComment')}
          </span>
        </div>
        <div className="recourse-card__content__row text-bold">
          <span>{_t('userInfo.agentComments')}</span>
        </div>
        {agentComments.length > 0 ? (
          agentComments.map(comment => (
            <div className="margin-y--xs">
              <div className="text-bold text-height--l">
                <span>
                  [<Date datetime={comment.dateCreated} />]
                </span>
                <span>
                  {_t('comment', {
                    agent: comment.agent.name,
                  })}
                </span>
              </div>
              <div>{comment.text}</div>
            </div>
          ))
        ) : (
          <div className="recourse-card__content__row">
            <span>
              {recourseDetails.recourse.comment ?? _t('userInfo.noComment')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecourseSummaryUserInfo;
