import React from 'react';
import { Card } from 'material-ui';

import useRecourse from 'commons/hooks/useRecourse';
import useFps from 'commons/hooks/useFps';
import useFpsPayments from 'commons/hooks/useFpsPayments';
import { computeAmountToPay } from 'tefps/Fps/Detail/helpers';
import Price from 'commons/Price';
import Date from 'commons/Date';

import './FpsHistory.css';

const { _t, _tg } = window.loadTranslations(__filename);

const FpsHistory = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const latestFps = useFps();
  const payments = useFpsPayments();

  return latestFps && recourseDetails ? (
    <Card className="recourse-card fps-history">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('title')}</div>
      </div>
      <div className="recourse-card__content text--small">
        <div className="margin-y--xs">
          <span className="text-bold">{_t('amounts.title')}</span>
        </div>
        <div className="list-row">
          <span className="text-bold list-cell">{_t('amounts.oldPrice')}</span>
          <span className="list-cell">
            <Price price={recourseDetails.fps.finePrice} />
          </span>
        </div>
        <div className="list-row">
          <span className="text-bold list-cell">{_t('amounts.newPrice')}</span>
          <span className="list-cell">
            <Price price={computeAmountToPay(latestFps)} />
          </span>
        </div>
        <div className="fps-history__title">
          <span className="text-bold">{_t('payments.title')}</span>
        </div>
        <div className="column">
          {payments.length > 0 ? (
            payments.map(payment => {
              return (
                <div className="fps-history__payment">
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_tg('field.date.date')}
                    </span>
                    <span className="list-cell">
                      <Date datetime={payment.datetime} withTime={false} />
                    </span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_tg('field.date.hour')}
                    </span>
                    <span className="list-cell">
                      <Date datetime={payment.datetime} withDate={false} />
                    </span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_t('payments.payedAmount')}
                    </span>
                    <span className="list-cell">
                      <Price price={payment.amount} />
                    </span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_t('payments.amountToPay')}
                    </span>
                    <span className="list-cell">
                      <Price price={payment.amountToPay} />
                    </span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_tg('field.payment.origin')}
                    </span>
                    <span className="list-cell">{payment.origin}</span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_tg('field.agent.agent')}
                    </span>
                    <span className="list-cell">
                      {payment.agent?.name ?? _t('payments.noData')}
                    </span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_tg('field.payment.mode')}
                    </span>
                    <span className="list-cell">{payment.mode}</span>
                  </div>
                  <div className="list-row">
                    <span className="text-bold list-cell">
                      {_t('payments.receptionDate')}
                    </span>
                    <span className="list-cell">
                      {payment.receptionDatetime ? (
                        <Date
                          datetime={payment.receptionDatetime}
                          withTime={false}
                        />
                      ) : (
                        _t('payments.noData')
                      )}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="margin--s">{_t('payments.noPayments')}</div>
          )}
        </div>
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default FpsHistory;
