import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import './style.css';

import MissNotFound from 'commons/MissNotFound';
import useCityConfig from 'commons/hooks/useCityConfig';

import RecourseLoader from './Loader';
import RecourseList from './pages/List/index';
import RecourseDetail from './pages/Detail';
import ComplementRequest from './pages/ComplementRequest';
import Proposal from './pages/Proposal';
import ServiceLoader from './ServiceLoader';

const RecoursesV2 = ({ match }: RouteComponentProps): JSX.Element => {
  const cityConfig = useCityConfig();
  return (
    <ServiceLoader>
      <Switch>
        {!cityConfig?.modulesConfiguration.rapo.enabled &&
          !cityConfig?.modulesConfiguration.rapo.displayed && <MissNotFound />}
        {/* Recourse list */}
        <Redirect path={`${match.url}`} to={`${match.url}/list`} exact />
        <Route path={`${match.url}/list`} component={RecourseList} exact />
        {/* Recourse details from recourse ID */}
        <Route
          path={`${match.url}/detail/:recourseId`}
          exact
          component={RecourseDetail}
        />
        {/* Being redirected from an FPS */}
        <Route
          path={`${match.url}/redirect/fps/:fpsId`}
          exact
          component={RecourseLoader}
        />
        <Route
          path={`${match.url}/detail/:recourseId/complement-request`}
          exact
          component={ComplementRequest}
        />
        <Route
          path={`${match.url}/proposal/:recourseId`}
          exact
          component={Proposal}
        />
        <MissNotFound />
      </Switch>
    </ServiceLoader>
  );
};

export default RecoursesV2;
