import React, { useEffect, useState } from 'react';

import {
  OrderCustomField,
  OrderDecision,
  OrderEvidence,
  OrderEvidenceRequest,
  OrderPayment,
  OrderPlateChangeRequest,
  OrderPlates,
  OrderPrivateDTO,
  OrderTrace,
  OrderTraceType,
  OrderTransfer,
  OrderValidityPeriod,
  OrderZones,
  ProductPrivateDTO,
  SubscriberDTO,
  SubscriptionSource,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';

import './OrderDetailPage.css';
import OrderDetailHistoryTodo from './OrderDetailHistoryTodo';
import OrderDetailHistoryValidityPeriod from './OrderDetailHistoryValidityPeriod';
import OrderDetailEvidenceRequestHistory from './OrderDetailHistoryEvidenceRequest';
import OrderDetailEvidenceHistory from './OrderDetailHistoryEvidence';
import OrderDetailHistoryCustomField from './OrderDetailHistoryCustomField';
import OrderDetailHistoryDecision from './OrderDetailHistoryDecision';
import OrderDetailHistoryClaimDecision from './OrderDetailHistoryClaimDecision';
import OrderDetailHistoryPlates, {
  OrderPlatesWithPreviousPlates,
} from './OrderDetailHistoryPlates';
import OrderDetailHistoryPayment from './OrderDetailHistoryPayment';
import TimelineBlock from './OrderDetailTimelineBlock';
import OrderDetailHistoryZones from './OrderDetailHistoryZones';
import OrderDetailHistoryTransfer from './OrderDetailHistoryTransfer';
import OrderDetailHistoryCreation from './OrderDetailHistoryCreation';
import OrderDetailHistoryPlateChangeRequest from './OrderDetailHistoryPlateChangeRequest';
import { downloadPaymentPDF } from './helpers';

const { _t } = window.loadTranslations(__filename);

type OrderDetailHistoryProps = {
  order: OrderPrivateDTO;
  orderProduct: ProductPrivateDTO;
  orderSubscribers: Array<SubscriberDTO>;
};

const OrderDetailHistory = ({
  order,
  orderProduct,
  orderSubscribers,
}: OrderDetailHistoryProps): JSX.Element => {
  const [zones, setZones] = useState<Array<ZoneDTO>>([]);

  function loadZoning(): void {
    void fetchZoning(FETCH_LIGHT_ZONING_CONFIG).then(fetchedZones => {
      setZones(fetchedZones.zones);
    });
  }

  useEffect(() => loadZoning(), []); // only when the component mounts

  function translateTraceType(orderTrace: OrderTrace) {
    switch (orderTrace.traceType) {
      case OrderTraceType.DECISION:
        return _t('trace.DECISION');
      case OrderTraceType.EVIDENCE:
        return _t('trace.EVIDENCE');
      case OrderTraceType.EVIDENCE_REQUEST:
        return _t('trace.EVIDENCE_REQUEST');
      case OrderTraceType.CLAIM_DECISION:
        return _t('trace.CLAIM_DECISION');
      case OrderTraceType.CLAIM_EVIDENCE:
        return _t('trace.CLAIM_EVIDENCE');
      case OrderTraceType.CLAIM_EVIDENCE_REQUEST:
        return _t('trace.CLAIM_EVIDENCE_REQUEST');
      case OrderTraceType.MAKE_CLAIM:
        return _t('trace.MAKE_CLAIM');
      case OrderTraceType.PLATE_CHANGE_REQUEST:
        return _t('trace.PLATE_CHANGE_REQUEST');
      case OrderTraceType.PAYMENT:
        return _t('trace.PAYMENT');
      case OrderTraceType.PLATES:
        return _t('trace.PLATES');
      case OrderTraceType.VALIDITY_PERIOD:
        return _t('trace.VALIDITY_PERIOD');
      case OrderTraceType.CUSTOM_FIELD:
        return _t('trace.CUSTOM_FIELD');
      case OrderTraceType.CREATION:
        return _t('trace.CREATION');
      case OrderTraceType.CANCELLATION:
        return _t('trace.CANCELLATION');
      case OrderTraceType.TRANSFER:
        return _t('trace.TRANSFER');
      case OrderTraceType.ZONES:
        return _t('trace.ZONES');
      case OrderTraceType.REFUND:
        return _t('trace.REFUND');
      default:
        return _t('trace.UNKNOWN_ERROR');
    }
  }

  function processDownloadPaymentPDF() {
    downloadPaymentPDF(order.orderId);
  }

  function getDownload(orderTrace: OrderTrace): undefined | (() => void) {
    switch (orderTrace.traceType) {
      case OrderTraceType.PAYMENT:
        return processDownloadPaymentPDF;
      default:
        return undefined;
    }
  }

  function translateSource(orderTrace: OrderTrace) {
    switch (orderTrace.source) {
      case SubscriptionSource.API:
        return _t('origin.API');
      case SubscriptionSource.FRONT:
        return _t('origin.FRONT');
      case SubscriptionSource.BACK:
        return _t('origin.BACK');
      case SubscriptionSource.IMPORT:
        return _t('origin.IMPORT');
      default:
        return _t('origin.UNKNOWN_ERROR');
    }
  }

  function orderTraceHistory(): Array<OrderTrace> {
    // Concat all order traces
    const orderHistory = [
      ...order.validityPeriodHistory.slice(1), // We remove automatic first element
      ...order.providedCustomFieldHistory,
      ...order.complementEvidenceHistory,
      ...order.plateHistory.slice(1), // We remove automatic first element
      ...order.paymentHistory,
      ...order.providedEvidenceHistory,
      ...order.plateChangeRequestHistory,
      ...order.transferHistory,
      ...order.zoneHistory.slice(1), // We remove automatic first element
    ] as Array<OrderTrace>;

    [
      order.creationRecord,
      order.cancellationRecord,
      order.decision,
      order.claim,
      order.claimDecision,
    ].forEach(trace => trace && orderHistory.push(trace));

    // Sort by timestamp
    orderHistory.sort((a, b) => {
      const aDate = new Date(a.timestamp);
      const bDate = new Date(b.timestamp);
      return bDate.getTime() - aDate.getTime();
    });

    return orderHistory;
  }

  function renderOrderTrace(
    orderTraces: Array<OrderTrace>,
    idx: number
  ): JSX.Element | null {
    const orderTrace = orderTraces[idx];
    switch (orderTrace.traceType) {
      case OrderTraceType.VALIDITY_PERIOD:
        return (
          <OrderDetailHistoryValidityPeriod
            key={`${idx}-validity`}
            orderTrace={orderTrace as OrderValidityPeriod}
          />
        );
      case OrderTraceType.EVIDENCE_REQUEST:
      case OrderTraceType.CLAIM_EVIDENCE_REQUEST:
        return (
          <OrderDetailEvidenceRequestHistory
            key={`${idx}-evidence-request`}
            orderTrace={orderTrace as OrderEvidenceRequest}
          />
        );
      case OrderTraceType.PLATE_CHANGE_REQUEST:
        return (
          <OrderDetailHistoryPlateChangeRequest
            subscribers={orderSubscribers}
            key={`${idx}-plate-change-request`}
            orderTrace={orderTrace as OrderPlateChangeRequest}
            orderProduct={orderProduct}
          />
        );
      case OrderTraceType.EVIDENCE:
      case OrderTraceType.CLAIM_EVIDENCE:
        return (
          <OrderDetailEvidenceHistory
            key={`${idx}-evidence`}
            orderTrace={orderTrace as OrderEvidence}
            subscribers={orderSubscribers}
            orderProduct={orderProduct}
            order={order}
          />
        );
      case OrderTraceType.PLATES: {
        const orderPlatesWithPreviousPlates = {
          ...orderTrace,
        } as OrderPlatesWithPreviousPlates;
        let previousPlates = (orderTraces
          .slice(idx + 1)
          .find(
            trace => trace.traceType === OrderTraceType.PLATES
          ) as OrderPlates)?.plates;
        if (previousPlates === undefined) {
          previousPlates = order.plateHistory[0].plates;
        }
        orderPlatesWithPreviousPlates.previousPlates = previousPlates;
        return (
          <OrderDetailHistoryPlates
            key={`${idx}-plates`}
            orderTrace={orderPlatesWithPreviousPlates}
          />
        );
      }
      case OrderTraceType.ZONES:
        return (
          <OrderDetailHistoryZones
            key={`${idx}-zones`}
            orderTrace={orderTrace as OrderZones}
            zones={zones}
          />
        );
      case OrderTraceType.CUSTOM_FIELD:
        return (
          <OrderDetailHistoryCustomField
            key={`${idx}-customField`}
            product={orderProduct}
            orderTrace={orderTrace as OrderCustomField}
          />
        );
      case OrderTraceType.DECISION:
        return (
          <OrderDetailHistoryDecision
            key={`${idx}-decision`}
            orderTrace={orderTrace as OrderDecision}
          />
        );
      case OrderTraceType.CLAIM_DECISION:
        return (
          <OrderDetailHistoryClaimDecision
            key={`${idx}-claim-decision`}
            orderTrace={orderTrace as OrderDecision}
          />
        );
      case OrderTraceType.MAKE_CLAIM:
        return null;
      case OrderTraceType.PAYMENT:
      case OrderTraceType.REFUND:
        return (
          <OrderDetailHistoryPayment
            key={`${idx}-${
              orderTrace.traceType === OrderTraceType.PAYMENT
                ? 'payment'
                : 'refund'
            }`}
            orderTrace={orderTrace as OrderPayment}
          />
        );
      case OrderTraceType.CANCELLATION:
      case OrderTraceType.CREATION:
        // the creation history combines the first zones, plates and validity period
        return (
          <OrderDetailHistoryCreation
            orderProduct={orderProduct}
            order={order}
            zones={zones}
          />
        );
      case OrderTraceType.TRANSFER:
        return (
          <OrderDetailHistoryTransfer
            orderTrace={orderTrace as OrderTransfer}
          />
        );
      default:
        return (
          <OrderDetailHistoryTodo
            key={`${idx}-todo`}
            orderTrace={orderTrace}
            order={order}
            orderProduct={orderProduct}
            orderSubscribers={orderSubscribers}
          />
        );
    }
  }

  const items: Array<OrderTrace> = orderTraceHistory();

  // For now, we store messages only on OrderClaims.
  // Until it's on all OrderTraces, we need to do this trick.
  const getItemMessage = (item: OrderTrace) => {
    const anyItem = item as Record<string, unknown>;
    if (anyItem.message) {
      return anyItem.message as string;
    }
    return undefined;
  };

  return (
    <>
      {items.map((item, index) => (
        <TimelineBlock
          type={translateTraceType(item)}
          source={translateSource(item)}
          massAction={item.massAction}
          downloadPDF={getDownload(item)}
          date={item.timestamp}
          comment={item.comment}
          message={getItemMessage(item)}
          agent={item.agent}
          orderTraceElement={renderOrderTrace(items, index)}
          key={index}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          isWarning={item.traceType === OrderTraceType.CANCELLATION}
        />
      ))}
    </>
  );
};

export default OrderDetailHistory;
