import React from 'react';
import { Card } from 'material-ui';

import { Tabs, Tab } from '@cvfm-front/commons-ui';
import useRecourse from 'commons/hooks/useRecourse';
import RecourseSummaryUserInfo from 'tefps/RecoursesV2/pages/Proposal/RecourseSummaryUserInfo';
import RecourseSummaryAttachedFiles from 'tefps/RecoursesV2/pages/Proposal/RecourseSummaryAttachedFiles';

const { _t } = window.loadTranslations(__filename);

const RecourseSummary = (): JSX.Element => {
  const recourseDetails = useRecourse();

  return recourseDetails ? (
    <Card className="recourses-card">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('title')}</div>
      </div>
      <div className="recourse-card__content text--small">
        <Tabs
          defaultActiveTabIndex={0}
          tabsClassName="text-bold"
          contentClassName="margin-y--s"
        >
          <Tab title={_t('recourseSummary.userInfo')}>
            <RecourseSummaryUserInfo recourseDetails={recourseDetails} />
          </Tab>
          <Tab title={_t('recourseSummary.attachedFiles')}>
            <RecourseSummaryAttachedFiles recourseDetails={recourseDetails} />
          </Tab>
        </Tabs>
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default RecourseSummary;
