import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { RecourseProposalDTO } from 'api/recourse/types';
import Separator from 'commons/Separator';

const { _tg } = window.loadTranslations(__filename);

type PutOnHoldProps = {
  proposal: RecourseProposalDTO;
};

const PutOnHold = ({ proposal }: PutOnHoldProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.date')}
            value={<Date datetime={proposal.datetime} withTime={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.agent.agent')}
            value={proposal.agent.name}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default PutOnHold;
