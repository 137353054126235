import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Dialog,
  MenuItem,
  RadioButton,
  RadioButtonGroup,
  SelectField,
  TextField,
} from 'material-ui';
import moment from 'moment';

import {
  OrderPlateChange,
  OrderPlateChangeType,
  OrderPrivateDTO,
  OrderSimpleDTO,
  ProductPrivateDTO,
  ProductType,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';
import { Flex, Text } from '@cvfm-front/commons-ui';
import {
  applyPlatesModifications,
  fetchChoosablePlates,
} from 'api/cvfm-core-subscription/order';
import { getLast } from 'commons/Utils/arrayUtils';

import './OrderDetailPage.css';
import { connect } from 'react-redux';

import { getConfigState } from 'config/duck';
import BoButton from 'facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_RADIO_BUTTON_GROUP: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  margin: '24px 0px',
  width: '100%',
};

const STYLE_RADIO_BUTTON: CSSProperties = {
  width: undefined,
};

const RED = 'rgba(255, 0, 0, 0.87)';
const RED_TRANSPARENT = 'rgba(255, 0, 0, 0.3)';
const BLACK = 'rgba(0, 0, 0, 0.87)';

function getDefaultChange() {
  return {
    oldPlate: null,
    newPlate: null,
    changeType: OrderPlateChangeType.MODIFICATION,
  };
}

type OrderDetailModalPlatesProps = {
  open: boolean;
  product: ProductPrivateDTO;
  order: OrderPrivateDTO;
  ordersInduced?: Array<OrderSimpleDTO>;
  adV3Enabled: boolean;
  subscriberVehicles?: Array<SubscriberVehicle>;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalPlates = ({
  open,
  product,
  order,
  ordersInduced,
  adV3Enabled,
  subscriberVehicles,
  onCancel,
  onConfirm,
}: OrderDetailModalPlatesProps): JSX.Element => {
  const [change, setChange] = useState<OrderPlateChange>(getDefaultChange());
  const [choosablePlates, setChoosablePlates] = useState<Array<string>>([]);
  const { mustUseActiveEligibilityPlate } = product.restrictions;

  useEffect(() => {
    const fetchData = async () => {
      if (mustUseActiveEligibilityPlate) {
        const fetchedChoosablePlates = await fetchChoosablePlates(
          order.orderId
        );
        setChoosablePlates(fetchedChoosablePlates.plates);
      }
    };
    fetchData();
  }, [product, order]);

  const currentPlates = getLast(order.plateHistory)?.plates || [];

  function isChangeValid() {
    switch (change.changeType) {
      case OrderPlateChangeType.ADDITION:
        return change.newPlate && change.newPlate.trim().length > 0;
      case OrderPlateChangeType.DELETION:
        return change.oldPlate && change.oldPlate.length > 0;
      case OrderPlateChangeType.MODIFICATION:
        return (
          change.oldPlate &&
          change.oldPlate.length > 0 &&
          change.newPlate &&
          change.newPlate.trim().length > 0
        );
      case null:
      default:
        return false;
    }
  }

  function isDeletionOrModification() {
    return (
      change.changeType === OrderPlateChangeType.DELETION ||
      change.changeType === OrderPlateChangeType.MODIFICATION
    );
  }

  function isAdditionOrModification() {
    return (
      change.changeType === OrderPlateChangeType.ADDITION ||
      change.changeType === OrderPlateChangeType.MODIFICATION
    );
  }

  function canAdd() {
    return product.restrictions.plateLimit > currentPlates.length;
  }

  function normalizePlate(plate: string): string {
    // replace "é" by "e" and remove non alphanumerical chars
    return (
      plate
        // https://stackoverflow.com/a/37511463
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^A-Za-z0-9]/g, '')
        .toUpperCase()
    );
  }

  function isPlateUsedByAnInducedOrder(plate: string): boolean {
    const normalizedPlate = normalizePlate(plate);
    return (
      !!ordersInduced &&
      ordersInduced.some(
        o =>
          o.plates.some(
            inducedPlate => normalizePlate(inducedPlate) === normalizedPlate
          ) &&
          moment(o.validityPeriod.endOfValidity).isAfter(moment()) &&
          o.status !== 'CANCELED'
      )
    );
  }

  function isLastPlate() {
    return product.restrictions.mustContainPlate && currentPlates.length === 1;
  }

  function isPlateDisabled(plate: string) {
    return (
      change.changeType === OrderPlateChangeType.DELETION &&
      (isLastPlate() || isPlateUsedByAnInducedOrder(plate))
    );
  }

  function computeColorOfPlate(plate: string): CSSProperties['color'] {
    if (change.changeType === OrderPlateChangeType.DELETION) {
      // the plate should be a bit transparent if it is used
      return isLastPlate() || isPlateUsedByAnInducedOrder(plate)
        ? RED_TRANSPARENT
        : BLACK;
    }
    return isPlateUsedByAnInducedOrder(plate) ? RED : BLACK;
  }

  function onCancelAction() {
    setChange(getDefaultChange());
    onCancel();
  }

  function onConfirmAction() {
    if (isChangeValid()) {
      const updatePromise = applyPlatesModifications(order.orderId, change);
      onConfirm(updatePromise);
    }
  }

  function onChangeType(
    _e: React.FormEvent<Element>,
    value: OrderPlateChangeType
  ) {
    const newChange = { ...change, changeType: value };
    if (value === OrderPlateChangeType.DELETION) {
      newChange.newPlate = null;
      if (isPlateUsedByAnInducedOrder(change.oldPlate || '')) {
        newChange.oldPlate = null;
      }
    }
    if (value === OrderPlateChangeType.ADDITION) {
      newChange.oldPlate = null;
    }
    setChange(newChange);
  }

  function onChangeOldPlate(
    _e: React.FormEvent<Element>,
    _index: number,
    value: string
  ) {
    const newChange = { ...change, oldPlate: value };
    setChange(newChange);
  }

  function onChangeNewPlateAddition(
    _e: React.FormEvent<Element>,
    value: string
  ) {
    const newChange = { ...change, newPlate: value };
    setChange(newChange);
  }

  function onChangeNewPlateAdditionFromVehicles(
    _e: React.FormEvent<Element>,
    _index: number,
    value: string
  ) {
    const newChange = { ...change, newPlate: value };
    setChange(newChange);
  }

  function onChangeNewPlateModification(
    _e: React.FormEvent<Element>,
    _index: number,
    value: string
  ) {
    const newChange = { ...change, newPlate: value };
    setChange(newChange);
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-plates-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-plates-confirm"
      primary
      disabled={!isChangeValid()}
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
    />,
  ];

  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
      autoScrollBodyContent
    >
      <Flex flexDirection="column" alignItems="center">
        <RadioButtonGroup
          name="group"
          onChange={onChangeType}
          valueSelected={change.changeType}
          style={STYLE_RADIO_BUTTON_GROUP}
        >
          <RadioButton
            name="ADDITION"
            value={OrderPlateChangeType.ADDITION}
            label={_t('radioButton.addition')}
            disabled={!canAdd()}
            style={STYLE_RADIO_BUTTON}
          />
          <RadioButton
            name="MODIFICATION"
            value={OrderPlateChangeType.MODIFICATION}
            label={_t('radioButton.modification')}
            style={STYLE_RADIO_BUTTON}
          />
          <RadioButton
            name="DELETION"
            value={OrderPlateChangeType.DELETION}
            label={_t('radioButton.deletion')}
            style={STYLE_RADIO_BUTTON}
          />
        </RadioButtonGroup>
        {isDeletionOrModification() && (
          <SelectField
            onChange={onChangeOldPlate}
            value={change.oldPlate}
            floatingLabelText={_t('floatingLabelText.oldPlate')}
            labelStyle={{ color: computeColorOfPlate(change.oldPlate || '') }} // when selected, what is displayed
            selectedMenuItemStyle={{
              // when selected and you open up the select again, this is applied to the MenuItem selected
              color: computeColorOfPlate(change.oldPlate || ''),
            }}
          >
            {currentPlates.map(plate => (
              <MenuItem
                key={plate}
                value={plate}
                primaryText={plate}
                disabled={isPlateDisabled(plate)}
                style={{ color: computeColorOfPlate(plate) }}
              />
            ))}
          </SelectField>
        )}
        {isAdditionOrModification() &&
          (product.restrictions.mustUseActiveEligibilityPlate ? (
            <SelectField
              onChange={onChangeNewPlateModification}
              value={change.newPlate}
              floatingLabelText={_t('floatingLabelText.newPlate')}
              labelStyle={{ color: computeColorOfPlate(change.oldPlate || '') }} // when selected, what is displayed
              selectedMenuItemStyle={{
                // when selected and you open up the select again, this is applied to the MenuItem selected
                color: computeColorOfPlate(change.oldPlate || ''),
              }}
            >
              {choosablePlates.map(plate => (
                <MenuItem
                  key={plate}
                  value={plate}
                  primaryText={plate}
                  style={{ color: computeColorOfPlate(plate) }}
                />
              ))}
            </SelectField>
          ) : (
            <>
              {(!adV3Enabled ||
                (!product.restrictions.mustUseActiveEligibilityPlate &&
                  product.productType === ProductType.BUNDLE)) && (
                <TextField
                  value={change.newPlate || ''}
                  onChange={onChangeNewPlateAddition}
                  floatingLabelText={_t('floatingLabelText.newPlate')}
                  inputStyle={{
                    color: computeColorOfPlate(change.newPlate || ''),
                  }}
                />
              )}
              {adV3Enabled &&
                (product.restrictions.mustUseActiveEligibilityPlate ||
                  product.productType === ProductType.ELIGIBILITY) && (
                  <>
                    <SelectField
                      onChange={onChangeNewPlateAdditionFromVehicles}
                      value={change.newPlate || ''}
                      floatingLabelText={_t('floatingLabelText.newPlate')}
                      labelStyle={{
                        color: computeColorOfPlate(change.newPlate || ''),
                      }} // when selected, what is displayed
                      selectedMenuItemStyle={{
                        // when selected and you open up the select again, this is applied to the MenuItem selected
                        color: computeColorOfPlate(change.newPlate || ''),
                      }}
                    >
                      {subscriberVehicles?.map(vehicle => (
                        <MenuItem
                          key={vehicle.plate}
                          value={vehicle.plate}
                          primaryText={vehicle.plate}
                          style={{ color: computeColorOfPlate(vehicle.plate) }}
                        />
                      ))}
                    </SelectField>
                    <Text className="text--small margin-y">
                      {_tg(
                        'tefps.subscription.addingVehicleFromSubscriberAccount'
                      )}
                    </Text>
                  </>
                )}
            </>
          ))}
      </Flex>
    </Dialog>
  );
};

export default connect(state => {
  const { adV3Enabled } = getConfigState(state).subscriptionConfigurationDTO;
  return {
    adV3Enabled,
  };
})(OrderDetailModalPlates);
