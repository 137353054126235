import React from 'react';

import './OrderDetailPage.css';

import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import { OrderZones, ZoneDTO } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type OrderDetailHistoryZonesProps = {
  orderTrace: OrderZones;
  zones: Array<ZoneDTO>;
};

const OrderDetailHistoryZones = ({
  orderTrace,
  zones,
}: OrderDetailHistoryZonesProps): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_tg('field.address.validityZones')}
        value={orderTrace.zoneIds
          .map(zId => zones?.find(zone => zone.id === zId)?.name)
          .join(', ')}
      />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryZones;
