import React, { useState } from 'react';
import { Dialog, MenuItem, SelectField, TextField } from 'material-ui';
import { connect } from 'react-redux';

import {
  OrderPrivateDTO,
  OrderTraceType,
  OrderTransfer,
  SubscriberDTO,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import { addTransferUpdate } from 'api/cvfm-core-subscription/order';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';

import './OrderDetailPage.css';
import BoButton from 'facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalCustomFieldProps = {
  open: boolean;
  order: OrderPrivateDTO;
  subscribers: Array<SubscriberDTO>;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalTransfer = ({
  open,
  order,
  userInfo,
  onCancel,
  onConfirm,
  subscribers,
}: OrderDetailModalCustomFieldProps): JSX.Element => {
  const [subscriberId, setSubscriberId] = useState<string>();
  const [fromSubscriberId, setFromSubscriberId] = useState<string>(
    order.subscribersHistory[order.subscribersHistory.length - 1]
      .subscriberIds[0]
  );
  const [comment, setComment] = useState<string>();

  function onCancelAction() {
    setComment(undefined);
    onCancel();
  }

  function onConfirmAction() {
    if (subscriberId) {
      const updatSubscriberId = {
        timestamp: new Date().toISOString(),
        traceType: OrderTraceType.TRANSFER,
        source: SubscriptionSource.BACK,
        agent: userInfo ? { ...userInfo } : null,
        comment,
        from: fromSubscriberId,
        to: subscriberId,
        subscriberId,
      } as OrderTransfer;
      const updatePromise = addTransferUpdate(order.orderId, updatSubscriberId);
      onConfirm(updatePromise);
    }
  }

  function handleChangeSubscriberValue(e: never, text: string) {
    if (text !== '') {
      setSubscriberId(text);
    } else {
      setSubscriberId(undefined);
    }
  }

  function onChangeSubscriberFrom(
    _e: never,
    _i: number,
    subscriberIdFrom: string
  ) {
    setFromSubscriberId(subscriberIdFrom);
  }

  function handleChangeComment(e: never, text: string) {
    setComment(text);
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-period-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-period-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
      disabled={!subscriberId}
    />,
  ];

  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      {subscribers.length > 1 && (
        <div className="order-detail_row">
          <SelectField
            onChange={onChangeSubscriberFrom}
            value={fromSubscriberId}
          >
            {subscribers.map(subscriber => (
              <MenuItem
                value={subscriber.subscriberId}
                label={`${subscriber.firstName} ${subscriber.lastName}`}
                primaryText={`${subscriber.firstName} ${subscriber.lastName}`}
              />
            ))}
          </SelectField>
        </div>
      )}
      <div className="order-detail_row">
        <span className="order-detail_cell-100">
          <TextField
            fullWidth
            floatingLabelFixed
            floatingLabelText={_t('field.value')}
            value={subscriberId || ''}
            onChange={handleChangeSubscriberValue}
          />
        </span>
      </div>
      <div className="order-detail_row">
        <span className="order-detail_cell-100">
          <TextField
            multiLine
            rows={5}
            fullWidth
            floatingLabelFixed
            floatingLabelText={_tg('field.comment')}
            value={comment || ''}
            onChange={handleChangeComment}
          />
        </span>
      </div>
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalTransfer);
