import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dialog, TimePicker } from 'material-ui';
import DatePicker from 'material-ui/DatePicker';

import {
  DayOfWeek,
  OrderPrivateDTO,
  OrderTraceType,
  OrderValidityPeriod,
  ProductPrivateDTO,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import { addValidityPeriodUpdate } from 'api/cvfm-core-subscription/order';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';
import './OrderDetailPage.css';
import { BKG_CYAN, BKG_LIGHT_BLUE, BKG_PINK } from 'theme';
import BoButton from 'facade/BoButton';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalValidityPeriodProps = {
  open: boolean;
  product: ProductPrivateDTO;
  order: OrderPrivateDTO;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalValidityPeriod = ({
  open,
  product,
  order,
  userInfo,
  onCancel,
  onConfirm,
}: OrderDetailModalValidityPeriodProps): JSX.Element => {
  const orderValidityPeriod =
    order.validityPeriodHistory[order.validityPeriodHistory.length - 1];

  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(orderValidityPeriod.startOfValidity)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    new Date(orderValidityPeriod.endOfValidity)
  );
  const [time, setTime] = useState<Date | undefined>(startDate);
  const [messages, setMessages] = useState<Array<string>>([]);
  const [valid, setValid] = useState<boolean>(false);

  function onCancelAction() {
    setStartDate(undefined);
    setEndDate(undefined);
    setTime(undefined);
    setValid(false);
    setMessages([]);
    onCancel();
  }

  function onConfirmAction() {
    if (startDate && endDate) {
      if (time) {
        // no need to set seconds and lower as they will be truncated in the back
        startDate.setHours(time.getHours());
        startDate.setMinutes(time.getMinutes());
        endDate.setHours(time.getHours());
        endDate.setMinutes(time.getMinutes());
      }
      const updatePeriod = {
        timestamp: new Date().toISOString(),
        traceType: OrderTraceType.VALIDITY_PERIOD,
        source: SubscriptionSource.BACK,
        agent: userInfo ? { ...userInfo } : null,
        startOfValidity: startDate.toISOString(),
        endOfValidity: endDate.toISOString(),
        subscriberId: null,
      } as OrderValidityPeriod;
      const updatePromise = addValidityPeriodUpdate(
        order.orderId,
        updatePeriod
      );
      onConfirm(updatePromise);
    }
  }

  function onStartNow() {
    const start = new Date();
    const period =
      order.validityPeriodHistory[order.validityPeriodHistory.length - 1];
    const duration = moment.duration(
      moment(new Date(period.endOfValidity)).diff(
        moment(new Date(period.startOfValidity))
      )
    );
    const end = moment(start)
      .add(duration)
      .toDate();
    setStartDate(start);
    setEndDate(end);
  }

  function handleChangeDatesButton(
    amount: number,
    unit: 'year' | 'month' | 'day'
  ) {
    setStartDate(
      moment(startDate)
        .add(amount, unit)
        .toDate()
    );
    setEndDate(
      moment(endDate)
        .add(amount, unit)
        .toDate()
    );
  }

  function handleChangeStart(e: never, newDate: Date) {
    setStartDate(newDate);
  }

  function handleChangeEnd(e: never, newDate: Date) {
    setEndDate(newDate);
  }

  function handleTimeChange(e: never, newDate: Date) {
    setTime(newDate);
  }

  useEffect(() => {
    if (startDate) {
      const warnings: Array<string> = [];
      const errors: Array<string> = [];
      const startMs = startDate.getTime();

      if (product.restrictions.mustStartOnWeekDays.length !== 0) {
        const startDayOfWeek = Object.values(DayOfWeek)[startDate.getDay()];
        const allowedWeekDays = product.restrictions.mustStartOnWeekDays;
        if (!allowedWeekDays.includes(startDayOfWeek)) {
          errors.push(_t('error.startOnWeekDay'));
        }
      }

      if (endDate) {
        const endMs = endDate.getTime();
        const deltaMs = endMs - startMs;

        if (deltaMs === 0) {
          if (order.orderDuration === 'P1D') {
            const optionMs = moment
              .duration(order.orderDuration)
              .asMilliseconds();
            setEndDate(new Date(startDate.getTime() + optionMs - 1000)); // End of previous day
          } else {
            errors.push(_t('error.zeroDuration'));
          }
        }
        if (deltaMs < 0) {
          errors.push(_t('error.negativeDuration'));
        }

        setValid(errors.length === 0);
      } else {
        const optionMs = moment.duration(order.orderDuration).asMilliseconds();
        setEndDate(new Date(startDate.getTime() + optionMs - 1000)); // End of previous day
      }
      setMessages([...warnings, ...errors]);
    }
  }, [startDate, endDate]);

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-period-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-period-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
      disabled={!valid}
    />,
  ];
  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              floatingLabelText={_tg('field.date.beginning')}
              style={{ width: '128px' }}
              onChange={handleChangeStart}
              value={startDate}
            />
            {product.computeExactHourlyEnd && (
              <TimePicker
                floatingLabelText={_t('field.time')}
                cancelLabel={_tg('action.cancel')}
                format="24hr"
                textFieldStyle={{ width: '128px' }}
                onChange={handleTimeChange}
                value={time}
              />
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              floatingLabelText={_tg('field.date.end')}
              style={{ width: '128px' }}
              onChange={handleChangeEnd}
              value={endDate}
            />
            {product.computeExactHourlyEnd && (
              <TimePicker
                floatingLabelText={_t('field.time')}
                cancelLabel={_tg('action.cancel')}
                format="24hr"
                textFieldStyle={{ width: '128px' }}
                onChange={handleTimeChange}
                value={time}
                disabled
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <BoButton
              backgroundColor={BKG_CYAN}
              labelColor="white"
              label={_t('action.startNow')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={onStartNow}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <BoButton
              backgroundColor={BKG_PINK}
              labelColor="white"
              label={_t('action.remove.day')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={() => handleChangeDatesButton(-1, 'day')}
            />
            <BoButton
              backgroundColor={BKG_PINK}
              labelColor="white"
              label={_t('action.remove.month')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={() => handleChangeDatesButton(-1, 'month')}
            />
            <BoButton
              backgroundColor={BKG_PINK}
              labelColor="white"
              label={_t('action.remove.year')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={() => handleChangeDatesButton(-1, 'year')}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <BoButton
              backgroundColor={BKG_LIGHT_BLUE}
              labelColor="white"
              label={_t('action.add.day')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={() => handleChangeDatesButton(1, 'day')}
            />
            <BoButton
              backgroundColor={BKG_LIGHT_BLUE}
              labelColor="white"
              label={_t('action.add.month')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={() => handleChangeDatesButton(1, 'month')}
            />
            <BoButton
              backgroundColor={BKG_LIGHT_BLUE}
              labelColor="white"
              label={_t('action.add.year')}
              key="order-period-startNow"
              style={{ marginTop: 10 }}
              onClick={() => handleChangeDatesButton(1, 'year')}
            />
          </div>
        </div>
      </div>
      <div className="order-detail-modal_warnings">
        {messages.map((msg, idx) => (
          <div key={`validity-warn-${idx}`} className="order-detail_row">
            {msg}
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalValidityPeriod);
