import React, { useState } from 'react';
import { Dialog, CircularProgress, TextField } from 'material-ui';

import RecourseButton from 'facade/RecourseButton';
import useRecourse from 'commons/hooks/useRecourse';
import {
  isDisabledStatus,
  isOnHold,
  isProposalOpen,
} from 'tefps/RecoursesV2/utils/recourseUtils';
import ModalActions from 'tefps/RecoursesV2/commons/ModalActions';
import { PatchObject } from 'api/commonTypes';
import {
  RecourseDecisionRequestDTO,
  RecourseProposalRequestDTO,
} from 'api/recourse/types';
import { patchRecourse } from 'api/recourse';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import FlexCenter from 'commons/FlexCenter';
import { ApiError } from 'api/ApiError';

import Services from '../../../../commons/services';

const { _t, _tg } = window.loadTranslations(__filename);

const PutOnHoldAction = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const setMessage = useSnackbar();

  const [onHold, setOnHold] = useState(
    recourseDetails && isOnHold(recourseDetails.recourse)
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const buildPutOnHoldProposal = (): Array<PatchObject<
    RecourseProposalRequestDTO | RecourseDecisionRequestDTO
  >> => {
    const patches: Array<PatchObject<
      RecourseProposalRequestDTO | RecourseDecisionRequestDTO
    >> = [];
    if (recourseDetails && isOnHold(recourseDetails.recourse)) {
      /* If it's on hold, then just cancel last proposal */
      patches.push({
        op: 'add',
        path: `/proposals/${recourseDetails.recourse.proposals.length -
          1}/decision`,
        value: {
          accepted: false,
          emailEnabled: false,
          letterEnabled: false,
        } as RecourseDecisionRequestDTO,
      });
    } else if (recourseDetails) {
      /* Build on hold proposal */
      patches.push({
        op: 'add',
        path: '/proposals',
        value: {
          agentComment: comment,
          newFpsPrice: recourseDetails.recourse.fpsPrice,
          newFpsReducedPrice: null,
          cityReason: 'R_OTHERS',
          status: 'IS_WAITING',
          waiting: true,
        } as RecourseProposalRequestDTO,
      });
    }

    return patches;
  };

  const handlePutOnHold = async () => {
    if (recourseDetails) {
      setLoading(true);
      try {
        const updatedRecourse = await patchRecourse(
          recourseDetails.recourse.rapoId,
          buildPutOnHoldProposal()
        );
        setMessage(
          onHold
            ? _t('updateMessage.proceedProposition')
            : _t('updateMessage.onHoldProposition')
        );
        setOnHold(!onHold);
        // Update recourse details
        await Services.recourse.init(updatedRecourse.rapoId);
      } catch (e) {
        const error = e as ApiError;
        setMessage(
          _tg('feedback.error.simple', {
            error: error.message,
          })
        );
      } finally {
        setLoading(false);
        setOpen(false);
      }
    }
  };

  return (
    <>
      <RecourseButton
        label={
          onHold ? _t('button.title.proceed') : _t('button.title.putOnHold')
        }
        backgroundColor="var(--bg-color--blue)"
        labelColor="var(--white)"
        disabled={
          recourseDetails === null ||
          isDisabledStatus(recourseDetails.recourse.status) ||
          !!recourseDetails.recourse.complementRequest ||
          isProposalOpen(recourseDetails.recourse)
        }
        onClick={_e => setOpen(true)}
      />
      <Dialog
        open={open}
        title={onHold ? _t('modal.proceed') : _t('modal.putOnHold')}
        actions={
          loading
            ? []
            : ModalActions({
                onValidate: handlePutOnHold,
                onClose: () => setOpen(false),
              })
        }
      >
        {!onHold && (
          <TextField
            floatingLabelText={_tg('field.comment')}
            onChange={(_e, text) => setComment(text)}
            value={comment}
            style={{ width: '100%' }}
            multiLine
            rows={3}
            rowsMax={5}
          />
        )}
        {loading && (
          <div className="margin-y--l" style={{ width: '100%' }}>
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default PutOnHoldAction;
