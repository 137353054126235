import React from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import PeriodPicker from 'commons/PeriodPicker';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  clickValidation: () => Promise<unknown>;
  comment: string;
  onChangeComment: (event: React.FormEvent<any>, value: string) => void;
  prolongationPeriod: string;
  onChangePeriod: (event: React.FormEvent<any>, value: string) => void;
};

function OrderProlongationInfoModal({
  isOpen,
  closeModal,
  clickValidation,
  comment,
  onChangeComment,
  prolongationPeriod,
  onChangePeriod,
}: Props): JSX.Element {
  const actions = [
    <BoButton
      className="order-actions_buttons-wrapper_middle"
      key="cancel"
      label={_tg('action.cancel')}
      secondary
      onClick={closeModal}
    />,
    <BoButton
      key="validate"
      label={_tg('action.validate')}
      primary
      onClick={clickValidation}
      disabled={prolongationPeriod.length === 0}
    />,
  ];

  return (
    <Dialog
      title={_t('header.title')}
      actions={actions}
      modal
      autoScrollBodyContent
      open={isOpen}
    >
      <PeriodPicker
        key={_t('picker.label')}
        value={prolongationPeriod}
        onChange={onChangePeriod}
      />
      <TextField
        value={comment}
        onChange={onChangeComment}
        floatingLabelText={_t('text_field.label')}
        fullWidth
        multiLine
        rows={3}
        rowsMax={3}
      />
    </Dialog>
  );
}

export default OrderProlongationInfoModal;
