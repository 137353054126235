import React, { CSSProperties, useState } from 'react';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import ClearRoundedIcon from 'material-ui/svg-icons/content/clear';
import moment, { now } from 'moment';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { connect } from 'react-redux';

import {
  RecourseFileDTO,
  RecourseFileType,
  RecourseReason,
} from '../../../../api/recourse/types';
import { TEXT_COLOR_LIGHT, TXT_BLACK } from '../../../../theme';
import { formatNumber } from '../../../../commons/Utils/numberUtil';
import DateComponent from '../../../../commons/Date';
import { formatDate } from '../../../../commons/Utils/dateUtil';
import ConfirmAction from '../../../../commons/ConfirmAction';
import { deleteDocument } from '../../../../api/recourse';
import { Base64 } from '../../../../commons/helpers/Base64';
import { getApiState } from '../../../../api/duck';

const FILE_REASON_TITLE = {
  PAYE: 'Justificatif de paiement',
  CESSION: 'Certificat de cession du véhicule',
  DISPENSE: "Justificatif d'autorisation particulière",
  DESTRUCTION: 'Justificatif de destruction du véhicule',
  VOL: 'Justificatif de vol du véhicule',
  USURPATION: "Justificatif d'usurpation",
  AVANCE: 'FPS ou ticket précédent',
  AUTRE: 'Autre',
};

const fileTypeTitle = (type: RecourseFileType, reason: RecourseReason) => {
  switch (type) {
    case 'IMMAT':
      return "Certificat d'immatriculation";
    case 'TRANSFER':
      return 'Accusé d’enregistrement de déclaration de cession';
    case 'MANDATE':
      return 'Mandat pour agir';
    case 'CUSTOM':
      return FILE_REASON_TITLE[reason];
    case 'AUTRE':
      return 'Autre';
    case 'BY_AGENT':
      return 'Document déposé par agent';
    case 'APA':
    default:
      return 'Avis de paiement contesté';
  }
};

const STYLE_CONTAINER: CSSProperties = {
  flex: 1,
  display: 'flex',
  fontSize: 10,
  justifyContent: 'center',
};

const STYLE_TITLE: CSSProperties = {
  display: 'flex',
};
const STYLE_NAME_CONTAINER: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  color: TEXT_COLOR_LIGHT,
  overflow: 'hidden',
};

const STYLE_FILE_NAME: CSSProperties = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '75%',
};

const STYLE_ICON: CSSProperties = {
  width: 40,
  height: 50,
};

type Props = {
  file: RecourseFileDTO | null | undefined;
  reason: RecourseReason;
  created: string;
  rapoId: string;
  canDelete: boolean;
};

const FileBlock = ({
  created,
  file,
  reason,
  rapoId,
  canDelete,
}: Props): JSX.Element => {
  if (!file) return <div />;

  const [localFile, setLocalFile] = useState(file);

  const displayDate = () => {
    if (file) {
      return moment(file.created).isAfter(moment(created).add(1, 'h'));
    }
    return false;
  };

  const onConfirmDelete = async (): Promise<void> => {
    await deleteDocument(rapoId, Base64.encode(localFile.urn));
    setLocalFile(prevState => ({
      ...prevState,
      deleted: formatDate(new Date(now())),
    }));
  };

  const getFileData = (): JSX.Element => {
    return (
      <div style={STYLE_CONTAINER}>
        {localFile.deleted ? (
          <ClearRoundedIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
        ) : (
          <FileIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
        )}
        <div
          style={{ paddingTop: 5, marginLeft: 10, lineHeight: '12px', flex: 1 }}
        >
          <div style={STYLE_TITLE}>
            <div style={{ color: TXT_BLACK, marginRight: 20 }}>
              {fileTypeTitle(localFile.type, reason)}
            </div>
            {displayDate() && <DateComponent datetime={localFile.created} />}
          </div>

          <div style={STYLE_NAME_CONTAINER}>
            <div style={STYLE_FILE_NAME}>{localFile.name}</div>
            <div>
              <span style={{ textTransform: 'uppercase' }}>
                {localFile.deleted
                  ? 'Expiré'
                  : localFile.name.split('.').slice(-1)[0]}
              </span>
              <span> | </span>
              {localFile.deleted
                ? formatDate(new Date(localFile.deleted))
                : formatNumber(localFile.size, 'filesize')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={STYLE_CONTAINER}>
      {!localFile.deleted && (
        <a
          href={localFile.deleted ? '' : localFile.contentUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex', width: '100%' }}
          title="Télécharger la pièce jointe"
        >
          {getFileData()}
        </a>
      )}
      {localFile.deleted && getFileData()}
      {canDelete && localFile.type === 'BY_AGENT' && !localFile.deleted && (
        <ConfirmAction
          action={onConfirmDelete}
          message="Êtes-vous sûr de vouloir supprimer ce document?"
          enabled
        >
          <IconButton>
            <DeleteIcon style={STYLE_ICON} color={TEXT_COLOR_LIGHT} />
          </IconButton>
        </ConfirmAction>
      )}
    </div>
  );
};
export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canDelete: userInfo && userInfo.rights.includes('RAPO_UPDATE'),
  };
})(FileBlock);
