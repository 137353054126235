import React from 'react';

import useRecourse from 'commons/hooks/useRecourse';
import CompleteRequestAction from 'tefps/RecoursesV2/pages/Detail/CompleteRequestAction';

import AddFileAction from './AddFileAction';
import ProposalAction from './ProposalAction';
import ComplementRequestAction from './ComplementRequestAction';
import CommentAction from './CommentAction';
import PutOnHoldAction from './PutOnHoldAction';
import SetSendingDateAction from './SetSendingDateAction';

import './ActionButtons.css';

const ActionButtons = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const waitingComplement =
    recourseDetails &&
    !!recourseDetails.recourse.complementRequest &&
    !recourseDetails.recourse.complementRequest.completionDate;
  const canSetSendingDate =
    recourseDetails &&
    !recourseDetails.recourse.shouldSendLetters && // city.sendingRecourseLetterEnabled === false
    !recourseDetails.recourse.user.illegibleAddress && // postal address is present
    !recourseDetails.recourse.user.email && // no email
    !recourseDetails.recourse.complementRequest?.sentDate;

  return (
    <div className="btn-container margin-x--xl">
      <CommentAction />
      <AddFileAction />
      <PutOnHoldAction />
      {waitingComplement ? (
        <>
          {canSetSendingDate ? (
            <SetSendingDateAction />
          ) : (
            <CompleteRequestAction />
          )}
        </>
      ) : (
        <ComplementRequestAction />
      )}
      <ProposalAction />
    </div>
  );
};

export default ActionButtons;
