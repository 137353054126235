import React from 'react';

import './TimelinePoint.css';

type Props = {
  filler?: boolean;
  style?: Record<string, any>;
};

const TimelinePoint = ({ filler, style }: Props): JSX.Element => {
  return (
    <div className="timeline__point__container" style={style}>
      <div
        className={`${filler ? 'filler ' : ''}timeline__point__container__bar`}
      >
        <div className="timeline__point__bar timeline__point__bar--top" />
        {!filler && <div className="timeline__point" />}
        <div className="timeline__point__bar timeline__point__bar--bottom" />
      </div>
    </div>
  );
};

export default TimelinePoint;
