import * as React from 'react';
import { connect } from 'react-redux';

import {
  ApplicationProcedureDTO,
  ProductPrivateDTO,
} from '@cvfm-front/tefps-types';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';

import ProductDetailSeparator from './commons/DetailSeparator';
import InformationToGive from './ProductApplicationProcedure/InformationToGive';
import InformationToAsk from './ProductApplicationProcedure/InformationToAsk';
import ProductEvidences from './ProductApplicationProcedure/ProductEvidences';
import Claims from './ProductApplicationProcedure/Claims';

const { _t } = window.loadTranslations(__filename);

const defaultApplicationProcedure = {
  customFields: [],
  description: '',
  documentationLinks: [],
  supportingEvidences: [],
  zoneUrlLink: null,
  requiresProofOfId: false,
  requiresProofOfAddress: false,
  requiresProofOfActivity: false,
  requiresProofDescription: null,
  allowRequestForOther: false,
  authorizeClaims: false,
} as ApplicationProcedureDTO;

interface ProductApplicationProcedureReduxProps {
  claimEnabled: boolean;
}

type ProductApplicationProcedureProps = {
  product: ProductPrivateDTO;
  canEdit: boolean;
  onChange: (field: string, newValue: unknown) => void;
} & ProductApplicationProcedureReduxProps;

function ProductApplicationProcedure({
  canEdit,
  product,
  onChange,
  claimEnabled,
}: ProductApplicationProcedureProps): JSX.Element {
  function OnChangeApplicationProcedure(newvalue: ApplicationProcedureDTO) {
    onChange('applicationProcedure', newvalue);
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />
      <InformationToGive
        applicationProcedure={
          product.applicationProcedure || defaultApplicationProcedure
        }
        canEdit={canEdit}
        updateApplicationProdecure={OnChangeApplicationProcedure}
      />

      <InformationToAsk
        applicationProcedure={
          product.applicationProcedure || defaultApplicationProcedure
        }
        canEdit={canEdit}
        updateApplicationProcedure={OnChangeApplicationProcedure}
      />

      <ProductEvidences
        applicationProcedure={
          product.applicationProcedure || defaultApplicationProcedure
        }
        canEdit={canEdit}
        updateApplicationProcedure={OnChangeApplicationProcedure}
        productScope={product.conditions.productScope}
      />

      {claimEnabled && (
        <Claims
          canEdit={canEdit}
          applicationProcedure={
            product.applicationProcedure ?? defaultApplicationProcedure
          }
          updateApplicationProcedure={OnChangeApplicationProcedure}
        />
      )}

      <div className="product-detail_section-content" />
    </div>
  );
}

function mapStateToProps(
  state: InternalApiState
): ProductApplicationProcedureReduxProps {
  const {
    subscriptionConfigurationDTO: { claimEnabled },
  } = getConfigState(state);

  return {
    claimEnabled,
  };
}

export default connect(mapStateToProps)(ProductApplicationProcedure);
