import React, { useState } from 'react';
import { CircularProgress, Dialog, DialogProps } from 'material-ui';
import TextField from 'material-ui/TextField';
import AutoComplete from 'material-ui/AutoComplete';

import Tag from 'commons/Tag';
import useTags from 'commons/hooks/useTags';
import useNewTags from 'commons/hooks/useNewTags';
import useComment from 'commons/hooks/useComment';

import './CommentActionModal.css';

const { _tg, _t } = window.loadTranslations(__filename);

type CommentActionModalProps = DialogProps & {
  loading: boolean;
};

const CommentActionModal = ({
  actions,
  open,
  onRequestClose,
  loading,
}: CommentActionModalProps): JSX.Element => {
  const [comment, setComment] = useComment();
  const tagList = useTags();
  const [newTags, setNewTags] = useNewTags();

  const [searchTag, setSearchTag] = useState<string>('');

  const removeTag = (tag: string): void => {
    setNewTags(newTags.filter(t => t !== tag));
  };

  const addTag = (tag: string): void => {
    setNewTags([...newTags, tag]);
    setSearchTag('');
  };

  return (
    <Dialog
      title={_t('modal.title')}
      actions={actions}
      open={open}
      onRequestClose={onRequestClose}
    >
      {loading ? (
        <div className="row center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="form__title">{_t('modal.form.title')}</div>
          <div className="form__tags">
            {!newTags.length && (
              <div className="form__no-tags">{_t('modal.form.noTags')}</div>
            )}
            {newTags.map((tag, i) => (
              <Tag key={i} tag={tag} onDelete={removeTag} />
            ))}
            <AutoComplete
              className="form__autocomplete"
              hintText={_t('modal.form.autocomplete')}
              searchText={searchTag}
              // eslint-disable-next-line @typescript-eslint/unbound-method
              filter={AutoComplete.fuzzyFilter}
              dataSource={tagList.filter(tag => !newTags.includes(tag))}
              openOnFocus
              onUpdateInput={text => setSearchTag(text)}
              onNewRequest={addTag}
              listStyle={{
                maxHeight: 250,
                overflow: 'auto',
                width: 'max-content',
              }}
            />
          </div>
          <TextField
            floatingLabelText={_tg('field.comment')}
            className="form__comment"
            onChange={(_e, text) => setComment(text)}
            value={comment}
            multiLine
            rows={5}
            rowsMax={5}
          />
        </div>
      )}
    </Dialog>
  );
};

export default CommentActionModal;
