import { unreachable } from 'commons/Enums';
import {
  CityRecourseReason,
  RecourseReason,
  RecourseStatus,
  RecourseUpdateStatus,
} from 'api/recourse/types';
import { PaymentStatus } from 'api/commonTypes';
import { EsClaimStatus, FpsState } from 'api/fps/types';
import { ValueLabel } from '@cvfm-front/commons-types';

const { _t, _tg } = window.loadTranslations(__filename);

export function getRecourseStatus(recourseStatus: RecourseStatus): string {
  switch (recourseStatus) {
    case 'SUBMITTED':
      return _t('recourse.status.SUBMITTED');
    case 'IS_WAITING':
      return _t('recourse.status.IS_WAITING');
    case 'IS_WAITING_COMPLEMENT':
      return _t('recourse.status.IS_WAITING_COMPLEMENT');
    case 'ONGOING_VALIDATION':
      return _t('recourse.status.ONGOING_VALIDATION');
    case 'ACCEPTED':
      return _t('recourse.status.ACCEPTED');
    case 'REFUSED':
      return _t('recourse.status.REFUSED');
    case 'INCORRECT':
      return _t('recourse.status.INCORRECT');
    default:
      return unreachable(recourseStatus);
  }
}

export function getRecourseUpdateMessage(
  decision: RecourseUpdateStatus,
  validation = false
): string {
  if (validation) return _t('recourse.updateMessage.ongoingValidation');
  switch (decision) {
    case 'ACCEPTED':
      return _t('recourse.updateMessage.accepted');
    case 'REFUSED':
      return _t('recourse.updateMessage.refused');
    case 'INCORRECT':
      return _t('recourse.updateMessage.incorrect');
    default:
      return unreachable(decision as never);
  }
}

export const FPS_STATUS = (): Record<FpsState, string> => ({
  INITIAL: _t('fps.status.INITIAL'),
  IDENTIFICATION: _t('fps.status.IDENTIFICATION'),
  USURPATION_SUSPICION: _t('fps.status.USURPATION_SUSPICION'),
  ONGOING_NOTIFICATION: _t('fps.status.ONGOING_NOTIFICATION'),
  SENT_NOTIFICATION: _t('fps.status.SENT_NOTIFICATION'),
  PENDING_RECOVERY: _t('fps.status.PENDING_RECOVERY'),
  ONGOING_RECOVERY: _t('fps.status.ONGOING_RECOVERY'),
  RECOVERED: _t('fps.status.RECOVERED'),
  ABANDONED: _t('fps.status.ABANDONED'),
  CITY_PAID: _t('fps.status.CITY_PAID'),
  ANTAI_PAID: _t('fps.status.ANTAI_PAID'),
  PRE_FPS: _t('fps.status.PRE_FPS'),
  ABANDONNED_CONSISTENCY: _t('fps.status.ABANDONNED_CONSISTENCY'),
  ABANDONNED_CONTROL_REPLAY_CONSISTENCY: _t(
    'fps.status.ABANDONNED_CONTROL_REPLAY_CONSISTENCY'
  ),
});

export const FPS_PAYMENT_STATUS = (): Record<PaymentStatus, string> => ({
  PENDING: _t('fps.paymentStatus.PENDING'),
  INCOMPLETE: _t('fps.paymentStatus.INCOMPLETE'),
  OVERPAID: _t('fps.paymentStatus.OVERPAID'),
  OVERPAID_REDUCED: _t('fps.paymentStatus.OVERPAID_REDUCED'),
  PAID: _t('fps.paymentStatus.PAID'),
  PAID_REDUCED: _t('fps.paymentStatus.PAID_REDUCED'),
  RECOVERED: _t('fps.paymentStatus.RECOVERED'),
  ABANDONED: _t('fps.paymentStatus.ABANDONED'),
});

export const CLAIM_STATUS = (): Record<EsClaimStatus, string> => ({
  NONE: _tg('field.none'),
  ONGOING_RAPO: _t('fps.claimStatus.ongoingRapo'),
  ONGOING_CCSP: _t('fps.claimStatus.ongoingCcsp'),
  RAPO_ACCEPTED: _t('fps.claimStatus.rapoAccepted'),
  RAPO_REJECTED: _t('fps.claimStatus.rapoRejected'),
  CCSP_ACCEPTED: _t('fps.claimStatus.ccspAccepted'),
  CCSP_REJECTED: _t('fps.claimStatus.ccspRejected'),
  ACCEPTED: _t('fps.claimStatus.accepted'),
  ACCEPTED_PARTIAL: _t('fps.claimStatus.acceptedPartial'),
  REJECTED: _t('fps.claimStatus.rejected'),
  DISMISSED: _t('fps.claimStatus.dismissed'),

  INCORRECT: _t('fps.claimStatus.incorrect'),
});

export const RECOURSE_REASONS: Record<RecourseReason, string> = {
  PAYE: _t('recourse.reason.PAYE'),
  CESSION: _t('recourse.reason.CESSION'),
  DISPENSE: _t('recourse.reason.DISPENSE'),
  DESTRUCTION: _t('recourse.reason.DESTRUCTION'),
  VOL: _t('recourse.reason.VOL'),
  USURPATION: _t('recourse.reason.USURPATION'),
  AVANCE: _t('recourse.reason.AVANCE'),
  AUTRE: _t('recourse.reason.AUTRE'),
};

export const RECOURSE_DECISION: Record<RecourseUpdateStatus, string> = {
  ACCEPTED: _t('recourse.decision.ACCEPTED'),
  REFUSED: _t('recourse.decision.REFUSED'),
  INCORRECT: _t('recourse.decision.INCORRECT'),
  IS_WAITING: _t('recourse.decision.IS_WAITING'),
};

export const CITY_RECOURSE_REASON: Record<CityRecourseReason, string> = {
  A_VALID_PAYMENT: _tg('tefps.filters.rapo.cityReasons.A_VALID_PAYMENT'),
  A_VEHICLE_SOLD: _tg('tefps.filters.rapo.cityReasons.A_VEHICLE_SOLD'),
  A_PLATE_FRAUD: _tg('tefps.filters.rapo.cityReasons.A_PLATE_FRAUD'),
  A_WRONG_PRICE: _tg('tefps.filters.rapo.cityReasons.A_WRONG_PRICE'),
  A_INVALID_TIME: _tg('tefps.filters.rapo.cityReasons.A_INVALID_TIME'),
  A_WAS_FREE: _tg('tefps.filters.rapo.cityReasons.A_WAS_FREE'),
  A_ERRONEOUS: _tg('tefps.filters.rapo.cityReasons.A_ERRONEOUS'),
  A_INCOMPLET: _tg('tefps.filters.rapo.cityReasons.A_INCOMPLET'),
  A_OTHERS_GOOD_WILL: _tg('tefps.filters.rapo.cityReasons.A_OTHERS_GOOD_WILL'),
  A_OTHERS: _tg('tefps.filters.rapo.cityReasons.A_OTHERS'),
  I_NOT_RECOMMENDED: _tg('tefps.filters.rapo.cityReasons.I_NOT_RECOMMENDED'),
  I_WRONG_FORM: _tg('tefps.filters.rapo.cityReasons.I_WRONG_FORM'),
  I_NO_CLAIM_REASON: _tg('tefps.filters.rapo.cityReasons.I_NO_CLAIM_REASON'),
  I_DELAY_OVER: _tg('tefps.filters.rapo.cityReasons.I_DELAY_OVER'),
  I_COMPLETION_PERIOD_OVER: _tg(
    'tefps.filters.rapo.cityReasons.I_COMPLETION_PERIOD_OVER'
  ),
  I_OTHERS: _tg('tefps.filters.rapo.cityReasons.I_OTHERS'),
  R_NON_CONVINCING: _tg('tefps.filters.rapo.cityReasons.R_NON_CONVINCING'),
  R_JUSTIFIED_FINE: _tg('tefps.filters.rapo.cityReasons.R_JUSTIFIED_FINE'),
  R_OTHERS: _tg('tefps.filters.rapo.cityReasons.R_OTHERS'),
};

export const RECOURSE_EMAIL_TEMPLATE_FIELDS: Array<{
  field: string;
  legend: string;
}> = [
  { field: 'civilite', legend: _tg('field.human.gender') },
  {
    field: 'date_rapo',
    legend: _t('recourse.template.field.date_rapo'),
  },
  {
    field: 'date_demande_complement',
    legend: _t('recourse.template.field.date_demande_complement'),
  },
  { field: 'motif_rapo', legend: _t('recourse.template.field.motif_rapo') },
  {
    field: 'raison_reponse',
    legend: _t('recourse.template.field.raison_reponse'),
  },
  {
    field: 'prenom_nom_validateur',
    legend: _t('recourse.template.field.prenom_nom_validateur'),
  },
  {
    field: 'nom_prenom_utilisateur_createur',
    legend: _t('recourse.template.field.nom_prenom_utilisateur_createur'),
  },
  {
    field: 'matricule_validateur',
    legend: _t('recourse.template.field.matricule_validateur'),
  },
  {
    field: 'immatriculation',
    legend: _tg('field.vehicle.license'),
  },
  { field: 'numero_rapo', legend: _t('recourse.template.field.numero_rapo') },
  { field: 'numero_fps', legend: _t('recourse.template.field.numero_fps') },
  {
    field: 'prix_fps',
    legend: _t('recourse.template.field.prix_fps'),
  },
  { field: 'date_controle', legend: _tg('field.date.fpsDate') },
  {
    field: 'heure_controle',
    legend: _t('recourse.template.field.heure_controle'),
  },
  {
    field: 'numero_adresse_controle',
    legend: _t('recourse.template.field.numero_adresse_controle'),
  },
  {
    field: 'type_rue_adresse_controle',
    legend: _tg('field.address.streetType_2'),
  },
  {
    field: 'nom_rue_adresse_controle',
    legend: _tg('field.address.streetName'),
  },
  {
    field: 'lieu_dit_adresse_controle',
    legend: _t('recourse.template.field.lieu_dit_adresse_controle'),
  },
  { field: 'nom_ville', legend: _t('recourse.template.field.nom_ville') },
  {
    field: 'nom_prenom_redacteur',
    legend: _t('recourse.template.field.nom_prenom_redacteur'),
  },
  {
    field: 'posting_date',
    legend: _t('recourse.template.field.posting_date'),
  },
];
export const RECOURSE_STATUSES_FILTER_OPTIONS: ValueLabel[] = [
  {
    value: 'IS_TO_PROCESS',
    label: _tg('tefps.filters.rapo.statuses.isToProcess'),
  },
  {
    value: 'IS_WAITING',
    label: _tg('tefps.filters.rapo.statuses.isWaiting'),
  },
  {
    value: 'IS_WAITING_COMPLEMENT',
    label: _tg('tefps.filters.rapo.statuses.isWaitingComplement'),
  },
  {
    value: 'ONGOING_VALIDATION',
    label: _tg('tefps.filters.rapo.statuses.ongoingValidation'),
  },
  {
    value: 'ACCEPTED',
    label: _tg('tefps.filters.rapo.statuses.accepted'),
  },
  {
    value: 'REJECTED',
    label: _tg('tefps.filters.rapo.statuses.rejected'),
  },
  {
    value: 'AUTO_REFUSED',
    label: _tg('tefps.filters.rapo.statuses.autoRefused'),
  },
  {
    value: 'INCORRECT',
    label: _tg('tefps.filters.rapo.statuses.irrecevable'),
  },
];

export const RECOURSE_SOURCES_FILTER_OPTIONS: ValueLabel[] = [
  {
    value: 'AGENT',
    label: _tg('tefps.filters.rapo.sources.AGENT'),
  },
  {
    value: 'OFFENDER',
    label: _tg('tefps.filters.rapo.sources.OFFENDER'),
  },
];

export const POSTAL_STATUSES_FILTER_OPTIONS: ValueLabel[] = [
  { value: 'MISSING', label: _t('recourse.postalStatus.MISSING') },
  {
    value: 'SENDING_COMPLEMENT',
    label: _t('recourse.postalStatus.SENDING_COMPLEMENT'),
  },
  {
    value: 'SENT_COMPLEMENT',
    label: _t('recourse.postalStatus.SENT_COMPLEMENT'),
  },
  {
    value: 'SENDING_VALIDATION',
    label: _t('recourse.postalStatus.SENDING_VALIDATION'),
  },
  {
    value: 'SENT_VALIDATION',
    label: _t('recourse.postalStatus.SENT_VALIDATION'),
  },
  {
    value: 'ERROR',
    label: _t('recourse.postalStatus.ERROR'),
  },
  { value: 'WRONG_ADDRESS', label: _t('recourse.postalStatus.WRONG_ADDRESS') },
];

export const REQUEST_REASONS_FILTER_OPTIONS: ValueLabel[] = [
  { value: 'PAYE', label: _tg('tefps.filters.rapo.reasons.paid') },
  {
    value: 'CESSION',
    label: _tg('tefps.filters.rapo.reasons.sold'),
  },
  { value: 'DISPENSE', label: _tg('tefps.filters.rapo.reasons.dispensed') },
  { value: 'DESTRUCTION', label: _tg('tefps.filters.rapo.reasons.destructed') },
  {
    value: 'VOL',
    label: _tg('tefps.filters.rapo.reasons.theft'),
  },
  { value: 'USURPATION', label: _tg('tefps.filters.rapo.reasons.usurpation') },
  { value: 'AVANCE', label: _tg('tefps.filters.rapo.reasons.apaInvalid') },
  {
    value: 'AUTRE',
    label: _tg('tefps.filters.rapo.reasons.other'),
  },
];

export const RESPONSE_REASONS_FILTER_OPTIONS: ValueLabel[] = [
  {
    value: 'A_VALID_PAYMENT',
    label: _tg('tefps.filters.rapo.cityReasons.A_VALID_PAYMENT'),
  },
  {
    value: 'A_VEHICLE_SOLD',
    label: _tg('tefps.filters.rapo.cityReasons.A_VEHICLE_SOLD'),
  },
  {
    value: 'A_PLATE_FRAUD',
    label: _tg('tefps.filters.rapo.cityReasons.A_PLATE_FRAUD'),
  },
  {
    value: 'A_WRONG_PRICE',
    label: _tg('tefps.filters.rapo.cityReasons.A_WRONG_PRICE'),
  },
  {
    value: 'A_INVALID_TIME',
    label: _tg('tefps.filters.rapo.cityReasons.A_INVALID_TIME'),
  },
  {
    value: 'A_WAS_FREE',
    label: _tg('tefps.filters.rapo.cityReasons.A_WAS_FREE'),
  },
  {
    value: 'A_ERRONEOUS',
    label: _tg('tefps.filters.rapo.cityReasons.A_ERRONEOUS'),
  },
  {
    value: 'A_INCOMPLET',
    label: _tg('tefps.filters.rapo.cityReasons.A_INCOMPLET'),
  },
  {
    value: 'A_OTHERS_GOOD_WILL',
    label: _tg('tefps.filters.rapo.cityReasons.A_OTHERS_GOOD_WILL'),
  },
  { value: 'A_OTHERS', label: _tg('tefps.filters.rapo.cityReasons.A_OTHERS') },
  {
    value: 'I_NOT_RECOMMENDED',
    label: _tg('tefps.filters.rapo.cityReasons.I_NOT_RECOMMENDED'),
  },
  {
    value: 'I_WRONG_FORM',
    label: _tg('tefps.filters.rapo.cityReasons.I_WRONG_FORM'),
  },
  {
    value: 'I_NO_CLAIM_REASON',
    label: _tg('tefps.filters.rapo.cityReasons.I_NO_CLAIM_REASON'),
  },
  {
    value: 'I_DELAY_OVER',
    label: _tg('tefps.filters.rapo.cityReasons.I_DELAY_OVER'),
  },
  {
    value: 'I_COMPLETION_PERIOD_OVER',
    label: _tg('tefps.filters.rapo.cityReasons.I_COMPLETION_PERIOD_OVER'),
  },
  {
    value: 'I_OTHERS',
    label: _tg('tefps.filters.rapo.cityReasons.I_OTHERS'),
  },
  {
    value: 'R_NON_CONVINCING',
    label: _tg('tefps.filters.rapo.cityReasons.R_NON_CONVINCING'),
  },
  {
    value: 'R_JUSTIFIED_FINE',
    label: _tg('tefps.filters.rapo.cityReasons.R_JUSTIFIED_FINE'),
  },
  { value: 'R_OTHERS', label: _tg('tefps.filters.rapo.cityReasons.R_OTHERS') },
];
