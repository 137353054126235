import React, { useState } from 'react';
import { Dialog, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import {
  OrderPrivateDTO,
  MailTemplateDTO,
  MailTypeOrder,
} from '@cvfm-front/tefps-types';
import {
  makeClaimUpdate,
  MakeClaimUpdateParams,
  renderClaimMailTemplate,
} from 'api/cvfm-core-subscription/order';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalMakeClaimProps = {
  open: boolean;
  order: OrderPrivateDTO;
  onCancel: () => void;
  onConfirm?: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalMakeClaim = ({
  open,
  order,
  onCancel,
  onConfirm,
}: OrderDetailModalMakeClaimProps): JSX.Element => {
  const [renderEmail, setRenderEmail] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [mailTemplate, setMailTemplate] = useState<MailTemplateDTO>(
    {} as MailTemplateDTO
  );

  const onCancelAction = () => {
    onCancel();
  };

  const getUpdateMakeClaimParams = (orderId: string): MakeClaimUpdateParams => {
    return {
      orderId,
      mailTemplate: order ? mailTemplate : undefined,
      message: reason,
      force: false,
    };
  };

  const onConfirmAction = () => {
    const params = getUpdateMakeClaimParams(order.orderId);
    const updatePromise = makeClaimUpdate(params);
    if (onConfirm) {
      onConfirm(updatePromise);
    }
  };

  const onConfirmMessage = () => {
    if (order?.orderId) {
      void renderClaimMailTemplate(
        order.orderId,
        reason,
        MailTypeOrder.ORDER_MAKE_CLAIM
      ).then(result => {
        setMailTemplate(result);
        setRenderEmail(true);
      });
    } else {
      console.error('order is undefined');
    }
  };

  const handleChangeMail = (_e: never, text: string) => {
    setMailTemplate(mailTmpl => ({ ...mailTmpl, mail: text }));
  };

  const handleChangeReason = (_e: never, text: string) => {
    setReason(text);
  };

  const renderEmailForm = (): JSX.Element => {
    return (
      <>
        <div className="order-detail_row">
          <TextField
            multiLine
            disabled
            rows={5}
            rowsMax={10}
            fullWidth
            name="order-make-claim-mail"
            floatingLabelText={_t('sent_email')}
            onChange={handleChangeMail}
            value={mailTemplate.mail || ''}
          />
        </div>
      </>
    );
  };

  const renderCommentForm = (): JSX.Element => {
    return (
      <>
        <div className="order-detail_row">
          <TextField
            fullWidth
            multiLine
            rows={5}
            rowsMax={10}
            name="order-make-claim-reason"
            floatingLabelText={_t('fields.notifySubscriber')}
            onChange={handleChangeReason}
            value={reason || ''}
          />
        </div>
      </>
    );
  };

  const onClickConfirm = (): void => {
    if (renderEmail) {
      onConfirmAction();
    } else {
      onConfirmMessage();
    }
  };

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-make-claim-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-make-claim-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onClickConfirm}
    />,
  ];

  const getTitle = (): string => {
    return _t('title');
  };

  return (
    <Dialog
      title={getTitle()}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      {renderEmail && renderEmailForm()}
      {!renderEmail && renderCommentForm()}
    </Dialog>
  );
};

export default OrderDetailModalMakeClaim;
