import React from 'react';
import { useParams } from 'react-router-dom';
import FlatButton from 'material-ui/FlatButton';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { RecourseProposalDTO } from 'api/recourse/types';
import Price from 'commons/Price';
import { getResponseDownloadUrl } from 'api/recourse';
import Separator from 'commons/Separator';
import {
  CITY_RECOURSE_REASON,
  RECOURSE_DECISION,
} from 'tefps/RecoursesV2/utils/translationUtils';
import { openNewAuthentifiedTab } from 'api/helpers';

const { _t, _tg } = window.loadTranslations(__filename);

type ProposalIssueProps = {
  proposal: RecourseProposalDTO;
  index: number;
};

const ProposalIssue = ({
  proposal,
  index,
}: ProposalIssueProps): JSX.Element => {
  const { recourseId } = useParams<{ recourseId: string }>();

  const openPdfUrl = () => {
    const url = getResponseDownloadUrl(recourseId, index);
    openNewAuthentifiedTab(url);
  };

  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.date')}
            value={<Date datetime={proposal.datetime} withTime={false} />}
          />
          <FakeInputBlock
            title={_tg('field.agent.id')}
            value={proposal.agent?.agentId ?? _t('field.noAgent')}
          />
          <FakeInputBlock
            title={_tg('field.agent.agent')}
            value={proposal.agent?.name ?? _t('field.noAgent')}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_t('field.decision')}
            value={RECOURSE_DECISION[proposal.status]}
          />
          <FakeInputBlock
            title={_t('field.reason')}
            value={CITY_RECOURSE_REASON[proposal.cityReason]}
          />
          {proposal.status === 'ACCEPTED' && (
            <FakeInputBlock
              title={_t('field.newFpsPrice')}
              value={<Price price={proposal.newFpsPrice} />}
            />
          )}
        </DataBoxItem>
      </DataBoxItemWrapper>
      <div className="text-center">
        <FlatButton
          onClick={openPdfUrl}
          primary
          label={_t('field.pdfPreview')}
        />
      </div>
    </div>
  );
};

export default ProposalIssue;
