import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CircularProgress } from 'material-ui';

import {
  ProductPrivateDTO,
  ProductType,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import Sidebar from 'commons/SidebarV2';
import {
  getProductById,
  patchProductById,
  searchProducts,
} from 'api/cvfm-core-subscription/product';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { fetchZoning } from 'api/pricing';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { CityOrganizationDTO } from 'api/organizations/types';
import { fetchOrganizations } from 'api/organizations';
import { getConfigState } from 'config/duck';

import { computeProductDiffPatchList } from './ProductDiffPatch';
import ProductDetail from './ProductDetail';

import './ProductDetailPage.css';
import Watchings from 'commons/Watchings';

type ProductDetailPageReduxProps = {
  canEdit: boolean;
  organizationFilterEnabled: boolean;
};

const { _t } = window.loadTranslations(__filename);

type ProductDetailPageProps = ProductDetailPageReduxProps &
  RouteComponentProps<{ productId: string }>;

const ProductDetailPage = ({
  canEdit,
  organizationFilterEnabled,
  match,
  history,
}: ProductDetailPageProps): JSX.Element => {
  const { productId } = match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [product, setProduct] = useState<ProductPrivateDTO>();
  const [zones, setZones] = useState<Array<ZoneDTO>>();
  const [bundleProducts, setBundleProducts] = useState<
    Array<ProductPrivateDTO>
  >();
  const [eligibleProducts, setEligibleProducts] = useState<
    Array<ProductPrivateDTO>
  >();
  const [organizations, setOrganizations] = useState<
    Array<CityOrganizationDTO>
  >();

  const setMessage = useSnackbar();

  function fetchProduct() {
    getProductById(productId)
      .then(productFetched => {
        setProduct(productFetched);
        setError(undefined);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function loadZoning(): void {
    fetchZoning(FETCH_LIGHT_ZONING_CONFIG)
      .then(fetchedZones => {
        setZones(fetchedZones.zones);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function loadBundleProducts(): void {
    searchProducts({ activeOnly: true, productType: ProductType.BUNDLE })
      .then(products => {
        setBundleProducts(products);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function loadEligiblityProducts(): void {
    searchProducts({ activeOnly: true, productType: ProductType.ELIGIBILITY })
      .then(products => {
        setEligibleProducts(products);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function loadOrganizations(): void {
    fetchOrganizations(false, 'fps')
      .then(orgs => {
        setOrganizations(orgs);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function handleReturn() {
    history.goBack();
  }

  function handleConfirm(
    updatedProduct: ProductPrivateDTO,
    callback: () => void
  ): void {
    if (!product) {
      return; // Does not happen
    }
    const patches = computeProductDiffPatchList(product, updatedProduct);
    if (patches.length) {
      patchProductById(productId, patches)
        .then(() => {
          history.goBack();
          setMessage(_t('patch.success', { product: product.name }));
          callback();
        })
        .catch(err => {
          const errTyped = err as Error;
          setMessage(_t('patch.error', { error: errTyped.message }));
        });
    } else {
      callback();
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchProduct();
    loadZoning();
    loadBundleProducts();
    loadEligiblityProducts();
    loadOrganizations();
  }, [productId]);

  useEffect(() => {
    if (
      product &&
      zones &&
      bundleProducts &&
      eligibleProducts &&
      organizations
    ) {
      setLoading(false);
    }
  }, [product, zones, bundleProducts, eligibleProducts, organizations]);

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock error={error} />
      </FlexCenter>
    );
  }

  return (
    <div className="product-detail-page_component">
      <Sidebar />
      <div className="product-detail-page_container">
        <div className="product-detail-page_content">
          {loading && (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          )}
          {!loading &&
            product &&
            zones &&
            bundleProducts &&
            eligibleProducts &&
            organizations && (
              <ProductDetail
                product={product}
                zones={zones}
                bundleProducts={bundleProducts}
                eligibilityProducts={eligibleProducts}
                organizations={organizations}
                organizationFilterEnabled={organizationFilterEnabled}
                canEdit={canEdit}
                onReturnAction={handleReturn}
                onConfirmAction={handleConfirm}
              />
            )}
        </div>
      </div>
      <Watchings resourceType="AD" resourceId={productId} />
    </div>
  );
};

function mapStateToProps(state: InternalApiState): ProductDetailPageReduxProps {
  const { userInfo } = getApiState(state);
  const { organizationFilterEnabled } = getConfigState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
    organizationFilterEnabled,
  };
}

export default withRouter(connect(mapStateToProps)(ProductDetailPage));
