import React from 'react';

import { OrderTransfer } from '@cvfm-front/tefps-types';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';

import './OrderDetailPage.css';

const { _t } = window.loadTranslations(__filename);

type OrderDetailHistoryTransfer = {
  orderTrace: OrderTransfer;
};

const OrderDetailHistoryTransfer = ({
  orderTrace,
}: OrderDetailHistoryTransfer): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock title={_t('title.from')} value={orderTrace.from} />
      <FakeInputBlock title={_t('title.to')} value={orderTrace.to} />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryTransfer;
