import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  Checkbox,
  SelectField,
  MenuItem,
  TextField,
} from 'material-ui';
import { connect } from 'react-redux';
import LinearProgress from 'material-ui/LinearProgress';

import BoButton from 'facade/BoButton';
import {
  MailTemplateDTO,
  MailTypeSubscriberMassAction,
  OrderMassUpdateMap,
  OrderMassUpdateMapValue,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import { translateMailType } from 'commons/Mail/utils';
import { fetchMailTemplate } from 'api/mail';
import { TXT_RED } from 'theme';
import { sendMassCommunication } from 'api/cvfm-core-subscription/order';
import { highlightDownloads, updateDownloadsList } from 'Topbar/Download/duck';

import OrderFilterService from './OrderFilterService';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalCommunicationReduxProps = {};

type OrderDetailModalCommunicationProps = {
  isOpen: boolean;
  setIsOpenCommunicationModal: Dispatch<SetStateAction<boolean>>;
  selectedOrders: OrderMassUpdateMap;
  onClose: () => void;
  refreshDownloadsList: () => void;
  highlightDownloadsIcon: () => void;
};

const OrderDetailModalCommunication = ({
  isOpen,
  setIsOpenCommunicationModal,
  selectedOrders,
  onClose,
  refreshDownloadsList,
  highlightDownloadsIcon,
}: OrderDetailModalCommunicationProps): JSX.Element => {
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [isAllSettled, setIsAllSettled] = useState<boolean>(false);

  const [mailType, setMailType] = useState<MailTypeSubscriberMassAction | null>(
    null
  );
  const [mailTemplate, setMailTemplate] = useState<MailTemplateDTO | null>(
    null
  );
  const [sendOnlyOneEmailPerUser, setSendOnlyOneEmailPerUser] = useState<
    boolean
  >(true);

  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState<boolean>(false);

  const onCloseModal = () => {
    setIsLoadingProgress(false);
    setIsOpenCommunicationModal(false);
    if (isAllSettled) {
      OrderFilterService.loadOrders();
    }
    setIsAllSettled(false);
    setIsOpenConfirm(false);
    setIsOpenSuccess(false);
    onClose();
  };

  const loadMailTemplate = async (mailType: MailTypeSubscriberMassAction) => {
    const loadedMailTemplate = await fetchMailTemplate({
      type: mailType,
      organizationId: null,
      resourceId: null,
    });
    setMailTemplate(loadedMailTemplate);
  };

  const onSelectMailType = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    v: MailTypeSubscriberMassAction
  ) => {
    loadMailTemplate(v);
    setMailType(v);
  };

  const handleChangeMailTemplateMail = (e: never, text: string) => {
    setMailTemplate(prevMailTemplate =>
      prevMailTemplate
        ? {
            ...prevMailTemplate,
            mail: text,
          }
        : null
    );
  };

  const onCheckSendOnlyOneEmailPerUser = (
    e: React.MouseEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSendOnlyOneEmailPerUser(checked);
  };

  const onClickSendMassCommunication = async () => {
    if (mailTemplate !== null) {
      setIsLoadingProgress(true);
      const ordersIds: string[] = Object.entries(selectedOrders).map(
        ([orderId]) => orderId
      );
      await sendMassCommunication(
        ordersIds,
        sendOnlyOneEmailPerUser,
        mailTemplate
      );
      highlightDownloadsIcon();
      setTimeout(() => {
        refreshDownloadsList();
        setIsAllSettled(true);
        setIsLoadingProgress(false);
        setIsOpenSuccess(true);
        setIsOpenConfirm(false);
      }, 2000);
    }
  };

  const getNumberOfCommunicationsToSend = (): number[] => {
    const allSubscribersIds = Object.values(selectedOrders).reduce<string[]>(
      (acc, order: OrderMassUpdateMapValue) => acc.concat(order.subscribersIds),
      []
    );

    if (sendOnlyOneEmailPerUser) {
      const uniqueSubscribers = new Set(allSubscribersIds);
      return [allSubscribersIds.length, uniqueSubscribers.size];
    }
    return [allSubscribersIds.length, allSubscribersIds.length];
  };
  const numberOfCommunicationsToSend = getNumberOfCommunicationsToSend();

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-communication-cancel"
      style={{ marginRight: 10 }}
      onClick={onCloseModal}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="order-communication-confirm"
      primary
      style={{ marginRight: 10 }}
      disabled={mailTemplate === null}
      onClick={() => setIsOpenConfirm(true)}
    />,
  ];

  const actionsConfirm = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-communication-cancel"
      style={{ marginRight: 10 }}
      onClick={() => setIsOpenConfirm(false)}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="order-communication-confirm"
      primary
      style={{ marginRight: 10 }}
      disabled={mailTemplate === null}
      onClick={onClickSendMassCommunication}
    />,
  ];

  return (
    <>
      <Dialog
        title={_t('title')}
        open={isOpen}
        actions={actions}
        titleClassName="order-detail-modal_title"
      >
        <SelectField
          floatingLabelText={_t('field.selectTemplate')}
          value={mailType}
          onChange={onSelectMailType}
          style={{ marginTop: '1.7em' }}
        >
          {Object.values(MailTypeSubscriberMassAction).map(type => (
            <MenuItem
              key={type}
              id={type}
              value={type}
              primaryText={translateMailType(type)}
            />
          ))}
        </SelectField>
        {mailType && mailTemplate && (
          <TextField
            floatingLabelText={_t('field.mailTemplateMail')}
            multiLine
            rows={5}
            rowsMax={10}
            fullWidth
            name="communication-mail"
            onChange={handleChangeMailTemplateMail}
            value={mailTemplate.mail || ''}
            style={{ marginTop: '1.7em' }}
          />
        )}
        <Checkbox
          label={_t('field.sendOnlyOneEmailPerUser')}
          checked={sendOnlyOneEmailPerUser}
          onCheck={onCheckSendOnlyOneEmailPerUser}
          style={{ marginTop: '1.7em' }}
        />
      </Dialog>
      <Dialog
        title={_t('titleConfirm')}
        open={isOpenConfirm}
        actions={actionsConfirm}
        titleClassName="order-detail-modal_title"
      >
        <p style={{ color: TXT_RED, marginTop: '1.7em' }}>
          {_t('textConfirm', {
            numberUsers: numberOfCommunicationsToSend[0],
            numberEmails: numberOfCommunicationsToSend[1],
          })}
        </p>
      </Dialog>
      <Dialog
        title={_t('header.titleProgress')}
        modal
        autoScrollBodyContent
        open={isLoadingProgress}
      >
        <LinearProgress />
      </Dialog>
      <Dialog
        title={_t('header.titleSuccess')}
        open={isOpenSuccess}
        actions={[
          <BoButton
            label={_tg('action.close')}
            key="order-communication-cancel"
            style={{ marginRight: 10 }}
            onClick={() => onCloseModal()}
          />,
        ]}
        titleClassName="order-detail-modal_title"
      >
        <p style={{ marginTop: '1.7em' }}>
          {_t('textSuccess', {
            numberUsers: Object.keys(selectedOrders).length,
          })}
        </p>
      </Dialog>
    </>
  );
};

function mapStateToProps(
  state: InternalApiState
): OrderDetailModalCommunicationReduxProps {
  return {};
}

export default connect(mapStateToProps, dispatch => ({
  refreshDownloadsList: () => dispatch(updateDownloadsList()),
  highlightDownloadsIcon: () => dispatch(highlightDownloads(1)),
}))(OrderDetailModalCommunication);
