import React, { FormEvent, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Checkbox, MenuItem, SelectField, TextField } from 'material-ui';

import { CityOrganizationDTO } from 'api/organizations/types';
import { ProductPrivateDTO, ProductType } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';

import './ProductDetailPage.css';
import ProductDetailSeparator from './commons/DetailSeparator';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailDescriptionReduxProps = {
  supportingDocumentGenerationEnabled: boolean;
};

type ProductDetailDescriptionProps = ProductDetailDescriptionReduxProps & {
  product: ProductPrivateDTO;
  organizations: Array<CityOrganizationDTO>;
  organizationFilterEnabled: boolean;
  canEdit: boolean;
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailDescription = ({
  product,
  organizations,
  organizationFilterEnabled,
  supportingDocumentGenerationEnabled,
  canEdit,
  onChange,
}: ProductDetailDescriptionProps): JSX.Element => {
  function getTypeTitle(): string {
    if (product.productType === ProductType.BUNDLE) {
      return _t('section.title.bundle');
    }

    return _t('section.title.eligibility');
  }

  function handleChangeOrg(e: SyntheticEvent, idx: number, newValue: string) {
    onChange('organizationId', newValue);
  }

  function handleChangeText(e: FormEvent<HTMLDataElement>, newValue: string) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      onChange(field, newValue);
    }
  }

  function handleCheck(e: React.MouseEvent<HTMLDataElement>, checked: boolean) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      onChange(field, checked);
    }
  }

  function handleChangeAddress(
    e: React.FormEvent<HTMLDataElement>,
    newValue: string
  ) {
    const optionField = e.currentTarget.dataset.field;
    const { organizationAddress } = product;
    if (optionField) {
      const newOrganizationAddress = {
        ...organizationAddress,
        [optionField]: newValue,
      };
      onChange('organizationAddress', newOrganizationAddress);
    }
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={getTypeTitle()} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <TextField
              fullWidth
              floatingLabelText={_t('field.name')}
              data-field="name"
              value={product.name}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </span>
          <span className="product-detail_cell-50">
            <TextField
              floatingLabelText={_t('field.productId')}
              value={product.productId}
              disabled
              fullWidth
            />
          </span>
        </div>
        {organizationFilterEnabled && (
          <div className="product-detail_section-content-row">
            <span className="product-detail_cell-100">
              <SelectField
                floatingLabelText={_t('field.organizationId')}
                floatingLabelFixed
                fullWidth
                value={product.organizationId}
                onChange={handleChangeOrg}
              >
                {organizations.map(org => (
                  <MenuItem
                    id={org.organizationId}
                    data-field="organizationId"
                    key={org.organizationId}
                    value={org.organizationId}
                    primaryText={org.name}
                  />
                ))}
              </SelectField>
            </span>
          </div>
        )}
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-25">
            <Checkbox
              id="private-order-enabled"
              className="product-detail_checkbox"
              label={_t('field.privateOrdersEnabled')}
              checked={product.privateOrdersEnabled}
              data-field="privateOrdersEnabled"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-25">
            <Checkbox
              id="public-order-enabled"
              className="product-detail_checkbox"
              label={_t('field.publicOrdersEnabled')}
              checked={product.publicOrdersEnabled}
              data-field="publicOrdersEnabled"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-25">
            <Checkbox
              id="product-detail"
              className="product-detail_checkbox"
              label={_t('field.paymentInvoiceEnabled')}
              checked={product.paymentInvoiceEnabled}
              data-field="paymentInvoiceEnabled"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          {supportingDocumentGenerationEnabled && (
            <span className="product-detail_cell-25">
              <Checkbox
                id="product-detail"
                className="product-detail_checkbox"
                label={_t('field.supportingDocumentEnabled')}
                checked={product.supportingDocumentEnabled}
                data-field="supportingDocumentEnabled"
                disabled={!canEdit}
                onCheck={handleCheck}
              />
            </span>
          )}
        </div>
        {product.supportingDocumentEnabled && (
          <div style={{ width: '100%' }}>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-50">
                {_t('field.supportingDocumentFormTitle')} :
              </span>
            </div>
            <span className="product-detail_cell-50">
              <TextField
                floatingLabelText={_t('field.supportingDocumentName')}
                floatingLabelFixed
                value={product.supportingDocumentName}
                data-field="supportingDocumentName"
                fullWidth
                onChange={handleChangeText}
              />
            </span>
          </div>
        )}
        {product.paymentInvoiceEnabled && (
          <div style={{ width: '100%' }}>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-50">
                {_t('field.responsibleOrganization')} :
              </span>
            </div>
            <span className="product-detail_cell-50">
              <TextField
                floatingLabelText={_tg('field.company.name')}
                floatingLabelFixed
                value={product.organizationName}
                data-field="organizationName"
                fullWidth
                onChange={handleChangeText}
              />
            </span>
            <Flex>
              <span className="product-detail_cell-25">
                <TextField
                  floatingLabelText={_tg('field.address.streetNumberBis')}
                  floatingLabelFixed
                  value={product.organizationAddress?.streetNumberBis}
                  data-field="streetNumberBis"
                  onChange={handleChangeAddress}
                  fullWidth
                />
              </span>
              <span className="product-detail_cell-25">
                <TextField
                  floatingLabelText={_tg('field.address.streetNumber')}
                  floatingLabelFixed
                  value={product.organizationAddress?.streetNumber}
                  data-field="streetNumber"
                  onChange={handleChangeAddress}
                  fullWidth
                />
              </span>
              <span className="product-detail_cell-50">
                <TextField
                  floatingLabelText={_tg('field.address.streetName')}
                  floatingLabelFixed
                  value={product.organizationAddress?.streetName}
                  data-field="streetName"
                  onChange={handleChangeAddress}
                  fullWidth
                />
              </span>
            </Flex>
            <div className="product-detail_section-content-row">
              <TextField
                floatingLabelText={_tg('field.address.complement')}
                floatingLabelFixed
                value={product.organizationAddress?.complement}
                data-field="complement"
                onChange={handleChangeAddress}
                fullWidth
              />
            </div>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-50">
                <TextField
                  floatingLabelText={_tg('field.address.postalCode')}
                  floatingLabelFixed
                  value={product.organizationAddress?.postalCode}
                  data-field="postalCode"
                  onChange={handleChangeAddress}
                  fullWidth
                />
              </span>
              <span className="product-detail_cell-50">
                <TextField
                  floatingLabelText={_tg('field.address.locality')}
                  floatingLabelFixed
                  value={product.organizationAddress?.locality}
                  data-field="locality"
                  onChange={handleChangeAddress}
                  fullWidth
                />
              </span>
            </div>
          </div>
        )}
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-100">
            <TextField
              floatingLabelText={_t('field.privateDescription')}
              data-field="privateDescription"
              value={product.privateDescription}
              disabled={!canEdit}
              multiLine
              rows={5}
              fullWidth
              onChange={handleChangeText}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-100">
            <TextField
              floatingLabelText={_t('field.publicDescription')}
              data-field="publicDescription"
              value={product.publicDescription}
              disabled={!canEdit}
              multiLine
              rows={5}
              fullWidth
              onChange={handleChangeText}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(
  state: InternalApiState
): ProductDetailDescriptionReduxProps {
  const { subscriptionConfigurationDTO } = getConfigState(state);
  return {
    supportingDocumentGenerationEnabled:
      subscriptionConfigurationDTO.supportingDocumentGenerationEnabled,
  };
}

export default connect(mapStateToProps)(ProductDetailDescription);
