import React from 'react';
import _cloneDeep from 'lodash.clonedeep';
import FileIcon from 'material-ui/svg-icons/content/content-paste';
import Delete from 'material-ui/svg-icons/action/delete';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, MenuItem, SelectField } from 'material-ui';

import { BKG_CYAN, TXT_RED, TEXT_COLOR_LIGHT } from 'theme';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { getEvidenceDirectUploadLink } from 'api/cvfm-core-subscription/subscriber';
import { uploadFileV2 } from 'api/mediaupload';
import { getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';
import { Files, Flex } from '@cvfm-front/commons-ui';
import {
  SubscriberMediaDTO,
  SubscriberDTO,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';

import {
  SubscriberRequiredProofs,
  SubscriberRequiredProofsType,
} from './OrderCreateTypes';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderCreateSubscriberRequiredProofsReduxProps = {
  watermarkText: string | null;
};

type OrderCreateSubscriberRequiredProofsProps = {
  subscriberRequiredProofs: SubscriberRequiredProofs[];
  subscriber: SubscriberDTO;
  setSubscriberRequiredProofs: React.Dispatch<
    React.SetStateAction<SubscriberRequiredProofs[]>
  >;
  watermarkText: string | null;
} & OrderCreateSubscriberRequiredProofsReduxProps;

function OrderCreateSubscriberRequiredProofs({
  subscriberRequiredProofs,
  subscriber,
  setSubscriberRequiredProofs,
  watermarkText,
}: OrderCreateSubscriberRequiredProofsProps): JSX.Element {
  const setMessage = useSnackbar();

  const choosableEvidencesBySubscriberProof = (
    element: SubscriberRequiredProofs,
    subscriber: SubscriberDTO
  ): SubscriberMediaDTO[] => {
    return subscriber.evidences.filter(
      evidence => !element.documents.map(doc => doc.id).includes(evidence.id)
    );
  };

  const computeAllVehicles = (
    subscriber: SubscriberDTO
  ): SubscriberVehicle[] => {
    const vehicles: SubscriberVehicle[] = [];
    subscriber?.professionalProfile?.vehicles?.forEach(vehicle =>
      vehicles.push(vehicle)
    );
    subscriber?.personalProfile?.vehicles?.forEach(vehicle =>
      vehicles.push(vehicle)
    );
    return vehicles;
  };

  const choosableVehicleDocumentsBySubscriberProof = (
    element: SubscriberRequiredProofs,
    subscriber: SubscriberDTO
  ): SubscriberMediaDTO[] => {
    const choosableVehiclesDocuments: SubscriberMediaDTO[] = [];
    computeAllVehicles(subscriber)
      .filter(vehicle => vehicle.id !== element.id)
      .forEach(vehicle => {
        vehicle.registrationDocuments?.documents?.forEach(vehicleDocument => {
          if (
            !element.documents.map(doc => doc.id).includes(vehicleDocument.id)
          ) {
            choosableVehiclesDocuments.push(vehicleDocument);
          }
        });
      });
    return choosableVehiclesDocuments;
  };

  const addDocumentOnSubscriberRequiredProofsFromSelection = (
    element: SubscriberRequiredProofs,
    documentId: string,
    subscriber: SubscriberDTO,
    subscriberRequiredProofsType: SubscriberRequiredProofsType
  ): void => {
    const document =
      subscriberRequiredProofsType ===
      SubscriberRequiredProofsType.SubscriberProfileProof
        ? subscriber.evidences.find(e => e.id === documentId)
        : choosableVehicleDocumentsBySubscriberProof(element, subscriber).find(
            d => d.id === documentId
          );
    if (document && !element.documents.some(doc => doc.id === document.id)) {
      const newSubscriberRequiredProofs: SubscriberRequiredProofs[] = _cloneDeep(
        subscriberRequiredProofs
      );
      newSubscriberRequiredProofs.forEach(item => {
        if (element.id === item.id) {
          item.documents.push(document);
        }
      });
      setSubscriberRequiredProofs(newSubscriberRequiredProofs);
    }
  };

  const removeDocumentOnSubscriberRequiredProofs = (
    id: string,
    documentId: string
  ): void => {
    const newSubscriberRequiredProofs: SubscriberRequiredProofs[] = _cloneDeep(
      subscriberRequiredProofs
    );
    newSubscriberRequiredProofs.forEach(element => {
      if (element.id === id) {
        element.documents = element.documents.filter(
          document => document.id !== documentId
        );
      }
    });
    setSubscriberRequiredProofs(newSubscriberRequiredProofs);
  };

  const addDocumentOnSubscriberRequiredProofs = async (
    id: string,
    file: File,
    subscriber: SubscriberDTO
  ): Promise<void> => {
    const directUploadDTO = await getEvidenceDirectUploadLink(
      subscriber.subscriberId,
      file.type
    );

    void uploadFileV2(
      directUploadDTO.signedUploadUrl,
      file,
      watermarkText,
      undefined,
      _err => {
        setMessage(_tg('commons.error'));
      }
    )
      .catch(err => setMessage((err as Error)?.message || _tg('commons.error')))
      .then(_z => {
        const document: SubscriberMediaDTO = {
          id: uuidv4(),
          name: file.name,
          url: directUploadDTO.fileUrn,
          dateCreated: new Date().toISOString(),
        };
        const newSubscriberRequiredProofs: SubscriberRequiredProofs[] = _cloneDeep(
          subscriberRequiredProofs
        );
        newSubscriberRequiredProofs.forEach(element => {
          if (element.id === id) {
            element.documents.push(document);
          }
        });
        setSubscriberRequiredProofs(newSubscriberRequiredProofs);
      });
  };

  const toggleManualCheckOnSubscriberRequiredProofs = (id: string): void => {
    const newSubscriberRequiredProofs: SubscriberRequiredProofs[] = _cloneDeep(
      subscriberRequiredProofs
    );
    newSubscriberRequiredProofs.forEach(element => {
      if (element.id === id) {
        element.manuallyChecked = !element.manuallyChecked;
      }
    });
    setSubscriberRequiredProofs(newSubscriberRequiredProofs);
  };

  const essentialEvidencesTitle = `${_tg(
    'field.evidence.essentialEvidences'
  )} *`;
  const vehiclesSelectedTitle = `${_tg('field.vehicles.selectedVehicles')} *`;
  const titlesPositions = {
    [essentialEvidencesTitle]: -1,
    [vehiclesSelectedTitle]: -1,
  };
  subscriberRequiredProofs.forEach((element, index) => {
    if (
      element.type === SubscriberRequiredProofsType.SubscriberProfileProof &&
      titlesPositions[essentialEvidencesTitle] === -1
    ) {
      titlesPositions[essentialEvidencesTitle] = index;
    }
    if (
      element.type === SubscriberRequiredProofsType.SubscriberVehicleProof &&
      titlesPositions[vehiclesSelectedTitle] === -1
    ) {
      titlesPositions[vehiclesSelectedTitle] = index;
    }
  });

  return (
    <>
      <div>
        <br />
        <div style={{ padding: '0 0.5%' }}>
          <Flex flexDirection="column" gap={35} width100>
            {subscriberRequiredProofs.map((element, index) => {
              const choosableEvidences = choosableEvidencesBySubscriberProof(
                element,
                subscriber
              );
              const choosableVehicleDocuments = choosableVehicleDocumentsBySubscriberProof(
                element,
                subscriber
              );
              return (
                <Flex
                  className="product-subscriber-proofs"
                  flexDirection="row"
                  gap={10}
                  flexWrap="wrap"
                  key={element.id}
                >
                  {[essentialEvidencesTitle, vehiclesSelectedTitle].map(
                    (title, titleIndex) => {
                      return (
                        titlesPositions[title] !== -1 && (
                          <div
                            key={`${title}-${titleIndex}`}
                            style={{ width: '100%', fontWeight: 'bold' }}
                          >
                            {titlesPositions[title] === index && (
                              <span>{title}</span>
                            )}
                          </div>
                        )
                      );
                    }
                  )}
                  <div style={{ width: '15%', flex: 1, alignSelf: 'center' }}>
                    {element.title}{' '}
                    {element.vehicle?.plate ? (
                      <div>{element.vehicle.plate} *</div>
                    ) : (
                      <>*</>
                    )}
                  </div>
                  <div style={{ width: '70%' }}>
                    <Flex flexDirection="column" gap={4} alignItems="center">
                      {element.documents.length === 0 &&
                        !element.manuallyChecked && (
                          <div style={{ color: TXT_RED }}>
                            {_tg(
                              'tefps.subscription.orderCreatePage.noEvidenceIsAdded'
                            )}
                          </div>
                        )}
                      {element.documents.map((document, documentIndex) => (
                        <div key={`${document.id}-${documentIndex}`}>
                          <a
                            href={document.contentUrl}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: element.manuallyChecked
                                ? TEXT_COLOR_LIGHT
                                : BKG_CYAN,
                            }}
                          >
                            <FileIcon
                              style={{
                                width: 20,
                                height: 30,
                                verticalAlign: 'middle',
                                marginRight: '8px',
                              }}
                              color={
                                element.manuallyChecked
                                  ? TEXT_COLOR_LIGHT
                                  : BKG_CYAN
                              }
                            />
                            &nbsp;&nbsp;
                            {document.name || `N°${index}`}
                          </a>
                          &nbsp;&nbsp;
                          <Delete
                            style={{
                              cursor: element.manuallyChecked
                                ? 'auto'
                                : 'pointer',
                              verticalAlign: 'middle',
                            }}
                            color={
                              element.manuallyChecked
                                ? TEXT_COLOR_LIGHT
                                : TXT_RED
                            }
                            onClick={() => {
                              if (!element.manuallyChecked) {
                                removeDocumentOnSubscriberRequiredProofs(
                                  element.id,
                                  document.id
                                );
                              }
                            }}
                          />
                          <br />
                        </div>
                      ))}
                      <Files
                        label={_tg('action.addNewFiles')}
                        withDropzone={false}
                        maxSize={2000000}
                        maxFiles={50}
                        onError={err => setMessage(err)}
                        onChange={(file: File) => {
                          if (
                            (element.type ===
                              SubscriberRequiredProofsType.SubscriberProfileProof &&
                              element.documents.length < 5) ||
                            (element.type ===
                              SubscriberRequiredProofsType.SubscriberVehicleProof &&
                              element.documents.length < 4)
                          ) {
                            void addDocumentOnSubscriberRequiredProofs(
                              element.id,
                              file,
                              subscriber
                            );
                          }
                        }}
                        disabled={
                          (element.type ===
                            SubscriberRequiredProofsType.SubscriberProfileProof &&
                            element.documents.length >= 5) ||
                          (element.type ===
                            SubscriberRequiredProofsType.SubscriberVehicleProof &&
                            element.documents.length >= 4) ||
                          element.manuallyChecked
                        }
                        multiple={false}
                        customFileRemove
                        hideFiles
                        blackIcon
                      />
                      {choosableEvidences.length > 0 ? (
                        <SelectField
                          id={`select-subscriber-evidence${element.id}`}
                          floatingLabelText={_t('hint')}
                          onChange={(_e: never, _idx: never, value: string) => {
                            addDocumentOnSubscriberRequiredProofsFromSelection(
                              element,
                              value,
                              subscriber,
                              SubscriberRequiredProofsType.SubscriberProfileProof
                            );
                          }}
                          disabled={
                            element.manuallyChecked ||
                            (element.type ===
                              SubscriberRequiredProofsType.SubscriberProfileProof &&
                              element.documents.length >= 5) ||
                            (element.type ===
                              SubscriberRequiredProofsType.SubscriberVehicleProof &&
                              element.documents.length >= 4) ||
                            element.manuallyChecked
                          }
                          style={{ width: '20rem' }}
                        >
                          {choosableEvidences.map(evidence => (
                            <MenuItem
                              id={evidence.id}
                              key={`evidence-document-${evidence.id}`}
                              value={evidence.id}
                              primaryText={evidence.name}
                            />
                          ))}
                        </SelectField>
                      ) : (
                        <></>
                      )}
                      {element.type ===
                        SubscriberRequiredProofsType.SubscriberVehicleProof &&
                      choosableVehicleDocuments.length > 0 ? (
                        <SelectField
                          id={`select-vehicle-document${element.id}`}
                          floatingLabelText={_t('vehicleHint')}
                          onChange={(_e: never, _idx: never, value: string) => {
                            addDocumentOnSubscriberRequiredProofsFromSelection(
                              element,
                              value,
                              subscriber,
                              SubscriberRequiredProofsType.SubscriberVehicleProof
                            );
                          }}
                          disabled={
                            element.manuallyChecked ||
                            element.documents.length >= 4
                          }
                          style={{ width: '20rem' }}
                        >
                          {choosableVehicleDocuments.map(evidence => (
                            <MenuItem
                              id={evidence.id}
                              key={`vehicle-document-${evidence.id}`}
                              value={evidence.id}
                              primaryText={evidence.name}
                            />
                          ))}
                        </SelectField>
                      ) : (
                        <></>
                      )}
                    </Flex>
                  </div>
                  <div style={{ width: '15%' }}>
                    <Checkbox
                      label={_tg('tefps.subscription.checkedByAgent')}
                      id={element.path}
                      checked={element.manuallyChecked}
                      onCheck={() =>
                        toggleManualCheckOnSubscriberRequiredProofs(element.id)
                      }
                    />
                  </div>
                  <br />
                </Flex>
              );
            })}
          </Flex>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(
  state: InternalApiState
): OrderCreateSubscriberRequiredProofsReduxProps {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
}

export default connect(mapStateToProps)(OrderCreateSubscriberRequiredProofs);
