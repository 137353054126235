import React, { useState } from 'react';
import { Dialog, TextField } from 'material-ui';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  OrderDecision,
  OrderPrivateDTO,
  OrderTraceType,
  SubscriptionSource,
  MailTemplateDTO,
  OrderMassUpdateMap,
} from '@cvfm-front/tefps-types';
import {
  renderDecisionTemplate,
  takeDecisionUpdate,
  TakeDecisionUpdateParams,
} from 'api/cvfm-core-subscription/order';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';

import './OrderDetailPage.css';
import OrderFilterService from './OrderFilterService';
import OrderMassUpdateProcess from './OrderMassUpdateProcess';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalDecisionProps = {
  isOpen: boolean;
  order?: OrderPrivateDTO;
  selectedOrders?: OrderMassUpdateMap; // We assume that multiple orders = multiple action
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm?: (
    update: Promise<OrderPrivateDTO>,
    params: TakeDecisionUpdateParams
  ) => void;
  initialDecisionAccept: boolean;
};

const OrderDetailModalDecision = ({
  isOpen,
  order,
  selectedOrders,
  userInfo,
  onCancel,
  onConfirm,
  initialDecisionAccept,
}: OrderDetailModalDecisionProps): JSX.Element => {
  const [renderEmail, setRenderEmail] = useState<boolean>(false);
  const [comment, setComment] = useState<string | null>();
  const [reason, setReason] = useState<string>('');
  const [mailTemplate, setMailTemplate] = useState<MailTemplateDTO | null>(
    null
  );

  // for multiple orders case
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [isAllSettled, setIsAllSettled] = useState<boolean>(false);
  const [uploadProgressState, setUploadProgressState] = useState<number>(0);
  const [uploadErrorIds, setUploadErrorIds] = useState<
    { id: string; err: string }[]
  >([]);

  const onCancelAction = () => {
    setIsLoadingProgress(false);
    setUploadProgressState(0);
    setUploadErrorIds([]);
    onCancel();
    setComment(undefined);
    if (isAllSettled) {
      void OrderFilterService.loadOrders();
    }
    setIsAllSettled(false);
  };

  const getDecision = (): OrderDecision => {
    return {
      timestamp: new Date().toISOString(),
      traceType: order?.claim
        ? OrderTraceType.CLAIM_DECISION
        : OrderTraceType.DECISION,
      source: SubscriptionSource.BACK,
      agent: userInfo ? { ...userInfo } : null,
      accepted: initialDecisionAccept,
      message: reason,
      comment,
      subscriberId: null,
      massAction: selectedOrders !== undefined,
    } as OrderDecision;
  };

  const getUpdateDecisionParams = (
    orderId: string
  ): TakeDecisionUpdateParams => {
    return {
      orderId,
      decision: getDecision(),
      mailTemplate, // if null, backend will load the default
      force: false,
    } as TakeDecisionUpdateParams;
  };

  const updateSelectedOrdersDecisions = async (
    selectedOrders: OrderMassUpdateMap
  ): Promise<void> => {
    setIsLoadingProgress(true);

    /* eslint-disable no-restricted-syntax */
    for (const orderId of Object.keys(selectedOrders)) {
      /* eslint-disable no-await-in-loop */
      await takeDecisionUpdate(getUpdateDecisionParams(orderId))
        .then(response => {
          return response;
        })
        .catch(err => {
          const error = err as Error;
          setUploadErrorIds(errors => [
            ...errors,
            { id: orderId, err: error.message },
          ]);
          return error;
        })
        .finally(() => {
          // Sert à incrementer la barre de progression
          setUploadProgressState(past => past + 1);
        });
      /* eslint-enable no-await-in-loop */
    }
    /* eslint-enable no-restricted-syntax */
    setIsAllSettled(true);
    setIsLoadingProgress(false);
  };

  const onConfirmAction = () => {
    if (selectedOrders) {
      void updateSelectedOrdersDecisions(selectedOrders);
    } else if (order) {
      const params = getUpdateDecisionParams(order.orderId);
      const updatePromise = takeDecisionUpdate(params);
      if (onConfirm) {
        onConfirm(updatePromise, params);
      }
    } else {
      console.error('neither order nor orders are defined');
    }
  };

  const onConfirmComment = () => {
    if (order?.orderId) {
      void renderDecisionTemplate(
        order.orderId,
        initialDecisionAccept,
        reason,
        null
      ).then(mailTemplateResult => {
        setMailTemplate(mailTemplateResult);
        setRenderEmail(true);
      });
    } else {
      console.error('order is undefined');
    }
  };

  const handleChangeComment = (e: never, text: string) => {
    setComment(text);
  };

  const handleChangeReason = (e: never, text: string) => {
    setReason(text);
  };

  const renderEmailForm = (mailTemplate: MailTemplateDTO): JSX.Element => {
    return (
      <>
        <div className="order-detail_row">
          <TextField
            multiLine
            disabled
            rows={5}
            rowsMax={10}
            fullWidth
            name="order-decision-mail"
            floatingLabelText={_t('sent_email')}
            onChange={handleChangeComment}
            value={mailTemplate.mail || ''}
          />
        </div>
      </>
    );
  };

  const renderCommentForm = (): JSX.Element => {
    return (
      <>
        <div className="order-detail_row">
          <TextField
            fullWidth
            multiLine
            rows={5}
            rowsMax={10}
            name="order-decision-reason"
            floatingLabelText={
              initialDecisionAccept ? _t('reason_accept') : _t('reason_reject')
            }
            onChange={handleChangeReason}
            value={reason || ''}
          />
        </div>
        <div className="order-detail_row">
          <TextField
            multiLine
            rows={5}
            rowsMax={10}
            fullWidth
            name="order-decision-comment"
            floatingLabelText={_t('comment')}
            onChange={handleChangeComment}
            value={comment || ''}
          />
        </div>
      </>
    );
  };

  const onClickConfirm = (): void => {
    if (selectedOrders || renderEmail) {
      onConfirmAction();
    } else {
      onConfirmComment();
    }
  };

  if (selectedOrders) {
    <OrderMassUpdateProcess
      titleFail={_t(
        `header.${initialDecisionAccept ? 'accept' : 'reject'}.titleFail`
      )}
      titleSuccess={_t(
        `header.${initialDecisionAccept ? 'accept' : 'reject'}.titleSuccess`
      )}
      titleProgress={_t(
        `header.${initialDecisionAccept ? 'accept' : 'reject'}.titleProgress`
      )}
      isOpen={isOpen}
      isAllSettled={isAllSettled}
      isLoadingProgress={isLoadingProgress}
      uploadProgressState={uploadProgressState}
      uploadErrorIds={uploadErrorIds}
      max={Object.keys(selectedOrders).length}
      onCloseModal={onCancelAction}
    />;
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-decision-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-decision-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onClickConfirm}
    />,
  ];

  const getTitle = (): string => {
    const decisionType = order?.claim ? 'claim' : 'order';

    if (selectedOrders) {
      if (initialDecisionAccept) {
        return _t(`title.${decisionType}.accept_multiple`);
      }
      return _t(`title.${decisionType}.reject_multiple`);
    }

    if (initialDecisionAccept) {
      return _t(`title.${decisionType}.accept`);
    }
    return _t(`title.${decisionType}.reject`);
  };

  return (
    <Dialog
      title={getTitle()}
      open={isOpen}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      {mailTemplate && renderEmail && renderEmailForm(mailTemplate)}
      {!renderEmail && renderCommentForm()}
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalDecision);
