import React, { useMemo } from 'react';
import { Checkbox, MenuItem, SelectField } from 'material-ui';
import '../ProductDetailPage.css';
import { connect } from 'react-redux';

import { MailTypeOrder, ProductPrivateDTO } from '@cvfm-front/tefps-types';
import { translateMailType } from 'commons/Mail/utils';
import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';

const { _t } = window.loadTranslations(__filename);

interface ProductSelectMailTemplateReduxProps {
  claimEnabled: boolean;
}

type ProductSelectMailTemplateProps = {
  selectedMailType: MailTypeOrder;
  updateSelectedMailType: (selectedMailType: MailTypeOrder) => void;
  product: ProductPrivateDTO;
  onChange: (field: string, newValue: unknown) => void;
} & ProductSelectMailTemplateReduxProps;

const ProductSelectMailTemplate = ({
  selectedMailType,
  updateSelectedMailType,
  product,
  onChange,
  claimEnabled,
}: ProductSelectMailTemplateProps): JSX.Element => {
  function handleSelectTypeChange(type: MailTypeOrder) {
    updateSelectedMailType(type);
  }

  function handleCheckboxChange(mailType: MailTypeOrder) {
    const newValue = product.enabledNotifications?.includes(mailType)
      ? product.enabledNotifications.filter(item => item !== mailType)
      : [...product.enabledNotifications, mailType];
    onChange('enabledNotifications', newValue);
  }

  const mailTypes = useMemo(() => {
    return Object.values(MailTypeOrder).filter(mailType => {
      return claimEnabled
        ? true
        : ![
            MailTypeOrder.ORDER_CLAIM_ACCEPTED,
            MailTypeOrder.ORDER_CLAIM_REJECTED,
            MailTypeOrder.ORDER_CLAIM_EVIDENCE_REQUEST,
            MailTypeOrder.ORDER_MAKE_CLAIM,
          ].includes(mailType);
    });
  }, [claimEnabled]);

  return (
    <span className="product-detail_cell-75">
      <SelectField
        floatingLabelFixed
        floatingLabelText={_t('label')}
        hintText={_t('hint')}
        fullWidth
        onChange={(event, index, value) => handleSelectTypeChange(value)}
        value={selectedMailType}
      >
        {mailTypes.map(mailType => (
          <MenuItem
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
            }}
            id={mailType}
            key={mailType}
            value={mailType}
            primaryText={translateMailType(mailType)}
            insetChildren
          >
            <Checkbox
              checked={product.enabledNotifications?.includes(mailType)}
              onClick={event => {
                event.stopPropagation();
                handleCheckboxChange(mailType);
              }}
              style={{
                position: 'absolute',
                width: '24px',
                left: '875px',
                marginTop: '4px',
                zIndex: 2,
              }}
            />
          </MenuItem>
        ))}
      </SelectField>
    </span>
  );
};

function mapStateToProps(
  state: InternalApiState
): ProductSelectMailTemplateReduxProps {
  const {
    subscriptionConfigurationDTO: { claimEnabled },
  } = getConfigState(state);

  return {
    claimEnabled,
  };
}

export default connect(mapStateToProps)(ProductSelectMailTemplate);
