import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { Checkbox, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import {
  ApplicationProcedureDTO,
  EvidenceProperties,
  SubscriberAccountType,
} from '@cvfm-front/tefps-types';
import PeriodPicker from 'commons/PeriodPicker';
import { evidenceNameValidatorNotNull } from 'tefps/Beneficiaries/commons/validators';

import ProductDetailSecondarySeparator from '../ProductDetailSecondarySeparator';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductEvidencesProps = {
  applicationProcedure: ApplicationProcedureDTO;
  productScope: SubscriberAccountType;
  updateApplicationProcedure: (
    applicationProcedure: ApplicationProcedureDTO
  ) => void;
  canEdit: boolean;
};

function ProductEvidences({
  applicationProcedure,
  productScope,
  updateApplicationProcedure,
  canEdit,
}: ProductEvidencesProps): JSX.Element {
  function updateEvidence(index: number, updates: Partial<EvidenceProperties>) {
    if (applicationProcedure.supportingEvidences) {
      const evidence = {
        ...applicationProcedure.supportingEvidences[index],
        ...updates,
      };
      const newEvidences = [...applicationProcedure.supportingEvidences];
      newEvidences[index] = evidence;
      updateApplicationProcedure({
        ...applicationProcedure,
        supportingEvidences: newEvidences,
      });
    }
  }

  function handleChangeExpiration(value: string, index: number) {
    updateEvidence(index, { expirationPeriod: value });
  }

  function handleCheckMandatory(
    event: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) {
    const index = parseInt(event.currentTarget.dataset.index as string, 10);
    if (Number.isInteger(index)) {
      updateEvidence(index, { mandatory: checked });
    }
  }

  function handleCheckUploadRequired(
    event: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) {
    const index = parseInt(event.currentTarget.dataset.index as string, 10);
    if (Number.isInteger(index)) {
      updateEvidence(index, { uploadRequired: checked });
    }
  }

  function handleCheckDeleteAfterDecisionOrCancellationEnabled(
    event: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) {
    const index = parseInt(event.currentTarget.dataset.index as string, 10);
    if (Number.isInteger(index)) {
      updateEvidence(index, {
        deleteAfterDecisionOrCancellationEnabled: checked,
      });
    }
  }

  function handleChangeApplicationProcedure(
    event: React.MouseEvent<HTMLDataElement>,
    value: boolean
  ) {
    const key = event.currentTarget.dataset.field as string;

    updateApplicationProcedure({
      ...applicationProcedure,
      [key]: value,
    });
  }

  function handleChangeName(
    event: React.MouseEvent<HTMLDataElement>,
    value: string
  ) {
    const index = parseInt(event.currentTarget.dataset.index as string, 10);
    if (Number.isInteger(index)) {
      updateEvidence(index, { name: value });
    }
  }

  function handleChangeProofDescription(
    event: React.MouseEvent<HTMLDataElement>,
    value: string
  ) {
    updateApplicationProcedure({
      ...applicationProcedure,
      requiresProofDescription: value,
    });
  }

  function handleChangeDetails(
    event: React.MouseEvent<HTMLDataElement>,
    value: string
  ) {
    const index = parseInt(event.currentTarget.dataset.index as string, 10);
    if (Number.isInteger(index)) {
      updateEvidence(index, { details: value });
    }
  }

  function removeEvidence(
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) {
    const index = parseInt(event.currentTarget.dataset.index as string, 10);
    if (Number.isInteger(index) && applicationProcedure.supportingEvidences) {
      const newEvidences = [...applicationProcedure.supportingEvidences];
      newEvidences.splice(index, 1);
      updateApplicationProcedure({
        ...applicationProcedure,
        supportingEvidences: newEvidences,
      });
    }
  }

  function addEvidence() {
    const emptyEvidence: EvidenceProperties = {
      name: '',
      id: uuidv4(),
      details: null,
      expirationPeriod: null,
      mandatory: false,
      uploadRequired: true,
      deleteAfterDecisionOrCancellationEnabled: false,
      type: null,
    };
    const newEvidences = [
      ...(applicationProcedure.supportingEvidences || []),
      emptyEvidence,
    ];
    updateApplicationProcedure({
      ...applicationProcedure,
      supportingEvidences: newEvidences,
    });
  }

  return (
    <>
      <ProductDetailSecondarySeparator title={_t('title')} />
      <div className="product-detail_section-content-row">
        <span className="product-detail_cell-50">
          <Checkbox
            className="product-detail_checkbox"
            label={_t('requiresProofOfId')}
            checked={applicationProcedure.requiresProofOfId}
            data-field="requiresProofOfId"
            disabled={!canEdit}
            onCheck={handleChangeApplicationProcedure}
          />
        </span>
        <span className="product-detail_cell-50">
          <Checkbox
            className="product-detail_checkbox"
            label={_t('requiresProofOfAddress')}
            checked={applicationProcedure.requiresProofOfAddress}
            data-field="requiresProofOfAddress"
            disabled={!canEdit}
            onCheck={handleChangeApplicationProcedure}
          />
        </span>
      </div>

      <div className="product-detail_section-content-row">
        <span className="product-detail_cell-100">
          <TextField
            fullWidth
            key="requiresProofDescription"
            name="requiresProofDescription"
            value={applicationProcedure.requiresProofDescription || ''}
            disabled={!canEdit}
            floatingLabelText={_t('requiresProofDescription')}
            onChange={handleChangeProofDescription}
          />
        </span>
      </div>

      <div className="product-detail_section-content-row">
        <span className="product-detail_cell-50">
          <Checkbox
            className="product-detail_checkbox"
            label={_t('requiresProofOfActivity')}
            checked={applicationProcedure.requiresProofOfActivity}
            data-field="requiresProofOfActivity"
            disabled={!canEdit}
            onCheck={handleChangeApplicationProcedure}
          />
        </span>
      </div>
      <div className="product-detail_section-content">
        {applicationProcedure.supportingEvidences?.map((evidence, index) => (
          <>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-25">
                <TextField
                  key={evidence.id}
                  name={evidence.name}
                  data-index={index}
                  value={evidence.name}
                  disabled={!canEdit}
                  floatingLabelText={_t('name')}
                  errorText={evidenceNameValidatorNotNull(evidence.name)}
                  onChange={handleChangeName}
                />
              </span>
              <span className="product-detail_cell-25">
                <TextField
                  key={_t('details')}
                  data-index={index}
                  value={evidence.details ?? undefined}
                  disabled={!canEdit}
                  floatingLabelText={_t('details')}
                  onChange={handleChangeDetails}
                />
              </span>
              <span className="product-detail_cell-25">
                <PeriodPicker
                  key={_t('expiration')}
                  data-index={index}
                  value={evidence.expirationPeriod}
                  disabled={!canEdit}
                  onChange={(_e, v) => handleChangeExpiration(v, index)}
                />
              </span>
            </div>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-25">
                <Checkbox
                  checked={evidence.mandatory}
                  data-index={index}
                  label={_t('mandatory')}
                  disabled={!canEdit}
                  onCheck={handleCheckMandatory}
                />
              </span>
              <span className="product-detail_cell-25">
                <Checkbox
                  checked={evidence.uploadRequired}
                  data-index={index}
                  label={_t('uploadRequired')}
                  disabled={!canEdit}
                  onCheck={handleCheckUploadRequired}
                />
              </span>
              <span
                className="product-detail_cell-25"
                title={_t('deleteAfterDecisionOrCancellationEnabledHover')}
              >
                <Checkbox
                  checked={evidence.deleteAfterDecisionOrCancellationEnabled}
                  data-index={index}
                  label={_t('deleteAfterDecisionOrCancellationEnabled')}
                  disabled={!canEdit}
                  onCheck={handleCheckDeleteAfterDecisionOrCancellationEnabled}
                />
              </span>
              <span
                className="product-detail_cell-25"
                title={_t('delete')}
                onClick={removeEvidence}
                data-index={index}
                style={{ cursor: 'pointer' }}
              >
                <DeleteIcon />
              </span>
            </div>
          </>
        ))}
        <div className="product-detail_section-content-row">
          {!canEdit &&
          applicationProcedure.supportingEvidences?.length === 0 ? (
            <div
              style={{
                fontStyle: 'italic',
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              {_t('noRequiredEvidence')}
            </div>
          ) : (
            <span className="product-detail_cell-12">
              <BoButton
                label={_tg('action.add')}
                onClick={addEvidence}
                fullWidth
                disabled={!canEdit}
                primary
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default ProductEvidences;
