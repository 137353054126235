import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { fetchFpsRecourses } from 'api/recourse';
import Loading from 'tefps/RecoursesV2/commons/Loading';
import ErrorPage from 'tefps/RecoursesV2/commons/ErrorPage';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';

const { _tg } = window.loadTranslations();

const RecourseLoader = (): JSX.Element => {
  const [error, setError] = useState<Error | undefined | null>();
  const [recourseId, setRecourseId] = useState<string | null>(null);

  const { fpsId } = useParams<{ fpsId: string }>();

  const fetchLastRecourseByFpsId = async (id: string): Promise<void> => {
    try {
      const searchResult = await fetchFpsRecourses(id);
      if (searchResult.recourses.length >= 1) {
        setRecourseId(
          searchResult.recourses[searchResult.recourses.length - 1].rapoId
        );
      } else {
        setError(new Error(_tg('feedback.error.fetchRecourse')));
        setRecourseId(null);
      }
    } catch (e) {
      setError(e as Error);
      setRecourseId(null);
    }
  };

  useEffect(() => {
    void fetchLastRecourseByFpsId(fpsId);
  }, []);

  // =========== Render ==========
  if (error) {
    return (
      <ErrorPage
        message={_tg('feedback.error.fetchRecourse')}
        error={error}
        navContent={
          <ReturnButton
            href="#/recourses/list"
            label={_tg('action.backToList')}
          />
        }
      />
    );
  }

  // DEV NOTE: In any case, when fetching FPS Recourses the object returned
  // is RapoOverviewDTO, thus we need to fetch the entire recourse.
  // Redirecting will allow us to have the same path for any recourse detail
  if (recourseId) {
    return <Redirect to={`/recourses/detail/${recourseId}`} />;
  }

  return <Loading />;
};

export default RecourseLoader;
