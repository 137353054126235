import React from 'react';
import { Checkbox, MenuItem, SelectField } from 'material-ui';

import { ProductPrivateDTO } from '@cvfm-front/tefps-types';
import { ItemIdName } from 'api/commonTypes';
import { Flex } from '@cvfm-front/commons-ui';

const { _t } = window.loadTranslations(__filename);

type ProductChoiceProps = {
  product: ProductPrivateDTO | undefined;
  onChange: (e: never, idx: never, _: string) => void;
  onCheck: (
    { currentTarget }: React.MouseEvent<HTMLInputElement>,
    isInputChecked: boolean
  ) => void;
  productsAvailableItems: Array<ItemIdName>;
  createdForProAccount: boolean;
  canSelectProfileTypeForOrder: boolean;
};
const OrderCreateProductChoice = ({
  product,
  onChange,
  onCheck,
  productsAvailableItems,
  createdForProAccount,
  canSelectProfileTypeForOrder,
}: ProductChoiceProps): JSX.Element => {
  return (
    <>
      <span className="order-detail_cell-25">{_t('choice.product')}</span>
      <Flex className="order-detail_cell-75" flexDirection="column" gap={10}>
        <SelectField
          fullWidth
          id="order-product-selector"
          value={product?.productId}
          onChange={onChange}
        >
          {productsAvailableItems.map(item => (
            <MenuItem
              id={item.id}
              key={item.id}
              value={item.id}
              primaryText={item.name}
            />
          ))}
        </SelectField>
        {canSelectProfileTypeForOrder && (
          <Checkbox
            checked={createdForProAccount}
            id="createdForProAccount"
            onCheck={onCheck}
            label={_t('createdForProAccount')}
          />
        )}
      </Flex>
    </>
  );
};

export default OrderCreateProductChoice;
