import React from 'react';

import {
  isDisabledStatus,
  isOnHold,
  isProposalOpen,
} from 'tefps/RecoursesV2/utils/recourseUtils';
import useRecourse from 'commons/hooks/useRecourse';
import RecourseButton from 'facade/RecourseButton';

const { _t } = window.loadTranslations(__filename);

const ProposalAction = (): JSX.Element => {
  const recourseDetails = useRecourse();

  const isProposalActionDisabled = () =>
    recourseDetails !== null &&
    !isProposalOpen(recourseDetails.recourse) &&
    (isDisabledStatus(recourseDetails.recourse.status) ||
      isOnHold(recourseDetails.recourse) ||
      (!!recourseDetails.recourse.complementRequest &&
        !recourseDetails.recourse.complementRequest.completionDate));

  return (
    <RecourseButton
      label={
        recourseDetails && isProposalOpen(recourseDetails.recourse)
          ? _t('button.title.seeProposal')
          : _t('button.title.newProposal')
      }
      backgroundColor="var(--bg-color--cyan)"
      labelColor="var(--white)"
      disabled={isProposalActionDisabled()}
      href={`#/recourses/proposal/${recourseDetails?.recourse.rapoId}`}
    />
  );
};

export default ProposalAction;
