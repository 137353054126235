import React, { useEffect, useState } from 'react';

import RichTextEditor from '@cvfm-front/commons-ui/src/ui/RichTextEditor';
import { RECOURSE_EMAIL_TEMPLATE_FIELDS } from 'tefps/RecoursesV2/utils/translationUtils';
import useCurrentProposal from 'commons/hooks/useCurrentProposal';
import useReason from 'commons/hooks/useReason';
import useRecourseReasons from 'commons/hooks/useRecourseReasons';
import useProposalComment from 'commons/hooks/useProposalComment';

const { _t } = window.loadTranslations(__filename);

const ProposalEditor = (): JSX.Element => {
  const currentProposal = useCurrentProposal();
  const recourseReasons = useRecourseReasons();
  const [decision] = useReason();
  const [reason] = useReason();
  const [, setProposalComment] = useProposalComment();

  const [initialContent, setInitialContent] = useState<string>('');

  /* Separate currentProposal to know when we are modifying the proposal or not */
  useEffect(() => {
    if (currentProposal?.proposal.agentComment) {
      setInitialContent(currentProposal.proposal.agentComment);
    }
  }, [currentProposal]);

  useEffect(() => {
    if (!currentProposal && recourseReasons && reason) {
      setInitialContent(recourseReasons[reason].mail);
    } else if (!currentProposal && decision === null && reason === null) {
      setInitialContent('');
    }
  }, [decision, reason]);

  const onContentChange = (newContent: string) => {
    setProposalComment(newContent);
  };

  return (
    <RichTextEditor
      initialContent={initialContent}
      onChangeContent={onContentChange}
      placeholder={_t('editor.placeholder')}
      wrapperClassName="margin-y"
      readOnly={!!currentProposal}
      toolbarHidden={!!currentProposal}
      stripPastedStyles
      mention={{
        separator: ' ',
        trigger: '{',
        suggestions: RECOURSE_EMAIL_TEMPLATE_FIELDS.map(x => {
          return { text: x.field, value: `${x.field}}` };
        }),
      }}
    />
  );
};

export default ProposalEditor;
