import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  OrderPayment,
  OrderPrivateDTO,
  OrderTraceType,
  ProductType,
  Payment,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import { addPaymentUpdate } from 'api/cvfm-core-subscription/order';
import AddPayment from 'tefps/Beneficiaries/commons/AddPayment';
import { PatchObject } from 'api/commonTypes';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';

import './OrderDetailPage.css';

type OrderDetailModalPaymentProps = {
  open: boolean;
  traceType?: OrderTraceType;
  order: OrderPrivateDTO;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalPayment = ({
  open,
  traceType,
  order,
  userInfo,
  onCancel,
  onConfirm,
}: OrderDetailModalPaymentProps): JSX.Element => {
  const orderValidityPeriod =
    order.validityPeriodHistory[order.validityPeriodHistory.length - 1];

  function onCancelAction() {
    onCancel();
  }

  function onConfirmAction(legacyPatch: PatchObject<Payment>) {
    const updateRequest = {
      timestamp: legacyPatch.value.paymentDatetime,
      traceType,
      source: SubscriptionSource.BACK,
      agent: userInfo ? { ...userInfo } : null,
      payment: { ...legacyPatch.value },
      comment: legacyPatch.value.comment,
      subscriberId: null,
    } as OrderPayment;
    const updatePromise = addPaymentUpdate(order.orderId, updateRequest);
    onConfirm(updatePromise);
  }

  const toPay = () => {
    const amountPaid = order.amountPaid || 0.0;
    const amountToPay = order.orderPrice - amountPaid;
    return traceType === OrderTraceType.REFUND ? amountPaid : amountToPay;
  };

  const paymentType = () => {
    if (traceType === OrderTraceType.REFUND) {
      return 'REFUND';
    }
    return toPay() > 0 ? 'PAYMENT' : 'REFUND';
  };

  const getProratedAmount = (): number | undefined => {
    if (
      paymentType() !== 'REFUND' ||
      order.productType !== ProductType.BUNDLE ||
      order.amountPaid === undefined ||
      moment().isBefore(moment(orderValidityPeriod.startOfValidity))
    )
      return undefined;

    if (moment().isAfter(moment(orderValidityPeriod.endOfValidity))) {
      return order.amountPaid;
    }

    const consumedTime =
      new Date().valueOf() -
      new Date(orderValidityPeriod.startOfValidity).valueOf();

    const totalTime =
      new Date(orderValidityPeriod.endOfValidity).valueOf() -
      new Date(orderValidityPeriod.startOfValidity).valueOf();

    return ((consumedTime / totalTime) * 100 * order.amountPaid) / 100;
  };

  return (
    <AddPayment
      isOpen={open}
      paymentType={paymentType()}
      close={onCancelAction}
      patchResource={onConfirmAction}
      amountToPay={toPay()}
      proratedAmount={getProratedAmount()}
    />
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalPayment);
