import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash.clonedeep';
import _isEqual from 'lodash.isequal';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@cvfm-front/commons-ui/node_modules/@material-ui/core/TextField';
import {
  CircularProgress,
  TextField as TextFieldV0,
  TimePicker,
} from 'material-ui';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import IntlPolyfill from 'intl';
import { addDays } from 'date-fns';

import {
  OrderCustomField,
  OrderEsPrivateDTO,
  OrderEsSearchResultDTO,
  OrderEvidence,
  OrderPlates,
  OrderPrivateDTO,
  OrderRightReference,
  OrderStatus,
  OrderSubscribers,
  OrderTraceType,
  OrderValidityPeriod,
  OrderZones,
  ProductOptionReference,
  ProductPrivateDTO,
  ProductType,
  SubscriberAccountType,
  SubscriberDTO,
  SubscriptionSource,
  OrderPriceRequestDTO,
  ZoneDTO,
  ProofUploadDTO,
  SubscriberVehicleUploadDTO,
  SubscriberVehicle,
  MandateUser,
  Lang,
  PricingPolicyStepDTO,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import Sidebar from 'commons/SidebarV2';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { TXT_RED } from 'theme';
import { Flex } from '@cvfm-front/commons-ui';
import {
  createOrderById,
  getOrderById,
  searchEsOrderPaged,
  calculateOrderPrice,
  getPlatesUsedByOrder,
} from 'api/cvfm-core-subscription/order';
import {
  getSubscriber,
  patchSubscriber,
} from 'api/cvfm-core-subscription/subscriber';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { ItemIdName } from 'api/commonTypes';
import { fetchZoning } from 'api/pricing';
import { searchProducts } from 'api/cvfm-core-subscription/product';
import './OrderCreatePage.css';
import './OrderDetailPage.css';
import { InternalAgent } from 'api/auth/types';
import { getLast } from 'commons/Utils/arrayUtils';
import { localPeriodRenderer } from 'commons/PeriodPicker';
import { formatCtsPrice } from 'commons/Price';
import { validateEmail, validateMandate } from 'commons/Validators';
import translateError from 'commons/Utils/translateErrorUtil';
import {
  filterSubscriberVehiclesFromProductScope,
  filterSubscriberVehiclesFromProductVehiclesCategories,
} from 'tefps/Subscription/helpers';
import BoButton from 'facade/BoButton';
import { ErrorType } from 'commons/types/error';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';
import { formatDate, isDatePast } from 'commons/Utils/dateUtil';
import useWatcher from 'commons/hooks/useWatcher';
import { PlatesService } from '@cvfm-front/commons-services';
import {
  getInitSubscriberRequiredProofs,
  getProductOptionByDuration,
} from '@cvfm-front/tefps-utils';

import BoDatePicker from '../../facade/BoDatePicker';

import OrderCreateZones from './OrderCreateZones';
import OrderCreateProductRequired from './OrderCreateProductRequired';
import OrderCreatePlates from './OrderCreatePlates';
import {
  OrderCustomFieldComplete,
  SimpleOrderEvidence,
  SubscriberRequiredProofs,
  SubscriberRequiredProofsType,
} from './OrderCreateTypes';
import OrderCreateSubscriberRequiredProofs from './OrderCreateSubscriberRequiredProofs';
import OrderCreateSubscriberChoice from './OrderCreateSubscriberChoice';
import OrderCreateProductChoice from './OrderCreateProductChoice';
import OrderCreateProductOptionChoice from './OrderCreateProductOptionChoice';
import OrderCreateProductEvidences from './OrderCreateProductEvidences';
import OrderCreateCustomFields from './OrderCreateCustomFields';
import OrderCreateSubscriberLang from './OrderCreateSubscriberLang';
import OrderCreateTariffEngineSelect from './OrderCreateTariffEngineSelect';

const { _t, _tg } = window.loadTranslations(__filename);

const createNewDefaultOrder = (
  cityId: string,
  orderSubscribers: OrderSubscribers,
  productId: string,
  orderDuration: string,
  orderPrice: number,
  zoneHistory: OrderZones,
  validityPeriod: OrderValidityPeriod,
  plates: OrderPlates,
  productType: ProductType,
  email: string | undefined,
  mandateUser: MandateUser | undefined,
  activeRightUsed: OrderRightReference | undefined,
  renewOrderId: string | undefined,
  customFields: Array<OrderCustomField> | [],
  evidences: Array<OrderEvidence> | [],
  createdForProAccount: boolean,
  comment: string | undefined,
  lang: Lang
): OrderPrivateDTO => {
  const newOrderId = uuidv4();
  return {
    orderRenewed: false,
    cityId,
    orderId: newOrderId,
    productId,
    subscribersHistory: [orderSubscribers],
    productType,
    creationSource: SubscriptionSource.BACK,
    zoneHistory: [zoneHistory],
    validityPeriodHistory: [validityPeriod],
    providedCustomFieldHistory: customFields,
    providedEvidenceHistory: evidences,
    complementEvidenceHistory: [],
    plateChangeRequestHistory: [],
    allEvidencesProvided: false,
    plateHistory: [plates],
    mandateUser,
    paymentHistory: [],
    fullyPaid: false,
    activeRightsCreated: [],
    activeRightUsed,
    status: OrderStatus.AWAIT_DECISION,
    reminders: [],
    tags: [],
    email,
    previousOrderId: renewOrderId, // TODO in the future, use undefined if the product/option/... changed (and therefore isn't a renewal)
    transferHistory: [],
    ingenicoAlias: null,
    orderDuration,
    orderPrice,
    createdForProAccount,
    comment,
    lang,
  };
};

type OrderCreatePageReduxProps = {
  cityId: string;
  isAdV3Enabled: boolean;
  userInfo: InternalAgent | null | undefined;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  selectedLang: string;
  langEnabled: boolean;
};

type OrderCreatePageProps = {
  productType: ProductType;
} & OrderCreatePageReduxProps;

enum VehiclePatchEndPoint {
  pro = '/professional-vehicle',
  perso = '/personal-vehicle',
}

const OrderCreatePage = ({
  cityId,
  isAdV3Enabled,
  productType,
  userInfo,
  selectedLang,
  langEnabled,
}: OrderCreatePageProps): JSX.Element => {
  const requestPlate = useWatcher(PlatesService.watchRequestPlate, null);
  const routerQuery = new URLSearchParams(useLocation().search);
  const initialSubscriberId = routerQuery.get('subscriberId') || undefined;
  const renewOrderId = routerQuery.get('renewId') || undefined;
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const [subscriberId, setSubscriberId] = useState(initialSubscriberId);
  const [lang, setLang] = useState<Lang | undefined>(undefined);
  const [subscribersItems, setSubscribersItems] = useState<Array<ItemIdName>>(
    []
  );
  const [subscriber, setSubscriber] = useState<SubscriberDTO>();

  const [activeRights, setActiveRights] = useState<Array<OrderEsPrivateDTO>>(
    []
  );
  const [useRight, setUseRight] = useState<OrderEsPrivateDTO>();

  const [products, setProducts] = useState<Array<ProductPrivateDTO>>([]);
  const [productItems, setProductItems] = useState<Array<ItemIdName>>([]);
  const [productsAvailable, setProductsAvailable] = useState<
    Array<ProductPrivateDTO>
  >([]);
  const [productsAvailableItems, setProductAvailableItems] = useState<
    Array<ItemIdName>
  >([]);
  const [product, setProduct] = useState<ProductPrivateDTO>();

  const [productOptionItems, setProductOptionItems] = useState<
    Array<ItemIdName>
  >([]);

  const [choosablePlates, setChoosablePlates] = useState<Array<string>>([]);

  const [subscriberRequiredProofs, setSubscriberRequiredProofs] = useState<
    SubscriberRequiredProofs[]
  >([]);

  const [
    isSubscriberRequiredProofsInitialized,
    setIsSubscriberRequiredProofsInitialized,
  ] = useState<boolean>(false);

  const [
    subscriberRequiredProofsInitialValue,
    setSubscriberRequiredProofsInitialValue,
  ] = useState<SubscriberRequiredProofs[]>([]);

  const [plates, setPlates] = useState<Array<string>>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [order, setOrder] = useState<OrderPrivateDTO>();
  const [zones, setZones] = useState<Array<ZoneDTO>>();
  const [customEmail, setCustomEmail] = useState<string | undefined>();
  const [mandateUser, setMandateUser] = useState<MandateUser | undefined>(
    undefined
  );
  const [selectedEligibilityId, setSelectedEligibilityId] = useState<
    string | null | undefined
  >();
  const [selectedBundleId, setSelectedBundleId] = useState<
    string | null | undefined
  >();
  const [selectedZones, setSelectedZones] = useState<Array<string>>([]);

  const [renewOrder, setRenewOrder] = useState<OrderPrivateDTO | undefined>(
    undefined
  );
  const [productEvidences, setProductEvidences] = useState<
    Array<SimpleOrderEvidence>
  >([]);
  const [mandatoryEvidencesSelect, setMandatoryEvidencesSelect] = useState<
    boolean
  >(false);
  const [isCreatedForProAccount, setIsCreatedForProAccount] = useState<boolean>(
    false
  );
  const [
    canSelectProfileTypeForOrder,
    setCanSelectProfileTypeForOrder,
  ] = useState<boolean>(false);

  const [customFields, setCustomFields] = useState<
    Array<OrderCustomFieldComplete>
  >([]);

  const [orderPrice, setOrderPrice] = useState<number | undefined>();
  const [orderDuration, setOrderDuration] = useState<string | undefined>();

  const [canSubscriberTakeProduct, setCanSubscriberTakeProduct] = useState<
    boolean
  >(true);

  const [
    reasonSubscriberCannotTakeProduct,
    setReasonSubscriberCannotTakeProduct,
  ] = useState<string>('');
  const [comment, setFieldComment] = useState<string>();

  const setMessage = useSnackbar();

  const hasEligibilityCondition = product?.conditions.activeProductsRequired.some(
    ref =>
      products.find(p => p.productId === ref.productId)?.productType ===
      'ELIGIBILITY'
  );

  const hasBundleCondition = product?.conditions.activeProductsRequired.some(
    ref =>
      products.find(p => p.productId === ref.productId)?.productType ===
      'BUNDLE'
  );

  const multipleOrderAllowed = product?.multipleOrderAllowed;

  const fetchOrderPrice = () => {
    if (
      product &&
      product.pricingConfiguration.usePricingCalculator &&
      startDate &&
      selectedZones.length !== 0 &&
      plates.length !== 0
    ) {
      const orderPriceRequestDTO: OrderPriceRequestDTO = {
        licensePlate: {
          plate: plates[0],
          plateCountry: 'FR',
          pricingCategory: useRight?.productId,
        },
        productId: product.productId,
        productOptionId: getProductOptionByDuration(
          product,
          orderDuration || ''
        ).id,
        requestDurationPeriod: orderDuration,
        requestStartDatetime: startDate,
        zoneId: selectedZones[0],
      };
      calculateOrderPrice(orderPriceRequestDTO)
        .then(price => {
          setOrderPrice(price);
        })
        .catch(err => {
          setOrderPrice(undefined);
          setMessage(translateError(err?.json?.error as ErrorType));
        });
    }
  };

  const getProductOptionItems = () => {
    if (product) {
      const items = product.options
        .filter(opt => !opt.deleted)
        .map(opt => {
          let price = '';
          if (product.pricingConfiguration.usePricingCalculator) {
            price =
              orderPrice !== undefined && opt.duration === orderDuration
                ? `- ${formatCtsPrice(orderPrice)}`
                : '';
          } else {
            price = `- ${formatCtsPrice(opt.price)}`;
          }

          return {
            id: opt.id,
            name: `${opt.name} : ${localPeriodRenderer(opt.duration) ||
              ''}   ${price}`,
          };
        });

      setProductOptionItems(items);
    }
  };

  useEffect(() => {
    PlatesService.init();
  }, []);

  useEffect(() => {
    getProductOptionItems();
  }, [orderPrice]);

  useEffect(() => {
    fetchOrderPrice();
  }, [[startDate, selectedZones, plates]]);

  // Récupère les plaques utilisées sur un produit sur une année. Recalculé à chaque changement de date ou produits
  useEffect(() => {
    const startOfValidity = startDate || undefined;

    PlatesService.fetchPlatesUsage(
      product,
      subscriberId,
      startOfValidity,
      getPlatesUsedByOrder
    );
  }, [product, startDate, requestPlate]);

  useEffect(() => {
    if (subscriber) {
      setLang(subscriber.lang);
    }
  }, [subscriber]);

  const reset = () => {
    setSelectedZones([]);
    setEndDate(undefined);
    setPlates([]);
    getProductOptionItems();
  };

  function orderMatchesProductOption(
    activeRight: OrderEsPrivateDTO,
    por: ProductOptionReference
  ): boolean {
    return activeRight.productId === por.productId;
  }

  function someActiveRightMatches(requiredPO: ProductOptionReference): boolean {
    return activeRights.some(activeRight =>
      orderMatchesProductOption(activeRight, requiredPO)
    );
  }

  function computeAndSetAvailableItems(
    availableProducts: Array<ProductPrivateDTO>
  ): void {
    const newAvailableProducts = availableProducts
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(p => ({
        id: p.productId,
        name: p.name,
      }));
    if (!newAvailableProducts.some(value => value.id === product?.productId)) {
      // the new available products changed and don't contain the currently selected product
      // we set selected product and product option to undefined
      setProduct(undefined);
      setOrderPrice(undefined);
      setOrderDuration(undefined);
    }
    setProductAvailableItems(newAvailableProducts);
  }

  const patchSubscriberProfileProofs = async (
    subscriberProfileProofs: SubscriberRequiredProofs[],
    subscriber: SubscriberDTO
  ): Promise<SubscriberDTO> => {
    const patchSubscriberProfileProofs: Array<{
      path: string;
      op: string;
      value: ProofUploadDTO;
    }> = [];

    // add to patch only if element changed
    subscriberProfileProofs.forEach(element => {
      if (
        !_isEqual(
          element,
          subscriberRequiredProofsInitialValue[
            subscriberRequiredProofsInitialValue.findIndex(
              initialElement => element.id === initialElement.id
            )
          ]
        )
      ) {
        patchSubscriberProfileProofs.push({
          path: element.path,
          op: 'replace',
          value: {
            manuallyChecked: element.manuallyChecked,
            documents: element.documents,
          },
        });
      }
    });

    if (patchSubscriberProfileProofs.length > 0) {
      const updateSubscriber: SubscriberDTO = await patchSubscriber(
        subscriber.subscriberId,
        patchSubscriberProfileProofs
      );

      setSubscriber(updateSubscriber);
      return updateSubscriber;
    }
    return subscriber;
  };

  const patchSubscriberVehicleProofs = async (
    subscriberVehicleProof: SubscriberRequiredProofs[],
    subscriber: SubscriberDTO
  ): Promise<SubscriberDTO> => {
    const patchSubscriberVehicleProofs: Array<{
      path: string;
      op: string;
      value: SubscriberVehicleUploadDTO;
    }> = [];

    // add to patch only if element changed
    subscriberVehicleProof.forEach(element => {
      if (
        !_isEqual(element.documents, element.vehicle?.registrationDocuments) &&
        element.vehicle
      ) {
        patchSubscriberVehicleProofs.push({
          path: element.path,
          op: 'replace',
          value: {
            ...element.vehicle,
            documents: element.documents,
            manuallyChecked: element.manuallyChecked,
          },
        });
      }
    });

    if (patchSubscriberVehicleProofs.length > 0) {
      const updateSubscriber: SubscriberDTO = await patchSubscriber(
        subscriber.subscriberId,
        patchSubscriberVehicleProofs
      );

      setSubscriber(updateSubscriber);
      return updateSubscriber;
    }
    return subscriber;
  };

  const onCancelAction = (): void => {
    history.goBack();
  };

  const onConfirmAction = async (): Promise<void> => {
    if (subscriber && order) {
      if (order.email) {
        const error = validateEmail(order.email);
        if (error !== undefined) {
          setMessage(error);
          return;
        }
      }

      if (product?.applicationProcedure?.allowRequestForOther) {
        const error = validateMandate(mandateUser);
        if (error !== undefined) {
          setMessage(error);
          return;
        }
      }

      if (isAdV3Enabled) {
        const updatedSubscriber: SubscriberDTO = await patchSubscriberProfileProofs(
          subscriberRequiredProofs.filter(
            element =>
              element.type ===
              SubscriberRequiredProofsType.SubscriberProfileProof
          ),
          subscriber
        );

        await patchSubscriberVehicleProofs(
          subscriberRequiredProofs.filter(
            element =>
              element.type ===
              SubscriberRequiredProofsType.SubscriberVehicleProof
          ),
          updatedSubscriber
        );
      }

      createOrderById(order.orderId, order)
        .then(createdOrder => {
          if (productType === ProductType.BUNDLE) {
            history.push(`/subscription/bundle/order/${createdOrder.orderId}`);
          } else {
            history.push(
              `/subscription/eligibility/order/${createdOrder.orderId}`
            );
          }
        })
        .catch(err => {
          setMessage(translateError((err as Error).name as ErrorType));
        });
    }
  };

  async function findSubscriber(subscriberFilter: string | null | undefined) {
    if (subscriberFilter && subscriberFilter.length >= 3) {
      try {
        const subscriber = await getSubscriber(subscriberFilter);
        const items = {
          id: subscriber.subscriberId,
          name: `${subscriber.firstName} ${subscriber.lastName}`,
        };
        setSubscribersItems([items]);
      } catch (err) {
        setMessage((err as Error).message);
      }
    }
  }

  function handleSubscriberChange(domId: unknown, changeSubscriberId: string) {
    setSubscriberId(changeSubscriberId || undefined);
  }

  function handleProductChange(
    e: unknown,
    idx: unknown,
    changeProductId: string
  ) {
    const newProduct = productsAvailable.find(
      p => p.productId === changeProductId
    );
    if (
      product?.restrictions.mustOrderWithPlate &&
      !product?.restrictions.mustUseActiveEligibilityPlate &&
      newProduct?.restrictions.mustOrderWithPlate &&
      !newProduct?.restrictions.mustUseActiveEligibilityPlate
    ) {
      // if the previous and the new product allow the user to fill out plates
      // then we want to keep the previously filled plates
      const newPlates = [...plates];
      if (newPlates.length > newProduct.restrictions.plateLimit) {
        newPlates.length = newProduct.restrictions.plateLimit;
      }
      setPlates(newPlates);
    } else {
      // else we just empty the plates
      setPlates([]);
    }
    setProduct(newProduct);
  }

  function handleProductOptionChange(
    e: unknown,
    idx: unknown,
    changeOptionId: string
  ) {
    reset();
    if (product) {
      const option = product.options.find(opt => opt.id === changeOptionId);
      setOrderPrice(option?.price);
      setOrderDuration(option?.duration);
    }
  }

  function handlePlateChange(changePlate: Array<string>) {
    setPlates(changePlate);
  }

  function handleStartDateChange(_e: unknown, changeDate: Date) {
    if (startDate) {
      // will be truncated to minutes in the back
      changeDate.setHours(startDate.getHours());
      changeDate.setMinutes(startDate.getMinutes());
    }
    setStartDate(changeDate);

    if (orderDuration && endDate && endDate < changeDate) {
      setEndDate(
        moment(changeDate)
          .add(orderDuration)
          .subtract(1, 'second')
          .toDate()
      );
    }
  }

  function handleEndDateChange(_e: unknown, changeDate: Date) {
    setEndDate(changeDate);
  }

  function handleStartTimeChange(_e: unknown, changeDate: Date) {
    if (startDate) {
      changeDate.setDate(startDate.getDate());
      changeDate.setMonth(startDate.getMonth());
      changeDate.setFullYear(startDate.getFullYear());
    }
    setStartDate(changeDate);
  }

  function handleCustomEmailChange(_e: unknown, changeCustomEmail: string) {
    setCustomEmail(changeCustomEmail);
  }

  function handleZonesChange(newZones: Array<string>) {
    setSelectedZones(newZones);
  }

  function handleEligibityRestrictionChange(orderId: string | undefined) {
    // when changing product, it can change the selectable eligibilities
    // the old selectedEligibilityId might not be in the new ones
    // in that case the child component calls this function with undefined
    // this if therefore prevents an infinite loop
    if (orderId !== undefined || selectedEligibilityId !== undefined) {
      const right = activeRights.find(ar => ar.orderId === orderId);
      const newChoosablePlates = right?.rawPlates || [];
      const newPlates =
        product?.restrictions.mustContainPlate &&
        !product.restrictions.mustUseActiveEligibilityPlate
          ? plates
          : plates.filter(plate => newChoosablePlates.includes(plate));
      setUseRight(right);
      setSelectedEligibilityId(orderId);
      setPlates(newPlates);
      setChoosablePlates(newChoosablePlates);
    }
  }

  function handleBundleRestrictionChange(orderId: string | undefined) {
    // same justification as above
    if (orderId !== undefined || selectedBundleId !== undefined) {
      const right = activeRights.find(ar => ar.orderId === orderId);
      const newChoosablePlates = right?.rawPlates || [];
      const newPlates =
        product?.restrictions.mustContainPlate &&
        !product.restrictions.mustUseActiveEligibilityPlate
          ? plates
          : plates.filter(plate => newChoosablePlates.includes(plate));
      setUseRight(right);
      setSelectedBundleId(orderId);
      setPlates(newPlates);
      setChoosablePlates(newChoosablePlates);
    }
  }

  function loadZoning(): void {
    fetchZoning(FETCH_LIGHT_ZONING_CONFIG)
      .then(fetchedZones => {
        setZones(fetchedZones.zones);
      })
      .catch(err => {
        setMessage((err as Error).message);
      });
  }

  function updateAvailableProducts(productsFound: Array<ProductPrivateDTO>) {
    const items = productsFound.map(p => ({
      id: p.productId,
      name: p.name,
    }));

    const availableProducts = products.filter(
      p => p.productType === productType && p.privateOrdersEnabled
    );

    setProductsAvailable(availableProducts);
    computeAndSetAvailableItems(availableProducts);

    setProducts(productsFound);
    setProductItems(items);
  }

  function handleSubscriberChangePromises([
    activeOrders,
    fetchedProducts,
    fetchedOrder,
  ]: Array<unknown>) {
    // Handle searchEsOrderPaged Promise
    setActiveRights((activeOrders as OrderEsSearchResultDTO).results);
    // Handle searchProducts Promise
    updateAvailableProducts(fetchedProducts as Array<ProductPrivateDTO>);
    if (fetchedOrder) {
      // Handle getOrderById or empty Promise (in which case, fetchedOrder is 0 because fetchRenewOrder returns 0)
      const orderPrivateDTO = fetchedOrder as OrderPrivateDTO;
      setRenewOrder(orderPrivateDTO);
      const { endOfValidity } = orderPrivateDTO.validityPeriodHistory[
        orderPrivateDTO.validityPeriodHistory.length - 1
      ];
      if (orderPrivateDTO && !isDatePast(endOfValidity)) {
        setStartDate(
          moment(endOfValidity)
            .add(1, 'day')
            .startOf('day')
            .toDate()
        );
      }
    } else {
      setRenewOrder(undefined);
    }
  }

  /**
   * Used to prevent query and/or console errors
   * @param id: Order object ID to retrieve
   */
  function fetchRenewOrder(id: string | undefined): Promise<any> {
    if (id) {
      return getOrderById(id);
    }
    // Resolve empty promise
    return Promise.resolve(0);
  }

  /**
   * Fill inputs with older information
   */
  function populateNewOrder() {
    if (renewOrder && product && product.productId === renewOrder.productId) {
      const renewOption = getProductOptionByDuration(
        product,
        renewOrder.orderDuration
      );
      setOrderDuration(renewOption.duration);
      setOrderPrice(renewOption.price);
      setPlates((getLast(renewOrder.plateHistory) as OrderPlates).plates);
      setSelectedZones(
        renewOrder.zoneHistory[renewOrder.zoneHistory.length - 1].zoneIds
      );
    }
  }

  function checkMandatoryEvidencesSelected(): void {
    setMandatoryEvidencesSelect(
      productEvidences.every(
        evidence =>
          evidence.evidenceSubscriberMediaId !== '' ||
          evidence.manuallyChecked ||
          !evidence.mandatory
      )
    );
  }

  /**
   * After fetching renewOrder, set the product to be renewed
   */
  useEffect(() => {
    if (renewOrder) {
      const renewProduct = productsAvailable.find(
        p => p.productId === renewOrder.productId
      );
      setProduct(renewProduct);
    }
  }, [renewOrder]);

  // Load subscriber on subscriberId select
  useEffect(() => {
    if (subscriberId) {
      setLoading(true);
      getSubscriber(subscriberId)
        .then(fetchedSubscriber => {
          setSubscriber(fetchedSubscriber);
        })
        .catch(err => {
          setMessage((err as Error).message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSubscriber(undefined);
    }
  }, [subscriberId]);

  // Load zoning
  useEffect(() => {
    loadZoning();
  }, [subscriberId]);

  // Load subscriber rights, products and older information for renewal on subscriber load
  useEffect(() => {
    if (subscriber) {
      setLoading(true);
      Promise.all([
        searchEsOrderPaged({
          page: 0,
          maxRecords: 1000,
          query: {
            strictSubscriberIds: [subscriber.subscriberId],
            orderStatuses: [
              OrderStatus.FULFILLED,
              OrderStatus.AWAIT_PLATE_DECISION,
            ],
            validityEnd: {
              from: new Date(),
            },
          },
        }),
        searchProducts({
          activeOnly: true,
          zoneIdCheck: subscriber.zoneId,
          professionalZoneIdCheck: subscriber.professionalZoneId,
          strictZoneIdCheck: true,
        }),
        fetchRenewOrder(renewOrderId),
      ])
        .then(handleSubscriberChangePromises)
        .catch(err => {
          setMessage((err as Error).message);
        })
        .finally(() => setLoading(false));
    }
  }, [subscriber?.id]);

  // Compute the available products based on required and forbidden products
  useEffect(() => {
    const availableProducts = products.filter(p => {
      const { activeProductsRequired } = p.conditions;

      return (
        p.productType === productType &&
        p.privateOrdersEnabled &&
        (activeProductsRequired.length === 0 ||
          activeProductsRequired.some(requiredPO =>
            someActiveRightMatches(requiredPO)
          ))
      );
    });

    computeAndSetAvailableItems(availableProducts);
    setProductsAvailable(availableProducts);
  }, [products, activeRights]);

  // Recompute available options on product select
  useEffect(() => {
    setOrderDuration(undefined);
    setOrderPrice(undefined);
    if (product) {
      const showPrice = !product.pricingConfiguration.usePricingCalculator;
      const items = product.options
        .filter(opt => !opt.deleted)
        .map(opt => ({
          id: opt.id,
          name: `${opt.name} : ${localPeriodRenderer(opt.duration) || ''} ${
            showPrice ? ` - ${formatCtsPrice(opt.price)}` : ''
          }`,
        }));
      setProductOptionItems(items);
      populateNewOrder();
    }
  }, [product]);

  // Prepare order to send with last required inputs
  useEffect(() => {
    if (
      subscriber &&
      product &&
      orderDuration &&
      orderPrice !== undefined &&
      (useRight || (!hasEligibilityCondition && !hasBundleCondition)) &&
      startDate &&
      (!product.pricingConfiguration.usePricingCalculator ||
        orderPrice !== undefined) &&
      ((endDate && multipleOrderAllowed) || !multipleOrderAllowed) &&
      plates &&
      mandatoryEvidencesSelect &&
      customFields.every(customField => !!customField.value)
    ) {
      let activeRightToUse: OrderRightReference | undefined;
      if (useRight) {
        activeRightToUse = {
          rightId: useRight.orderId,
          productType: useRight.productType,
        };
      }
      const newOrderReady = createNewDefaultOrder(
        cityId,
        {
          traceType: OrderTraceType.ZONES,
          source: SubscriptionSource.BACK,
          timestamp: new Date().toISOString(),
          subscriberIds: [subscriber.subscriberId],
          agent: userInfo ? { ...userInfo } : null,
          subscriberId: subscriber.subscriberId,
        } as OrderSubscribers,
        product.productId,
        orderDuration,
        orderPrice,
        {
          traceType: OrderTraceType.ZONES,
          source: SubscriptionSource.BACK,
          timestamp: new Date().toISOString(),
          zoneIds: selectedZones,
          agent: userInfo ? { ...userInfo } : null,
          subscriberId: subscriber.subscriberId,
        } as OrderZones,
        {
          traceType: OrderTraceType.VALIDITY_PERIOD,
          source: SubscriptionSource.BACK,
          timestamp: new Date().toISOString(),
          startOfValidity: startDate.toISOString(),
          endOfValidity: endDate
            ? endDate.toISOString()
            : new Date().toISOString(), // will be computed by the back
          agent: userInfo ? { ...userInfo } : null, // will be compute by back
          subscriberId: subscriber.subscriberId,
        } as OrderValidityPeriod,
        {
          traceType: OrderTraceType.PLATES,
          source: SubscriptionSource.BACK,
          timestamp: new Date().toISOString(),
          plates,
          agent: userInfo ? { ...userInfo } : null,
          subscriberId: subscriber.subscriberId,
        } as OrderPlates,
        productType,
        customEmail || undefined,
        mandateUser && !mandateUser.mandateLastName?.length
          ? undefined
          : mandateUser,
        activeRightToUse,
        renewOrderId,
        customFields as Array<OrderCustomField>,
        productEvidences
          .filter(
            evidence =>
              evidence.evidenceSubscriberMediaId || evidence.manuallyChecked
          )
          .map(evidence => ({
            timestamp: new Date().toISOString(),
            traceType: OrderTraceType.EVIDENCE,
            source: SubscriptionSource.BACK,
            agent: userInfo ? { ...userInfo } : null,
            evidencePropertiesId: evidence.evidenceItem.id,
            evidenceSubscriberMediaId: evidence.evidenceSubscriberMediaId,
            manuallyChecked: evidence.manuallyChecked,
            comment: evidence.comment,
            subscriberId: subscriber.subscriberId,
          })) as Array<OrderEvidence>,
        isCreatedForProAccount,
        comment || undefined,
        lang || subscriber.lang
      );
      setOrder(newOrderReady);
    } else {
      setOrder(undefined);
    }
  }, [
    subscriber,
    product,
    orderPrice,
    orderDuration,
    useRight,
    selectedZones,
    plates,
    startDate,
    endDate,
    customEmail,
    productEvidences,
    customFields,
    isCreatedForProAccount,
    orderPrice,
    comment,
    mandateUser,
    lang,
  ]);

  const isProductScopePersonalOrBoth = (
    product: ProductPrivateDTO
  ): boolean => {
    return [
      SubscriberAccountType.BOTH,
      SubscriberAccountType.PERSONAL,
    ].includes(product.conditions.productScope);
  };

  const isProductScopeProOrBoth = (product: ProductPrivateDTO): boolean => {
    return [
      SubscriberAccountType.BOTH,
      SubscriberAccountType.PROFESSIONAL,
    ].includes(product.conditions.productScope);
  };

  const getSubscriberProofOfId = (
    product: ProductPrivateDTO,
    subscriber: SubscriberDTO
  ): SubscriberRequiredProofs | undefined => {
    return product.applicationProcedure?.requiresProofOfId
      ? {
          id: 'proofOfId',
          title: _tg('field.evidenceType.proofOfId'),
          path: '/identity/proofs',
          type: SubscriberRequiredProofsType.SubscriberProfileProof,
          documents: subscriber.identity.documents || [],
          manuallyChecked: subscriber.identity.manuallyChecked || false,
        }
      : undefined;
  };

  const getSubscriberPersonalProofOfAddress = (
    product: ProductPrivateDTO,
    isCreatedForProAccount: boolean,
    subscriber: SubscriberDTO
  ): SubscriberRequiredProofs | undefined => {
    return product.applicationProcedure?.requiresProofOfAddress &&
      isProductScopePersonalOrBoth(product) &&
      !subscriber.personalProfile?.location.dgFipAddressVerified &&
      !isCreatedForProAccount
      ? {
          id: 'personalProofOfAddress',
          title: _tg('field.evidenceType.personalProofOfAddress'),
          path: '/personal-address/proofs',
          type: SubscriberRequiredProofsType.SubscriberProfileProof,
          documents:
            subscriber.personalProfile?.location.proofOfAddress?.documents ||
            [],
          manuallyChecked:
            subscriber.personalProfile?.location.proofOfAddress
              ?.manuallyChecked || false,
        }
      : undefined;
  };

  const getSubscriberProfessionalProofOfAddress = (
    product: ProductPrivateDTO,
    isCreatedForProAccount: boolean,
    subscriber: SubscriberDTO
  ): SubscriberRequiredProofs | undefined => {
    return product.applicationProcedure?.requiresProofOfAddress &&
      isProductScopeProOrBoth(product) &&
      isCreatedForProAccount
      ? {
          id: 'professionalProofOfAddress',
          title: _tg('field.evidenceType.professionalProofOfAddress'),
          path: '/professional-address/proofs',
          type: SubscriberRequiredProofsType.SubscriberProfileProof,
          documents:
            subscriber.professionalProfile?.location.proofOfAddress
              ?.documents || [],
          manuallyChecked:
            subscriber.professionalProfile?.location.proofOfAddress
              ?.manuallyChecked || false,
        }
      : undefined;
  };

  const getSubscriberProfessionalProofOfActivity = (
    product: ProductPrivateDTO,
    isCreatedForProAccount: boolean,
    subscriber: SubscriberDTO
  ): SubscriberRequiredProofs | undefined => {
    return product.applicationProcedure?.requiresProofOfActivity &&
      isProductScopeProOrBoth(product) &&
      isCreatedForProAccount
      ? {
          id: 'professionalProofOfActivity',
          title: _tg('field.evidenceType.professionalProofOfActivity'),
          path: '/professional-enterprise/proofs',
          type: SubscriberRequiredProofsType.SubscriberProfileProof,
          documents:
            subscriber.professionalProfile?.company.proofDocuments?.documents ||
            [],
          manuallyChecked:
            subscriber.professionalProfile?.company.proofDocuments
              ?.manuallyChecked || false,
        }
      : undefined;
  };

  const initSubscriberRequiredProofs = (
    isCreatedForProAccount: boolean
  ): void => {
    if (
      isAdV3Enabled &&
      subscriber &&
      product &&
      product.applicationProcedure
    ) {
      const list = getInitSubscriberRequiredProofs(
        isAdV3Enabled,
        subscriber,
        product,
        isCreatedForProAccount,
        {
          proofOfId: _tg('field.evidenceType.proofOfId'),
          proofOfAddressPerso: _tg('field.evidenceType.personalProofOfAddress'),
          proofOfAddressPro: _tg(
            'field.evidenceType.professionalProofOfAddress'
          ),
          proofOfActivityPro: _tg(
            'field.evidenceType.professionalProofOfActivity'
          ),
        }
      );

      setSubscriberRequiredProofs(list);
      // copy to use before save to update only modified elements
      setSubscriberRequiredProofsInitialValue(_cloneDeep(list));
      setIsSubscriberRequiredProofsInitialized(true);
    }
  };

  const prepareOrCheckProfileTypeForOrder = (): void => {
    // initSubscriberRequiredProofs is called here because it uses isCreatedForProAccount value
    if (product?.conditions.productScope === SubscriberAccountType.BOTH) {
      if (
        isAdV3Enabled
          ? subscriber?.personalProfile !== null &&
            subscriber?.professionalProfile !== null
          : subscriber?.professionalAddress !== null &&
            subscriber?.address !== null
      ) {
        setCanSelectProfileTypeForOrder(true);
        setIsCreatedForProAccount(false);
        initSubscriberRequiredProofs(false);
        return;
      }
      if (
        isAdV3Enabled
          ? subscriber?.professionalProfile !== null
          : subscriber?.professionalAddress !== null
      ) {
        setCanSelectProfileTypeForOrder(false);
        setIsCreatedForProAccount(true);
        initSubscriberRequiredProofs(true);
        return;
      }
      setCanSelectProfileTypeForOrder(false);
      setIsCreatedForProAccount(false);
      initSubscriberRequiredProofs(false);
      return;
    }
    if (
      product?.conditions.productScope === SubscriberAccountType.PROFESSIONAL &&
      (isAdV3Enabled
        ? subscriber?.professionalProfile !== null
        : subscriber?.professionalAddress !== null)
    ) {
      setCanSelectProfileTypeForOrder(false);
      setIsCreatedForProAccount(true);
      initSubscriberRequiredProofs(true);
      return;
    }
    setCanSelectProfileTypeForOrder(false);
    setIsCreatedForProAccount(false);
    initSubscriberRequiredProofs(false);
  };

  useEffect(() => {
    prepareOrCheckProfileTypeForOrder();
  }, [subscriberId, product]);

  function initOrderEvidences(): void {
    if (
      product &&
      product.applicationProcedure &&
      product.applicationProcedure.supportingEvidences
    ) {
      const orderEvidences = product.applicationProcedure.supportingEvidences.map(
        evidenceItem => ({
          evidenceSubscriberMediaId: '',
          comment: '',
          manuallyChecked: false,
          evidenceItem,
          mandatory: evidenceItem.mandatory,
          id: evidenceItem.id,
        })
      );
      setProductEvidences(orderEvidences);
    }
  }

  function initCustomFields(): void {
    if (
      product &&
      product.applicationProcedure &&
      product.applicationProcedure.customFields
    ) {
      const emptyCustomFields = product.applicationProcedure.customFields
        .filter(customField => !customField.deleted)
        .map(
          customField =>
            ({
              traceType: OrderTraceType.CUSTOM_FIELD,
              source: SubscriptionSource.BACK,
              timestamp: new Date().toISOString(),
              subsriberIds: [subscriber?.subscriberId],
              agent: userInfo ? { ...userInfo } : null,
              subscriberId: subscriber?.subscriberId,
              key: customField.key,
              value: '',
              comment: '',
              label: customField.label,
            } as OrderCustomFieldComplete)
        );
      setCustomFields(emptyCustomFields);
    }
  }

  // isCreatedForProAccountValue is used only when the user check/uncheck its checkbox so when productScope = BOTH
  const updateCanSubscriberTakeProduct = (
    isCreatedForProAccountValue?: boolean
  ): void => {
    if (isAdV3Enabled && product && subscriber) {
      // test reasons : product require proof of address AND subscriber doesnt have a profile
      if (product.applicationProcedure?.requiresProofOfAddress) {
        if (
          ([
            SubscriberAccountType.PERSONAL,
            SubscriberAccountType.BOTH,
          ].includes(product.conditions.productScope) ||
            (product.conditions.productScope === SubscriberAccountType.BOTH &&
              isCreatedForProAccountValue === false)) &&
          !subscriber.personalProfile
        ) {
          setCanSubscriberTakeProduct(false);
          setReasonSubscriberCannotTakeProduct(
            _t('subscriberCannotTakeProduct.personal')
          );
          return;
        }
        if (
          (product.conditions.productScope ===
            SubscriberAccountType.PROFESSIONAL ||
            (product.conditions.productScope === SubscriberAccountType.BOTH &&
              isCreatedForProAccountValue === true)) &&
          !subscriber.professionalProfile
        ) {
          setCanSubscriberTakeProduct(false);
          setReasonSubscriberCannotTakeProduct(
            _t('subscriberCannotTakeProduct.professional')
          );
          return;
        }
      }
      // test reason : product require vehicle document AND subscriber doesnt have a vehicle
      if (
        !product.restrictions.canOrderWithoutPlateMatchingVehicle &&
        filterSubscriberVehiclesFromProductScope(
          product,
          subscriber,
          isCreatedForProAccountValue !== undefined
            ? product.conditions.productScope === SubscriberAccountType.BOTH &&
                isCreatedForProAccountValue
            : product.conditions.productScope ===
                SubscriberAccountType.PROFESSIONAL
        ).length === 0
      ) {
        setCanSubscriberTakeProduct(false);
        setReasonSubscriberCannotTakeProduct(
          _t('subscriberCannotTakeProduct.vehicleRequired')
        );
        return;
      }
    }
    setCanSubscriberTakeProduct(true);
  };

  // when product is updated populate productEvidences and customFields and check for subscriber vehicles
  useMemo(() => {
    initOrderEvidences();
    initCustomFields();
    updateCanSubscriberTakeProduct();
  }, [product]);

  useMemo(checkMandatoryEvidencesSelected, [productEvidences]);

  const hasSubscriberRequiredProofsById = (id: string): boolean => {
    return subscriberRequiredProofs.some(element => element.id === id);
  };

  const getVehiclePathByVehicleId = (
    subscriber: SubscriberDTO,
    vehicleId: string
  ): VehiclePatchEndPoint.perso | VehiclePatchEndPoint.pro => {
    const personalVehicles = subscriber.personalProfile?.vehicles || [];
    const professionalVehicles = subscriber.professionalProfile?.vehicles || [];

    if (
      isCreatedForProAccount &&
      professionalVehicles.some(vehicle => vehicle.id === vehicleId)
    ) {
      return VehiclePatchEndPoint.pro;
    }
    if (personalVehicles.some(vehicle => vehicle.id === vehicleId)) {
      return VehiclePatchEndPoint.perso;
    }

    throw new Error("Shouldn't happen! [getVehiclePathByVehicleId]");
  };

  const updateSubscriberRequiredProofsProfile = (
    product: ProductPrivateDTO,
    subscriber: SubscriberDTO,
    isCreatedForProAccount: boolean,
    subscriberRequiredProofs: SubscriberRequiredProofs[]
  ): SubscriberRequiredProofs[] => {
    const subscriberProofOfId = getSubscriberProofOfId(product, subscriber);
    const subscriberPersonalProofOfAddress = getSubscriberPersonalProofOfAddress(
      product,
      isCreatedForProAccount,
      subscriber
    );
    const subscriberProfessionalProofOfAddress = getSubscriberProfessionalProofOfAddress(
      product,
      isCreatedForProAccount,
      subscriber
    );
    const subscriberProfessionalProofOfActivity = getSubscriberProfessionalProofOfActivity(
      product,
      isCreatedForProAccount,
      subscriber
    );

    const newSubscriberRequiredProofs: SubscriberRequiredProofs[] = _cloneDeep(
      subscriberRequiredProofs
    ).filter(
      element =>
        element.type === SubscriberRequiredProofsType.SubscriberVehicleProof ||
        element.id === subscriberProofOfId?.id ||
        (isCreatedForProAccount &&
          [
            subscriberProfessionalProofOfAddress?.id,
            subscriberProfessionalProofOfActivity?.id,
          ].includes(element.id)) ||
        (!isCreatedForProAccount &&
          element.id === subscriberPersonalProofOfAddress?.id)
    );

    let insertNewRequiredProofsAtIndex = newSubscriberRequiredProofs.findIndex(
      element =>
        element.type === SubscriberRequiredProofsType.SubscriberVehicleProof
    );
    if (insertNewRequiredProofsAtIndex === -1) {
      insertNewRequiredProofsAtIndex = newSubscriberRequiredProofs.length;
    }
    if (isCreatedForProAccount) {
      if (subscriberProfessionalProofOfAddress) {
        newSubscriberRequiredProofs.splice(
          insertNewRequiredProofsAtIndex,
          0,
          subscriberProfessionalProofOfAddress
        );
      }
      if (subscriberProfessionalProofOfActivity) {
        newSubscriberRequiredProofs.splice(
          insertNewRequiredProofsAtIndex,
          0,
          subscriberProfessionalProofOfActivity
        );
      }
    } else if (subscriberPersonalProofOfAddress) {
      newSubscriberRequiredProofs.splice(
        insertNewRequiredProofsAtIndex,
        0,
        subscriberPersonalProofOfAddress
      );
    }

    setSubscriberRequiredProofs(newSubscriberRequiredProofs);
    return newSubscriberRequiredProofs;
  };

  const updateSubscriberRequiredProofsVehicles = (
    product: ProductPrivateDTO,
    subscriber: SubscriberDTO,
    plates: string[],
    isCreatedForProAccount: boolean,
    subscriberRequiredProofs: SubscriberRequiredProofs[]
  ): SubscriberRequiredProofs[] => {
    const subscriberChoosableVehicles = filterSubscriberVehiclesFromProductScope(
      product,
      subscriber,
      isCreatedForProAccount
    );

    const newSubscriberRequiredProofs: SubscriberRequiredProofs[] = _cloneDeep(
      subscriberRequiredProofs
    );

    const platesVehicles: SubscriberVehicle[] = [];

    plates.forEach(plate => {
      subscriberChoosableVehicles.forEach(vehicle => {
        if (vehicle.plate === plate) {
          platesVehicles.push(vehicle);
          // add elements corresponding to added plates
          if (!hasSubscriberRequiredProofsById(vehicle.id)) {
            newSubscriberRequiredProofs.push({
              id: vehicle.id,
              title: vehicle.name,
              path: getVehiclePathByVehicleId(subscriber, vehicle.id),
              type: SubscriberRequiredProofsType.SubscriberVehicleProof,
              documents: vehicle.registrationDocuments?.documents || [],
              manuallyChecked:
                vehicle.registrationDocuments?.manuallyChecked || false,
              vehicle,
            });
          }
        }
      });
    });

    // remove elements corresponding to plates not present anymore
    newSubscriberRequiredProofs.forEach((element, index) => {
      if (
        element.type === SubscriberRequiredProofsType.SubscriberVehicleProof &&
        !platesVehicles.some(vehicle => vehicle.id === element.id)
      ) {
        newSubscriberRequiredProofs.splice(index, 1);
      }
    });

    setSubscriberRequiredProofs(newSubscriberRequiredProofs);
    return newSubscriberRequiredProofs;
  };

  useMemo(() => {
    if (
      isAdV3Enabled &&
      subscriber &&
      product &&
      !product.restrictions.canOrderWithoutPlateMatchingVehicle &&
      isSubscriberRequiredProofsInitialized
    ) {
      updateSubscriberRequiredProofsVehicles(
        product,
        subscriber,
        plates,
        isCreatedForProAccount,
        subscriberRequiredProofs
      );
    }
  }, [plates]);

  const hasSubscriberRequiredProofs = (): boolean => {
    return subscriberRequiredProofs.every(
      element => element.documents.length > 0 || element.manuallyChecked
    );
  };

  const onCheck = (): void => {
    setIsCreatedForProAccount(!isCreatedForProAccount);
    if (isAdV3Enabled) {
      updateCanSubscriberTakeProduct(!isCreatedForProAccount);
      setPlates([]);
      if (product && subscriber) {
        const newSubscriberRequiredProofs = updateSubscriberRequiredProofsProfile(
          product,
          subscriber,
          !isCreatedForProAccount,
          subscriberRequiredProofs
        );
        updateSubscriberRequiredProofsVehicles(
          product,
          subscriber,
          plates,
          !isCreatedForProAccount,
          newSubscriberRequiredProofs
        );
      }
    }
  };

  function handleChangeComment(
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const field = e.currentTarget.value;
    if (field) {
      setFieldComment(field);
    }
  }

  function handleChangeMandate(
    firstname: string | undefined,
    lastname: string | undefined
  ) {
    if ((!firstname || !firstname.length) && (!lastname || !lastname.length)) {
      setMandateUser(undefined);
    } else {
      setMandateUser({
        mandateFirstName: firstname,
        mandateLastName: lastname,
      });
    }
  }

  const firstValidDate = useMemo<Date | undefined>(() => {
    const blockedPeriodFromStartOfValidityInDays =
      product?.restrictions.blockedPeriodFromStartOfValidityInDays;

    if (!blockedPeriodFromStartOfValidityInDays) {
      return undefined;
    }

    return addDays(new Date(), blockedPeriodFromStartOfValidityInDays);
  }, [product]);

  // This effect sets the preselected start and end date
  // to a valid date if the current date is invalid.
  useEffect(() => {
    setStartDate(date => {
      if (!date && firstValidDate && new Date() < firstValidDate) {
        return firstValidDate;
      }
      return date;
    });
  }, [firstValidDate]);

  const createDisabled =
    !order ||
    (product?.restrictions.canUseCustomEmail &&
      customEmail &&
      !!validateEmail(customEmail)) ||
    (isAdV3Enabled && !hasSubscriberRequiredProofs());

  const mustContainPlate = !!product?.restrictions.mustContainPlate;
  const mustOrderWithPlate = !!product?.restrictions.mustOrderWithPlate;
  const mustUseActiveEligibilityPlate = !!product?.restrictions
    .mustUseActiveEligibilityPlate;
  const canSelectAnyPlate =
    (product?.productType === ProductType.BUNDLE &&
      !mustUseActiveEligibilityPlate) ||
    !!product?.restrictions.canOrderWithoutPlateMatchingVehicle;

  const setFieldsFromStep = (step?: PricingPolicyStepDTO): void => {
    if (step) {
      setOrderPrice(step.price);
      setEndDate(new Date(step.endDatetime));
      setOrderDuration(step.duration);
    }
  };

  const content = product?.pricingConfiguration.useTariffEngine ? (
    <>
      <div className="order-detail_row">
        <OrderCreateSubscriberChoice
          subscribersItems={subscribersItems}
          onChange={handleSubscriberChange}
          onAutocomplete={findSubscriber}
          subscriberId={subscriberId as string}
        />
      </div>
      {langEnabled && subscriber && lang && (
        <OrderCreateSubscriberLang lang={lang} setLang={setLang} />
      )}
      {subscriber && (
        <div className="order-detail_row">
          <OrderCreateProductChoice
            product={product}
            onChange={handleProductChange}
            onCheck={onCheck}
            productsAvailableItems={productsAvailableItems}
            createdForProAccount={isCreatedForProAccount}
            canSelectProfileTypeForOrder={canSelectProfileTypeForOrder}
          />
        </div>
      )}
      {product && hasEligibilityCondition && (
        <OrderCreateProductRequired
          activeProductsRequired={product.conditions.activeProductsRequired}
          productItems={productItems}
          selectedRightId={selectedEligibilityId}
          activeRights={activeRights.filter(
            r => r.productType === 'ELIGIBILITY'
          )}
          label={_t('field.eligibilityRestriction')}
          onChange={handleEligibityRestrictionChange}
        />
      )}
      {product && hasBundleCondition && (
        <OrderCreateProductRequired
          activeProductsRequired={product.conditions.activeProductsRequired}
          productItems={productItems}
          selectedRightId={selectedBundleId}
          activeRights={activeRights.filter(r => r.productType === 'BUNDLE')}
          label={_t('field.bundleRestriction')}
          onChange={handleBundleRestrictionChange}
        />
      )}
      {product && product.applicationProcedure?.allowRequestForOther && (
        <div className="order-detail_row">
          <span className="order-detail_cell-25">
            {_t('field.requestForOther')}
          </span>
          <span className="order-detail_cell-75 order-detail-page_component">
            <Flex gap={5}>
              <TextField
                name="mandateLastName"
                margin="normal"
                variant="outlined"
                fullWidth
                style={{ flexBasis: '50%' }}
                label={_tg(
                  'tefps.subscription.orderCreatePage.mandateLastName'
                )}
                onChange={(lastName): void => {
                  handleChangeMandate(
                    mandateUser?.mandateFirstName,
                    lastName.target?.value
                  );
                }}
              />
              <TextField
                name="mandateFirstName"
                margin="normal"
                variant="outlined"
                fullWidth
                style={{ flexBasis: '50%' }}
                label={_tg(
                  'tefps.subscription.orderCreatePage.mandateFirstName'
                )}
                onChange={(firstname): void => {
                  handleChangeMandate(
                    firstname.target?.value,
                    mandateUser?.mandateLastName
                  );
                }}
              />
            </Flex>
          </span>
        </div>
      )}
      {product && zones && (
        <OrderCreateZones
          product={product}
          activeRights={activeRights}
          selectedEligibilityId={selectedEligibilityId}
          selectedBundleId={selectedBundleId}
          selectedZones={selectedZones}
          onChange={handleZonesChange}
        />
      )}
      {subscriber && product && (
        <>
          <div className="order-detail_row">
            <div className="order-detail_cell-25 plate">
              <span>{_t('field.plate')}</span>
              <span className="plate-hint">{_t('plateHint')}</span>
            </div>
            <span className="order-detail_cell-75">
              <OrderCreatePlates
                canSelectAnyPlate={canSelectAnyPlate}
                mustOrderWithPlate={mustOrderWithPlate}
                mustUseActiveEligibilityPlate={mustUseActiveEligibilityPlate}
                plates={plates}
                choosablePlates={choosablePlates}
                maxAmountOfPlates={1}
                subscriberVehicles={filterSubscriberVehiclesFromProductVehiclesCategories(
                  product,
                  filterSubscriberVehiclesFromProductScope(
                    product,
                    subscriber,
                    isCreatedForProAccount
                  )
                )}
                onChange={handlePlateChange}
                product={product}
              />
            </span>
          </div>
          <div className="order-detail_row">
            <span className="order-detail_cell-25">
              {_t('field.startDate')}
            </span>
            <span className="order-detail_cell-75 order-detail-page_component">
              <BoDatePicker
                id="order-start-date-picker-2"
                DateTimeFormat={
                  selectedLang.includes('fr')
                    ? IntlPolyfill.DateTimeFormat
                    : undefined
                }
                value={startDate}
                onChange={handleStartDateChange}
                formatDate={formatDate}
                minDate={firstValidDate}
              />
              <TimePicker
                okLabel={_tg('action.ok').toUpperCase()}
                cancelLabel={_tg('action.cancel')}
                format="24hr"
                onChange={handleStartTimeChange}
                value={startDate}
                textFieldStyle={{ width: 100 }}
                style={{ marginLeft: 20 }}
              />
            </span>
          </div>
        </>
      )}
      <>
        {startDate &&
          plates &&
          plates[0] &&
          selectedZones &&
          selectedZones[0] && (
            <OrderCreateTariffEngineSelect
              plate={plates[0]}
              startOfValidity={startDate.toISOString()}
              zone={selectedZones[0]}
              setOrderFieldsFromStep={setFieldsFromStep}
              usedRight={useRight}
              product={product}
            />
          )}
        {endDate && (
          <div className="order-detail_row">
            <span className="order-detail_cell-25">{_t('field.endDate')}</span>
            <span className="order-detail_cell-75 order-detail-page_component">
              <BoDatePicker
                id="order-end-date-picker"
                DateTimeFormat={
                  selectedLang.includes('fr')
                    ? IntlPolyfill.DateTimeFormat
                    : undefined
                }
                value={endDate}
                disabled
                formatDate={formatDate}
              />
              <TimePicker
                format="24hr"
                disabled
                value={endDate}
                textFieldStyle={{ width: 100 }}
                style={{ marginLeft: 20 }}
              />
            </span>
          </div>
        )}
      </>
    </>
  ) : (
    <>
      <div className="order-detail_row">
        <OrderCreateSubscriberChoice
          subscribersItems={subscribersItems}
          onChange={handleSubscriberChange}
          onAutocomplete={findSubscriber}
          subscriberId={subscriberId as string}
        />
      </div>
      {langEnabled && subscriber && lang && (
        <OrderCreateSubscriberLang lang={lang} setLang={setLang} />
      )}
      {subscriber && (
        <div className="order-detail_row">
          <OrderCreateProductChoice
            product={product}
            onChange={handleProductChange}
            onCheck={onCheck}
            productsAvailableItems={productsAvailableItems}
            createdForProAccount={isCreatedForProAccount}
            canSelectProfileTypeForOrder={canSelectProfileTypeForOrder}
          />
        </div>
      )}
      {subscriber && product && (
        <div className="order-detail_row">
          {canSubscriberTakeProduct ? (
            <OrderCreateProductOptionChoice
              productOption={product?.options.find(
                o => o.duration === orderDuration
              )}
              onChange={handleProductOptionChange}
              productOptionItems={productOptionItems}
            />
          ) : (
            <>
              <div className="order-detail_cell-25" />
              <div className="order-detail_cell-75">
                <p style={{ color: TXT_RED }}>
                  {reasonSubscriberCannotTakeProduct}
                </p>
              </div>
            </>
          )}
        </div>
      )}
      {product && hasEligibilityCondition && (
        <OrderCreateProductRequired
          activeProductsRequired={product.conditions.activeProductsRequired}
          productItems={productItems}
          selectedRightId={selectedEligibilityId}
          activeRights={activeRights.filter(
            r => r.productType === 'ELIGIBILITY'
          )}
          label={_t('field.eligibilityRestriction')}
          onChange={handleEligibityRestrictionChange}
        />
      )}
      {product && hasBundleCondition && (
        <OrderCreateProductRequired
          activeProductsRequired={product.conditions.activeProductsRequired}
          productItems={productItems}
          selectedRightId={selectedBundleId}
          activeRights={activeRights.filter(r => r.productType === 'BUNDLE')}
          label={_t('field.bundleRestriction')}
          onChange={handleBundleRestrictionChange}
        />
      )}
      {product &&
        orderDuration &&
        product.applicationProcedure?.allowRequestForOther && (
          <div className="order-detail_row">
            <span className="order-detail_cell-25">
              {_t('field.requestForOther')}
            </span>
            <span className="order-detail_cell-75 order-detail-page_component">
              <Flex gap={5}>
                <TextField
                  name="mandateLastName"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  style={{ flexBasis: '50%' }}
                  label={_tg(
                    'tefps.subscription.orderCreatePage.mandateLastName'
                  )}
                  onChange={(lastName): void => {
                    handleChangeMandate(
                      mandateUser?.mandateFirstName,
                      lastName.target?.value
                    );
                  }}
                />
                <TextField
                  name="mandateFirstName"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  style={{ flexBasis: '50%' }}
                  label={_tg(
                    'tefps.subscription.orderCreatePage.mandateFirstName'
                  )}
                  onChange={(firstname): void => {
                    handleChangeMandate(
                      firstname.target?.value,
                      mandateUser?.mandateLastName
                    );
                  }}
                />
              </Flex>
            </span>
          </div>
        )}
      {product && orderDuration && product.zones.mustPickOne && zones && (
        <OrderCreateZones
          product={product}
          activeRights={activeRights}
          selectedEligibilityId={selectedEligibilityId}
          selectedBundleId={selectedBundleId}
          selectedZones={selectedZones}
          onChange={handleZonesChange}
        />
      )}
      {subscriber && product && orderDuration && mustContainPlate && (
        <div className="order-detail_row">
          <div className="order-detail_cell-25 plate">
            <span>{_t('field.plate')}</span>
            <span className="plate-hint">{_t('plateHint')}</span>
          </div>
          <span className="order-detail_cell-75">
            <OrderCreatePlates
              canSelectAnyPlate={canSelectAnyPlate}
              mustOrderWithPlate={mustOrderWithPlate}
              mustUseActiveEligibilityPlate={mustUseActiveEligibilityPlate}
              plates={plates}
              choosablePlates={choosablePlates}
              maxAmountOfPlates={product.restrictions.plateLimit}
              subscriberVehicles={filterSubscriberVehiclesFromProductVehiclesCategories(
                product,
                filterSubscriberVehiclesFromProductScope(
                  product,
                  subscriber,
                  isCreatedForProAccount
                )
              )}
              onChange={handlePlateChange}
              product={product}
            />
          </span>
        </div>
      )}
      {subscriber && product && orderDuration && (
        <>
          <div className="order-detail_row">
            <span className="order-detail_cell-25">
              {_t('field.startDate')}
            </span>
            <span className="order-detail_cell-75 order-detail-page_component">
              <BoDatePicker
                id="order-start-date-picker-2"
                DateTimeFormat={
                  selectedLang.includes('fr')
                    ? IntlPolyfill.DateTimeFormat
                    : undefined
                }
                value={startDate}
                onChange={handleStartDateChange}
                formatDate={formatDate}
                minDate={firstValidDate}
              />
              {product.computeExactHourlyEnd && (
                <TimePicker
                  okLabel={_tg('action.ok').toUpperCase()}
                  cancelLabel={_tg('action.cancel')}
                  format="24hr"
                  onChange={handleStartTimeChange}
                  value={startDate}
                  textFieldStyle={{ width: 100 }}
                  style={{ marginLeft: 20 }}
                />
              )}
            </span>
          </div>
          {multipleOrderAllowed && (
            <div className="order-detail_row">
              <span className="order-detail_cell-25">
                {_t('field.endDate')}
              </span>
              <span className="order-detail_cell-75 order-detail-page_component">
                <BoDatePicker
                  id="order-end-date-picker"
                  DateTimeFormat={
                    selectedLang.includes('fr')
                      ? IntlPolyfill.DateTimeFormat
                      : undefined
                  }
                  value={endDate}
                  onChange={handleEndDateChange}
                  formatDate={formatDate}
                  minDate={startDate}
                />
              </span>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <div className="order-detail-page_component">
      <Sidebar />
      <div className="order-detail-page_container">
        <div className="order-detail-page_content" style={{ height: 'auto' }}>
          {content}
          {subscriber &&
            product &&
            orderDuration &&
            product.restrictions.canUseCustomEmail && (
              <div className="order-detail_row">
                <span className="order-detail_cell-25">
                  {_t('field.customEmail')}
                </span>
                <span className="order-detail_cell-25">
                  <TextFieldV0
                    value={customEmail}
                    onChange={handleCustomEmailChange}
                    errorText={
                      customEmail ? validateEmail(customEmail) : undefined
                    }
                  />
                </span>
              </div>
            )}

          {isAdV3Enabled &&
            subscriber &&
            orderDuration &&
            startDate &&
            subscriberRequiredProofs.length > 0 && (
              <OrderCreateSubscriberRequiredProofs
                subscriberRequiredProofs={subscriberRequiredProofs}
                subscriber={subscriber}
                setSubscriberRequiredProofs={setSubscriberRequiredProofs}
              />
            )}

          {subscriber &&
            orderDuration &&
            startDate &&
            product &&
            product.applicationProcedure?.supportingEvidences &&
            productEvidences.length > 0 && (
              <OrderCreateProductEvidences
                subscriber={subscriber}
                setSubscriber={setSubscriber}
                productEvidences={productEvidences}
                setProductEvidences={setProductEvidences}
              />
            )}

          {subscriber &&
            orderDuration &&
            startDate &&
            product &&
            product.applicationProcedure?.customFields &&
            customFields.length > 0 && (
              <OrderCreateCustomFields
                customFields={customFields}
                setCustomFields={setCustomFields}
              />
            )}

          {subscriber && orderDuration && startDate && product && (
            <Flex>
              <TextField
                name="comment"
                multiline
                rowsMax={4}
                margin="normal"
                variant="outlined"
                fullWidth
                style={{ flexBasis: '50%' }}
                label={_tg('tefps.subscription.orderCreatePage.commentField')}
                helperText={_tg(
                  'tefps.subscription.orderCreatePage.helperText'
                )}
                onChange={handleChangeComment}
              />
            </Flex>
          )}
          {loading && (
            <div className="order-detail_row">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="order-detail_actions">
          <span className="order-detail_cell-25" />
          <span className="order-detail_cell-12" />
          <span className="order-detail_cell-12">
            <BoButton
              fullWidth
              label={_tg('action.back')}
              onClick={onCancelAction}
            />
          </span>
          <span className="order-detail_cell-12">
            <BoButton
              fullWidth
              primary
              label={_tg('action.create')}
              onClick={onConfirmAction}
              disabled={createDisabled}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: InternalApiState): OrderCreatePageReduxProps {
  const { cityId, userInfo } = getApiState(state);
  const {
    subscriptionConfigurationDTO,
    authorizedVehicleCategories,
    selectedLang,
  } = getConfigState(state);
  return {
    cityId: cityId || '',
    userInfo,
    isAdV3Enabled: subscriptionConfigurationDTO.adV3Enabled,
    langEnabled: subscriptionConfigurationDTO.langEnabled,
    authorizedVehicleCategories,
    selectedLang,
  };
}

export default connect(mapStateToProps)(OrderCreatePage);
