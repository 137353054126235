import React from 'react';
import './OverviewBlock.css';
import { Card } from 'material-ui';
import { Link } from 'react-router-dom';

import BoButton from 'facade/BoButton';
import { getRecourseStatus } from 'tefps/RecoursesV2/utils/translationUtils';
import FpsNumber from 'commons/FpsNumber';
import Tag from 'commons/Tag';
import useRecourse from 'commons/hooks/useRecourse';
import { BKG_CYAN } from 'theme';

import OverviewBlockField from './OverviewBlockField';

const { _t, _tg } = window.loadTranslations(__filename);

const OverviewBlock = (): JSX.Element => {
  const recourseDetails = useRecourse();
  return recourseDetails ? (
    <Card className="overview-block">
      <div className="overview-block__header">
        <div className="text-bold">
          {_t('element.title', {
            rapoNumber: recourseDetails.recourse.rapoNumber,
          })}
        </div>
        <div className="text--small">
          <span>{_t('element.fpsNumber')}</span>
          <span className="text-bold">
            <FpsNumber fpsId={recourseDetails.fps.rootFineLegalId} />
          </span>
        </div>
      </div>
      <div className="overview-block__content">
        <div className="row">
          <OverviewBlockField
            label={_tg('field.vehicle.license')}
            value={recourseDetails.fps.licensePlate.plate}
          />
          <OverviewBlockField
            label={_tg('field.vehicle.country')}
            value={recourseDetails.fps.licensePlate.plateCountry}
          />
          <OverviewBlockField
            label={_t('element.recourseStatus')}
            value={
              <BoButton
                label={getRecourseStatus(recourseDetails.recourse.status)}
                disabledBackgroundColor="var(--bg-color--cyan)"
                disabledLabelColor="white"
                disabled
              />
            }
          />
        </div>
        {recourseDetails.recourse.createdByAgent === true && (
          <div
            className="row"
            style={{
              justifyContent: 'left',
              width: '368px',
            }}
          >
            <OverviewBlockField
              label={_tg('field.source')}
              value={_tg('field.agentSource')}
            />
          </div>
        )}
        <div className="overview-block__tag-container">
          {recourseDetails.recourse.tags.map((tag, i) => (
            <Tag key={i} tag={tag} />
          ))}
        </div>
        {recourseDetails.oldRapoId && (
          <div className="overview-block__color">
            {_t('element.alerteIrreceivableRAPO')}
            <Link
              to={`/recourses/detail/${recourseDetails.oldRapoId}`}
              style={{ color: BKG_CYAN }}
            >
              {' '}
              {_t('element.oldRapoHyperLink')}
            </Link>
          </div>
        )}
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default OverviewBlock;
