import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { ComplementRequestDTO, RecourseDTO } from 'api/recourse/types';
import { getComplementRequestFiles } from 'tefps/RecoursesV2/utils/recourseFilesUtils';

import FilesContainer from '../FilesContainer';

const { _t } = window.loadTranslations(__filename);

type ComplementRequestReceptionProps = {
  complementRequest: ComplementRequestDTO;
  recourse: RecourseDTO;
};

const ComplementRequestReception = ({
  complementRequest,
  recourse,
}: ComplementRequestReceptionProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_t('field.receptionDate')}
            value={
              <Date
                datetime={complementRequest.completionDate}
                withTime={false}
              />
            }
          />
          <FakeInputBlock
            title={_t('field.sendDate')}
            value={
              <Date
                datetime={complementRequest.creationDate}
                withDate={false}
              />
            }
          />
        </DataBoxItem>
        <Separator />
        <div className="row">
          <FilesContainer
            created={recourse.created}
            files={getComplementRequestFiles(recourse)}
            reason={recourse.reason}
            rapoId={recourse.rapoId}
          />
        </div>
      </DataBoxItemWrapper>
    </div>
  );
};

export default ComplementRequestReception;
