import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import DescriptionIcon from 'material-ui/svg-icons/action/description';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import ConfirmationNumberIcon from 'material-ui/svg-icons/notification/confirmation-number';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import LiveHelpIcon from 'material-ui/svg-icons/communication/live-help';
import InfoIcon from 'material-ui/svg-icons/action/info';

import { Flex, Tabs, Tab } from '@cvfm-front/commons-ui';
import Content from 'commons/Content';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';
import Loading from 'tefps/RecoursesV2/commons/Loading';
import ErrorPage from 'tefps/RecoursesV2/commons/ErrorPage';
import Services from 'commons/services';
import useRecourse from 'commons/hooks/useRecourse';

import ProposalDetail from './ProposalDetail';
import EmailGuide from './EmailGuide';
import ControlSummary from './ControlSummary';
import FpsHistory from './FpsHistory';
import FpsSummary from './FpsSummary';
import RecourseSummary from './RecourseSummary';
import TicketSummary from './TicketSummary';

import './Proposal.css';
import Watchings from 'commons/Watchings';

const { _tg } = window.loadTranslations(__filename);
const { _t } = window.loadTranslations(__filename);

const Proposal = (): JSX.Element => {
  const history = useHistory();
  const recourse = useRecourse();

  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);

  const currentTab = useRef<number | undefined>(undefined);

  const getRecourseReasons = async (): Promise<void> => {
    try {
      await Services.recourseReasons.init();
      Services.proposal.init();
      Services.decisionPrice.init();
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const getControl = async (): Promise<void> => {
    try {
      if (recourse && recourse.fps.controlId) {
        await Services.control.init(recourse.fps.controlId);
      }
      setError(undefined);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const getParkingRights = async (): Promise<void> => {
    try {
      if (recourse && recourse.fps.parkingRightId) {
        await Services.parkingRights.init(
          recourse.fps.licensePlate.plate,
          recourse.fps.statementDatetime
        );
      }
      setError(undefined);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const getZoning = async (): Promise<void> => {
    try {
      if (recourse) {
        await Services.zoning.init();
      }
      setError(undefined);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  const getLatestFps = async (fpsId: string): Promise<void> => {
    try {
      await Services.fps.init(fpsId);
      Services.fpsPayments.init();
    } catch (e) {
      setError(e as Error);
    }
  };

  const getEligibilities = async (): Promise<void> => {
    try {
      if (recourse) {
        await Services.eligibilities.init(recourse.fps.licensePlate.plate);
      }
      setError(undefined);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getRecourseReasons();
    void getControl();
    void getZoning();
    void getEligibilities();
    if (recourse) void getLatestFps(recourse.latestFpsVersion);
    void getParkingRights();
  }, [recourse]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      if (currentTab.current !== undefined && currentTab.current !== 0) {
        currentTab.current -= 1;
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
      if (currentTab.current === undefined) {
        currentTab.current = 0;
      } else {
        currentTab.current += 1;
      }
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      currentTab.current = undefined;
    }
    setActiveTab(currentTab.current);
  };

  // Add event listener to handle keyboard shortcuts
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  if (!recourse) {
    history.goBack();
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorPage
        message={_tg('feedback.error.fetchRecourse')}
        error={error}
        navContent={
          <ReturnButton
            href="#/recourses/list"
            label={_tg('action.backToList')}
          />
        }
      />
    );
  }

  if (!recourse) {
    history.goBack();
    return <></>; // Tell TypeScript rendering has finished
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Content style={{ padding: 0 }}>
      <Flex className="navigation-bar">
        <ReturnButton
          href={`#/recourses/detail/${recourse.recourse.rapoId}`}
          label={_tg('action.backToRecourse')}
        />
      </Flex>
      <div className="proposal-container">
        <Tabs
          defaultActiveTabIndex={activeTab ?? undefined}
          tabsClassName="icon-large"
          orientation="vertical"
        >
          <Tab
            title={
              <Flex flexDirection="column">
                <DescriptionIcon color="#767676" />
                <span className="table-title__text">
                  {_t('tabs.fpsSummary')}
                </span>
              </Flex>
            }
          >
            <FpsSummary />
          </Tab>
          <Tab
            title={
              <Flex flexDirection="column">
                <HistoryIcon color="#767676" />
                <span className="table-title__text">
                  {_t('tabs.fpsHistoric')}
                </span>
              </Flex>
            }
          >
            <FpsHistory />
          </Tab>
          <Tab
            title={
              <Flex flexDirection="column">
                <ConfirmationNumberIcon color="#767676" />
                <span className="table-title__text">
                  {_t('tabs.ticketList')}
                </span>
              </Flex>
            }
          >
            <TicketSummary recoursePlate={recourse.fps.licensePlate.plate} />
          </Tab>
          <Tab
            title={
              <Flex flexDirection="column">
                <AccountCircleIcon color="#767676" />
                <span className="table-title__text">
                  {_t('tabs.recourseSummary')}
                </span>
              </Flex>
            }
          >
            <RecourseSummary />
          </Tab>
          <Tab
            title={
              <Flex flexDirection="column">
                <InfoIcon color="#767676" />{' '}
                <span className="table-title__text">{_t('tabs.controls')}</span>
              </Flex>
            }
          >
            <ControlSummary />
          </Tab>
          <Tab
            title={
              <Flex flexDirection="column">
                <LiveHelpIcon color="#767676" />
                <span className="table-title__text">{_t('tabs.guide')}</span>
              </Flex>
            }
          >
            <EmailGuide />
          </Tab>
        </Tabs>
        {/* Proposal mail with rich text editor */}
        <ProposalDetail />
      </div>
      {recourse?.recourse && (
        <Watchings resourceType="RAPO" resourceId={recourse?.recourse.rapoId} />
      )}
    </Content>
  );
};

export default Proposal;
