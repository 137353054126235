import React from 'react';

import { OrderEvidenceRequest } from '@cvfm-front/tefps-types';

import './OrderDetailPage.css';
import FakeInputBlock from 'commons/FakeInputBlock';
import { DataBoxItem } from 'commons/DataBox';

const { _t } = window.loadTranslations(__filename);

type OrderDetailEvidenceRequestHistoryProps = {
  orderTrace: OrderEvidenceRequest;
};

const OrderDetailEvidenceRequestHistory = ({
  orderTrace,
}: OrderDetailEvidenceRequestHistoryProps): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_t('name')}
        value={orderTrace.evidenceProperties.name}
      />
      <FakeInputBlock
        title={_t('id')}
        value={orderTrace.evidenceProperties.id}
      />
    </DataBoxItem>
  );
};

export default OrderDetailEvidenceRequestHistory;
