import React from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import Dialog from 'material-ui/Dialog';
import './OrderActions.css';

type Props = {
  title: string;
  isOpen: boolean;
  max: number;
  progress: number;
};

function OrderMassUpdateProgressBar({
  title,
  isOpen,
  max,
  progress,
}: Props): JSX.Element {
  return (
    <Dialog title={title} modal autoScrollBodyContent open={isOpen}>
      <LinearProgress mode="determinate" max={max} value={progress} />
    </Dialog>
  );
}

export default OrderMassUpdateProgressBar;
