import React, { SyntheticEvent, FormEvent } from 'react';
import { Checkbox, SelectField, MenuItem, TextField } from 'material-ui';

import {
  CritAirSource,
  ProductAutomaticAcceptConfiguration,
  ProductType,
  VehicleTypeSource,
} from '@cvfm-front/tefps-types';

import ProductDetailSeparator from './commons/DetailSeparator';
import { CRITAIR_SOURCES, VEHICLES_TYPES_SOURCES } from './helpers';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailAutomaticAcceptOrderProps = {
  automaticAcceptConfiguration: ProductAutomaticAcceptConfiguration;
  productType: ProductType;
  canEdit: boolean;
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailAutomaticAcceptOrder = ({
  automaticAcceptConfiguration,
  productType,
  canEdit,
  onChange,
}: ProductDetailAutomaticAcceptOrderProps): JSX.Element => {
  const handleCheck = (
    e: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) => {
    const { field } = e.currentTarget.dataset;
    if (field) {
      const updateAutomaticAcceptConfiguration = {
        ...automaticAcceptConfiguration,
        [field]: checked,
      };
      onChange(
        'automaticAcceptConfiguration',
        updateAutomaticAcceptConfiguration
      );
    }
  };

  function handleChangeVehiclesTypesSources(
    e: SyntheticEvent,
    idx: number,
    newValue: VehicleTypeSource[]
  ): void {
    const updateAutomaticAcceptConfiguration = {
      ...automaticAcceptConfiguration,
      vehiclesTypesSources: newValue,
    } as ProductAutomaticAcceptConfiguration;
    onChange(
      'automaticAcceptConfiguration',
      updateAutomaticAcceptConfiguration
    );
  }

  function handleChangeVehiclesCritAirSources(
    e: SyntheticEvent,
    idx: number,
    newValue: CritAirSource[]
  ): void {
    const updateAutomaticAcceptConfiguration = {
      ...automaticAcceptConfiguration,
      vehiclesCritAirSources: newValue,
    } as ProductAutomaticAcceptConfiguration;
    onChange(
      'automaticAcceptConfiguration',
      updateAutomaticAcceptConfiguration
    );
  }

  const handleChangeText = (
    e: FormEvent<HTMLDataElement>,
    newValue: string
  ) => {
    const { field } = e.currentTarget.dataset;
    if (field) {
      const updateAutomaticAcceptConfiguration = {
        ...automaticAcceptConfiguration,
        [field]: newValue,
      };
      onChange(
        'automaticAcceptConfiguration',
        updateAutomaticAcceptConfiguration
      );
    }
  };

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />

      <div className="product-detail_section-content-row">
        <span className="product-detail_cell-50">
          <Checkbox
            className="product-detail_checkbox"
            label={_t('field.autoAcceptEnabled')}
            checked={
              ProductType.BUNDLE === productType ||
              automaticAcceptConfiguration.autoAcceptEnabled
            }
            data-field="autoAcceptEnabled"
            disabled={!canEdit}
            onCheck={handleCheck}
          />
        </span>

        {(automaticAcceptConfiguration.autoAcceptEnabled ||
          ProductType.BUNDLE === productType) && (
          <div className="product-detail_section-content-row">
            <span className="product-detail_cell-100">
              <SelectField
                floatingLabelFixed
                floatingLabelText={_t('field.vehiclesTypesSources')}
                fullWidth
                multiple
                value={automaticAcceptConfiguration.vehiclesTypesSources}
                data-field="vehiclesTypesSources"
                onChange={handleChangeVehiclesTypesSources}
                disabled={!canEdit || ProductType.BUNDLE === productType}
              >
                {VEHICLES_TYPES_SOURCES.map(item => (
                  <MenuItem
                    id={item}
                    key={item}
                    value={item}
                    primaryText={_tg(
                      `tefps.subscription.VehiclesTypesSources.${item}`
                    )}
                  />
                ))}
              </SelectField>
            </span>

            <span className="product-detail_cell-100">
              <SelectField
                floatingLabelFixed
                floatingLabelText={_t('field.vehiclesCritAirSources')}
                fullWidth
                multiple
                value={automaticAcceptConfiguration.vehiclesCritAirSources}
                data-field="vehiclesCritAirSources"
                onChange={handleChangeVehiclesCritAirSources}
                disabled={!canEdit || ProductType.BUNDLE === productType}
              >
                {CRITAIR_SOURCES.map(item => (
                  <MenuItem
                    id={item}
                    key={item}
                    value={item}
                    primaryText={_tg(
                      `tefps.subscription.CritAirSources.${item}`
                    )}
                  />
                ))}
              </SelectField>
            </span>

            <span className="product-detail_cell-100">
              <TextField
                fullWidth
                type="text"
                floatingLabelText={_t('field.comment')}
                value={automaticAcceptConfiguration.comment || ''}
                data-field="comment"
                onChange={handleChangeText}
                disabled={!canEdit || ProductType.BUNDLE === productType}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailAutomaticAcceptOrder;
