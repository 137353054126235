import React from 'react';
import { Table, TableBody } from 'material-ui/Table';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import {
  Checkbox,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';

import { OrderPrivateDTO } from '@cvfm-front/tefps-types';
import Date from 'commons/Date';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};

interface Props {
  order: OrderPrivateDTO;
}

function OrderReminders({ order }: Props): JSX.Element {
  function getContent() {
    if (order.reminders.length === 0) {
      return <>{_t('noReminders')}</>;
    }
    if (order.orderRenewed) {
      return (
        <span>
          {order.reminders.map(reminder => (
            <span>
              <Date datetime={reminder.executionTime} withTime />
              {_tg('tefps.subscription.renewed')}
              <br />
            </span>
          ))}
        </span>
      );
    }
    return (
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>{_t('header.executionTime')}</TableHeaderColumn>
            <TableHeaderColumn>{_t('header.mail')}</TableHeaderColumn>
            <TableHeaderColumn>{_t('header.sms')}</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {order.reminders.map(reminder => (
            <TableRow>
              <TableRowColumn>
                <Date datetime={reminder.executionTime} withTime />
              </TableRowColumn>
              <TableRowColumn>
                <Checkbox disabled checked={reminder.isMail} />
              </TableRowColumn>
              <TableRowColumn>
                <Checkbox disabled checked={reminder.isSms} />
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={_t('cardHeader')}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>{getContent()}</CardText>
      </Card>
    </div>
  );
}

export default OrderReminders;
