import React, { useEffect, useState } from 'react';
import { MenuItem, SelectField } from 'material-ui';

// TODO
import './OrderDetailPage.css';
import { ItemIdName } from 'api/commonTypes';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { fetchZoning } from 'api/pricing';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import {
  OrderEsPrivateDTO,
  ProductPrivateDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type OrderCreateZonesProps = {
  product: ProductPrivateDTO;
  selectedEligibilityId: string | null | undefined;
  selectedBundleId: string | null | undefined;
  activeRights: Array<OrderEsPrivateDTO>;
  selectedZones: Array<string>;
  onChange: (zones: Array<string>) => void;
};

function computeValidityZones(
  product: ProductPrivateDTO,
  selectedEligibilityId: string | null | undefined,
  selectedBundleId: string | null | undefined,
  activeRights: Array<OrderEsPrivateDTO>
): Array<string> {
  let result: Array<string> =
    product.zones.agentZoneIds.length > 0
      ? product.zones.agentZoneIds
      : product.zones.zoneIds;

  if (product.zones.restrictFromEligibility && selectedEligibilityId) {
    const selectedOrder = activeRights.find(
      p => p.orderId === selectedEligibilityId
    );

    if (selectedOrder) {
      result = result.filter(r => selectedOrder.validityZoneIds.includes(r));
    }
  }

  if (product.zones.restrictFromBundle && selectedBundleId) {
    const selectedOrder = activeRights.find(
      p => p.productId === selectedBundleId
    );

    if (selectedOrder) {
      result = result.filter(r => selectedOrder.validityZoneIds.includes(r));
    }
  }

  return result;
}

function computeZonesItem(
  zoneIds: Array<string>,
  zones: Array<ZoneDTO>
): Array<ItemIdName> {
  const reduced = zoneIds.reduce((filtered: Array<ItemIdName>, zoneId) => {
    const zone = zones.find(z => z.id === zoneId);

    if (zone) {
      filtered.push({ id: zone.id, name: zone.name });
    }
    return filtered;
  }, []);

  return reduced;
}

const OrderCreateZones = ({
  product,
  selectedEligibilityId,
  selectedBundleId,
  activeRights,
  selectedZones,
  onChange,
}: OrderCreateZonesProps): JSX.Element => {
  const [zones, setZones] = useState<Array<ZoneDTO>>();

  const setMessage = useSnackbar();

  function handleZonesChange(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: Array<string>
  ): void {
    onChange(value);
  }
  function handleZoneChange(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: string
  ): void {
    onChange([value]);
  }

  function loadZoning(): void {
    fetchZoning(FETCH_LIGHT_ZONING_CONFIG)
      .then(fetchedZones => {
        setZones(fetchedZones.zones);
      })
      .catch(err => {
        setMessage((err as Error).message);
      });
  }

  useEffect(() => loadZoning(), []); // only when the component mounts

  const zoneIds = computeValidityZones(
    product,
    selectedEligibilityId,
    selectedBundleId,
    activeRights
  );

  const sortZones = (): ItemIdName[] => {
    const zoneItems = zones ? computeZonesItem(zoneIds, zones) : [];
    zoneItems.sort((a, b) => a.name.localeCompare(b.name));
    return zoneItems;
  };

  return (
    <div className="order-detail_row">
      <span className="order-detail_cell-25">
        {_tg('field.address.validityZones')}
      </span>
      <span className="order-detail_cell-25">
        <SelectField
          fullWidth
          id="order-zone-selector"
          hintText={_tg('field.address.validityZones')}
          onChange={
            product.zones.mustPickOne ? handleZoneChange : handleZonesChange
          }
          value={product.zones.mustPickOne ? selectedZones[0] : selectedZones}
          multiple={!product.zones.mustPickOne}
        >
          {sortZones().map(item => (
            <MenuItem
              id={item.id}
              key={item.id}
              value={item.id}
              primaryText={item.name}
              insetChildren
            />
          ))}
        </SelectField>
      </span>
    </div>
  );
};

export default OrderCreateZones;
