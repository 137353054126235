import React from 'react';
import PDFIcon from 'material-ui/svg-icons/image/picture-as-pdf';

import './OrderDetailPage.css';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from 'commons/DataBox';
import Date from 'commons/Date';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import { BKG_CYAN, ICON_RED, TXT_BLACK } from 'theme';
import { ControlAgent } from 'api/commonTypes';
import { OrderTraceStatus } from '@cvfm-front/tefps-types/build/order/OrderTraceStatus';

const { _tg } = window.loadTranslations(__filename);

type OrderDetailTimelineBox = {
  type: string;
  source: string;
  date: string;
  agent?: ControlAgent;
  status?: OrderTraceStatus;
  massAction?: boolean;
  orderTraceElement: JSX.Element | null;
  isWarning?: boolean;
  downloadPDF?: () => void;
};

const OrderDetailTimelineBox = ({
  type,
  source,
  massAction,
  date,
  agent,
  downloadPDF,
  status,
  orderTraceElement,
  isWarning,
}: OrderDetailTimelineBox): JSX.Element => {
  return (
    <DataBox style={{ flex: 10 }}>
      <DataBoxHeader
        color={status === OrderTraceStatus.REJECTED ? TXT_BLACK : BKG_CYAN}
      >
        {type}
        {downloadPDF && (
          <span
            style={{ marginLeft: 5 }}
            onClick={downloadPDF}
            title={_tg('action.pdf')}
          >
            <PDFIcon style={{ cursor: 'pointer' }} color={ICON_RED} />
          </span>
        )}
      </DataBoxHeader>
      <DataBoxContent warning={isWarning}>
        <DataBoxItemWrapper>
          <DataBoxItem>
            <FakeInputBlock
              title={_tg('field.date.date')}
              value={<Date datetime={date} withTime={false} />}
            />
            <FakeInputBlock
              title={_tg('field.date.time')}
              value={<Date datetime={date} withDate={false} />}
            />
            <FakeInputBlock title={_tg('field.source')} value={source} />
            {massAction && (
              <FakeInputBlock
                title={_tg('field.massAction')}
                value={_tg('field.yes')}
              />
            )}
          </DataBoxItem>
          {agent && (
            <>
              <Separator />
              <DataBoxItem>
                <FakeInputBlock
                  title={_tg('field.agent.name')}
                  value={agent.name}
                />
                {agent.worksFor && (
                  <FakeInputBlock
                    title={_tg('field.organisation.organisation')}
                    value={agent.worksFor.organizationId}
                  />
                )}
              </DataBoxItem>
            </>
          )}
          {!!orderTraceElement && (
            <>
              <Separator />
              {orderTraceElement}
            </>
          )}
        </DataBoxItemWrapper>
      </DataBoxContent>
    </DataBox>
  );
};

export default OrderDetailTimelineBox;
