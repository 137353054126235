import React from 'react';
import { Checkbox, Card } from 'material-ui';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK } from 'theme';
import {
  EvidenceProperties,
  EvidenceType,
  SubscriberVehicle,
} from '@cvfm-front/tefps-types';
import { Text, Flex } from '@cvfm-front/commons-ui';

import './OrderDetailModalEvidenceRequestPersonalFiles.css';

type OrderDetailModalEvidenceRequestPersonalFilesProps = {
  professional: boolean;
  evidences: Array<EvidenceProperties>;
  handleCheck: (e: React.MouseEvent<HTMLDataElement>, checked: boolean) => void;
  subscribersVehicles: SubscriberVehicle[];
};

const { _t } = window.loadTranslations(__filename);

const OrderDetailModalEvidenceRequestPersonalFiles = ({
  professional,
  evidences,
  handleCheck,
  subscribersVehicles,
}: OrderDetailModalEvidenceRequestPersonalFilesProps): JSX.Element => {
  const getEvidenceTypeName = (evidenceType: EvidenceType) => {
    switch (evidenceType) {
      case 'PROOF_OF_ID':
        return _t('field.PROOF_OF_ID');
      case 'PERSONAL_PROOF_OF_ADDRESS':
        return _t('field.PERSONAL_PROOF_OF_ADDRESS');
      case 'PROFESSIONAL_PROOF_OF_ACTIVITY':
        return _t('field.PROFESSIONAL_PROOF_OF_ACTIVITY');
      case 'PROFESSIONAL_PROOF_OF_ADDRESS':
        return _t('field.PROFESSIONAL_PROOF_OF_ADDRESS');
      default:
        return evidenceType;
    }
  };

  const renderCheckbox = (evidenceType: EvidenceType) => {
    return (
      <Checkbox
        className="product-detail_checkbox"
        label={getEvidenceTypeName(evidenceType)}
        checked={evidences.some(e => e.type === evidenceType)}
        data-name={getEvidenceTypeName(evidenceType)}
        data-type={evidenceType}
        data-id={evidenceType}
        onCheck={handleCheck}
      />
    );
  };

  return (
    <>
      <SeparatorWithTitle
        title={_t('field.userEvidences')}
        color={BKG_PINK}
        titleSize={20}
        className="evidence-request__title"
      />
      {professional ? (
        <>
          {renderCheckbox('PROOF_OF_ID')}
          {renderCheckbox('PROFESSIONAL_PROOF_OF_ACTIVITY')}
          {renderCheckbox('PROFESSIONAL_PROOF_OF_ADDRESS')}
        </>
      ) : (
        <>
          {renderCheckbox('PROOF_OF_ID')}
          {renderCheckbox('PERSONAL_PROOF_OF_ADDRESS')}
        </>
      )}
      <SeparatorWithTitle
        title={_t('field.vehicleEvidences')}
        color={BKG_PINK}
        titleSize={20}
        className="evidence-request__title"
      />
      <Flex>
        {subscribersVehicles.map((vehicle, index) => (
          <Card className="evidence-request__vehicle-card">
            <Flex
              flexDirection="column"
              alignItems="center"
              id={`plate.${index}`}
              width100
              gap={8}
            >
              <img src="/static/img/car.svg" alt="vehicle" />
              <Flex width100 justifyContent="center" alignItems="center">
                <Text>{vehicle.name}</Text>
              </Flex>
              <Flex width100 justifyContent="center" alignItems="center">
                <Text className="text--accent">{vehicle.plate}</Text>
              </Flex>
              <Checkbox
                className="product-detail_checkbox"
                label={_t('field.VEHICLE_PROOF_OF_REGISTRATION')}
                checked={evidences.some(e => e.name === vehicle.plate)}
                data-name={vehicle.plate}
                data-type={
                  professional
                    ? 'PROFESSIONAL_VEHICLE_PROOF_OF_REGISTRATION'
                    : 'PERSONAL_VEHICLE_PROOF_OF_REGISTRATION'
                }
                data-id={vehicle.id}
                onCheck={handleCheck}
              />
            </Flex>
          </Card>
        ))}
      </Flex>
    </>
  );
};

export default OrderDetailModalEvidenceRequestPersonalFiles;
