import React, { useEffect, useState } from 'react';
import { Dialog, MenuItem } from 'material-ui';
import SelectField from 'material-ui/SelectField';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  OrderPrivateDTO,
  OrderSubscribers,
  OrderTraceType,
  SortOrder,
  SubscriberDTO,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import {
  generateShareToken,
  updateSubscribersFromOrder,
} from 'api/cvfm-core-subscription/order';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { BKG_GREEN, STYLE_INPUTS } from 'theme';
import CopyValueButton from 'commons/CopyValueButton';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { searchSubscribers } from 'api/cvfm-core-subscription/subscriber';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalPlatesProps = {
  open: boolean;
  order: OrderPrivateDTO;
  orderSubscribers: Array<SubscriberDTO>;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalSubscribers = ({
  open,
  order,
  orderSubscribers,
  userInfo,
  onCancel,
  onConfirm,
}: OrderDetailModalPlatesProps): JSX.Element => {
  const [subscribersInOrder, setSubscriberIdOrder] = useState<Array<string>>(
    order.subscribersHistory[order.subscribersHistory.length - 1].subscriberIds
  );
  const [orderSelectableSubscriber, setOrderSelectableSubscribers] = useState<
    Array<{ id: string; name: string }>
  >(
    orderSubscribers.map(sub => {
      return {
        id: sub.subscriberId,
        name: `${sub.lastName} ${sub.firstName}`,
      };
    })
  );
  const [newSubscriberName, setNewSuscriberName] = useState<string>();
  const [subscribersFetched, setSubscriberFetched] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [generatedToken, setGeneratedToken] = useState<string | null>();

  async function fetchSubscribersForAutocomplete(lastName: string) {
    const result = await searchSubscribers({
      lastName: newSubscriberName,
      order: SortOrder.ASC,
      maxRecords: 10,
      sort: 'lastName',
    });
    setNewSuscriberName(lastName);
    setSubscriberFetched(
      result.subscribers
        .filter(
          sub => !subscribersInOrder.some(subId => subId === sub.subscriberId) // we don't show subscriber that are already in order
        )
        .map(sub => {
          return {
            id: sub.subscriberId,
            name: `${sub.lastName || ''} ${sub.firstName || ''}`,
          };
        })
    );
  }

  useEffect(() => {
    void fetchSubscribersForAutocomplete('');
  }, [order.orderId]);

  function onChangeSubscriberAutocomplete(
    _field: never,
    id: string | null | undefined
  ) {
    if (id && !orderSelectableSubscriber.some(sub => sub.id === id)) {
      const entry = subscribersFetched.find(sub => sub.id === id);
      if (entry) {
        setOrderSelectableSubscribers(oldValue => oldValue.concat(entry));
        setNewSuscriberName('');
        setSubscriberIdOrder(old => old.concat(entry.id));
      }
    }
  }

  const setMessage = useSnackbar();

  function onCancelAction() {
    setSubscriberIdOrder(
      order.subscribersHistory[order.subscribersHistory.length - 1]
        .subscriberIds
    );
    onCancel();
  }

  function onConfirmAction() {
    try {
      const updatePromise = updateSubscribersFromOrder(order.orderId, {
        timestamp: new Date().toISOString(),
        traceType: OrderTraceType.SUBSCRIBERS,
        source: SubscriptionSource.BACK,
        agent: userInfo ? { ...userInfo } : null,
        subscriberIds: subscribersInOrder,
        subscriberId: null,
      } as OrderSubscribers);
      onConfirm(updatePromise);
    } catch (ex) {
      setMessage(ex);
    }
  }

  async function onClickGenerateToken() {
    try {
      const token = await generateShareToken(order.orderId);
      setGeneratedToken(token.token);
    } catch (ex) {
      setMessage(ex);
    }
  }
  function handleChangeMultiple(
    _e: React.ChangeEvent<HTMLDataElement>,
    _index: number,
    value: Array<string>
  ): void {
    if (value.length >= 1) {
      setSubscriberIdOrder(value);
    }
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-plates-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-plates-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
    />,
  ];

  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '1em 0' }}
      >
        <BoButton
          label={_t('generate')}
          onClick={onClickGenerateToken}
          style={{ width: '50%' }}
        />
        {generatedToken && (
          <span style={{ color: BKG_GREEN, marginTop: 5 }}>
            {_t('afterGeneration', { generatedToken })}
            <CopyValueButton value={generatedToken} />
          </span>
        )}
        <SelectField
          multiple
          value={subscribersInOrder}
          hintText={_t('selectSubscribers')}
          floatingLabelText={_t('selectSubscribers')}
          fullWidth
          onChange={handleChangeMultiple}
        >
          {orderSelectableSubscriber.map(
            (subscriber: { id: string; name: string }) => (
              <MenuItem
                value={subscriber.id}
                primaryText={subscriber.name}
                key={subscriber.id}
                checked={subscribersInOrder.includes(subscriber.id)}
                insetChildren
              />
            )
          )}
        </SelectField>
        <div style={{ ...STYLE_INPUTS, marginTop: 40 }}>
          {_t('addSubscriber')}
        </div>
        <Autocomplete
          id="subscribers"
          secondaryColor
          options={subscribersFetched}
          onChange={onChangeSubscriberAutocomplete}
          onAutocomplete={fetchSubscribersForAutocomplete}
          search={newSubscriberName}
          blockStyle={{ marginTop: 0 }}
        />
      </div>
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalSubscribers);
