import React, { useEffect, useState } from 'react';

import useNewTags from 'commons/hooks/useNewTags';
import useRecourse from 'commons/hooks/useRecourse';
import useComment from 'commons/hooks/useComment';
import BoButton from 'facade/BoButton';
import RecourseButton from 'facade/RecourseButton';
import { patchRecourse } from 'api/recourse';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import Services from 'commons/services';

import CommentActionModal from './CommentActionModal';

const { _t, _tg } = window.loadTranslations(__filename);

const CommentAction = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const [comment, setComment] = useComment();
  const [newTags, setNewTags] = useNewTags();
  const setMessage = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const handleModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setNewTags(recourseDetails ? recourseDetails.recourse.tags : []);
  }, [recourseDetails, open]);

  useEffect(() => {
    const toAdd = newTags.find(
      tag => !recourseDetails?.recourse.tags.includes(tag)
    );
    const toDelete = recourseDetails?.recourse.tags.find(
      tag => !newTags.includes(tag)
    );

    setCanSubmit(!!toAdd || !!toDelete || !!comment);
  }, [comment, newTags]);

  const submit = async (): Promise<void> => {
    if (!recourseDetails) {
      return;
    }
    setLoading(true);
    const addedTags = newTags.filter(
      tag => !recourseDetails.recourse.tags.includes(tag)
    );
    const removedTags = recourseDetails.recourse.tags.filter(
      tag => !newTags.includes(tag)
    );
    try {
      // Init upload
      const patches = [];

      /* Adding comment */
      patches.push({
        path: '/comments',
        op: 'add',
        value: { addedTags, removedTags, text: comment },
      });
      await patchRecourse(recourseDetails.recourse.rapoId, patches);
      // Clean input
      setComment('');
      setMessage(_t('submit.success'));
      // Close modal
      handleModal();
      // Update recourse details
      await Services.recourse.init(recourseDetails.recourse.rapoId);
    } catch (e) {
      setMessage(_t('submit.error'));
    } finally {
      // If error, re-enable submit
      setLoading(false);
    }
  };

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      onClick={handleModal}
      style={{ marginRight: 10 }}
      disabled={loading}
    />,
    <BoButton
      label={_tg('action.add')}
      primary
      keyboardFocused
      disabled={!canSubmit || loading}
      onClick={submit}
    />,
  ];

  return (
    <>
      <RecourseButton
        label={_tg('action.comment')}
        backgroundColor="var(--bg-color--cyan)"
        labelColor="var(--white)"
        onClick={handleModal}
      />
      <CommentActionModal
        open={open}
        actions={actions}
        onRequestClose={handleModal}
        loading={loading}
      />
    </>
  );
};

export default CommentAction;
