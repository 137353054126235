import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { Checkbox, MenuItem, SelectField, TextField } from 'material-ui';

import {
  CustomFieldProperties,
  DayOfWeek,
  ProductRestrictions,
  ProductPrivateDTO,
  SpecialDay,
} from '@cvfm-front/tefps-types';
import { translateDaysOfWeek } from 'commons/Days';
import { getConfigState } from 'config/duck';
import { InternalApiState } from 'api/duck';

import ProductDetailSeparator from './commons/DetailSeparator';

import './ProductDetailPage.css';
import { DAYS_OFF } from 'tefps/Pricing/Policies/helpers';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailRestrictionsReduxProps = {
  adV3Enabled: boolean;
  specialDays: Array<SpecialDay>;
};

type ProductDetailRestrictionsProps = ProductDetailRestrictionsReduxProps & {
  restrictions: ProductRestrictions;
  productList: Array<ProductPrivateDTO>;
  canEdit: boolean;
  customFields: Array<CustomFieldProperties> | null | undefined;
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailRestrictions = ({
  restrictions,
  productList,
  canEdit,
  customFields,
  onChange,
  adV3Enabled,
  specialDays,
}: ProductDetailRestrictionsProps): JSX.Element => {
  function handleWeekDayChange(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: Array<string>
  ): void {
    const updateRestrictions = { ...restrictions, mustStartOnWeekDays: value };
    onChange('restrictions', updateRestrictions);
  }

  function handleMustNotStartOnFreeDays(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: Array<string>
  ): void {
    const updateRestrictions = {
      ...restrictions,
      mustNotStartOnFreeDays: value,
    };
    onChange('restrictions', updateRestrictions);
  }

  function handleMustNotStartOnSpecialDays(
    e: React.SyntheticEvent<HTMLDataElement>,
    index: number,
    value: Array<string>
  ): void {
    const updateRestrictions = {
      ...restrictions,
      mustNotStartOnSpecialDays: value,
    };
    onChange('restrictions', updateRestrictions);
  }

  function handleChangeText(e: FormEvent<HTMLDataElement>, newValue: string) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      const updateRestrictions = { ...restrictions, [field]: newValue };
      onChange('restrictions', updateRestrictions);
    }
  }

  function handleChangeCustomField(_e: never, _i: never, newValue: string) {
    const updateRestrictions = { ...restrictions, restrictionKey: newValue };
    onChange('restrictions', updateRestrictions);
  }

  function handleCheck(e: React.MouseEvent<HTMLDataElement>, checked: boolean) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      const updateRestrictions = { ...restrictions, [field]: checked };
      onChange('restrictions', updateRestrictions);
    }
  }

  function handleChangeNextProduct(_e: never, _i: never, newValue: string) {
    const updateRestrictions = { ...restrictions, nextProductId: newValue };
    onChange('restrictions', updateRestrictions);
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title.limits')} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.renewable')}
              checked={restrictions.renewable}
              data-field="renewable"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.canCancelBeforeStartOfValidity')}
              checked={restrictions.canCancelBeforeStartOfValidity}
              data-field="canCancelBeforeStartOfValidity"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            {restrictions.renewable && (
              <span className="product-detail_cell-50">
                <SelectField
                  floatingLabelFixed
                  disabled={!canEdit}
                  hintText={_t('field.nextProductId')}
                  fullWidth
                  onChange={handleChangeNextProduct}
                  value={restrictions.nextProductId}
                  data-field="nextProductId"
                >
                  {productList.map(item => (
                    <MenuItem
                      id={item.productId}
                      key={item.productId}
                      value={item.productId}
                      primaryText={item.name}
                    />
                  ))}
                </SelectField>
              </span>
            )}
          </span>
        </div>
        <div
          className="product-detail_section-content-row"
          style={{ alignItems: 'flex-start' }}
        >
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.orderLimitAppliesDuringEligibilityPeriod')}
              checked={restrictions.orderLimitAppliesDuringEligibilityPeriod}
              data-field="orderLimitAppliesDuringEligibilityPeriod"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>

          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.orderLimitAppliesDuringCivilYear')}
              checked={restrictions.orderLimitAppliesDuringCivilYear}
              data-field="orderLimitAppliesDuringCivilYear"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <TextField
              fullWidth
              floatingLabelText={_t('field.orderLimitCounter')}
              data-field="orderLimitCounter"
              value={restrictions.orderLimitCounter}
              type="number"
              min={0}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <TextField
              title={_t('info.subscriberLimit')}
              fullWidth
              floatingLabelText={_t('field.subscriberLimit')}
              data-field="subscriberLimit"
              value={restrictions.subscriberLimit}
              type="number"
              min={0}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <TextField
              title={_t('info.orderPlateLimit')}
              fullWidth
              floatingLabelText={_t('field.orderPlateLimit')}
              data-field="orderPlateLimit"
              value={restrictions.orderPlateLimit}
              type="number"
              min={0}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </span>
        </div>
        {restrictions.orderPlateLimit > 0 && (
          <div className="product-detail_section-content-row">
            <span className="product-detail_cell-50">
              <Checkbox
                className="product-detail_checkbox"
                label={_t('field.showOrderPlateLimit')}
                checked={restrictions.showOrderPlateLimit}
                data-field="showOrderPlateLimit"
                disabled={!canEdit}
                onCheck={handleCheck}
              />
            </span>
          </div>
        )}
        {customFields && customFields.length > 0 && (
          <div
            className="product-detail_section-content-row"
            style={{ alignItems: 'end' }}
          >
            <SelectField
              style={{ marginRight: 10 }}
              floatingLabelFixed
              disabled={!canEdit}
              hintText={_t('field.restrictionKey')}
              onChange={handleChangeCustomField}
              value={restrictions.restrictionKey}
            >
              {customFields.map(item => (
                <MenuItem
                  id={item.key}
                  key={item.key}
                  value={item.key}
                  primaryText={item.label}
                />
              ))}
            </SelectField>
            <TextField
              floatingLabelText={_t('field.restrictionCount')}
              data-field="restrictionCount"
              value={restrictions.restrictionCount}
              type="number"
              min={0}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </div>
        )}
      </div>
      <ProductDetailSeparator title={_t('section.title.plates')} />
      <div className="product-detail_section-content">
        <div
          className="product-detail_section-content-row"
          style={{ justifyContent: 'space-between' }}
        >
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustOrderWithPlate')}
              checked={restrictions.mustOrderWithPlate}
              data-field="mustOrderWithPlate"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span
            className="product-detail_cell-50"
            title={_tg(
              'tefps.subscription.restrictions.orderWithoutPlateForbidden'
            )}
          >
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustContainPlate')}
              checked={restrictions.mustContainPlate}
              data-field="mustContainPlate"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          {adV3Enabled && (
            <>
              <span
                className="product-detail_cell-50"
                title={_tg(
                  'tefps.subscription.restrictions.canOrderWithoutPlateMatchingVehicle'
                )}
              >
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('field.canOrderWithoutPlateMatchingVehicle')}
                  checked={restrictions.canOrderWithoutPlateMatchingVehicle}
                  data-field="canOrderWithoutPlateMatchingVehicle"
                  disabled={!canEdit}
                  onCheck={handleCheck}
                />
              </span>
              {restrictions.canOrderWithoutPlateMatchingVehicle && (
                <span className="product-detail_cell-50">
                  <Checkbox
                    className="product-detail_checkbox"
                    label={_t('field.displayRegisteredPlates')}
                    checked={restrictions.displayRegisteredPlates}
                    data-field="displayRegisteredPlates"
                    disabled={!canEdit}
                    onCheck={handleCheck}
                  />
                </span>
              )}
            </>
          )}
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustUseActiveEligibilityPlate')}
              checked={restrictions.mustUseActiveEligibilityPlate}
              data-field="mustUseActiveEligibilityPlate"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.canUseCustomEmail')}
              checked={restrictions.canUseCustomEmail}
              data-field="canUseCustomEmail"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.canRequestForPlateChange')}
              checked={restrictions.canRequestForPlateChange}
              data-field="canRequestForPlateChange"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.canEditPlateDuringValidity')}
              checked={restrictions.canEditPlateDuringValidity}
              data-field="canEditPlateDuringValidity"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <TextField
              fullWidth
              floatingLabelText={_t('field.plateLimit')}
              data-field="plateLimit"
              value={restrictions.plateLimit}
              type="number"
              min={0}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </span>
        </div>
      </div>
      <ProductDetailSeparator title={_t('section.title.period')} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-100">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustEndOnSameRestrictionDays')}
              checked={restrictions.mustEndOnSameRestrictionDays}
              data-field="mustEndOnSameRestrictionDays"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>

        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-25">
            <SelectField
              multiple
              fullWidth
              floatingLabelText={_t('field.mustStartOnWeekDays')}
              floatingLabelFixed
              hintText={_t('hint.any')}
              data-field="mustStartOnWeekDays"
              value={restrictions.mustStartOnWeekDays}
              disabled={!canEdit}
              onChange={handleWeekDayChange}
            >
              {translateDaysOfWeek().map(dow => (
                <MenuItem
                  id={dow.id}
                  key={dow.id}
                  value={dow.id}
                  primaryText={dow.name}
                  checked={restrictions.mustStartOnWeekDays.includes(
                    DayOfWeek[dow.id]
                  )}
                  insetChildren
                />
              ))}
            </SelectField>
          </span>
          <span className="product-detail_cell-25">
            <SelectField
              multiple
              fullWidth
              floatingLabelText={_t('field.mustNotStartOnFreeDays')}
              floatingLabelFixed
              hintText={_t('hint.any')}
              data-field="mustNotStartOnFreeDays"
              value={restrictions.mustNotStartOnFreeDays}
              disabled={!canEdit}
              onChange={handleMustNotStartOnFreeDays}
            >
              {DAYS_OFF().map(day => (
                <MenuItem
                  id={day.id}
                  key={day.id}
                  value={day.id}
                  primaryText={day.name}
                  checked={restrictions.mustNotStartOnFreeDays.includes(day.id)}
                  insetChildren
                />
              ))}
            </SelectField>
          </span>
          <span className="product-detail_cell-25">
            <SelectField
              multiple
              fullWidth
              floatingLabelText={_t('field.mustNotStartOnSpecialDays')}
              floatingLabelFixed
              hintText={_t('hint.any')}
              data-field="mustNotStartOnSpecialDays"
              value={restrictions.mustNotStartOnSpecialDays}
              disabled={!canEdit}
              onChange={handleMustNotStartOnSpecialDays}
            >
              {specialDays.map(day => (
                <MenuItem
                  id={day.id}
                  key={day.id}
                  value={day.id}
                  primaryText={day.label}
                  checked={restrictions.mustNotStartOnSpecialDays.includes(
                    day.id
                  )}
                  insetChildren
                />
              ))}
            </SelectField>
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustPickStartInsideEligibilityPeriod')}
              checked={restrictions.mustPickStartInsideEligibilityPeriod}
              data-field="mustPickStartInsideEligibilityPeriod"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('field.mustPickEndInsideEligibilityPeriod')}
              checked={restrictions.mustPickEndInsideEligibilityPeriod}
              data-field="mustPickEndInsideEligibilityPeriod"
              disabled={!canEdit}
              onCheck={handleCheck}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <TextField
              fullWidth
              title={_t('info.blockedPeriodFromStartOfValidityInDays')}
              floatingLabelText={_t(
                'field.blockedPeriodFromStartOfValidityInDays'
              )}
              data-field="blockedPeriodFromStartOfValidityInDays"
              value={restrictions.blockedPeriodFromStartOfValidityInDays}
              type="number"
              min={0}
              disabled={!canEdit}
              onChange={handleChangeText}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(
  state: InternalApiState
): ProductDetailRestrictionsReduxProps {
  const {
    subscriptionConfigurationDTO: { adV3Enabled },
    pricingConfiguration: { specialDays },
  } = getConfigState(state);
  return {
    adV3Enabled,
    specialDays,
  };
}

export default connect(mapStateToProps)(ProductDetailRestrictions);
