import React, { useState } from 'react';

import EditUserInfoButton from 'commons/Recourses/EditUserInfo/Button';
import EditUserInfoModal from 'commons/Recourses/EditUserInfo/Modal';
import useSnackBar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { RecourseDTO } from 'api/recourse/types';
import { BKG_LIGHT } from 'theme';

const { _tg } = window.loadTranslations(__filename);

type UserInformationTitleProps = {
  title: string;
  userType: 'user' | 'transferUser' | 'representative';
  recourse: RecourseDTO;
  disabled?: boolean;
};

const UserInformationTitle = ({
  title,
  userType,
  recourse,
  disabled,
}: UserInformationTitleProps): JSX.Element => {
  const [open, setOpen] = useState<string | null | undefined>(null);
  const setMessage = useSnackBar;

  const applyChanges = (): void => {
    window.location.reload();
  };

  return (
    <>
      <span className={!disabled ? 'margin-l' : ''}>{title}</span>
      {!disabled && (
        <EditUserInfoButton
          onClick={setOpen}
          userType={userType}
          title={_tg('action.edit')}
          color={BKG_LIGHT}
          className="user-information-title__edit-user-information-button__tooltip-fix"
        />
      )}
      <EditUserInfoModal
        type={open}
        onChangeEditUserType={setOpen}
        recourse={recourse}
        applyChanges={applyChanges}
        showMessage={setMessage}
      />
    </>
  );
};

export default UserInformationTitle;
