import React, { useState, useEffect } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';

import useRecourse from 'commons/hooks/useRecourse';
import ProposalEditor from 'tefps/RecoursesV2/pages/Proposal/ProposalEditor';
import { CityRecourseReason, RecourseUpdateStatus } from 'api/recourse/types';
import { isProposalOpen } from 'tefps/RecoursesV2/utils/recourseUtils';
import { RECOURSE_DECISION } from 'tefps/RecoursesV2/utils/translationUtils';
import useCurrentProposal from 'commons/hooks/useCurrentProposal';
import useRecourseReasons from 'commons/hooks/useRecourseReasons';
import useDecision from 'commons/hooks/useDecision';
import useReason from 'commons/hooks/useReason';
import useRecourseConfiguration from 'commons/hooks/useRecourseConfiguration';
import Price from 'commons/Price';
import ConfirmProposal from 'tefps/RecoursesV2/pages/Proposal/ConfirmProposal';
import SubmitProposal from 'tefps/RecoursesV2/pages/Proposal/SubmitProposal';
import Tag from 'commons/Tag';
import useTags from 'commons/hooks/useTags';
import useNewTags from 'commons/hooks/useNewTags';

import DecisionPrice from './DecisionPrice';

import './ProposalDetail.css';

const { _t } = window.loadTranslations(__filename);

const ProposalDetail = (): JSX.Element => {
  /* Immutable vars */
  const recourse = useRecourse();
  const currentProposal = useCurrentProposal();
  const recourseReasons = useRecourseReasons();
  const recourseConfiguration = useRecourseConfiguration();
  const tagList = useTags();
  const [newTags, setNewTags] = useNewTags();

  if (!recourse) {
    return <></>;
  }

  /* Service vars */
  const [decision, setDecision] = useDecision();
  const [reason, setReason] = useReason();

  const fpsNumber = recourse?.fps.fineId ?? ''; // To avoid parsing problems with i18n
  const shouldAddTags = !!(
    recourseConfiguration?.tagsMandatory && !newTags.length
  );

  /* States */
  const [searchTag, setSearchTag] = useState<string>('');

  const onChangeDecision = (
    event: React.MouseEvent,
    index: number,
    value: RecourseUpdateStatus
  ) => {
    setDecision(value);
  };

  const onChangeReason = (
    event: any,
    index: number,
    value: CityRecourseReason
  ) => {
    setReason(value);
  };

  const removeTag = (tag: string): void => {
    setNewTags(newTags.filter(t => t !== tag));
  };

  const addTag = (tag: string): void => {
    setNewTags([...newTags, tag]);
    setSearchTag('');
  };

  const showEditableTags =
    !(recourse && currentProposal && isProposalOpen(recourse.recourse)) ||
    !isProposalOpen(recourse.recourse);

  return (
    <div className="proposal-detail">
      <div className="proposal-detail__subject margin-y--s">
        <div className="text-bold margin-y--xs">{_t('mail.subject')}</div>
        <div>{_t('mail.subjectContent', { fpsNumber })}</div>
      </div>
      {showEditableTags && (
        <div className="search-container">
          <div className="proposal-detail proposal-detail__subject text-bold margin-t--xs">
            {_t('tags.label')}
          </div>
          <div className="search-and-button">
            <AutoComplete
              className="form__autocomplete"
              hintText={_t('tags.searchTag')}
              searchText={searchTag}
              filter={(searchText, key) =>
                AutoComplete.fuzzyFilter(searchText, key)
              }
              dataSource={tagList.filter(tag => !newTags.includes(tag))}
              openOnFocus
              onUpdateInput={text => setSearchTag(text)}
              onNewRequest={addTag}
              listStyle={{
                maxHeight: 250,
                overflow: 'auto',
                width: 'max-content',
              }}
            />
          </div>
          <div className="form__tags">
            {!newTags.length && (
              <div className="form__no-tags">{_t('tags.noTag')}</div>
            )}
            {newTags.map((tag, i) => (
              <Tag key={i} tag={tag} onDelete={removeTag} />
            ))}
          </div>
        </div>
      )}
      <SelectField
        floatingLabelText={_t('proposal.decision')}
        value={decision}
        onChange={onChangeDecision}
        disabled={!!currentProposal}
      >
        {Object.entries(RECOURSE_DECISION).map(entry => {
          if (entry[0] === 'IS_WAITING') return null; // IS_WAITING decision no longer available as proposal decision
          return (
            <MenuItem key={entry[0]} value={entry[0]} primaryText={entry[1]} />
          );
        })}
      </SelectField>
      {decision && recourseReasons && (
        <div className="proposal-detail__reason-select margin-y--s">
          <SelectField
            floatingLabelText={_t('proposal.reason')}
            value={reason}
            onChange={onChangeReason}
            disabled={!!currentProposal}
            fullWidth
          >
            {Object.keys(recourseReasons)
              .filter(key => recourseReasons[key].status === decision)
              .map(key => (
                <MenuItem
                  key={recourseReasons[key].reason}
                  value={recourseReasons[key].reason}
                  primaryText={recourseReasons[key].label}
                />
              ))}
          </SelectField>
        </div>
      )}
      {currentProposal && currentProposal.proposal.status === 'ACCEPTED' && (
        <div className="proposal-detail__subject margin-y--s">
          <div className="text-bold margin-y--xs">
            {_t('proposal.newPrice')}
          </div>
          <Price price={currentProposal.proposal.newFpsPrice} />
        </div>
      )}
      {currentProposal?.proposal.newFpsReducedPrice &&
        currentProposal.proposal.status === 'ACCEPTED' && (
          <div className="proposal-detail__subject margin-y--s">
            <div className="text-bold margin-y--xs">
              {_t('proposal.newReducedPrice')}
            </div>
            <Price price={currentProposal.proposal.newFpsReducedPrice} />
          </div>
        )}
      {!currentProposal && decision === 'ACCEPTED' && <DecisionPrice />}
      <ProposalEditor />
      {/* Submit decision or proposal */}
      {shouldAddTags && (
        <div className="text-error margin-y--s">
          <p>{_t('proposal.tagsMissingWarning')}</p>
        </div>
      )}
      <div className="row">
        {recourse && currentProposal && isProposalOpen(recourse.recourse) ? (
          <ConfirmProposal />
        ) : (
          <SubmitProposal newTags={newTags} />
        )}
      </div>
    </div>
  );
};

export default ProposalDetail;
