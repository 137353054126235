import React from 'react';

import { OrderCustomField, ProductPrivateDTO } from '@cvfm-front/tefps-types';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';

type OrderDetailHistoryCustomFieldProps = {
  product: ProductPrivateDTO;
  orderTrace: OrderCustomField;
};

const OrderDetailHistoryCustomField = ({
  product,
  orderTrace,
}: OrderDetailHistoryCustomFieldProps): JSX.Element => {
  let customFieldPropertyName = orderTrace.key;
  if (
    product.applicationProcedure &&
    product.applicationProcedure.customFields
  ) {
    const productField = product.applicationProcedure.customFields.find(
      cf => cf.key === orderTrace.key
    );
    if (productField) {
      customFieldPropertyName = productField.label;
    }
  }

  return (
    <DataBoxItem>
      <FakeInputBlock
        title={customFieldPropertyName}
        value={orderTrace.value}
      />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryCustomField;
