import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'material-ui';

import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import BoButton from 'facade/BoButton';
import { OrderMassUpdateMap } from '@cvfm-front/tefps-types';
import { deleteOrderById } from 'api/cvfm-core-subscription/order';

import {
  CheckboxCustom,
  TextFieldCustom,
} from '../../commons/FormComponent/Fields';
import FormComponent from '../../commons/FormComponent';

import { CancelFormType, getCancelOrderDTO } from './helpers';
import OrderFilterService from './OrderFilterService';
import OrderMassUpdateProcess from './OrderMassUpdateProcess';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalCancelOrderProps = {
  isOpen: boolean;
  cancelForm?: React.MutableRefObject<FormComponent | null>;
  selectedOrders?: OrderMassUpdateMap; // We assume that multiple orders = multiple action
  userInfo: InternalAgent | null | undefined;
  onConfirm?: () => void;
  onClose: () => void;
};

const OrderDetailModalCancel = ({
  isOpen,
  cancelForm,
  selectedOrders,
  userInfo,
  onConfirm,
  onClose,
}: OrderDetailModalCancelOrderProps): JSX.Element => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const formEntries = cancelForm || React.useRef<FormComponent | null>(null);

  // for multiple action
  const [isAllSettled, setIsAllSettled] = useState<boolean>(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState<number>(0);
  const [uploadErrorIds, setUploadErrorIds] = useState<
    { id: string; err: string }[]
  >([]);

  const onCloseModal = () => {
    setIsLoadingProgress(false);
    setUploadProgressState(0);
    setUploadErrorIds([]);
    if (isAllSettled) {
      OrderFilterService.loadOrders();
    }
    setIsAllSettled(false);
    onClose();
  };

  const cancelMultipleOrders = async (selectedOrders: OrderMassUpdateMap) => {
    setIsLoadingProgress(true);
    // important to keep value in variable before the loop with await
    const cancelFormEntries: CancelFormType = formEntries.current?.getFormEntries() as CancelFormType;
    /* eslint-disable no-restricted-syntax */
    for (const orderId of Object.keys(selectedOrders)) {
      /* eslint-disable no-await-in-loop */
      await deleteOrderById(
        orderId,
        getCancelOrderDTO(cancelFormEntries, userInfo, true)
      )
        .then(response => {
          return response;
        })
        .catch(err => {
          const error = err as Error;
          setUploadErrorIds(errors => [
            ...errors,
            { id: orderId, err: error.message },
          ]);
          return error;
        })
        .finally(() => {
          // Sert à incrementer la barre de progression
          setUploadProgressState(past => past + 1);
        });
      /* eslint-enable no-await-in-loop */
    }
    /* eslint-enable no-restricted-syntax */
    setIsAllSettled(true);
    setIsLoadingProgress(false);
  };

  const onConfirmCancellation = (): void => {
    if (selectedOrders) {
      cancelMultipleOrders(selectedOrders);
      setOpenDialog(false);
    } else if (onConfirm) {
      onConfirm();
    } else {
      console.error('neither selectedOrders nor onConfirm are defined');
    }
  };

  if (selectedOrders) {
    <OrderMassUpdateProcess
      titleFail={_t('header.titleFail')}
      titleSuccess={_t('header.titleSuccess')}
      titleProgress={_t('header.titleProgress')}
      isOpen={isOpen}
      isAllSettled={isAllSettled}
      isLoadingProgress={isLoadingProgress}
      uploadProgressState={uploadProgressState}
      uploadErrorIds={uploadErrorIds}
      max={Object.keys(selectedOrders).length}
      onCloseModal={onCloseModal}
    />;
  }

  const cancelModalActions = [
    <BoButton
      label={_tg('action.cancel')}
      onClick={onCloseModal}
      style={{ marginRight: 20 }}
    />,
    <BoButton
      primary
      label={_tg('action.confirm')}
      onClick={() => setOpenDialog(!!formEntries.current?.isValid())}
    />,
  ];

  const confirmDialogActions = [
    <BoButton
      secondary
      label={_tg('action.cancel')}
      onClick={() => setOpenDialog(false)}
      style={{ marginRight: 20 }}
    />,
    <BoButton
      primary
      label={_tg('action.confirm')}
      onClick={onConfirmCancellation}
    />,
  ];

  return (
    <>
      <Dialog
        open={isOpen}
        actions={cancelModalActions}
        title={selectedOrders ? _t('titleMultiple') : _t('title')}
        titleClassName="order-detail-modal_title"
      >
        <FormComponent ref={formEntries}>
          <TextFieldCustom
            name="message"
            hint={_t('fields.message')}
            rows={3}
            multiLine
            fullWidth
            mandatory
          />
          <CheckboxCustom
            name="notifySubscriber"
            label={_t('fields.notifySubscriber')}
          />
        </FormComponent>
      </Dialog>
      <Dialog
        open={openDialog}
        actions={confirmDialogActions}
        title={selectedOrders ? _t('dialog.titleMultiple') : _t('dialog.title')}
      />
    </>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalCancel);
