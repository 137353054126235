import React from 'react';

import { Flex } from '@cvfm-front/commons-ui';
import ClusterMap from 'commons/ClusterMap';
import { ControlDTO } from 'api/control/types';
import { FpsCaseDTO } from 'api/fps/types';

import './ControlMap.css';

const { _t } = window.loadTranslations(__filename);

const iconMap: Map<string, string> = new Map([
  ['control', '/static/img/map/circles/circle_position.png'],
  ['fps', '/static/img/map/circles/circle_ok.png'],
]);

type Props = {
  control: ControlDTO | null;
  fps: FpsCaseDTO | null;
};

const ControlMap = ({ control, fps }: Props): JSX.Element => {
  if (!control || !fps) {
    return <div>{_t('noData')}</div>;
  }

  const mapStyle = { height: '40vh', width: 'auto', margin: '2vh 0' };

  const controlMarker = {
    title: _t('control'),
    position: {
      lat: control.latitude,
      lng: control.longitude,
    },
    id: 'controle',
    status: 'control',
  };

  const fpsMarker = {
    title: 'FPS',
    position: {
      lat: fps.statementLocation.latitude,
      lng: fps.statementLocation.longitude,
    },
    id: 'fpsMarker',
    status: 'fps',
  };

  return (
    <Flex flexDirection="column">
      <ClusterMap
        center={controlMarker.position}
        zoom={18}
        style={mapStyle}
        markers={[controlMarker, fpsMarker]}
        displayAsClusters={false}
        markerIcons={iconMap}
      />
      <div>
        <div className="position-informations">
          <span className="text-grey text-bold text-center">
            {_t('controlPosition')}
          </span>
          <div className="position-details">
            <div className="position">
              <span className="text-grey text-bold">{_t('latitude')} : </span>
              <span className="text-grey">{control.latitude}</span>
            </div>
            <div className="position">
              <span className="text-grey text-bold">{_t('longitude')} : </span>
              <span className="text-grey">{control.longitude}</span>
            </div>
          </div>
        </div>
        <div className="position-informations">
          <span className="text-grey text-bold text-center">
            {_t('fpsPosition')}
          </span>
          <div className="position-details">
            <div className="position">
              <span className="text-grey text-bold">{_t('latitude')} : </span>
              <span className="text-grey">
                {fps.statementLocation.latitude}
              </span>
            </div>
            <div className="position text-grey text-bolds">
              <span className="text-grey text-bold">{_t('longitude')} : </span>
              <span className="text-grey">
                {fps.statementLocation.longitude}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default ControlMap;
