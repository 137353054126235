import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Base64 } from '../../../../../commons/helpers/Base64';

import STYLE from './style';

type ComputeFilterProps = {
  type: string;
  label: string;
  plate: string;
  date: string | null;
};

type RedirectInfo = {
  type: string;
  link: string;
};

class ComputeFilterAndRedirect extends React.Component<ComputeFilterProps> {
  getRedirectInfo = (): RedirectInfo => {
    const { type } = this.props;
    const redirectInfo: RedirectInfo = {
      type,
      link: '#',
    };
    switch (type) {
      case 'FPS':
        redirectInfo.link = '/fps/list';
        break;
      case 'SUBSCRIPTION':
        redirectInfo.link = '/eligibilities/list';
        break;
      case 'TV':
        redirectInfo.link = '/tickets/list';
        break;
      case 'RAPO':
        redirectInfo.link = '/recourses/list';
        break;
      case 'AD_ELIGIBILITIES':
        redirectInfo.link = '/subscription/eligibility/eligibilities';
        break;

      default:
        break;
    }
    return redirectInfo;
  };

  getLinkWithPlate = (): string => {
    const redirectInfo: RedirectInfo = this.getRedirectInfo();
    const { plate } = this.props;
    if (redirectInfo.link !== '#') {
      const b64Plate = Base64.encode(JSON.stringify(plate));
      const encodedPlate = encodeURIComponent(b64Plate);

      const { date } = this.props;
      if (!date) {
        return `${redirectInfo.link}?plate=${encodedPlate}`;
      }
      const fpsStatementDate = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
      return `${redirectInfo.link}?plate=${encodedPlate}&fpsStatementDate=${fpsStatementDate}`;
    }

    return `${redirectInfo.link}`;
  };

  render(): React.ReactNode {
    const { label } = this.props;

    return (
      <Link to={this.getLinkWithPlate()} style={STYLE.LINK}>
        {label}
      </Link>
    );
  }
}

export default ComputeFilterAndRedirect;
