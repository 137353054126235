import React from 'react';

import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { ItemIdName } from 'api/commonTypes';

const { _tg } = window.loadTranslations(__filename);

type SubscriberChoiceProps = {
  subscribersItems: Array<ItemIdName>;
  onChange: (domId: never, changeSubscriberId: string) => void;
  onAutocomplete: (
    subscriberFilters: string | null | undefined
  ) => Promise<any>;
  subscriberId: string;
};

const OrderCreateSubscriberChoice = ({
  subscribersItems,
  onChange,
  onAutocomplete,
  subscriberId,
}: SubscriberChoiceProps): JSX.Element => {
  return (
    <>
      <span className="order-detail_cell-25">
        {_tg('field.rights.accountNameOrId')}
      </span>
      <span className="order-detail_cell-75">
        <Autocomplete
          secondaryColor
          id="subscriberId"
          options={subscribersItems}
          onChange={onChange}
          onAutocomplete={onAutocomplete}
          search={subscriberId}
        />
      </span>
    </>
  );
};

export default OrderCreateSubscriberChoice;
