import React from 'react';
import { CircularProgress, Dialog, DialogProps } from 'material-ui';
import TextField from 'material-ui/TextField';

import FormComponent from 'commons/FormComponent';
import useFilesComment from 'commons/hooks/useFilesComment';
import UploadFilesRecourse from 'tefps/RecoursesV2/pages/Detail/AddFileActionUploadFiles';

import './CommentActionModal.css';

const { _tg, _t } = window.loadTranslations(__filename);

type AddFileModalProps = DialogProps & {
  errors?: {
    [key: string]: string;
  };
  loading: boolean;
};

const AddFileActionModal = ({
  actions,
  open,
  onRequestClose,
  errors,
  loading,
}: AddFileModalProps): JSX.Element => {
  const [filesComment, setFilesComment] = useFilesComment();

  return (
    <Dialog
      title={_t('modal.title')}
      actions={actions}
      open={open}
      onRequestClose={onRequestClose}
    >
      {loading ? (
        <div className="row center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <FormComponent>
            <UploadFilesRecourse name="files" errors={errors} />
          </FormComponent>
          <TextField
            floatingLabelText={_tg('field.comment')}
            className="form__comment"
            onChange={(_e, text) => setFilesComment(text)}
            value={filesComment}
            multiLine
            rows={2}
            rowsMax={5}
          />
        </div>
      )}
    </Dialog>
  );
};

export default AddFileActionModal;
