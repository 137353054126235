import React from 'react';
import { Link } from 'react-router-dom';

import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import Price from 'commons/Price';
import {
  FPS_PAYMENT_STATUS,
  FPS_STATUS,
} from 'tefps/RecoursesV2/utils/translationUtils';
import FpsAddressRenderer from 'commons/Address/FpsAddressRenderer';
import { FpsCaseDTO } from 'api/fps/types';
import { RapoDetailDTO } from 'api/recourse/types';

type FpsSummaryInfoProps = {
  recourseDetails: RapoDetailDTO;
  latestFps: FpsCaseDTO;
};

const { _t, _tg } = window.loadTranslations(__filename);

const FpsSummaryInfo = ({
  recourseDetails,
  latestFps,
}: FpsSummaryInfoProps): JSX.Element => {
  return (
    <div className="recourse-card__content text--small">
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.number')}</span>
        <span>
          <FpsNumber fpsId={recourseDetails.fps.rootFineLegalId} />
        </span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.statementDatetime')}</span>
        <span>
          <Date datetime={latestFps.statementDatetime} />
        </span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.status')}</span>
        <span>{FPS_STATUS()[latestFps.state]}</span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.notificationAuthority')}</span>
        <span>{recourseDetails.fps.agent.worksFor?.name ?? ''}</span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.statementAddress')}</span>
        <span>
          <FpsAddressRenderer fpsAddress={latestFps.statementAddress} />
        </span>
      </div>
      {/* Space between groups */}
      <div className="margin-y--s" />
      <div className="recourse-card__content__row">
        <span className="text-bold">{_tg('field.vehicle.license')}</span>
        <span>{latestFps.licensePlate.plate}</span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_tg('field.vehicle.country')}</span>
        <span>{latestFps.licensePlate.plateCountry}</span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_tg('field.vehicle.category')}</span>
        <span>{latestFps.vehicle.vehicleCategory}</span>
      </div>
      <div className="recourse-card__content__row">
        <span className="text-bold">{_tg('field.vehicle.brand')}</span>
        <span>{latestFps.vehicle.brand}</span>
      </div>
      {/* Space between groups */}
      <div className="margin-y--s" />
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.lastNotificationNumber')}</span>
        <span>
          <FpsNumber
            fpsId={latestFps.versions[latestFps.versions.length - 1].id}
          />
        </span>
      </div>
      {latestFps.paymentStatus && (
        <div className="recourse-card__content__row">
          <span className="text-bold">{_t('fps.paymentStatus')}</span>
          <span>{FPS_PAYMENT_STATUS()[latestFps.paymentStatus]}</span>
        </div>
      )}
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.finePrice')}</span>
        <span>
          <Price price={recourseDetails.fps.finePrice} />
        </span>
      </div>
      {(recourseDetails.fps.reducedDatetime ||
        (recourseDetails.fps.responsibleOfReducedPeriod === 'ANTAI' &&
          recourseDetails.fps.reducedFinePrice)) && (
        <div className="recourse-card__content__row">
          <span className="text-bold">{_t('fps.reducedFinePrice')}</span>
          <span>
            <Price price={recourseDetails.fps.reducedFinePrice} />
          </span>
        </div>
      )}
      <div className="recourse-card__content__row">
        <span className="text-bold">{_t('fps.paidAmount')}</span>
        <span>
          <Price price={recourseDetails.amountPaid} />
        </span>
      </div>
      {(recourseDetails.fps.reducedDatetime ||
        recourseDetails.fps.responsibleOfReducedPeriod === 'ANTAI') && (
        <div className="recourse-card__content__row">
          <span className="text-bold">{_t('fps.endReducedDate')}</span>
          <span>
            {recourseDetails.fps.reducedDatetime ? (
              <Date datetime={recourseDetails.fps.reducedDatetime} withTime />
            ) : (
              _t('fps.noDate')
            )}
          </span>
        </div>
      )}
      {/* Space between groups */}
      <div className="margin-y--s" />
      <div className="recourse-card__content__row">
        <span>
          <Link to={`/fps/detail/${recourseDetails.fps.fineId}`}>
            {_t('fps.link')}
          </Link>
        </span>
      </div>
    </div>
  );
};

export default FpsSummaryInfo;
