import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { CircularProgress, FlatButton, Dialog } from 'material-ui';

import { InternalAgent } from 'api/auth/types';
import BoButton from 'facade/BoButton';
import {
  OrderPrivateDTO,
  OrderStatus,
  ProductOption,
  ProductPrivateDTO,
  ProductType,
  SubscriberDTO,
  OrderSimpleDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import {
  deleteOrderById,
  getOrderById,
  getOrderHistoryPdfUrl,
  TakeDecisionUpdateParams,
  takeDecisionUpdate,
} from 'api/cvfm-core-subscription/order';
import { getProductById } from 'api/cvfm-core-subscription/product';
import { getSubscriber } from 'api/cvfm-core-subscription/subscriber';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import Content from 'commons/Content';
import { ErrorType } from 'commons/types/error';
import translateError from 'commons/Utils/translateErrorUtil';
import { openNewAuthentifiedTab } from 'api/helpers';

import {
  CancelFormType,
  getCancelOrderDTO,
  downloadSupportingDocumentPDF,
} from './helpers';
import OrderDetail from './OrderDetail';

import './OrderDetailPage.css';
import Watchings from 'commons/Watchings';
import { getConfigState } from 'config/duck';

const { _tg } = window.loadTranslations(__filename);

type ForceDecisionParams =
  | {
      update: Promise<OrderPrivateDTO>;
      params: TakeDecisionUpdateParams;
    }
  | undefined;

type OrderDetailPageReduxProps = {
  userInfo: InternalAgent | null | undefined;
  canEdit: boolean;
  canAccept: boolean;
  supportingDocumentGenerationEnabled: boolean;
};

type OrderDetailPageProps = OrderDetailPageReduxProps &
  RouteComponentProps<{ orderId: string }>;

const { _t } = window.loadTranslations(__filename);

const OrderDetailPage = ({
  userInfo,
  canEdit,
  canAccept,
  supportingDocumentGenerationEnabled,
  match,
  history,
}: OrderDetailPageProps): JSX.Element => {
  const { orderId } = match.params;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [order, setOrder] = useState<OrderPrivateDTO>();
  const [orderProduct, setOrderProduct] = useState<ProductPrivateDTO>();
  const [orderSubscribers, setOrderSubscribers] = useState<
    Array<SubscriberDTO>
  >([]);
  const [zones, setZones] = useState<Array<ZoneDTO>>();
  const [forceDecisionParams, setForceDecisionParams] = useState<
    ForceDecisionParams
  >(undefined);
  const [forceDecisionModalOpen, setForceDecisionModalOpen] = useState<boolean>(
    false
  );

  const setMessage = useSnackbar();

  function fetchOrder() {
    getOrderById(orderId)
      .then(orderFetched => {
        setOrder(orderFetched);
        setError(undefined);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function fetchOrderProduct() {
    if (order) {
      getProductById(order.productId)
        .then(productFetched => {
          setOrderProduct(productFetched);
        })
        .catch(err => {
          setError(err as Error);
        });
    }
  }

  async function fetchOrderSubscribers() {
    if (order) {
      const subscribers: Array<SubscriberDTO> = [];
      await Promise.all(
        order.subscribersHistory[
          order.subscribersHistory.length - 1
        ].subscriberIds.map(async subscriberId => {
          const subscriber = await getSubscriber(subscriberId);
          subscribers.push(subscriber);
        })
      );
      setOrderSubscribers(subscribers);
    }
  }

  function loadZoning(): void {
    fetchZoning(FETCH_LIGHT_ZONING_CONFIG)
      .then(fetchedZones => {
        setZones(fetchedZones.zones);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function handleUpdate(
    update: Promise<OrderPrivateDTO>,
    params?: TakeDecisionUpdateParams,
    force?: boolean
  ) {
    setLoading(true);
    if (force && params !== undefined) {
      // eslint-disable-next-line
      update = takeDecisionUpdate({ ...params, force: true });
    }
    update
      .then(updatedOrder => {
        setOrder(updatedOrder);
      })
      .catch(err => {
        if (
          err.status === 417 &&
          params !== undefined &&
          order?.status === OrderStatus.AWAIT_COMPLETION_USER
        ) {
          setForceDecisionParams({
            update,
            params,
          });
          setForceDecisionModalOpen(true);
        }

        setMessage(translateError((err as Error).name as ErrorType));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleCancel(cancelledOrderId: string, formEntries: CancelFormType) {
    setLoading(true);
    deleteOrderById(
      cancelledOrderId,
      getCancelOrderDTO(formEntries, userInfo, false)
    )
      .then(updatedOrder => {
        setOrder(updatedOrder);
      })
      .catch(err => {
        setMessage(translateError((err as Error).name as ErrorType));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onExport() {
    if (order) {
      const url = getOrderHistoryPdfUrl(order.orderId);
      openNewAuthentifiedTab(url);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchOrder();
    loadZoning();
  }, [orderId]);

  useEffect(() => {
    if (order) {
      fetchOrderProduct();
      void fetchOrderSubscribers();
    }
  }, [order]);

  useEffect(() => {
    if (order && zones && orderProduct && orderSubscribers) {
      setLoading(false);
      setError(undefined);
    }
  }, [order, zones, orderProduct, orderSubscribers]);

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock error={error} />
      </FlexCenter>
    );
  }

  return (
    <Content>
      <div className="order-detail-page_header">
        {(orderProduct?.productType === ProductType.BUNDLE && (
          <FlatButton
            href="#/subscription/bundle/order"
            label={_t('navigation.back.bundle')}
          />
        )) ||
          (orderProduct?.productType === ProductType.ELIGIBILITY &&
            order?.status === OrderStatus.FULFILLED && (
              <FlatButton
                href="#/subscription/eligibility/eligibilities"
                label={_t('navigation.back.eligibilities')}
              />
            )) || (
            <FlatButton
              href="#/subscription/eligibility/order"
              label={_t('navigation.back.eligibilityOrder')}
            />
          )}
        | <FlatButton label={_tg('action.export')} onClick={onExport} />
        {supportingDocumentGenerationEnabled &&
          order &&
          [OrderStatus.FULFILLED, OrderStatus.REJECTED].includes(
            order.status
          ) &&
          orderProduct?.supportingDocumentEnabled && (
            <>
              |{' '}
              <FlatButton
                label={_tg(
                  'tefps.Subscription.ProductDetailDescription.field.supportingDocumentEnabled'
                )}
                onClick={() => downloadSupportingDocumentPDF(order.orderId)}
              />
            </>
          )}
      </div>

      <div className="order-detail-page_container">
        {loading && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}
        {!loading &&
          order &&
          orderProduct &&
          orderSubscribers &&
          orderSubscribers.length > 0 &&
          zones && (
            <OrderDetail
              order={order}
              zones={zones}
              orderProduct={orderProduct}
              orderSubscribers={orderSubscribers}
              canEdit={canEdit}
              canAccept={canAccept}
              onUpdateAction={handleUpdate}
              onCancelAction={handleCancel}
              refreshOrder={fetchOrder}
              accessToSubscriber={() => {
                history.push(
                  `/subscription/subscribers/${
                    order.subscribersHistory[
                      order.subscribersHistory.length - 1
                    ].subscriberIds[0]
                  }`
                );
              }}
              accessToActiveRightUsed={() => {
                history.push(
                  `/subscription/${
                    order.activeRightUsed?.productType ===
                    ProductType.ELIGIBILITY
                      ? 'eligibility'
                      : 'bundle'
                  }/order/${order.activeRightUsed?.rightId || ''}`
                );
              }}
              accessToOrderInduced={(orderInduced: OrderSimpleDTO) => {
                history.push(
                  `/subscription/${
                    orderInduced.productType === ProductType.ELIGIBILITY
                      ? 'eligibility'
                      : 'bundle'
                  }/order/${orderInduced.orderId}`
                );
              }}
            />
          )}
      </div>
      {forceDecisionParams && (
        <Dialog
          title={_t('dialog.decision.title')}
          open={forceDecisionModalOpen}
          actions={[
            <BoButton
              label={_tg('action.cancel')}
              key="order-decision-cancel"
              style={{ marginRight: 10 }}
              onClick={() => setForceDecisionModalOpen(false)}
            />,
            <BoButton
              label={_tg('action.confirm')}
              key="orer-decision-confirm"
              primary
              style={{ marginRight: 10 }}
              onClick={() => {
                handleUpdate(
                  forceDecisionParams.update,
                  forceDecisionParams.params,
                  true
                );
                setForceDecisionModalOpen(false);
              }}
            />,
          ]}
          titleClassName="order-detail-modal_title"
        >
          <div>
            <p style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
              {_t('dialog.decision.content')}
            </p>
          </div>
        </Dialog>
      )}
      <Watchings resourceType="AD" resourceId={orderId} />
    </Content>
  );
};

function mapStateToProps(state: InternalApiState): OrderDetailPageReduxProps {
  const { userInfo } = getApiState(state);
  const { subscriptionConfigurationDTO } = getConfigState(state);
  return {
    userInfo,
    canEdit: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
    canAccept: !!userInfo && userInfo.rights.includes('ORDER_ACCEPT'),
    supportingDocumentGenerationEnabled:
      subscriptionConfigurationDTO.supportingDocumentGenerationEnabled,
  };
}

export default withRouter(connect(mapStateToProps)(OrderDetailPage));
