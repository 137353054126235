import React from 'react';

import { OrderPlates } from '@cvfm-front/tefps-types';
import './OrderDetailPage.css';
import { DataBoxItem } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';

const { _tg } = window.loadTranslations(__filename);

export type OrderPlatesWithPreviousPlates = OrderPlates & {
  previousPlates: Array<string>;
};

type OrderDetailHistoryPlatesProps = {
  orderTrace: OrderPlatesWithPreviousPlates;
};

const OrderDetailHistoryPlates = ({
  orderTrace,
}: OrderDetailHistoryPlatesProps): JSX.Element => {
  return (
    <DataBoxItem>
      <FakeInputBlock
        title={_tg('field.vehicle.licensePlate_maybe_plural')}
        value={orderTrace.plates.join(', ')}
      />
      <FakeInputBlock
        title={_tg('field.vehicle.old_licensePlate_maybe_plural')}
        value={orderTrace.previousPlates.join(', ')}
      />
    </DataBoxItem>
  );
};

export default OrderDetailHistoryPlates;
