import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';

import { FileCheckbox } from '../utils/recourseFilesUtils';

const useOtherFile = (): [FileCheckbox, (arg0: FileCheckbox) => void] => {
  return [
    useWatcher(services.complementRequest.watchOtherFile, {
      name: '',
      checked: false,
    }),
    services.complementRequest.setOtherFile,
  ];
};

export default useOtherFile;
