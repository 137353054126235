import * as React from 'react';
import { Checkbox } from 'material-ui';

import { ApplicationProcedureDTO } from '@cvfm-front/tefps-types';

import ProductDetailSecondarySeparator from '../ProductDetailSecondarySeparator';

const { _t } = window.loadTranslations(__filename);

type ProductEvidencesProps = {
  applicationProcedure: ApplicationProcedureDTO;
  updateApplicationProcedure: (
    applicationProcedure: ApplicationProcedureDTO
  ) => void;
  canEdit: boolean;
};

function Claims({
  applicationProcedure,
  updateApplicationProcedure,
  canEdit,
}: ProductEvidencesProps): JSX.Element {
  function handleChangeApplicationProcedure(
    event: React.MouseEvent<HTMLDataElement>,
    value: boolean
  ) {
    const key = event.currentTarget.dataset.field as string;

    updateApplicationProcedure({
      ...applicationProcedure,
      [key]: value,
    });
  }

  return (
    <>
      <ProductDetailSecondarySeparator title={_t('title')} />
      <div className="product-detail_section-content-row">
        <span className="product-detail_cell-50">
          <Checkbox
            className="product-detail_checkbox"
            label={_t('authorizeClaims')}
            checked={applicationProcedure.authorizeClaims}
            data-field="authorizeClaims"
            disabled={!canEdit}
            onCheck={handleChangeApplicationProcedure}
          />
        </span>
      </div>
    </>
  );
}

export default Claims;
