import moment from 'moment';

import { OrderStatus } from '@cvfm-front/tefps-types';

const { _t, _tg } = window.loadTranslations(__filename);

export function translateOrderStatus(
  status: OrderStatus,
  startOfValidity?: string,
  endOfValidity?: string,
  isFilter?: boolean
): string {
  if (
    status === OrderStatus.FULFILLED &&
    startOfValidity &&
    moment(startOfValidity).isAfter(moment())
  ) {
    return _tg('status.awaitingBegining');
  }

  if (
    status === OrderStatus.FULFILLED &&
    endOfValidity &&
    moment(endOfValidity).isBefore(moment())
  ) {
    return _tg('status.expired');
  }

  switch (status) {
    case OrderStatus.AWAIT_TREATMENT_COMPLETUDE:
      return _t('status.AWAIT_TREATMENT_COMPLETUDE');
    case OrderStatus.AWAIT_TREATMENT_RENEWAL:
      return _t('status.AWAIT_TREATMENT_RENEWAL');
    case OrderStatus.AWAIT_COMPLETION_USER:
      return _t('status.AWAIT_COMPLETION_USER');
    case OrderStatus.AWAIT_PLATE_DECISION:
      return _t('status.AWAIT_PLATE_DECISION');
    case OrderStatus.AWAIT_DECISION:
      return _t('status.AWAIT_DECISION');
    case OrderStatus.AWAIT_EVIDENCE:
      return _t('status.AWAIT_EVIDENCE');
    case OrderStatus.AWAIT_PAYMENT:
      return _t('status.AWAIT_PAYMENT');
    case OrderStatus.FULFILLED:
      return isFilter ? _t('status.FULFILLED_FILTER') : _t('status.FULFILLED');
    case OrderStatus.REJECTED:
      return _t('status.REJECTED');
    case OrderStatus.CANCELED:
      return _t('status.CANCELED');
    case OrderStatus.AWAIT_CLAIM_DECISION:
      return _t('status.AWAIT_CLAIM_DECISION');
    case OrderStatus.AWAIT_CLAIM_EVIDENCES:
      return _t('status.AWAIT_CLAIM_EVIDENCES');
    case OrderStatus.AWAIT_CLAIM_COMPLETION_USER:
      return _t('status.AWAIT_CLAIM_COMPLETION_USER');
    case OrderStatus.AWAIT_CLAIM_TREATMENT_COMPLETUDE:
      return _t('status.AWAIT_CLAIM_TREATMENT_COMPLETUDE');
    case OrderStatus.REJECTED_CLAIM:
      return _t('status.REJECTED_CLAIM');
    default:
      return _t('status.UNKNOWN_ERROR');
  }
}
