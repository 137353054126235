import React from 'react';
import { Table, TableBody } from 'material-ui/Table';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import {
  CircularProgress,
  IconButton,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import EyeIcon from 'material-ui/svg-icons/image/remove-red-eye';

import { OrderSimpleDTO } from '@cvfm-front/tefps-types';
import FlexCenter from 'commons/FlexCenter';
import Date from 'commons/Date';

import { translateOrderStatus } from './OrderStatusTranslator';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CARD = {
  flex: 1,
  margin: 10,
  minWidth: 300,
};

interface Props {
  ordersInduced?: Array<OrderSimpleDTO>;
  accessToOrderInduced: (orderInduced: OrderSimpleDTO) => void;
}

function OrderInduced({
  ordersInduced,
  accessToOrderInduced,
}: Props): JSX.Element {
  function getContent() {
    if (ordersInduced === undefined) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }
    if (ordersInduced.length === 0) {
      return <>{_t('noOrders')}</>;
    }
    return (
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>{_t('header.productName')}</TableHeaderColumn>
            <TableHeaderColumn>
              {_t('header.productOptionName')}
            </TableHeaderColumn>
            <TableHeaderColumn>{_tg('field.vehicle.plate')}</TableHeaderColumn>
            <TableHeaderColumn>{_tg('field.status')}</TableHeaderColumn>
            <TableHeaderColumn>
              {_tg('field.date.startValidity')}
            </TableHeaderColumn>
            <TableHeaderColumn>
              {_tg('field.date.endValidity')}
            </TableHeaderColumn>
            <TableHeaderColumn>{_t('header.go_to')}</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {ordersInduced?.map(order => (
            <TableRow>
              <TableRowColumn>
                <span title={order.productName}>{order.productName} </span>
              </TableRowColumn>
              <TableRowColumn>
                <span title={order.productOptionName}>
                  {order.productOptionName}
                </span>
              </TableRowColumn>
              <TableRowColumn>{order.plates}</TableRowColumn>
              <TableRowColumn>
                {translateOrderStatus(
                  order.status,
                  order.validityPeriod.startOfValidity,
                  order.validityPeriod.endOfValidity
                )}
              </TableRowColumn>
              <TableRowColumn>
                <Date
                  datetime={order.validityPeriod.startOfValidity}
                  withTime={false}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Date
                  datetime={order.validityPeriod.endOfValidity}
                  withTime={false}
                />
              </TableRowColumn>
              <TableRowColumn>
                <IconButton
                  title={_tg('action.preview')}
                  onClick={() => accessToOrderInduced(order)}
                >
                  <EyeIcon />
                </IconButton>
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      <Card style={STYLE_CARD}>
        <CardHeader
          title={`${_t('cardHeader')} - ${_tg('commons.total')} : ${
            ordersInduced ? ordersInduced.length : 0
          }`}
          showExpandableButton
          actAsExpander
        />
        <CardText expandable>{getContent()}</CardText>
      </Card>
    </div>
  );
}

export default OrderInduced;
