import * as React from 'react';
import { useState } from 'react';

import Sidebar from 'commons/SidebarV2';
import { ProductSearchQueryDTO } from '@cvfm-front/tefps-types';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Input from 'commons/SidebarV2/Components/Input';

const { _t } = window.loadTranslations(__filename);

type ProductTableSidebarProps = {
  initialFilters: ProductSearchQueryDTO;
  onChangeFilters: (filters: ProductSearchQueryDTO) => void;
  totalHits: number;
};

const ProductTableSidebar = ({
  initialFilters,
  onChangeFilters,
  totalHits,
}: ProductTableSidebarProps): JSX.Element => {
  const [filters, setFilters] = useState<ProductSearchQueryDTO>(initialFilters);

  function resetFilters() {
    const updateFilters = { ...initialFilters };
    setFilters(updateFilters);
    onChangeFilters(updateFilters);
  }

  function onChangeText(id: string, value: string) {
    const newFilters = {
      ...filters,
      [id]: value || undefined,
    } as ProductSearchQueryDTO;
    setFilters(newFilters);
    onChangeFilters(newFilters);
  }

  function onChangeNumber(id: string, value: string) {
    if (value !== ' ' && !Number.isNaN(Number(value))) {
      const newFilters = {
        ...filters,
        [id]: value !== '' ? parseInt(value, 10) : undefined,
      } as ProductSearchQueryDTO;
      setFilters(newFilters);
      onChangeFilters(newFilters);
    }
  }

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Input
        id="productName"
        title={_t('productName.title')}
        placeholder={_t('productName.placeholder')}
        onChange={onChangeText}
        value={filters.productName || ''}
      />
      <Input
        id="productId"
        title={_t('productId.title')}
        placeholder={_t('productId.placeholder')}
        onChange={onChangeText}
        value={filters.productId || ''}
      />
      <Input
        id="productNbOptions"
        title={_t('productNbOptions.title')}
        placeholder={_t('productNbOptions.placeholder')}
        onChange={onChangeNumber}
        value={
          filters.productNbOptions ? filters.productNbOptions.toString() : ''
        }
      />
      <Input
        id="productPrice"
        title={_t('productPrice.title')}
        placeholder={_t('productPrice.placeholder')}
        onChange={onChangeNumber}
        value={
          filters.productPrice !== undefined
            ? filters.productPrice.toString()
            : ''
        }
      />
    </Sidebar>
  );
};

export default ProductTableSidebar;
