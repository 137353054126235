import React, { useState } from 'react';
import SwapIcon from 'material-ui/svg-icons/action/swap-horiz';
import Checkbox from 'material-ui/Checkbox';

import useInitialPrice from 'commons/hooks/useInitialPrice';
import useInitialReducedPrice from 'commons/hooks/useInitialReducedPrice';
import useNewPrice from 'commons/hooks/useNewPrice';
import useNewReducedPrice from 'commons/hooks/useNewReducedPrice';
import FakeInputBlock from 'commons/FakeInputBlock';
import NumberField from 'commons/NumberField';

import './DecisionPrice.css';

const { _t } = window.loadTranslations(__filename);

const getPercentFromInitialPrice = (
  newPrice: number | null | undefined,
  initialPrice: number
): number | null | undefined => {
  if (typeof newPrice === 'number') {
    return ((initialPrice - newPrice) / initialPrice) * 100;
  }
  return null;
};

const DecisionPrice = (): JSX.Element => {
  /* Immutable service vars */
  const initialPrice = useInitialPrice();
  const initialReducedPrice = useInitialReducedPrice();

  /* Service vars */
  const [newPrice, setNewPrice] = useNewPrice();
  const [newReducedPrice, setNewReducedPrice] = useNewReducedPrice();

  /* Component state vars */
  const [percent, setPercent] = useState(
    getPercentFromInitialPrice(newPrice, initialPrice)
  );
  const [warning, setWarning] = useState<string | null>(null);
  const [withReducedPrice, setWithReducedPrice] = useState(
    newReducedPrice != null
  );

  const computeWarning = (
    price: number | null,
    reducedPrice: number | null | undefined
  ): string | null => {
    if (typeof price !== 'number') {
      return null;
    }
    if (reducedPrice != null && reducedPrice > price) {
      return _t('warning');
    }
    return null;
  };

  /* For legacy component handling */
  const handleChangePrice = (
    newFpsPrice: number | null,
    newFpsReducedPrice: number | null | undefined
  ) => {
    setNewPrice(newFpsPrice);
    setNewReducedPrice(
      newFpsReducedPrice === undefined ? null : newFpsReducedPrice
    );
  };

  const onChangePrice = (price: number): void => {
    // If price is zero, no point in having a reduced price
    const newReducedPriceVal = price === 0 ? null : newReducedPrice;
    const withReducedPriceVal = price === 0 ? false : withReducedPrice;

    setPercent(getPercentFromInitialPrice(price, initialPrice));
    setWarning(computeWarning(price, newReducedPriceVal));
    setWithReducedPrice(withReducedPriceVal);

    handleChangePrice(price, newReducedPriceVal);
  };

  const onChangePercent = (newPercent: number | null | undefined): void => {
    if (newPercent == null) {
      setPercent(newPercent);
      handleChangePrice(null, newReducedPrice);
    } else {
      // Same here, if price is zero, no reduced price
      const price = initialPrice - initialPrice * (newPercent / 100);
      const reducedPrice = price > 0 ? newReducedPrice : null;
      setPercent(newPercent);
      setWithReducedPrice(price > 0 ? withReducedPrice : false);
      setWarning(computeWarning(price, newReducedPrice));
      handleChangePrice(price, reducedPrice);
    }
  };

  const onChangeReducedPrice = (
    reducedPrice: number | null | undefined
  ): void => {
    if (!initialReducedPrice) return; // Guard
    setWarning(computeWarning(newPrice, reducedPrice));
    handleChangePrice(newPrice, reducedPrice);
  };

  const handleCheck = (_e: unknown, checkWithReducedPrice: boolean): void => {
    if (!initialReducedPrice) return; // Guard

    let reducedPrice;
    if (checkWithReducedPrice) {
      reducedPrice = newPrice
        ? Math.min(initialReducedPrice, newPrice)
        : initialReducedPrice;
    } else {
      reducedPrice = null;
    }
    setWithReducedPrice(checkWithReducedPrice);

    handleChangePrice(newPrice, reducedPrice);
  };

  return (
    <div className="decision-price">
      <FakeInputBlock title={_t('decisionPrice.price')} />
      <div className="decision-price__text">
        {_t('decisionPrice.description')}
      </div>
      <div className="decision-price__amount-container">
        <div className="decision-price__amount">
          <div>
            <span>{_t('decisionPrice.amountModification')}</span>
            <NumberField
              style={{ width: 50 }}
              value={percent !== null ? percent : undefined}
              onChange={onChangePercent}
              min={0}
              max={100}
              name="percent-reduction"
            />
            <span>%</span>
          </div>
          <SwapIcon
            className="decision-price__amount"
            color="var(--bg-color--pink)"
          />
          <div>
            <span>{_t('decisionPrice.newAmount')}</span>
            <NumberField
              style={{ width: 50 }}
              value={newPrice !== null ? newPrice : undefined}
              onChange={onChangePrice}
              min={0}
              max={initialPrice}
              name="new-price"
            />
            <span>€</span>
          </div>
        </div>
        {initialReducedPrice && (
          <div className="decision-price__amount decision-price__reduced-amount">
            <div>
              <Checkbox
                label={_t('decisionPrice.preserveReduction')}
                title={
                  newPrice != null && newPrice <= 0
                    ? _t('decisionPrice.canceledMessage')
                    : undefined
                }
                checked={withReducedPrice}
                onCheck={handleCheck}
                disabled={newPrice != null && newPrice <= 0}
                labelPosition="left"
                labelStyle={{ color: 'var(--bg-color--pink)', width: '100%' }}
              />
            </div>
            {withReducedPrice && (
              <div>
                <span>{_t('decisionPrice.newReducedPrice')}</span>
                <NumberField
                  className="decision-price__reduced-amount__number"
                  value={newReducedPrice !== null ? newReducedPrice : undefined}
                  onChange={onChangeReducedPrice}
                  min={0}
                  max={initialReducedPrice}
                  name="new-reduced-price"
                />
                <span>{_t('currency')}</span>
              </div>
            )}
          </div>
        )}
        {warning && <div className="decision-price__warning">{warning}</div>}
      </div>
    </div>
  );
};

export default DecisionPrice;
