import { Dialog, FlatButton } from 'material-ui';
import React from 'react';

import { FpsEligibility } from '@cvfm-front/tefps-types';
import Date from 'commons/Date';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  fpsEligibilities: Array<FpsEligibility>;
};

const EasyParkEligibilitiesModal = ({
  open,
  setOpen,
  fpsEligibilities,
}: Props): JSX.Element => {
  return (
    <Dialog
      open={open}
      actions={[<FlatButton label="Fermer" onClick={() => setOpen(false)} />]}
    >
      <div style={{ paddingBottom: 40, fontWeight: 'bold', fontSize: '1.2em' }}>
        <span>
          {_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.EasyParkEligibilitiesModal.title'
          )}
        </span>
      </div>
      <table className="full-width-table">
        <thead>
          <tr>
            <th>
              {_tg(
                'tefps.recourseV2.pages.Detail.FpsBlock.EasyParkEligibilitiesModal.profile'
              )}
            </th>
            <th>
              {_tg(
                'tefps.recourseV2.pages.Detail.FpsBlock.EasyParkEligibilitiesModal.validFrom'
              )}
            </th>
            <th>
              {_tg(
                'tefps.recourseV2.pages.Detail.FpsBlock.EasyParkEligibilitiesModal.validUntil'
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {fpsEligibilities.map(eligibility => {
            return (
              <tr>
                {' '}
                {/* Add a unique key for each row */}
                <td>{eligibility.profile}</td>
                <td>
                  <Date
                    datetime={eligibility.startOfValidity}
                    withTime={false}
                  />
                </td>
                <td>
                  <Date datetime={eligibility.endOfValidity} withTime={false} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ul />
    </Dialog>
  );
};

export default EasyParkEligibilitiesModal;
