import React, { useEffect, useState } from 'react';

import { Flex } from '@cvfm-front/commons-ui';
import Loading from 'tefps/RecoursesV2/commons/Loading';
import { ZoneDTO, ZoningDTO } from '@cvfm-front/tefps-types';
import { ControlDTO } from 'api/control/types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  control: ControlDTO | null;
  zoning: ZoningDTO | null;
};

const ControlDetail = ({ control, zoning }: Props): JSX.Element => {
  const [zone, setZone] = useState<ZoneDTO | null>(null);

  useEffect(() => {
    if (control && control.zoneId && zoning) {
      const zoneDTO = zoning.zones.find(zone => zone.id === control.zoneId);
      setZone(zoneDTO || null);
    }
  }, [control, zoning]);

  if (!control || !zoning) {
    return <Loading />;
  }

  return (
    <Flex>
      <table className="table">
        <tbody>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.controlNbr')}
            </td>
            <td className="field">{control.controlId}</td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.dateHourControl')}
            </td>
            <td className="field">{control.statementDatetime}</td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.controlStatus')}
            </td>
            <td className="field">{control.status}</td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.agentOfControl')}
            </td>
            <td className="field">{control.agent.name}</td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.controlSource')}
            </td>
            <td className="field">
              {control.deviceServiceChannel?.deviceType || ''}
            </td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.terminalNbr')}
            </td>
            <td className="field">{control.terminalId || ''}</td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.controlArea')}
            </td>
            <td className="field">{zone ? zone.name : ''}</td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg(
                'tefps.recourseV2.proposal.controlDetail.distanceControlAddress'
              )}
            </td>
            <td className="field">
              {control.statementAddressDistance
                ? Math.round(control.statementAddressDistance)
                : ''}
            </td>
          </tr>
          <tr>
            <td className="field-name">
              {_tg('tefps.recourseV2.proposal.controlDetail.comment')}
            </td>
            <td className="field">{control.comment || ''}</td>
          </tr>
        </tbody>
      </table>
    </Flex>
  );
};

export default ControlDetail;
