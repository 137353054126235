import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loading from 'tefps/RecoursesV2/commons/Loading';
import ErrorPage from 'tefps/RecoursesV2/commons/ErrorPage';
import ReturnButton from 'tefps/RecoursesV2/commons/ReturnButton';
import Services from 'commons/services';

import RecoursePage from './RecoursePage';

const { _tg } = window.loadTranslations();

const RecourseDetail = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();

  const { recourseId } = useParams<{ recourseId: string }>();

  const getRecourse = async (id: string): Promise<void> => {
    try {
      await Services.recourse.init(id);
      setError(undefined);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getRecourse(recourseId);
  }, [recourseId]);

  if (error) {
    return (
      <ErrorPage
        message={_tg('feedback.error.fetchRecourse')}
        error={error}
        navContent={
          <ReturnButton
            href="#/recourses/list"
            label={_tg('action.backToList')}
          />
        }
      />
    );
  }

  if (!loading) {
    return <RecoursePage />;
  }

  return <Loading />;
};

export default RecourseDetail;
