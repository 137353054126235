import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { RecourseUser } from 'api/recourse/types';
import RapoAddressRenderer from 'commons/Address/RapoAddressRenderer';

const { _tg } = window.loadTranslations(__filename);

type UserInformationProps = {
  user: RecourseUser;
};

const UserInformation = ({ user }: UserInformationProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        {user.gender === 'ORGA' ? (
          <DataBoxItem>
            <FakeInputBlock
              title={_tg('field.representative')}
              value={user.lastName}
            />
          </DataBoxItem>
        ) : (
          <DataBoxItem>
            <FakeInputBlock
              title={_tg('field.human.lastname_short')}
              value={user.lastName}
            />
            <FakeInputBlock
              title={_tg('field.human.firstname')}
              value={user.firstName}
            />
          </DataBoxItem>
        )}
        <Separator />
        <DataBoxItem style={{ margin: '0 3.5vw' }}>
          <FakeInputBlock
            title={_tg('field.address.address')}
            value={<RapoAddressRenderer user={user} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock title={_tg('field.human.email')} value={user.email} />
          <FakeInputBlock
            title={_tg('field.human.phone')}
            value={user.phoneNumber}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default UserInformation;
