import React from 'react';

import { FnmsFpsDTO } from 'api/fps/types';
import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Price from 'commons/Price';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import AddressRenderer from 'commons/Address/AddressRenderer';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';

import ComputeFilterAndRedirect from '../FpsBlock/ComputeFilterAndRedirect';

import './InitialFps.css';

const { _t, _tg } = window.loadTranslations(__filename);

type InitialFpsProps = {
  fps: FnmsFpsDTO;
};

const InitialFps = ({ fps }: InitialFpsProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <div className="initial-fps__title">
        <span className="initial-fps__subtitle">{_t('content.title')}</span>
        <FpsNumber fpsId={fps.fineId} />
      </div>
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.date')}
            value={<Date datetime={fps.statementDatetime} withTime={false} />}
          />
          <FakeInputBlock
            title={_tg('field.date.hour')}
            value={<Date datetime={fps.statementDatetime} withDate={false} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.agent.id')}
            value={fps.agent.agentId}
          />
          <FakeInputBlock
            title={_t('content.statementAddress')}
            value={<AddressRenderer address={fps.statementAddress} />}
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.payment.amount')}
            value={<Price price={fps.finePrice} />}
          />
          {fps.reducedFinePrice && (
            <FakeInputBlock
              title={_tg('field.payment.priceReduced')}
              value={<Price price={fps.reducedFinePrice} />}
            />
          )}
        </DataBoxItem>
      </DataBoxItemWrapper>
      <div className="initial-fps__link">
        <span>{_t('links.title')}</span>
        <ComputeFilterAndRedirect
          type="FPS"
          label={_t('links.fps')}
          plate={fps.licensePlate.plate}
          date={null}
        />
        -
        <ComputeFilterAndRedirect
          type="SUBSCRIPTION"
          label={_t('links.eligibilities')}
          plate={fps.licensePlate.plate}
          date={null}
        />
        -
        <ComputeFilterAndRedirect
          type="AD_ELIGIBILITIES"
          label={_t('links.subscription')}
          plate={fps.licensePlate.plate}
          date={null}
        />
        -
        <ComputeFilterAndRedirect
          type="TV"
          label={_t('links.tickets')}
          plate={fps.licensePlate.plate}
          date={fps.statementDatetime}
        />
        -
        <ComputeFilterAndRedirect
          type="RAPO"
          label={_t('links.recourse')}
          plate={fps.licensePlate.plate}
          date={null}
        />
      </div>
    </div>
  );
};

export default InitialFps;
