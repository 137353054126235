import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Flex } from '@cvfm-front/commons-ui';
import { Config, getConfigState } from 'config/duck';
import { getApiState } from 'api/duck';
import { InternalAgent } from 'api/auth/types';

import services from '../../commons/services';

import Loading from './commons/Loading';

interface ServiceLoaderProps {
  config: Config;
  userInfo: InternalAgent;
  children: React.ReactNode;
}

const ServiceLoader = ({
  config,
  userInfo,
  children,
}: ServiceLoaderProps): JSX.Element => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    void (async (): Promise<void> => {
      await services.init(config, userInfo, 'RAPO');
      setDone(true);
    })();
    setDone(true);
  }, []);

  return (
    <>
      {!done && (
        <Flex className="service-loader">
          <Loading />
        </Flex>
      )}
      {done && children}
    </>
  );
};

export default connect(state => {
  const config = getConfigState(state);
  const { userInfo } = getApiState(state);
  return { config, userInfo };
})(ServiceLoader);
