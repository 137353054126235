import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import useRecourse from 'commons/hooks/useRecourse';
import useFilesComment from 'commons/hooks/useFilesComment';
import useEvidenceFiles from 'commons/hooks/useEvidenceFiles';
import BoButton from 'facade/BoButton';
import RecourseButton from 'facade/RecourseButton';
import { patchRecourse } from 'api/recourse';
import { RecourseFileDTO } from 'api/recourse/types';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { uploadRapoFiles } from 'tefps/Fps/Detail/AddContents/AddRecourse/helpers';
import Services from 'commons/services';
import { getConfigState } from 'config/duck';

import AddFileActionModal from './AddFileActionModal';

const { _t, _tg } = window.loadTranslations(__filename);

const AddFileAction = ({
  watermarkText,
}: {
  watermarkText: string | null;
}): JSX.Element => {
  const recourseDetails = useRecourse();
  const [filesComment, setFilesComment] = useFilesComment();
  const [evidenceFiles, setEvidenceFiles] = useEvidenceFiles();
  const setMessage = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<{ [p: string]: string }>({});

  const handleModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setCanSubmit(!!evidenceFiles || !!filesComment);
  }, [filesComment, evidenceFiles]);

  const submit = async (): Promise<void> => {
    if (!recourseDetails) {
      return;
    }
    setLoading(true);
    try {
      // Init upload
      const patches = [];
      const recourseFiles: Array<RecourseFileDTO> = [];

      // Upload files (First param should be mapped before)
      const filesResponse = await uploadRapoFiles(
        Object.assign({}, ...evidenceFiles.map(x => ({ [x.id]: x.file }))),
        recourseDetails.recourse.rapoId,
        watermarkText,
        recourseFiles
      );

      if (Object.keys(filesResponse).length > 0) {
        setUploadErrors(filesResponse);
        return;
      }

      /* Adding recourse files by agent */
      patches.push({
        path: '/files',
        op: 'add',
        value: recourseFiles,
      });
      /* Adding comment */
      patches.push({
        path: '/comments',
        op: 'add',
        value: { addedTags: [], removedTags: [], text: filesComment },
      });
      await patchRecourse(recourseDetails.recourse.rapoId, patches);
      // Clean input
      setFilesComment('');
      setEvidenceFiles([]);
      setMessage(_t('submit.success'));
      // Close modal
      handleModal();
      // Update recourse details
      await Services.recourse.init(recourseDetails.recourse.rapoId);
    } catch (e) {
      setMessage(_t('submit.error'));
    } finally {
      // If error, re-enable submit
      setLoading(false);
    }
  };

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      onClick={handleModal}
      style={{ marginRight: 10 }}
      disabled={loading}
    />,
    <BoButton
      label={_tg('action.add')}
      primary
      keyboardFocused
      disabled={!canSubmit || loading}
      onClick={submit}
    />,
  ];

  return (
    <>
      <RecourseButton
        label={_t('title')}
        backgroundColor="var(--bg-color--light-blue)"
        labelColor="var(--white)"
        onClick={handleModal}
      />
      <AddFileActionModal
        open={open}
        actions={actions}
        onRequestClose={handleModal}
        errors={uploadErrors}
        loading={loading}
      />
    </>
  );
};

export default connect(state => {
  const { watermarkText } = getConfigState(state);
  return {
    watermarkText,
  };
})(AddFileAction);
