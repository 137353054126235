import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Separator from 'commons/Separator';
import Date from 'commons/Date';
import { DataBoxItem, DataBoxItemWrapper } from 'commons/DataBox';
import { RecourseDTO } from 'api/recourse/types';
import { RECOURSE_REASONS } from 'tefps/RecoursesV2/utils/translationUtils';
import { getCreationRecourseFiles } from 'tefps/RecoursesV2/utils/recourseFilesUtils';

import FilesContainer from '../FilesContainer';

const { _t, _tg } = window.loadTranslations(__filename);

type RecourseCreationProps = {
  recourse: RecourseDTO;
};

const RecourseCreation = ({ recourse }: RecourseCreationProps): JSX.Element => {
  return (
    <div className="recourse-timeline__block">
      <DataBoxItemWrapper>
        <DataBoxItem>
          <FakeInputBlock
            title={_tg('field.date.creation')}
            value={<Date datetime={recourse.created} withTime={false} />}
          />
          <FakeInputBlock
            title={_t('field.receptionDate')}
            value={<Date datetime={recourse.received} withTime={false} />}
          />
          <FakeInputBlock
            title={_t('field.requestReason')}
            value={RECOURSE_REASONS[recourse.reason]}
          />
          <FakeInputBlock
            title={_t('field.userSource')}
            value={
              recourse.createdByAgent ? _t('field.agent') : _t('field.user')
            }
          />
        </DataBoxItem>
        <Separator />
        <DataBoxItem>
          <FilesContainer
            created={recourse.created}
            files={getCreationRecourseFiles(recourse)}
            reason={recourse.reason}
            rapoId={recourse.rapoId}
          />
        </DataBoxItem>
      </DataBoxItemWrapper>
    </div>
  );
};

export default RecourseCreation;
