import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, MenuItem, SelectField } from 'material-ui';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  OrderPrivateDTO,
  OrderTags,
  OrderTraceType,
  SubscriptionSource,
} from '@cvfm-front/tefps-types';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { fetchTags, TagsTypes } from 'api/tags';
import { addTagsUpdate } from 'api/cvfm-core-subscription/order';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalZonesProps = {
  open: boolean;
  order: OrderPrivateDTO;
  userInfo: InternalAgent | null | undefined;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
};

const OrderDetailModalTags = ({
  open,
  order,
  userInfo,
  onCancel,
  onConfirm,
}: OrderDetailModalZonesProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [allChoosableTags, setAllChoosableTags] = useState<string[]>([]);
  const [newOrderTags, setNewOrderTags] = useState<Array<string>>(order.tags);

  const setMessage = useSnackbar();

  const loadAllChoosableTagsList = async (): Promise<void> => {
    try {
      setLoading(true);
      const tags = await fetchTags(
        TagsTypes.order,
        userInfo?.worksFor?.organizationId,
        false
      );
      setAllChoosableTags(tags);
    } catch (e) {
      setMessage(
        _tg('Configuration.commons.AdminTags.index.feedback.error.load')
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    void loadAllChoosableTagsList();
  }, []);

  const onCancelAction = () => {
    setNewOrderTags([]);
    onCancel();
  };

  const onConfirmAction = () => {
    const updateTags = {
      timestamp: new Date().toISOString(),
      traceType: OrderTraceType.TAGS,
      source: SubscriptionSource.BACK,
      agent: userInfo ? { ...userInfo } : null,
      tags: newOrderTags,
      subscriberId: null,
    } as OrderTags;

    const updatePromise = addTagsUpdate(order.orderId, updateTags);

    onConfirm(updatePromise);
  };

  const onChange = (newTags: Array<string>) => {
    setNewOrderTags(newTags);
  };

  const hasTagsChanged = (): boolean => {
    return (
      order.tags.length !== newOrderTags.length ||
      order.tags.some(x => !new Set(newOrderTags).has(x)) ||
      newOrderTags.some(x => !new Set(order.tags).has(x))
    );
  };

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-tags-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="order-tags-confirm"
      primary
      style={{ marginRight: 10 }}
      disabled={!hasTagsChanged()}
      onClick={onConfirmAction}
    />,
  ];

  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      {loading && (
        <div className="order-detail_row">
          <CircularProgress />
        </div>
      )}
      <SelectField
        key="select-order-tags"
        floatingLabelFixed
        floatingLabelText={_t('field.modifyTags')}
        fullWidth
        multiple
        onChange={(_e, _i, v) => onChange(v)}
        value={newOrderTags}
      >
        {allChoosableTags.map(tag => (
          <MenuItem
            id={tag}
            key={tag}
            value={tag}
            primaryText={tag}
            insetChildren
          />
        ))}
      </SelectField>
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(OrderDetailModalTags);
