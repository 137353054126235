import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';

import useRecourse from 'commons/hooks/useRecourse';
import { Flex } from '@cvfm-front/commons-ui';
import FormComponent from 'commons/FormComponent';
import { PatchObject } from 'api/commonTypes';
import ModalActions from 'tefps/RecoursesV2/commons/ModalActions';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { RecourseFileDTO, RecourseInformationDTO } from 'api/recourse/types';
import { patchRecourse } from 'api/recourse';
import Services from 'commons/services';
import { DatetimePickerCustom } from 'commons/FormComponent/Fields';
import {
  STYLE_FIELD,
  STYLE_INPUTS_CONTAINER,
} from 'tefps/Fps/List/AddFPS/common.styles';
import RecourseButton from 'facade/RecourseButton';

const { _t } = window.loadTranslations(__filename);

const SetSendingDateAction = (): JSX.Element => {
  const recourseDetails = useRecourse();
  const setMessage = useSnackbar();

  /* Component state */
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sendingDate, setSendingDate] = useState<Date>(new Date());

  const handleModal = () => {
    setOpen(!open);
  };

  const onChangeDate = (newDate: Date | null | undefined): void => {
    if (newDate) {
      setSendingDate(newDate);
    }
  };

  const onValidate = async () => {
    if (!recourseDetails) {
      return;
    }
    try {
      setLoading(true);
      const value: RecourseInformationDTO = {
        sentDate: sendingDate,
      };

      const patches: Array<PatchObject<
        RecourseInformationDTO | RecourseFileDTO[]
      >> = [
        {
          path: '/information',
          op: 'replace',
          value,
        },
      ];
      await patchRecourse(recourseDetails.recourse.rapoId, patches);
      setMessage(_t('submit.success'));
      // Close modal
      handleModal();
      // Update recourse details
      await Services.recourse.init(recourseDetails.recourse.rapoId);
    } catch (e) {
      setMessage(_t('submit.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <RecourseButton
        label={_t('button.title.sendingDate')}
        backgroundColor="var(--bg-color--pink)"
        labelColor="var(--white)"
        onClick={handleModal}
      />
      <Dialog
        open={open}
        title={_t('modal.title')}
        titleClassName="modal-form__title"
        bodyClassName="modal-form"
        actions={
          !loading ? ModalActions({ onValidate, onClose: handleModal }) : []
        }
        onRequestClose={handleModal}
      >
        <Flex flexDirection="column">
          <FormComponent>
            <DatetimePickerCustom
              id="completionDate"
              onChange={onChangeDate}
              name={_t('modal.sendingDate')}
              hintDate={_t('modal.sendingDate')}
              hintHour={_t('modal.sendingTime')}
              defaultValue={sendingDate}
              styleContainer={STYLE_INPUTS_CONTAINER}
              styleField={STYLE_FIELD}
              mandatory
            />
          </FormComponent>
        </Flex>
      </Dialog>
    </>
  );
};

export default SetSendingDateAction;
