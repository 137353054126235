import React, { useEffect, useState } from 'react';
import { Dialog } from 'material-ui';

import { ParkingRightOverviewDTO } from 'api/tickets/types';
import useZoning from 'commons/hooks/useZoning';
import { ZoneDTO } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';
import Date from 'commons/Date';
import FakeInputBlock from 'commons/FakeInputBlock';

import './TicketDetail.css';

const { _t } = window.loadTranslations(__filename);

type Props = {
  parkingRight: ParkingRightOverviewDTO;
  open: boolean;
  onRequestClose: () => void;
};

const TicketDetail = ({
  parkingRight,
  open,
  onRequestClose,
}: Props): JSX.Element => {
  const [zone, setZone] = useState<ZoneDTO | null>(null);
  const {
    plate,
    creationDatetime,
    startDatetime,
    endDatetime,
    price,
    source,
    pricingCategory,
    versionDatetime,
    type,
  } = parkingRight;

  const zoning = useZoning();

  useEffect(() => {
    if (parkingRight && parkingRight.zoneId && zoning) {
      const zoneDTO = zoning.zones.find(
        zone => zone.id === parkingRight.zoneId
      );
      setZone(zoneDTO || null);
    }
  }, [parkingRight, zoning]);

  return (
    <Dialog open={open} onRequestClose={onRequestClose}>
      <Flex flexDirection="column">
        <span
          onClick={onRequestClose}
          className="ticket-detail__close-button ticket-detail__close-button-hover"
        >
          {_t('closeWindow')}
        </span>
        <FakeInputBlock title={_t('table.type')} value={type} />
        <FakeInputBlock title={_t('table.source')} value={source} />
        <FakeInputBlock title={_t('table.zone')} value={zone?.name || ''} />
        <FakeInputBlock title={_t('table.plate')} value={plate} />
        <FakeInputBlock
          title={_t('table.pricingCategory')}
          value={pricingCategory}
        />
        <FakeInputBlock
          title={_t('table.receptionDatetime')}
          value={<Date datetime={versionDatetime} withDate withTime />}
        />
        <FakeInputBlock
          title={_t('table.creationDatetime')}
          value={<Date datetime={creationDatetime} />}
        />
        <FakeInputBlock
          title={_t('table.validityStartDatetime')}
          value={<Date datetime={startDatetime} />}
        />
        <FakeInputBlock
          title={_t('table.validityEndDatetime')}
          value={<Date datetime={endDatetime} />}
        />
        <FakeInputBlock
          title={_t('table.tariffCode')}
          value={price.toString()}
          inputStyle={{ borderTop: 'solid red', borderWidth: 'thin' }}
        />
      </Flex>
    </Dialog>
  );
};

export default TicketDetail;
