import { Dialog, FlatButton } from 'material-ui';
import React from 'react';

import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import { SivVehicleDetails } from '@cvfm-front/tefps-types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  sivVehicle: SivVehicleDetails;
};

const SivVehicleDetailsModal = ({
  open,
  setOpen,
  sivVehicle,
}: Props): JSX.Element => {
  return (
    <Dialog
      open={open}
      title={_tg(
        'tefps.recourseV2.pages.Detail.FpsBlock.SivVehicleDetailsModal.title'
      )}
      actions={[<FlatButton label="Fermer" onClick={() => setOpen(false)} />]}
    >
      <div>
        <FakeInputBlock
          title={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.SivVehicleDetailsModal.massF4'
          )}
          value={sivVehicle.massF4?.toString()}
        />
        <FakeInputBlock
          title={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.SivVehicleDetailsModal.category'
          )}
          value={sivVehicle.category}
        />
        <FakeInputBlock
          title={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.SivVehicleDetailsModal.fuelType'
          )}
          value={sivVehicle.fuelType}
        />
        <FakeInputBlock
          title={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.SivVehicleDetailsModal.co2Emissions'
          )}
          value={sivVehicle.co2Emissions?.toString()}
        />
        <FakeInputBlock
          title={_tg(
            'tefps.recourseV2.pages.Detail.FpsBlock.SivVehicleDetailsModal.registrationDate'
          )}
          value={
            <Date datetime={sivVehicle.registrationDate} withTime={false} />
          }
        />
      </div>
    </Dialog>
  );
};

export default SivVehicleDetailsModal;
