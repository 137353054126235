import React from 'react';

import {
  OrderPrivateDTO,
  OrderTrace,
  ProductPrivateDTO,
  SubscriberDTO,
} from '@cvfm-front/tefps-types';

import './OrderDetailPage.css';

const { _tg } = window.loadTranslations(__filename);

type OrderDetailHistoryTodoProps = {
  orderTrace: OrderTrace;
  order: OrderPrivateDTO;
  orderProduct: ProductPrivateDTO;
  orderSubscribers: Array<SubscriberDTO>;
};

const OrderDetailHistoryTodo = ({
  orderTrace,
  order,
  orderProduct,
  orderSubscribers,
}: OrderDetailHistoryTodoProps): JSX.Element => {
  return (
    <div className="order-detail_block-history">
      <div className="order-detail_block-history-content">
        <span>
          {_tg('commons.todo')} {orderTrace.traceType}
        </span>
      </div>
    </div>
  );
};

export default OrderDetailHistoryTodo;
