import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Dialog from 'material-ui/Dialog';

import useUserInfo from 'commons/hooks/useUserInfo';
import useRecourse from 'commons/hooks/useRecourse';
import useCurrentProposal from 'commons/hooks/useCurrentProposal';
import useReason from 'commons/hooks/useReason';
import useRecourseReasons from 'commons/hooks/useRecourseReasons';
import useRecourseConfiguration from 'commons/hooks/useRecourseConfiguration';
import BoButton from 'facade/BoButton';
import { Flex } from '@cvfm-front/commons-ui';
import FakeInputBlock from 'commons/FakeInputBlock';
import Price from 'commons/Price';
import Tag from 'commons/Tag';
import { patchRecourse } from 'api/recourse';
import { ApiError } from 'api/ApiError';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import TefpsToggle from 'tefps/RecoursesV2/facades/TefpsToggle';
import { RECOURSE_DECISION } from 'tefps/RecoursesV2/utils/translationUtils';
import Services from 'commons/services';

const { _t, _tg } = window.loadTranslations(__filename);

const ConfirmProposal = (): JSX.Element => {
  /* Immutable service vars */
  const history = useHistory();
  const userInfo = useUserInfo();
  const recourse = useRecourse();
  const proposal = useCurrentProposal();
  const recourseReasons = useRecourseReasons();
  const recourseConfiguration = useRecourseConfiguration();

  /* Service vars */
  const [reason] = useReason();
  const setMessage = useSnackbar();

  /* Component state vars */
  const [emailEnabled, setEmailEnabled] = useState<boolean>(
    !recourse?.recourse.shouldSendLetters
  );
  const [letterEnabled, setLetterEnabled] = useState<boolean>(
    !!recourse?.recourse.shouldSendLetters
  );
  const [validate, setValidate] = useState<boolean | null>(null);
  const [
    showInvalidPriceErrorMessage,
    setShowInvalidPriceErrorMessage,
  ] = useState(false);

  /* Dynamic rendering vars */
  const canAccept = !!userInfo?.rights.includes('RAPO_ACCEPT');
  const canUpdate = !!userInfo?.rights.includes('RAPO_UPDATE');

  const shouldAddTags = !!(
    recourseConfiguration?.tagsMandatory && !recourse?.recourse.tags.length
  );

  const emailNotificationOption =
    recourse &&
    ((recourse.recourse.user.addressLocality &&
      recourse.recourse.representative === null) ||
      recourse.recourse.representative?.addressLocality);

  const letterNotificationOption =
    recourse &&
    ((recourse.recourse.user.addressLocality &&
      recourse.recourse.representative === null) ||
      recourse.recourse.representative?.addressLocality);

  /* Functions */
  const closeModal = (): void => {
    setValidate(null);
    setShowInvalidPriceErrorMessage(false);
  };

  const openValidate = (): void => {
    setValidate(true);
  };

  const openReject = (): void => {
    setValidate(false);
  };

  const handleConfirm = async (): Promise<void> => {
    if (!recourse) {
      return;
    }
    if (shouldAddTags) {
      return;
    }
    if (
      recourse.fps.paymentStatus === 'PAID' &&
      proposal?.proposal.newFpsPrice &&
      proposal.proposal.newFpsPrice > recourse.amountPaid
    ) {
      setShowInvalidPriceErrorMessage(true);
      return;
    }
    try {
      const updatedRecourse = await patchRecourse(recourse.recourse.rapoId, [
        {
          op: 'add',
          path: `/proposals/${recourse.recourse.proposals.length - 1}/decision`,
          value: {
            accepted: validate ?? false,
            emailEnabled,
            letterEnabled,
          },
        },
      ]);
      Services.recourse.applyRecourseChanges(updatedRecourse);
      history.goBack();
    } catch (e) {
      if ((e as ApiError).status === 409) {
        setMessage((e as Error).message);
      } else {
        const error = e as ApiError;
        setMessage(_t('error.validation', { error: error.message }));
      }
    } finally {
      closeModal();
    }
  };

  const handleModify = () => {
    Services.proposal.setCurrentProposal(null);
  };

  return (
    <>
      <BoButton
        primary
        className="margin-x--xs"
        label={_tg('action.accept')}
        onClick={openValidate}
        disabled={
          recourse?.recourse.status === 'IS_WAITING_COMPLEMENT' ||
          !canAccept ||
          shouldAddTags
        }
      />
      <BoButton
        secondary
        className="margin-x--xs"
        label={_tg('action.reject')}
        onClick={openReject}
        disabled={
          recourse?.recourse.status === 'IS_WAITING_COMPLEMENT' ||
          !canAccept ||
          shouldAddTags
        }
      />
      <BoButton
        className="margin-x--xs"
        backgroundColor="var(--bg-color--dark-blue)"
        labelColor="var(--white)"
        label={_tg('action.modify')}
        onClick={handleModify}
        disabled={!canUpdate}
      />
      {recourse?.recourse.user.illegibleAddress && (
        <div className="text-error">{_t('warning.illegibleAddress')}</div>
      )}
      <Dialog
        actions={[
          <BoButton
            onClick={handleConfirm}
            secondary={!validate}
            primary={!!validate}
            label={validate ? _tg('action.accept') : _tg('action.reject')}
          />,
          <BoButton
            label={_tg('action.cancel')}
            onClick={closeModal}
            className="margin-x--s"
          />,
        ]}
        title={validate ? _t('modal.title.validate') : _t('modal.title.reject')}
        open={validate !== null}
        onRequestClose={closeModal}
      >
        {proposal && (
          <div>
            {recourse && recourse.recourse.tags.length > 0 && (
              <FakeInputBlock
                title={_t('modal.proposal.labels')}
                inputStyle={{ backgroundColor: 'white' }}
                value={
                  <Flex flexWrap="wrap">
                    {recourse.recourse.tags.map(tag => (
                      <Tag tag={tag} />
                    ))}
                  </Flex>
                }
              />
            )}
            <FakeInputBlock
              title={_t('modal.proposal.newStatus')}
              value={RECOURSE_DECISION[proposal.proposal.status]}
            />
            <FakeInputBlock
              title={_t('modal.proposal.reason')}
              value={
                recourseReasons && reason ? recourseReasons[reason].label : ''
              }
            />
            {proposal.proposal.status === 'ACCEPTED' && (
              <FakeInputBlock
                title={_t('modal.proposal.newPrice')}
                value={<Price price={proposal.proposal.newFpsPrice} />}
              />
            )}
            {proposal.proposal.status === 'ACCEPTED' &&
              proposal.proposal.newFpsReducedPrice && (
                <FakeInputBlock
                  title={_t('modal.proposal.newReducedPrice')}
                  value={<Price price={proposal.proposal.newFpsReducedPrice} />}
                />
              )}
            {emailNotificationOption && (
              <TefpsToggle
                label={_t('modal.proposal.emailNotification')}
                toggled={emailEnabled}
                onToggle={() => setEmailEnabled(!emailEnabled)}
              />
            )}
            {letterNotificationOption && (
              <TefpsToggle
                label={_t('modal.proposal.letterNotification')}
                toggled={letterEnabled}
                onToggle={() => setLetterEnabled(!letterEnabled)}
              />
            )}
            {showInvalidPriceErrorMessage && (
              <div className="text-error margin-y--s">
                <p>{_t('warning.invalidPrice')}</p>
              </div>
            )}
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmProposal;
