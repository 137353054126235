import React, { useState } from 'react';
import { Dialog, MenuItem, SelectField, TextField } from 'material-ui';

import {
  ProductOption,
  ProductOptionReference,
  ProductPrivateDTO,
} from '@cvfm-front/tefps-types';
import './ProductDetailPage.css';
import { getActions } from 'commons/ModalUtils';

const { _t } = window.loadTranslations(__filename);

type ProductDetailAutomaticOrderProps = {
  otherProducts: Array<ProductPrivateDTO>;
  opened: boolean;
  availableChoices: { [key: string]: Array<ProductOption> };
  onSave: (newAutomaticOrder: ProductOptionReference) => void;
  onClose: () => void;
};

const ProductDetailAddAutomaticOrder = ({
  availableChoices,
  otherProducts,
  opened,
  onSave,
  onClose,
}: ProductDetailAutomaticOrderProps): JSX.Element => {
  const [productId, setProductId] = useState<string | null>(null);
  const [productOptionId, setProductOptionId] = useState<string | null>(null);

  function handleSelectProduct(
    _e: React.SyntheticEvent,
    _index: number,
    value: string
  ): void {
    setProductId(value);
  }

  function handleSelectProductOption(
    _e: React.SyntheticEvent,
    _index: number,
    value: string
  ): void {
    setProductOptionId(value);
  }

  function saveNewOptionReference() {
    if (productOptionId != null && productId !== null) {
      setProductId(null);
      setProductOptionId(null);
      onSave({ productId, productOptionId });
    }
  }

  return (
    <Dialog
      open={opened}
      actions={getActions(
        saveNewOptionReference,
        onClose,
        productId === null || productOptionId === null
      )}
    >
      <span className="product-detail_cell-25">
        <SelectField
          floatingLabelFixed
          floatingLabelText={_t('field.label.product')}
          hintText={_t('hintOptions.product')}
          fullWidth
          onChange={handleSelectProduct}
          value={productId}
        >
          {otherProducts
            .filter(
              otherProduct =>
                availableChoices[otherProduct.productId] &&
                availableChoices[otherProduct.productId].length
            )
            .map(otherProduct => (
              <MenuItem
                id={otherProduct.productId}
                key={otherProduct.productId}
                value={otherProduct.productId}
                primaryText={otherProduct.name}
                insetChildren
              />
            ))}
        </SelectField>
      </span>
      <span className="product-detail_cell-25">
        {productId &&
        availableChoices[productId] &&
        availableChoices[productId].length ? (
          <SelectField
            floatingLabelFixed
            floatingLabelText={_t('field.label.option')}
            hintText={_t('hintOptions.option')}
            fullWidth
            onChange={handleSelectProductOption}
            value={productOptionId}
          >
            {productId &&
              availableChoices[productId].map(otherOptions => (
                <MenuItem
                  id={otherOptions.id}
                  key={otherOptions.id}
                  value={otherOptions.id}
                  primaryText={otherOptions.name}
                  insetChildren
                />
              ))}
          </SelectField>
        ) : (
          <TextField disabled value={_t('text.no_option')} />
        )}
      </span>
    </Dialog>
  );
};

export default ProductDetailAddAutomaticOrder;
