import React from 'react';
import Visibility from 'material-ui/svg-icons/action/visibility';
import VisibilityOff from 'material-ui/svg-icons/action/visibility-off';

import { BKG_CYAN_SELECTED } from 'theme';
import { OrderEsSearchQueryDTO, TeamDTO } from '@cvfm-front/tefps-types';

import OrderFilterService from './OrderFilterService';

const { _t } = window.loadTranslations(__filename);

const ICON_STYLE = {
  color: BKG_CYAN_SELECTED,
  marginLeft: 10,
  width: 30,
  height: 30,
  cursor: 'pointer',
};

type OrderTableSidebarAgentTeamFiltersProps = {
  agentTeamFiltersEnabled: boolean;
  setAgentTeamFiltersEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderTableSidebarAgentTeamFilters = ({
  agentTeamFiltersEnabled,
  setAgentTeamFiltersEnabled,
}: OrderTableSidebarAgentTeamFiltersProps): JSX.Element => {
  const onToggleAgentTeamFilter = (isTeamEnabled: boolean) => {
    setAgentTeamFiltersEnabled(isTeamEnabled);

    OrderFilterService.setFilters((prevFilters: OrderEsSearchQueryDTO) => {
      return isTeamEnabled
        ? OrderFilterService.addAgentTeamFilters(prevFilters)
        : OrderFilterService.removeAgentTeamFilters(prevFilters);
    });
  };

  return (
    <>
      <div
        key="agentTeamFilters"
        style={{
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{_t('title')}</span>
        <span>
          {agentTeamFiltersEnabled ? (
            <Visibility
              style={ICON_STYLE}
              onClick={() => onToggleAgentTeamFilter(false)}
            />
          ) : (
            <VisibilityOff
              style={ICON_STYLE}
              onClick={() => onToggleAgentTeamFilter(true)}
            />
          )}
        </span>
      </div>
    </>
  );
};

export default OrderTableSidebarAgentTeamFilters;
