import React from 'react';

import BoButton from 'facade/BoButton';

const { _tg } = window.loadTranslations();

type ModalActionsProps = {
  onValidate: () => void;
  onClose: () => void;
};

const ModalActions = ({
  onValidate,
  onClose,
}: ModalActionsProps): Array<JSX.Element> => {
  return [
    <BoButton
      label={_tg('action.cancel')}
      className="margin-x--xs"
      onClick={onClose}
    />,
    <BoButton
      label={_tg('action.validate')}
      backgroundColor="var(--bg-color--light-blue)"
      labelColor="var(--white)"
      onClick={onValidate}
    />,
  ];
};

export default ModalActions;
