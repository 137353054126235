import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CircularProgress from 'material-ui/CircularProgress';

import { getExport } from 'api/recourse';
import Exporter from 'commons/Exporter';
import AdvertisingModal from 'commons/AdvertisingModal';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import RecourseFilters from 'tefps/RecoursesV2/pages/List/RecourseFilters';
import RecourseTable from 'tefps/RecoursesV2/pages/List/RecourseTable';
import useUserInfo from 'commons/hooks/useUserInfo';
import { Flex } from '@cvfm-front/commons-ui';
import useCityConfig from 'commons/hooks/useCityConfig';
import useRecourseFilters from 'commons/hooks/useRecourseFilters';
import useRecourseSort from 'commons/hooks/useRecourseSort';
import useRecoursesPager from 'commons/hooks/useRecoursesPager';
import useRecourseSearchResult from 'commons/hooks/useRecourseSearchResult';
import useRecoursesError from 'commons/hooks/useRecoursesError';
import services from 'commons/services';
import {
  filtersToRequest,
  getExportCols,
} from 'tefps/RecoursesV2/utils/recourseUtils';
import useWatcher from 'commons/hooks/useWatcher';

const { _tg } = window.loadTranslations(__filename);

const RecourseList = (): JSX.Element => {
  const location = useLocation();
  /* Custom hooks */
  const userInfo = useUserInfo();
  const cityConfig = useCityConfig();
  const pager = useRecoursesPager();
  const error = useRecoursesError();
  const recourseSearchResult = useRecourseSearchResult();
  const [filters] = useRecourseFilters();
  const [sort] = useRecourseSort();
  const isLoading = useWatcher(
    services.recourseListService.watchLoading,
    services.recourseListService.isLoading()
  );

  /* Rendering & component variables */
  const totalHits = recourseSearchResult ? recourseSearchResult.totalHits : 0;
  const canExport =
    userInfo && userInfo.rights.includes('BACKOFFICE_RAPO_EXPORT');

  /* Component hooks */
  useEffect(() => {
    void services.recourseListService.init(
      location && location.search ? location.search : null
    );
  }, []);

  if (error) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message={_tg('feedback.error.fetchRecourse')}
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  return (
    <Flex height100>
      <AdvertisingModal module="rapo" />
      <RecourseFilters />
      <ListWrapper>
        <ListBody loading={!recourseSearchResult}>
          <RecourseTable />
        </ListBody>
        <ListBottom style={{ position: 'relative' }}>
          {canExport && (
            <Exporter
              disabled={totalHits === 0}
              columns={getExportCols(
                !!cityConfig?.sendingRecourseLetterEnabled
              )}
              type="RAPO"
              filters={filtersToRequest(filters, sort, pager)}
              fileExport={getExport}
            />
          )}
          {isLoading && (
            <div style={{ position: 'absolute', left: '50%' }}>
              <CircularProgress />
            </div>
          )}
        </ListBottom>
      </ListWrapper>
    </Flex>
  );
};

export default RecourseList;
