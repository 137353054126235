import React from 'react';
import { Card } from 'material-ui';

import { RECOURSE_EMAIL_TEMPLATE_FIELDS } from 'tefps/RecoursesV2/utils/translationUtils';

import './EmailGuide.css';

const { _t, Trans } = window.loadTranslations(__filename);

const EmailGuide = (): JSX.Element => {
  return (
    <Card className="recourse-card">
      <div className="recourse-card__header">
        <div className="text-bold">{_t('title')}</div>
      </div>
      <div className="email-guide">
        <div className="margin--xs">
          <div className="email-guide__explanation">
            {_t('element.explanation')}
            <div style={{ fontStyle: 'italic', marginTop: 6 }}>
              {_t('element.withNoteAboutReplacement')}
            </div>
          </div>
          <div className="email-guide__format-list">
            <table>
              <tbody>
                {RECOURSE_EMAIL_TEMPLATE_FIELDS.map(template => (
                  <tr key={template.field}>
                    <td className="email-guide__format-list__field">{`{${template.field}}`}</td>
                    <td>{template.legend}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="email-guide__example-container">
          <div className="text-center">{_t('element.mailType.title')}</div>
          <div className="email-guide__example">
            <Trans
              i18nKey="element.mailType.mail"
              components={[<div style={{ marginBottom: 8 }} />, <br />]}
            />
          </div>
        </div>
        <div className="email-guide__example-container">
          <div className="text-center">{_t('element.result.title')}</div>
          <div className="email-guide__example">
            <Trans
              i18nKey="element.result.mail"
              components={[<div style={{ marginBottom: 8 }} />, <br />]}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EmailGuide;
