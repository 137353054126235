import React from 'react';
import { MenuItem, SelectField } from 'material-ui';

import { ItemIdName } from 'api/commonTypes';
import { ProductOption } from '@cvfm-front/tefps-types';

const { _t } = window.loadTranslations(__filename);

type ProductOptionChoiceProps = {
  productOption: ProductOption | undefined;
  onChange: (e: never, idx: never, changeOptionId: string) => void;
  productOptionItems: Array<ItemIdName>;
};

const OrderCreateProductOptionChoice = ({
  productOption,
  onChange,
  productOptionItems,
}: ProductOptionChoiceProps): JSX.Element => {
  return (
    <>
      <span className="order-detail_cell-25">{_t('choice.productOption')}</span>
      <span className="order-detail_cell-75">
        <SelectField
          fullWidth
          id="order-product-option-selector"
          value={productOption?.id}
          onChange={onChange}
        >
          {productOptionItems.map(item => (
            <MenuItem
              id={item.id}
              key={item.id}
              value={item.id}
              primaryText={item.name}
            />
          ))}
        </SelectField>
      </span>
    </>
  );
};

export default OrderCreateProductOptionChoice;
