import React, { useEffect, useState } from 'react';
import { Checkbox, TextField } from 'material-ui';
import { v4 as uuidv4 } from 'uuid';

import BoButton from 'facade/BoButton';
import {
  ProductOption,
  ProductPrivateDTO,
  ProductType,
} from '@cvfm-front/tefps-types';
import { convertFloatPriceToIntergerPrice } from '@cvfm-front/commons-utils';
import PeriodPicker from 'commons/PeriodPicker';
import './ProductDetailPage.css';

import ProductDetailSeparator from './commons/DetailSeparator';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailOptionsProps = {
  product: ProductPrivateDTO;
  canEdit: boolean;
  onChange: (field: string, newValue: unknown) => void;
};

const ProductDetailOptions = ({
  product,
  canEdit,
  onChange,
}: ProductDetailOptionsProps): JSX.Element => {
  const { orderShareEnabled } = product;
  const { multipleOrderAllowed } = product;
  const { computeExactHourlyEnd } = product;
  const { remindAwaitingPaymentAndPurge } = product;
  const { pricingConfiguration } = product;
  const { options } = product;

  const [deletedOptions, setDeletedOptions] = useState<Array<ProductOption>>(
    []
  );
  const [activeOptions, setActiveOptions] = useState<Array<ProductOption>>([]);

  function handleCheck(e: React.MouseEvent<HTMLDataElement>, checked: boolean) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      onChange(field, checked);
    }
  }

  function handleCheckPricingOption(
    e: React.MouseEvent<HTMLDataElement>,
    checked: boolean
  ) {
    const { field } = e.currentTarget.dataset;
    if (field) {
      const newPricingConfig = {
        ...pricingConfiguration,
        [field]: checked,
      };
      if (!newPricingConfig.useCityPricing) {
        newPricingConfig.shouldExtendWeekDays = false;
      }
      onChange('pricingConfiguration', newPricingConfig);
    }
  }

  function handleChangeText(
    e: React.FormEvent<HTMLDataElement>,
    newValue: string
  ) {
    const optionId = e.currentTarget.dataset.id;
    const optionField = e.currentTarget.dataset.field;
    if (optionId && optionField) {
      const option = options.find(opt => opt.id === optionId);
      if (option) {
        const newOption = { ...option, [optionField]: newValue };
        const newOptions = options.map(opt =>
          opt.id === optionId ? newOption : opt
        );
        onChange('options', newOptions);
      }
    }
  }

  function handleChangeDuration(optionId: string, newValue: string) {
    const option = options.find(opt => opt.id === optionId);
    if (option) {
      const newOption = { ...option, duration: newValue };
      const newOptions = options.map(opt =>
        opt.id === optionId ? newOption : opt
      );
      onChange('options', newOptions);
    }
  }

  function handleChangePrice(
    e: React.FormEvent<HTMLDataElement>,
    newValue: string
  ) {
    const optionId = e.currentTarget.dataset.id;
    const optionField = e.currentTarget.dataset.field;
    if (optionId && optionField) {
      const option = options.find(opt => opt.id === optionId);
      if (option) {
        const newOption = {
          ...option,
          [optionField]: convertFloatPriceToIntergerPrice(parseFloat(newValue)),
        };
        const newOptions = options.map(opt =>
          opt.id === optionId ? newOption : opt
        );
        onChange('options', newOptions);
      }
    }
  }

  function handleClickAdd(): void {
    const newOption = { ...activeOptions[0] };
    newOption.id = uuidv4();
    const newOptions = [...options, newOption];
    onChange('options', newOptions);
  }

  function handleClickRemove(e: React.MouseEvent<HTMLDataElement>): void {
    const optionId = e.currentTarget.dataset.id;
    const deleteOptionIndex = options.findIndex(opt => opt.id === optionId);
    if (deleteOptionIndex !== -1) {
      const deleteOption = { ...options[deleteOptionIndex] };
      deleteOption.deleted = true;
      deleteOption.deletionDate = new Date().toISOString();

      const newOptions = [...options];
      newOptions[deleteOptionIndex] = deleteOption;
      onChange('options', newOptions);
    }
  }

  useEffect(() => {
    const deletedOpts = options.filter(opt => opt.deleted);
    const activeOpts = options.filter(opt => !opt.deleted);

    setDeletedOptions(deletedOpts);
    setActiveOptions(activeOpts);
  }, [options]);

  function renderOption(option: ProductOption, active: boolean): JSX.Element {
    const disable = !(active && canEdit);
    const deleteDisable = disable || activeOptions.length <= 1;
    return (
      <div
        className="product-detail_section-content-row"
        key={`opt-${option.id}`}
      >
        <span className="product-detail_cell-25">
          <TextField
            floatingLabelText={_t('option.field.name')}
            data-id={option.id}
            data-field="name"
            value={option.name}
            disabled={disable}
            fullWidth
            onChange={handleChangeText}
          />
        </span>
        <span className="product-detail_cell-25">
          <TextField
            floatingLabelText={_t('option.field.price')}
            data-id={option.id}
            data-field="price"
            value={option.price / 100.0}
            disabled={disable}
            fullWidth
            type="number"
            min={0}
            step={0.01}
            onChange={handleChangePrice}
          />
        </span>
        <span className="product-detail_cell-25">
          <PeriodPicker
            key={_t('option.field.duration')}
            data-id={option.id}
            data-field="duration"
            value={option.duration}
            disabled={disable}
            onChange={(e: never, v: string) => {
              handleChangeDuration(option.id, v);
            }}
          />
        </span>
        <span className="product-detail_cell-12" />
        {active && (
          <span className="product-detail_cell-12">
            <BoButton
              data-id={option.id}
              label={_tg('action.delete')}
              secondary
              fullWidth
              disabled={deleteDisable}
              onClick={handleClickRemove}
            />
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="product-detail_section">
      <ProductDetailSeparator title={_t('section.title')} />
      <div className="product-detail_section-content">
        <span className="product-detail_cell-50">
          <Checkbox
            className="product-detail_checkbox"
            label={_t('option.field.useTariffEngine')}
            checked={pricingConfiguration.useTariffEngine}
            data-field="useTariffEngine"
            disabled={!canEdit}
            onCheck={handleCheckPricingOption}
          />
        </span>
        {!pricingConfiguration.useTariffEngine && (
          <>
            {deletedOptions.length > 0 && (
              <div className="product-detail_section-content-row">
                <span className="product-detail_cell-25">
                  {_t('list.deleted')}:
                </span>
              </div>
            )}
            {deletedOptions.map(opt => renderOption(opt, false))}
            {activeOptions.length > 0 && (
              <div className="product-detail_section-content-row">
                <span className="product-detail_cell-25">
                  {_t('list.active')}:
                </span>
              </div>
            )}
            {activeOptions.map(opt => renderOption(opt, true))}
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-12">
                <BoButton
                  label={_tg('action.add')}
                  onClick={handleClickAdd}
                  fullWidth
                  primary
                />
              </span>
            </div>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-50">
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('option.field.orderShareEnabled')}
                  checked={orderShareEnabled}
                  data-field="orderShareEnabled"
                  disabled={!canEdit}
                  onCheck={handleCheck}
                />
              </span>
              <span className="product-detail_cell-50">
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('option.field.multipleOrderAllowed')}
                  checked={multipleOrderAllowed}
                  data-field="multipleOrderAllowed"
                  disabled={!canEdit}
                  onCheck={handleCheck}
                />
              </span>
            </div>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-50">
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('option.field.computeExactHourlyEnd')}
                  checked={computeExactHourlyEnd}
                  data-field="computeExactHourlyEnd"
                  disabled={!canEdit}
                  onCheck={handleCheck}
                />
              </span>
              <span className="product-detail_cell-50">
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('option.field.useCityPricing')}
                  checked={pricingConfiguration.useCityPricing}
                  data-field="useCityPricing"
                  disabled={!canEdit}
                  onCheck={handleCheckPricingOption}
                />
              </span>
            </div>
            <div className="product-detail_section-content-row">
              <span className="product-detail_cell-50">
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('option.field.shouldExtendWeekDays')}
                  checked={pricingConfiguration.shouldExtendWeekDays}
                  data-field="shouldExtendWeekDays"
                  disabled={!canEdit || !pricingConfiguration.useCityPricing}
                  onCheck={handleCheckPricingOption}
                />
              </span>
              <span className="product-detail_cell-50">
                <Checkbox
                  className="product-detail_checkbox"
                  label={_t('option.field.usePricingCalculator')}
                  checked={pricingConfiguration.usePricingCalculator}
                  data-field="usePricingCalculator"
                  onCheck={handleCheckPricingOption}
                />
              </span>
            </div>
          </>
        )}
        <div className="product-detail_section-content-row">
          {product.productType === ProductType.BUNDLE && (
            <span className="product-detail_cell-50">
              <Checkbox
                className="product-detail_checkbox"
                label={_t('option.field.remindAwaitingPaymentAndPurge')}
                checked={remindAwaitingPaymentAndPurge}
                data-field="remindAwaitingPaymentAndPurge"
                disabled={!canEdit}
                onCheck={handleCheck}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailOptions;
