import React from 'react';
import Toggle from 'material-ui/Toggle';
import { ToggleProps } from 'material-ui';

import { TXT_BLACK } from 'theme';

const TefpsToggle = ({ ...otherProps }: ToggleProps): JSX.Element => {
  return (
    <Toggle
      labelStyle={{
        color: TXT_BLACK,
        fontSize: 16,
        fontWeight: 'bold',
      }}
      style={{ marginTop: 20 }}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...otherProps}
    />
  );
};

export default TefpsToggle;
