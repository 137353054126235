import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import SuccessIcon from 'material-ui/svg-icons/navigation/check';

import { BKG_GREEN } from 'theme';
import CsvImportDropZone from 'commons/CsvImportDropZone';
import { importOrders } from 'api/cvfm-core-subscription/order';

import OrderFilterService from './OrderFilterService';

const { _t, _tg } = window.loadTranslations(__filename);

type State = {
  openModal: boolean;
  loading: boolean;
  success: boolean;
  errors: Array<string>;
};

type Props = {
  style: CSSProperties | undefined;
};

class OrderImport extends React.Component<Props, State> {
  state: State = {
    openModal: false,
    loading: false,
    success: false,
    errors: [],
  };

  onDrop = (files: Array<File>): void => {
    if (files.length) {
      void this.submit(files[0]);
    } else {
      this.setState({
        loading: false,
        openModal: true,
        errors: [_t('feedback.error.fileFormat')],
      });
    }
  };

  handleClose = (): void => this.setState({ errors: [], openModal: false });

  submit = async (file: File): Promise<void> => {
    this.setState({ loading: true, openModal: true, success: false });
    const result = await importOrders(file);
    if (result.success) {
      this.setState({ success: true });
      setTimeout(() => {
        void OrderFilterService.loadOrders();
      }, 3000);
    }
    this.setState({ errors: result.errors, loading: false });
  };

  static renderError = (errors: Array<string>): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 10,
        }}
      >
        <ul style={{ marginTop: 20 }}>
          {errors.map(error => (
            <li key={error} style={{ marginBottom: 2 }}>
              {_tg('characters.dot')} {error}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  static renderLoading = (): JSX.Element => {
    return (
      <div
        style={{
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  static renderSuccess = (): JSX.Element => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SuccessIcon color={BKG_GREEN} style={{ width: 100, height: 100 }} />
        <div>{_t('feedback.success.subscriptionImport')}</div>
        <div>{_t('feedback.warning.pageRefresh')}</div>
      </div>
    );
  };

  static renderContent = (
    loading: boolean,
    success: boolean,
    errors: Array<string>
  ): JSX.Element => {
    if (loading) {
      return OrderImport.renderLoading();
    }
    if (success) {
      return OrderImport.renderSuccess();
    }
    return OrderImport.renderError(errors);
  };

  render(): JSX.Element {
    const { openModal, loading, errors, success } = this.state;
    const { style } = this.props;
    return (
      <div>
        <CsvImportDropZone
          buttonStyle={style}
          buttonLabel={_tg('action.importCSV')}
          onDrop={this.onDrop}
        />
        <Dialog
          title={_t('element.dialog.title')}
          onRequestClose={this.handleClose}
          modal
          open={openModal}
          autoScrollBodyContent
          actions={[
            <FlatButton
              label={_tg('action.close')}
              onClick={this.handleClose}
              disabled={loading}
            />,
          ]}
        >
          {OrderImport.renderContent(loading, success, errors)}
        </Dialog>
      </div>
    );
  }
}

export default OrderImport;
