import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import RecourseButton from 'facade/RecourseButton';
import { isDisabledStatus } from 'tefps/RecoursesV2/utils/recourseUtils';
import ModalActions from 'tefps/RecoursesV2/commons/ModalActions';
import useRecourse from 'commons/hooks/useRecourse';
import useComplementRequest from 'commons/hooks/useComplementRequest';
import useOtherFile from 'tefps/RecoursesV2/hooks/useOtherFile';

import ComplementRequestActionModal from './ComplementRequestActionModal';

const { _tg, _t } = window.loadTranslations(__filename);

const ComplementRequestAction = (): JSX.Element => {
  const history = useHistory();

  /* Service hooks */
  const recourseDetails = useRecourse();
  const [complementRequest, setComplementRequest] = useComplementRequest();
  const [otherFile, setOtherFile] = useOtherFile();

  /* Component state */
  const [open, setOpen] = useState<boolean>(false);

  const handleModal = () => {
    /* Restart file selection when opening the modal */
    if (!open) {
      setComplementRequest({ files: [], complementInfo: '' });
      setOtherFile({ name: '', checked: false });
    }
    setOpen(!open);
  };

  /* Add custom document request before sending complement request */
  const onValidate = () => {
    /* Block validation if "other file" is checked but no description for the file */
    if (otherFile.checked && !otherFile.name.trim()) {
      return;
    }
    if (otherFile.checked) {
      complementRequest.files.push(otherFile.name.trim() ?? _tg('field.other'));
    }
    setComplementRequest(complementRequest);
    history.push({
      pathname: `/recourses/detail/${recourseDetails?.recourse.rapoId}/complement-request`,
    });
  };

  /* Complement request is only available if the recurse is still open,
    no request is in progress and the user has a valid address */
  const disableComplementRequest =
    !recourseDetails ||
    isDisabledStatus(recourseDetails.recourse.status) ||
    !!recourseDetails.recourse.complementRequest ||
    recourseDetails.recourse.user.illegibleAddress;

  return (
    <>
      <RecourseButton
        label={_t('button.title.requestComplement')}
        backgroundColor="var(--bg-color--pink)"
        labelColor="var(--white)"
        disabled={disableComplementRequest}
        onClick={handleModal}
      />
      <ComplementRequestActionModal
        actions={ModalActions({ onValidate, onClose: handleModal })}
        open={open}
        onRequestClose={handleModal}
      />
    </>
  );
};

export default ComplementRequestAction;
