import * as React from 'react';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import RestoreIcon from 'material-ui/svg-icons/action/restore';
import { v4 } from 'uuid';
import { Checkbox, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import {
  ApplicationProcedureDTO,
  CustomFieldProperties,
} from '@cvfm-front/tefps-types';

import ProductDetailSecondarySeparator from '../ProductDetailSecondarySeparator';

const { _t, _tg } = window.loadTranslations(__filename);

// champ du formulaire sur le front office (hard code)
const translateMandatoryInformation = () => [
  _t('mandatoryInformation.plate'),
  _t('mandatoryInformation.firstname'),
  _t('mandatoryInformation.lastname'),
  _tg('field.address.address'),
  _tg('field.human.phoneNumber'),
  _t('mandatoryInformation.email'),
];

type Props = {
  applicationProcedure: ApplicationProcedureDTO;
  updateApplicationProcedure: (
    applicationProcedure: ApplicationProcedureDTO
  ) => void;
  canEdit: boolean;
};

function InformationToAsk({
  applicationProcedure,
  updateApplicationProcedure,
  canEdit,
}: Props): JSX.Element {
  function addCustomField() {
    const emptyCustomField = {
      key: v4(),
      label: '',
      deleted: undefined,
    } as CustomFieldProperties;
    const customFields = applicationProcedure.customFields
      ? [...applicationProcedure.customFields, emptyCustomField]
      : [emptyCustomField];
    updateApplicationProcedure({ ...applicationProcedure, customFields });
  }

  function onChangeCustomField(
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) {
    const index = e.currentTarget.dataset.index as string;
    const newApplicationProcedure = { ...applicationProcedure };
    newApplicationProcedure.customFields = [
      ...(applicationProcedure.customFields || []),
    ];
    const newCustomField = {
      ...newApplicationProcedure.customFields[index],
    } as CustomFieldProperties;
    newCustomField.label = newValue;
    newApplicationProcedure.customFields[index] = newCustomField;
    updateApplicationProcedure(newApplicationProcedure);
  }

  function deleteCustomField(
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    deleted: boolean
  ) {
    const { index } = event.currentTarget.dataset;
    if (index) {
      const newCustomFields = [...(applicationProcedure.customFields || [])];
      const newCustomField = {
        ...newCustomFields[index],
      } as CustomFieldProperties;
      newCustomField.deleted = deleted ? new Date().toISOString() : undefined;
      newCustomFields[index] = newCustomField;
      updateApplicationProcedure({
        ...applicationProcedure,
        customFields: newCustomFields,
      });
    }
  }

  function handleChangeRequestForOther(
    event: React.MouseEvent<HTMLDataElement>
  ) {
    const key = event.currentTarget.dataset.field as string;
    const checked = !applicationProcedure.allowRequestForOther;

    updateApplicationProcedure({
      ...applicationProcedure,
      [key]: checked,
    });
  }

  return (
    <>
      <ProductDetailSecondarySeparator title={_t('title')} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-50">
            <Checkbox
              className="product-detail_checkbox"
              label={_t('requestForOther')}
              checked={applicationProcedure.allowRequestForOther}
              data-field="allowRequestForOther"
              onCheck={handleChangeRequestForOther}
            />
          </span>
          <span className="product-detail_cell-100">
            <ul style={{ columnCount: 2, marginTop: 30, lineHeight: 1.5 }}>
              {translateMandatoryInformation().map(info => (
                <li key={info}>-- {info}</li>
              ))}
            </ul>
            <ul style={{ columnCount: 2, marginTop: 30, lineHeight: 1.5 }}>
              {applicationProcedure.customFields &&
                applicationProcedure.customFields.map((customField, index) => (
                  <li key={index}>
                    --{' '}
                    <TextField
                      key={customField.key}
                      value={customField.label}
                      onChange={onChangeCustomField}
                      data-index={index}
                      disabled={!!customField.deleted}
                    />
                    {customField.deleted ? (
                      <span
                        title={_t('restore')}
                        onClick={e => deleteCustomField(e, false)}
                        data-index={index}
                      >
                        <RestoreIcon />
                      </span>
                    ) : (
                      <span
                        title={_t('delete')}
                        onClick={e => deleteCustomField(e, true)}
                        data-index={index}
                        style={{ cursor: 'pointer' }}
                      >
                        <DeleteIcon />
                      </span>
                    )}
                  </li>
                ))}
            </ul>
          </span>
          <span className="product-detail_cell-12">
            <BoButton
              label={_tg('action.add')}
              onClick={addCustomField}
              fullWidth
              disabled={!canEdit}
              primary
            />
          </span>
        </div>
      </div>
    </>
  );
}

export default InformationToAsk;
