import React from 'react';

import DataBoxComment from 'commons/DataBox/DataBoxComment';

type Props = {
  comment: string | null | undefined;
};

const Comments = ({ comment }: Props): JSX.Element => {
  if (comment) {
    return (
      <DataBoxComment style={{ flex: 10, marginBottom: 60 }}>
        {comment}
      </DataBoxComment>
    );
  }

  // Placeholder to keep a proper layout
  return <div style={{ flex: 10, marginBottom: 60 }} />;
};

export default Comments;
