import * as React from 'react';
import AddNew from 'material-ui/svg-icons/content/add-box';
import { IconButton, TextField } from 'material-ui';

import { ApplicationProcedureDTO } from '@cvfm-front/tefps-types';
import { STYLE_FIELD } from 'tefps/Beneficiaries/commons.styles';

import ProductDetailSecondarySeparator from '../ProductDetailSecondarySeparator';

const { _t } = window.loadTranslations(__filename);

type Props = {
  applicationProcedure: ApplicationProcedureDTO;
  updateApplicationProdecure: (
    applicationProcedure: ApplicationProcedureDTO
  ) => void;
  canEdit: boolean;
};

function InformationToGive({
  applicationProcedure,
  updateApplicationProdecure,
  canEdit,
}: Props): JSX.Element {
  function handleChangeDescription(newDescription: string) {
    const newApplicationProcedure = { ...applicationProcedure };
    newApplicationProcedure.description = newDescription;
    updateApplicationProdecure(newApplicationProcedure);
  }

  function addLink() {
    const newApplicationProcedure = { ...applicationProcedure };
    const links = applicationProcedure.documentationLinks
      ? [...applicationProcedure.documentationLinks, '']
      : [''];
    newApplicationProcedure.documentationLinks = links;
    updateApplicationProdecure(newApplicationProcedure);
  }

  function onChangeLink(
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) {
    const index = e.currentTarget.dataset.idx as string;
    const { documentationLinks } = applicationProcedure;
    const links = documentationLinks ? [...documentationLinks] : [];
    if (newValue) {
      links[index] = newValue;
    } else {
      links.splice(parseInt(index, 10), 1);
    }
    updateApplicationProdecure({
      ...applicationProcedure,
      documentationLinks: links,
    });
  }

  function onChangeZoneURL(
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) {
    updateApplicationProdecure({
      ...applicationProcedure,
      zoneUrlLink: newValue,
    });
  }

  return (
    <>
      <ProductDetailSecondarySeparator title={_t('title')} />
      <div className="product-detail_section-content">
        <div className="product-detail_section-content-row">
          <span className="product-detail_cell-100">
            <TextField
              name="name"
              fullWidth
              floatingLabelText={_t('infoToGive.description')}
              multiLine
              rows={4}
              disabled={!canEdit}
              value={applicationProcedure.description || ''}
              onChange={(_e, v) => handleChangeDescription(v)}
            />
          </span>
        </div>
        <div className="product-detail_section-content-row">
          <TextField
            floatingLabelText={_t('infoToGive.zoneUrlText')}
            style={{ ...STYLE_FIELD, width: '100%', marginLeft: 0 }}
            value={applicationProcedure.zoneUrlLink || ''}
            disabled={!canEdit}
            onChange={onChangeZoneURL}
          />
        </div>
        <div className="product-detail_section-content-row">
          {_t('infoToGive.documentation.title')}
          {canEdit && (
            <IconButton
              tooltip={_t('infoToGive.documentation.addLink.tooltip')}
              style={{ padding: 0, marginLeft: 12, height: 24, width: 24 }}
              iconStyle={{ height: 24, width: 24 }}
              onClick={addLink}
            >
              <AddNew />
            </IconButton>
          )}
        </div>
        {!canEdit &&
          (!applicationProcedure.documentationLinks ||
            applicationProcedure.documentationLinks.length === 0) && (
            <div style={{ marginTop: 15 }}>
              {_t('infoToGive.documentation.noDocumentationLink')}
            </div>
          )}
        {applicationProcedure.documentationLinks &&
          applicationProcedure.documentationLinks.map((link, i) => (
            <TextField
              data-idx={i}
              key={i}
              floatingLabelText={_t(
                'infoToGive.documentation.floatingLabelText'
              )}
              style={{ ...STYLE_FIELD, width: '100%', marginLeft: 0 }}
              value={link}
              disabled={!canEdit}
              onChange={onChangeLink}
            />
          ))}
      </div>
    </>
  );
}

export default InformationToGive;
