import React from 'react';
import { Checkbox } from 'material-ui';

import './CheckboxList.css';

type CheckboxListProps = {
  title: string;
  itemList: Array<string>;
  onCheck?: (_e: React.MouseEvent<HTMLInputElement>, checked: boolean) => void;
};

const CheckboxList = ({
  title,
  itemList,
  onCheck,
}: CheckboxListProps): JSX.Element => {
  return (
    <div className="checkbox-list">
      <span className="checkbox-list__title">{title}</span>
      <div className="checkbox-list__items">
        {itemList.map((item, index) => (
          <div key={index}>
            <Checkbox label={item} onCheck={onCheck} value={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxList;
