import React, { CSSProperties } from 'react';

import {
  RecourseFileDTO,
  RecourseFileType,
  RecourseReason,
} from '../../../../api/recourse/types';
import { TEXT_COLOR_LIGHT } from '../../../../theme';

import FileBlock from './FileBlock';

const STYLE_BAR: CSSProperties = {
  width: 1,
  backgroundColor: TEXT_COLOR_LIGHT,
  margin: '0 20px',
};

const RECOURSE_TYPE_ORDER: Array<RecourseFileType> = [
  'APA',
  'IMMAT',
  'TRANSFER',
  'CUSTOM',
  'AUTRE',
  'BY_AGENT',
];

const sortAndAggregate = (
  files: Array<RecourseFileDTO>
): Array<Array<RecourseFileDTO>> => {
  // Sort by type using RECOURSE_TYPE_ORDER, then aggregate 2 values at a time
  return files
    .sort(
      (a, b) =>
        RECOURSE_TYPE_ORDER.indexOf(a.type) -
        RECOURSE_TYPE_ORDER.indexOf(b.type)
    )
    .reduce(
      (newArray: Array<Array<RecourseFileDTO>>, value: RecourseFileDTO) => {
        if (
          newArray.length === 0 ||
          newArray[newArray.length - 1].length >= 2
        ) {
          newArray.push([value]);
        } else {
          newArray[newArray.length - 1].push(value);
        }
        return newArray;
      },
      []
    );
};

type Props = {
  files: Array<RecourseFileDTO>;
  reason: RecourseReason;
  created: string;
  rapoId: string;
};

const FilesContainer = ({
  created,
  files,
  reason,
  rapoId,
}: Props): JSX.Element => {
  const orderedFiles = sortAndAggregate(files);
  return (
    <div>
      {orderedFiles.map((twoFiles, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px 0px',
          }}
        >
          <div style={{ width: '45%' }}>
            <FileBlock
              created={created}
              file={twoFiles[0]}
              reason={reason}
              rapoId={rapoId}
            />
          </div>
          {twoFiles.length > 1 && <div style={STYLE_BAR} />}
          <div style={{ width: '45%' }}>
            <FileBlock
              created={created}
              file={twoFiles[1]}
              reason={reason}
              rapoId={rapoId}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilesContainer;
