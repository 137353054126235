import moment from 'moment';

import {
  RecourseCommentDTO,
  RecourseDTO,
  RecourseFileDTO,
  RecourseReason,
} from 'api/recourse/types';
import { UploadFile } from 'api/commonTypes';

export const MAX_FILE_SIZE = 20000000; // 20MB
export const MAX_TOTAL_FILE_SIZE = 20000000; // 20MB
export const ACCEPTED_FORMATS = 'image/jpeg, image/png, application/pdf';

const { _t } = window.loadTranslations(__filename);

const FilesFromType = {
  GLOBAL: [
    {
      title: _t('fileType.global.fpsPaymentNotice.title'),
      label: _t('fileType.global.fpsPaymentNotice.label'),
      mandatory: false,
    },
    {
      title: _t('fileType.global.registrationDocument.title'),
      label: _t('fileType.global.registrationDocument.label'),
      mandatory: true,
    },
  ],
  PAYE: {
    title: _t('fileType.paye.title'),
    label: _t('fileType.paye.label'),
    mandatory: false,
  },
  CESSION: {
    title: _t('fileType.cession.title'),
    label: _t('fileType.cession.label'),
    mandatory: true,
  },
  DISPENSE: {
    title: _t('fileType.dispense.title'),
    label: _t('fileType.dispense.label'),
    mandatory: false,
  },
  DESTRUCTION: {
    title: _t('fileType.destruction.title'),
    label: _t('fileType.destruction.label'),
    mandatory: false,
  },
  VOL: {
    title: _t('fileType.vol.title'),
    label: _t('fileType.vol.label'),
    mandatory: false,
  },
  USURPATION: {
    title: _t('fileType.usurpation.title'),
    label: _t('fileType.usurpation.label'),
    mandatory: false,
  },
  AVANCE: {
    title: _t('fileType.avance.title'),
    label: _t('fileType.avance.label'),
    mandatory: false,
  },
  OTHER: [
    {
      title: _t('fileType.other.transferReceipt.title'),
      label: _t('fileType.other.transferReceipt.label'),
      mandatory: false,
    },
    {
      title: _t('fileType.other.mif.title'),
      label: _t('fileType.other.mif.label'),
      mandatory: false,
    },
    {
      title: _t('fileType.other.thirdPartyMif.title'),
      label: _t('fileType.other.thirdPartyMif.label'),
      mandatory: false,
    },
    {
      title: _t('fileType.other.parkingCardDisabledPeople.title'),
      label: _t('fileType.other.parkingCardDisabledPeople.label'),
      mandatory: false,
    },
    {
      title: _t('fileType.other.professionalCertificate.title'),
      label: _t('fileType.other.professionalCertificate.label'),
      mandatory: false,
    },
  ],
};

export type FileCheckbox = {
  name: string;
  checked: boolean;
};

export type RecourseFile = {
  title: string;
  label: string;
  mandatory: boolean;
};

export type RecourseRequiredFiles = {
  main: Array<string>;
  others: Array<string>;
};

export const getRecourseRequiredFiles = (
  reason: RecourseReason
): RecourseRequiredFiles => {
  const main: Array<string> = [];
  const others: Array<string> = [];
  Object.keys(FilesFromType).forEach(name => {
    if (name === reason) {
      main.push((FilesFromType[name] as RecourseFile).title);
    } else if (name === 'GLOBAL') {
      FilesFromType.GLOBAL.forEach(f => main.push(f.title));
    } else if (name === 'OTHER') {
      FilesFromType.OTHER.forEach(f => others.push(f.title));
    } else {
      others.push((FilesFromType[name] as RecourseFile).title);
    }
  });
  return { main, others };
};

export const translateFile = (file: string): string => {
  let result = file;
  Object.keys(FilesFromType).forEach(key => {
    if (FilesFromType[key].title && FilesFromType[key].title === file) {
      result = FilesFromType[key].label;
    } else if (Array.isArray(FilesFromType[key])) {
      FilesFromType[key].forEach((f: { title: string; label: any }) => {
        if (f.title === file) {
          result = f.label;
        }
      });
    }
  });
  return result;
};

export const getComplementRequestFiles = (
  recourse: RecourseDTO
): RecourseFileDTO[] => {
  return recourse.files.filter(file => {
    return (
      recourse.complementRequest &&
      moment(file.created).diff(
        moment(recourse.complementRequest.completionDate),
        'minutes'
      ) === 0
    );
  });
};

export const getRecourseFiles = (recourse: RecourseDTO): RecourseFileDTO[] => {
  return recourse.files.filter(file => {
    return (
      !recourse.complementRequest ||
      moment(file.created).diff(
        moment(recourse.complementRequest.completionDate),
        'minutes'
      ) !== 0
    );
  });
};

/* Files and comments do not have the same creation date */
const SECONDS_THRESHOLD = 30;
export const getAgentCommentFiles = (
  recourse: RecourseDTO,
  comment: RecourseCommentDTO
): RecourseFileDTO[] => {
  return recourse.files.filter(file => {
    const secondsDifference = moment(file.created).diff(
      moment(comment.dateCreated),
      'seconds'
    );
    return (
      file.type === 'BY_AGENT' &&
      Math.abs(secondsDifference) <= SECONDS_THRESHOLD
    );
  });
};

export const getCreationRecourseFiles = (
  recourse: RecourseDTO
): RecourseFileDTO[] => {
  return recourse.files.filter(file => {
    return moment(recourse.created).diff(moment(file.created), 'minutes') === 0;
  });
};

export const validateFileSize = (
  file: UploadFile | null | undefined
): string | null | undefined => {
  if (file && file.size > MAX_FILE_SIZE) {
    return _t('error.fileSize', { maxSize: MAX_FILE_SIZE / 1000000 });
  }
  return undefined;
};

export const validateTotalFileSize = (
  file: UploadFile | null | undefined,
  totalSize: number
): string | null | undefined => {
  if (file && totalSize > MAX_TOTAL_FILE_SIZE) {
    return _t('error.totalFileSize', {
      maxSize: MAX_TOTAL_FILE_SIZE / 1000000,
    });
  }
  return undefined;
};
