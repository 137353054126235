import React, { useState } from 'react';
import { Dialog, TextField } from 'material-ui';

import BoButton from 'facade/BoButton';
import { OrderPrivateDTO } from '@cvfm-front/tefps-types';
import { addCustomOrderLimitCounter } from 'api/cvfm-core-subscription/order';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailCustomLimitCounterModal = {
  open: boolean;
  order: OrderPrivateDTO;
  orderCountLimit: number;
  onCancel: () => void;
  onConfirm: () => void;
};

const OrderDetailCustomLimitCounterModal = ({
  open,
  order,
  orderCountLimit,
  onCancel,
  onConfirm,
}: OrderDetailCustomLimitCounterModal): JSX.Element => {
  const [counter, setCounter] = useState<number>(orderCountLimit);

  function onCancelAction() {
    onCancel();
  }

  function handleChangeCounter(e: never, value: string) {
    setCounter(parseInt(value, 10));
  }

  const onSubmit = async () => {
    if (!order.activeRightUsed) {
      return;
    }

    await addCustomOrderLimitCounter(order.activeRightUsed?.rightId, {
      productId: order.productId,
      counter,
    });
    onConfirm();
  };

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-decision-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-decision-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onSubmit}
    />,
  ];
  return (
    <Dialog
      title={_t('title')}
      open={open}
      actions={actions}
      titleClassName="order-detail-modal_title"
    >
      <div className="order-detail_row">
        <TextField
          fullWidth
          type="number"
          name="order-custom-limit-counter"
          floatingLabelText={_tg('field.limit')}
          onChange={handleChangeCounter}
          value={counter}
        />
      </div>
    </Dialog>
  );
};

export default OrderDetailCustomLimitCounterModal;
