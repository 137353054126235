import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import BoButton from 'facade/BoButton';
import {
  MailTypeOrder,
  ProductPrivateDTO,
  ZoneDTO,
} from '@cvfm-front/tefps-types';
import { CityOrganizationDTO } from 'api/organizations/types';
import { recomputeReminders } from 'api/cvfm-core-subscription/product';
import { NotificationService } from '@cvfm-front/commons-services';
import { fetchMailTemplate, updateMailTemplate } from 'api/mail';

import ProductDetailOptions from './ProductDetailOptions';
import ProductDetailDescription from './ProductDetailDescription';
import ProductDetailConditions from './ProductDetailConditions';
import ProductDetailRestrictions from './ProductDetailRestrictions';
import './ProductDetailPage.css';
import ProductDetailAutomaticOrder from './ProductDetailAutomaticOrder';
import ProductDetailTags from './ProductDetailTags';
import ProductDetailZones from './ProductDetailZones';
import ProductApplicationProcedure from './ProductApplicationProcedure';
import ProductDetailReminders from './ProductDetailReminders';
import ProductMailConfig from './ProductMailConfig/ProductMailConfig';
import ProductCreateModal from './ProductCreateModal';
import ProductDetailAutomaticAcceptOrder from './ProductDetailAutomaticAcceptOrder';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailProps = {
  product: ProductPrivateDTO;
  zones: Array<ZoneDTO>;
  bundleProducts: Array<ProductPrivateDTO>;
  eligibilityProducts: Array<ProductPrivateDTO>;
  organizations: Array<CityOrganizationDTO>;
  canEdit: boolean;
  organizationFilterEnabled: boolean;
  onReturnAction: () => void;
  onConfirmAction: (
    updatedProduct: ProductPrivateDTO,
    onSuccess: () => void
  ) => void;
};

const ProductDetail = ({
  product: productImmutable,
  zones,
  bundleProducts,
  eligibilityProducts,
  organizations,
  canEdit,
  organizationFilterEnabled,
  onReturnAction,
  onConfirmAction,
}: ProductDetailProps): JSX.Element => {
  const [product, setProduct] = useState<ProductPrivateDTO>({
    ...productImmutable,
  });
  const [isDuplicate, setIsDuplication] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [otherProducts, setOtherProducts] = useState<Array<ProductPrivateDTO>>(
    []
  );
  const [
    retroactiveReminderModification,
    setRetroactiveReminderModification,
  ] = useState<boolean>(false);
  const history = useHistory();
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  useMemo(() => {
    const othBundles = [...bundleProducts].filter(
      p => p.productId !== product.productId
    );
    const othEligibility = [...eligibilityProducts].filter(
      p => p.productId !== product.productId
    );

    setOtherProducts([...othBundles, ...othEligibility]);
  }, [bundleProducts, eligibilityProducts]);

  function handleConfirm() {
    onConfirmAction({ ...product }, () => {
      setDirty(false);
      if (retroactiveReminderModification) {
        void recomputeReminders(product.productId);
        setRetroactiveReminderModification(false);
      }
    });
  }

  function handleCancel() {
    setProduct({ ...productImmutable });
    setRetroactiveReminderModification(false);
    setDirty(false);
  }

  function handleReturn() {
    onReturnAction();
  }

  function handleChangeField(field: string, newValue: unknown) {
    setDirty(true);
    setProduct({ ...product, [field]: newValue });
  }

  function handleChangeRetroactivity(newValue: boolean) {
    setDirty(true);
    setRetroactiveReminderModification(newValue);
  }

  function onOpenCreate() {
    setOpenCreate(true);
    setIsDuplication(true);
  }

  async function copyAllExistingMailTemplateProduct(productId: string) {
    await Promise.all(
      Object.keys(MailTypeOrder).map(async (mailType: MailTypeOrder) => {
        const newTemplate = await fetchMailTemplate({
          type: mailType,
          organizationId: product.organizationId,
          resourceId: product.productId,
        });
        if (newTemplate) {
          newTemplate.resourceId = productId;
          newTemplate.id = undefined;
          await updateMailTemplate(newTemplate);
        }
      })
    );
  }

  async function onConfirmCreate(
    creation: Promise<ProductPrivateDTO>,
    productId: string,
    duplication: boolean | undefined
  ) {
    try {
      if (duplication) {
        copyAllExistingMailTemplateProduct(productId);
      }
      const createdProduct = await creation;
      history.push(`../product/${createdProduct.productId}`);
    } catch (e) {
      NotificationService.pushNotification({
        id: 'product-detail',
        type: 'error',
        message: e?.json?.message || _tg('commons.error'),
      });
    }
  }

  function onCancelCreate() {
    setOpenCreate(false);
    setIsDuplication(false);
  }

  const canSave = useMemo<boolean>(() => {
    // No field has been changed
    if (!dirty) {
      return false;
    }

    const prodZones = product.zones;
    if (prodZones.mustPickOne && !prodZones.showZoneMap) {
      return false;
    }

    return true;
  }, [dirty, product.zones]);

  return (
    <>
      <div className="product-detail_component">
        <div className="product-detail_content">
          <ProductDetailDescription
            product={product}
            organizations={organizations}
            organizationFilterEnabled={organizationFilterEnabled}
            canEdit={canEdit}
            onChange={handleChangeField}
          />
          <ProductDetailOptions
            product={product}
            canEdit={canEdit}
            onChange={handleChangeField}
          />
          <ProductDetailZones
            productZones={product.zones}
            canEdit={canEdit}
            onChange={handleChangeField}
            zones={zones}
          />
          <ProductApplicationProcedure
            product={product}
            canEdit={canEdit}
            onChange={handleChangeField}
          />
          <ProductDetailConditions
            product={product}
            conditions={product.conditions}
            canEdit={canEdit}
            productType={product.productType}
            onChange={handleChangeField}
            zones={zones}
            bundleProducts={bundleProducts}
            eligibilityProducts={eligibilityProducts}
          />
          <ProductDetailRestrictions
            restrictions={product.restrictions}
            productList={[...bundleProducts, ...eligibilityProducts]}
            customFields={product.applicationProcedure?.customFields}
            canEdit={canEdit}
            onChange={handleChangeField}
          />
          <ProductDetailAutomaticOrder
            automaticOrders={product.automaticOrders}
            otherProducts={otherProducts}
            canEdit={canEdit}
            onChange={handleChangeField}
          />
          <ProductDetailTags
            organizationId={product.organizationId}
            configTags={product.tags}
            onChange={handleChangeField}
          />
          <ProductDetailReminders
            reminders={product.reminders}
            fpsPreMajorationReminders={product.fpsPreMajorationReminders}
            canEdit={canEdit}
            retroactiveReminderModification={retroactiveReminderModification}
            setRetroactiveReminderModification={handleChangeRetroactivity}
            onChange={handleChangeField}
          />
          <ProductDetailAutomaticAcceptOrder
            automaticAcceptConfiguration={product.automaticAcceptConfiguration}
            productType={product.productType}
            canEdit={canEdit}
            onChange={handleChangeField}
          />
          <ProductMailConfig
            productId={product.productId}
            product={product}
            onChange={handleChangeField}
          />
        </div>
        <div className="product-detail_actions">
          <span className="product-detail_cell-12">
            <BoButton
              fullWidth
              label={_t('action.return')}
              onClick={handleReturn}
            />
          </span>
          <span className="product-detail_cell-25" />
          <span className="product-detail_cell-12">
            <BoButton
              secondary
              fullWidth
              label={_t('action.cancel')}
              disabled={!dirty}
              onClick={handleCancel}
            />
          </span>
          <span className="product-detail_cell-12">
            <BoButton
              primary
              fullWidth
              label={_t('action.duplicate')}
              onClick={onOpenCreate}
            />
          </span>
          <span className="product-detail_cell-12">
            <BoButton
              primary
              disabled={!(canEdit && canSave)}
              fullWidth
              label={_t('action.confirm')}
              onClick={handleConfirm}
            />
          </span>
        </div>
      </div>
      <ProductCreateModal
        productType={product.productType}
        isDuplicate={isDuplicate}
        open={openCreate}
        onCancel={onCancelCreate}
        onConfirm={onConfirmCreate}
        productToDuplicate={product}
      />
    </>
  );
};

export default ProductDetail;
