import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import BoButton from 'facade/BoButton';
import {
  ProductPrivateDTO,
  ProductSearchQueryDTO,
  ProductType,
} from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import {
  deleteProductById,
  patchProductById,
  searchProducts,
  exportProducts,
} from 'api/cvfm-core-subscription/product';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { highlightDownloads, updateDownloadsList } from 'Topbar/Download/duck';

import ProductTable from './ProductTable';
import SubscriptionNavigation from './SubscriptionNavigation';
import ProductTableSidebar from './ProductTableSidebar';
import { computeProductDiffPatchList } from './ProductDiffPatch';
import ProductCreateModal from './ProductCreateModal';
import './ProductTablePage.css';

const { _tg } = window.loadTranslations(__filename);

type ProductTablePageReduxProps = {
  canEdit: boolean;
  canDelete: boolean;
};

type ProductTablePageProps = {
  productType: ProductType;
  refreshDownloadsList: () => void;
  highlightDownloadsIcon: () => void;
} & ProductTablePageReduxProps;

const ProductTablePage = ({
  canEdit,
  canDelete,
  productType,
  highlightDownloadsIcon,
  refreshDownloadsList,
}: ProductTablePageProps): JSX.Element => {
  const defaultFilters = {
    productType,
    activeOnly: true,
  } as ProductSearchQueryDTO;
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<ProductSearchQueryDTO>(defaultFilters);
  const [products, setProducts] = useState<Array<ProductPrivateDTO>>([]);
  const history = useHistory();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);

  const setMessage = useSnackbar();

  function onTableDelete(productId: string) {
    void deleteProductById(productId)
      .then(() => {
        const newProducts = products.filter(p => p.productId !== productId);
        setProducts(newProducts);
      })
      .catch(err => {
        setMessage((err as Error).message);
      });
  }

  function onTableToggle(productId: string, field: string, toggled: boolean) {
    const product = products.find(p => p.productId === productId);
    if (product) {
      const updateProduct = { ...product, [field]: toggled };
      const patches = computeProductDiffPatchList(product, updateProduct);
      patchProductById(productId, patches)
        .then(patchedProduct => {
          const newProducts = products.map(p =>
            p.productId !== productId ? p : patchedProduct
          );
          setProducts(newProducts);
        })
        .catch(err => {
          setError(err as Error);
        });
    }
  }

  function onOpenCreate() {
    setOpenCreate(true);
  }

  function onConfirmCreate(creation: Promise<ProductPrivateDTO>) {
    creation
      .then(created => {
        history.push(`product/${created.productId}`);
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function onCancelCreate() {
    setOpenCreate(false);
  }

  function onChangeFilters(changedFilters: ProductSearchQueryDTO) {
    setFilters(changedFilters);
  }

  async function filterProducts(): Promise<void> {
    setLoading(true);
    try {
      const filteredProducts = await searchProducts({
        ...filters,
        productPrice: filters.productPrice
          ? filters.productPrice * 100
          : undefined,
      });
      filteredProducts.sort((a, b) => {
        const aCmp = `${a.name}_${a.productId}`;
        const bCmp = `${b.name}_${b.productId}`;
        return aCmp.localeCompare(bCmp);
      });
      setProducts(filteredProducts);
      setLoading(false);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }

  const onExport = (): void => {
    setExportInProgress(true);
    exportProducts(productType)
      .then(() => {
        highlightDownloadsIcon();
        setTimeout(() => {
          refreshDownloadsList();
          setExportInProgress(false);
        }, 2000);
      })
      .catch(err => {
        setMessage('Export failed');
        setExportInProgress(false);
      });
  };

  useEffect(() => {
    const reloadTimeout = setTimeout(() => void filterProducts(), 1000);

    return () => clearTimeout(reloadTimeout);
  }, [filters]);

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock message="Error: " error={error} />
      </FlexCenter>
    );
  }

  return (
    <div className="product-page_container">
      <ProductTableSidebar
        initialFilters={defaultFilters}
        onChangeFilters={onChangeFilters}
        totalHits={products.length}
      />
      <div className="product-page_content">
        <SubscriptionNavigation />
        <div className="product-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={loading}>
              <ProductTable
                canEdit={canEdit}
                canDelete={canDelete}
                products={products}
                onToggle={onTableToggle}
                onDelete={onTableDelete}
              />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              {canEdit && (
                <BoButton
                  label={_tg('action.create')}
                  onClick={onOpenCreate}
                  primary
                  style={{ marginRight: 10 }}
                />
              )}
              {canEdit && (
                <BoButton
                  label={_tg('action.export')}
                  disabled={exportInProgress || products.length === 0}
                  onClick={() => onExport()}
                  primary
                  style={{ marginRight: 10 }}
                />
              )}
            </ListBottom>
          </ListWrapper>
        </div>
        <ProductCreateModal
          productType={productType}
          open={openCreate}
          onCancel={onCancelCreate}
          onConfirm={onConfirmCreate}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: InternalApiState): ProductTablePageReduxProps {
  const { userInfo } = getApiState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
    canDelete: !!userInfo && userInfo.rights.includes('SUBSCRIBER_WRITE'),
  };
}

export default connect(mapStateToProps, dispatch => ({
  refreshDownloadsList: () => dispatch(updateDownloadsList()),
  highlightDownloadsIcon: () => dispatch(highlightDownloads(1)),
}))(ProductTablePage);
