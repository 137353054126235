import React from 'react';
import './OverviewBlockField.css';

type OverviewBlockField = {
  label: string;
  value: string | JSX.Element;
};

const OverviewBlockField = ({
  label,
  value,
}: OverviewBlockField): JSX.Element => {
  return (
    <div className="overview-field">
      <span className="overview-field__label">{label}</span>
      <div className="overview-field__value">{value}</div>
    </div>
  );
};

export default OverviewBlockField;
