import React, { CSSProperties, useEffect, useState } from 'react';
import { Dialog, TextField } from 'material-ui';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import AutoComplete from 'material-ui/AutoComplete';

import BoButton from 'facade/BoButton';
import {
  EvidenceProperties,
  OrderEvidenceRequest,
  OrderPrivateDTO,
  OrderTraceType,
  SubscriptionSource,
  EvidenceType,
  SubscriberVehicle,
  MailTemplateDTO,
  MailTypeOrder,
} from '@cvfm-front/tefps-types';
import {
  addEvidenceRequestsUpdate,
  renderDecisionTemplate,
} from 'api/cvfm-core-subscription/order';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import Tag from 'commons/Tag';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK } from 'theme';
import { getConfigState } from 'config/duck';
import OrderDetailModalEvidenceRequestPersonalFiles from 'tefps/Subscription/OrderDetailModalEvidenceRequestPersonalFiles';

import './OrderDetailPage.css';

const { _t, _tg } = window.loadTranslations(__filename);

type OrderDetailModalEvidenceRequestProps = {
  open: boolean;
  order: OrderPrivateDTO;
  userInfo: InternalAgent | null | undefined;
  adV3Enabled: boolean;
  onCancel: () => void;
  onConfirm: (update: Promise<OrderPrivateDTO>) => void;
  supportingEvidences: Array<EvidenceProperties> | null | undefined;
  professional: boolean;
  subscribersVehicles: SubscriberVehicle[];
};

const STYLE_EVIDENCES: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'baseline',
};

const AUTOSCROLL_STYLE: CSSProperties = {
  maxHeight: 300,
  overflow: 'auto',
};

const OrderDetailModalEvidenceRequest = ({
  open,
  order,
  userInfo,
  adV3Enabled,
  onCancel,
  onConfirm,
  supportingEvidences,
  professional,
  subscribersVehicles,
}: OrderDetailModalEvidenceRequestProps): JSX.Element => {
  const [renderEmail, setRenderEmail] = useState<boolean>(false);
  const [evidences, setEvidences] = useState<Array<EvidenceProperties>>([]);
  const [comment, setComment] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);
  const [template, setTemplate] = useState<MailTemplateDTO>(
    {} as MailTemplateDTO
  );

  const [evidenceInput, setEvidenceInput] = useState<string>('');

  const evidenceOptions = (): Array<string> => {
    return (
      supportingEvidences?.map(e => (e.details ? e.details : e.name)) || []
    );
  };

  function onCancelAction() {
    setEvidences([]);
    setValid(false);
    onCancel();
  }

  function onConfirmAction() {
    if (valid) {
      const updateRequests = evidences.map(e => {
        return {
          timestamp: new Date().toISOString(),
          traceType: order.claim
            ? OrderTraceType.CLAIM_EVIDENCE_REQUEST
            : OrderTraceType.EVIDENCE_REQUEST,
          source: SubscriptionSource.BACK,
          agent: userInfo ? { ...userInfo } : null,
          evidenceProperties: {
            id: uuidv4(),
            name: e.name,
            details: e.details,
            expirationPeriod: null,
            mandatory: e.mandatory,
            uploadRequired: false,
            deleteAfterDecisionOrCancellationEnabled: false,
            type: e.type,
          },
          comment,
          subscriberId: null,
        } as OrderEvidenceRequest;
      });
      const updatePromise = addEvidenceRequestsUpdate(
        order.orderId,
        updateRequests,
        template
      );
      onConfirm(updatePromise);
    }
  }

  function handleChangeComment(e: never, text: string) {
    setComment(text);
  }

  function handleChangeMessage(e: never, text: string) {
    setMessage(text);
  }

  function onConfirmComment() {
    void renderDecisionTemplate(
      order.orderId,
      true,
      message,
      order.claim
        ? MailTypeOrder.ORDER_CLAIM_EVIDENCE_REQUEST
        : MailTypeOrder.ORDER_EVIDENCE_REQUEST
    ).then(result => {
      setTemplate(result);
      setRenderEmail(true);
    });
  }

  const addEvidence = (
    input: string,
    evidenceType: EvidenceType | null | undefined,
    id?: string
  ): void => {
    const newEvidences = [...evidences];
    const evidence = supportingEvidences?.find(
      e => e.details === input || e.name === input
    );
    const newEvidence = {
      id: id ?? uuidv4(),
      name: evidence?.name || input,
      details: evidence?.details || input,
      expirationPeriod: null,
      mandatory: true,
      uploadRequired: true,
      type: evidenceType,
    } as EvidenceProperties;

    setEvidences([...newEvidences, newEvidence]);
  };

  const handleAddEvidence = (): void => {
    if (evidenceInput.trim() !== '') {
      addEvidence(evidenceInput, null);
      setEvidenceInput('');
    }
  };

  const removeEvidence = (evidenceId: string): void => {
    const newEvidences = [...evidences];
    setEvidences(newEvidences.filter(t => t.id !== evidenceId));
  };

  function handleCheck(e: React.MouseEvent<HTMLDataElement>, checked: boolean) {
    const { type, name, id } = e.currentTarget.dataset;
    if (checked && type && name && id) {
      addEvidence(name, type as EvidenceType, id);
    } else if (id) {
      removeEvidence(id);
    }
  }

  function renderEvidencesForm() {
    return (
      <>
        {adV3Enabled && (
          <OrderDetailModalEvidenceRequestPersonalFiles
            evidences={evidences}
            handleCheck={handleCheck}
            professional={professional}
            subscribersVehicles={subscribersVehicles}
          />
        )}
        <SeparatorWithTitle
          title={_t('field.requestEvidences')}
          color={BKG_PINK}
          titleSize={20}
          className="margin-y--s"
        />

        <AutoComplete
          hintText={_t('field.name')}
          searchText={evidenceInput}
          // eslint-disable-next-line @typescript-eslint/unbound-method
          filter={AutoComplete.fuzzyFilter}
          dataSource={evidenceOptions()}
          openOnFocus
          onUpdateInput={e => setEvidenceInput(e)}
          onNewRequest={handleAddEvidence}
          fullWidth
          listStyle={AUTOSCROLL_STYLE}
        />

        <div style={STYLE_EVIDENCES}>
          {evidences.map((e, i) => (
            <Tag
              key={i}
              tag={e.details ? e.details : e.name}
              onDelete={_s => removeEvidence(e.id)}
            />
          ))}
        </div>

        <SeparatorWithTitle
          title={_t('field.requestTexts')}
          color={BKG_PINK}
          titleSize={20}
          className="margin-y--s"
        />

        <div className="order-detail_row">
          <TextField
            multiLine
            rows={5}
            rowsMax={10}
            fullWidth
            name="evidence-request-message"
            floatingLabelText={_t('field.message')}
            onChange={handleChangeMessage}
            value={message || ''}
          />
        </div>
        <div className="order-detail_row">
          <TextField
            multiLine
            rows={5}
            rowsMax={10}
            fullWidth
            name="evidence-request-comment"
            floatingLabelText={_t('field.comment')}
            onChange={handleChangeComment}
            value={comment || ''}
          />
        </div>
      </>
    );
  }

  function renderEmailForm() {
    return (
      <>
        <div className="order-detail_row">
          <TextField
            multiLine
            rows={5}
            rowsMax={10}
            fullWidth
            name="order-decision-mail"
            floatingLabelText={_t('field.sent_mail')}
            onChange={handleChangeComment}
            value={template.mail || ''}
          />
        </div>
      </>
    );
  }

  useEffect(() => {
    setValid(
      (evidences.length !== 0 &&
        evidences.every(e => e.name !== undefined && e.name.length > 0)) ||
        false
    );
  }, [evidences]);

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="order-period-cancel"
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="orer-period-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={renderEmail ? onConfirmAction : onConfirmComment}
      disabled={!valid}
    />,
  ];

  return (
    <Dialog
      title={_t(order.claim ? 'title.claim' : 'title.order')}
      open={open}
      actions={actions}
      bodyClassName="order-detail-modal_body"
      titleClassName="order-detail-modal_title"
    >
      {!renderEmail && renderEvidencesForm()}
      {renderEmail && renderEmailForm()}
    </Dialog>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { adV3Enabled } = getConfigState(state).subscriptionConfigurationDTO;
  return {
    userInfo,
    adV3Enabled,
  };
})(OrderDetailModalEvidenceRequest);
