import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { CircularProgress, Dialog, FlatButton } from 'material-ui';

import useDecision from 'commons/hooks/useDecision';
import useReason from 'commons/hooks/useReason';
import useProposalComment from 'commons/hooks/useProposalComment';
import useNewPrice from 'commons/hooks/useNewPrice';
import useNewReducedPrice from 'commons/hooks/useNewReducedPrice';
import useUserInfo from 'commons/hooks/useUserInfo';
import useCityConfig from 'commons/hooks/useCityConfig';
import useRecourseConfiguration from 'commons/hooks/useRecourseConfiguration';
import BoButton from 'facade/BoButton';
import {
  RecourseDecisionRequestDTO,
  RecourseDTO,
  RecourseProposalRequestDTO,
} from 'api/recourse/types';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { convertToCents } from 'commons/Utils/paymentUtil';
import { PatchObject } from 'api/commonTypes';
import { ApiError } from 'api/ApiError';
import { patchRecourse, previewRapoAnswerWipUrl } from 'api/recourse';
import useRecourse from 'commons/hooks/useRecourse';
import TefpsToggle from 'tefps/RecoursesV2/facades/TefpsToggle';
import { getRecourseUpdateMessage } from 'tefps/RecoursesV2/utils/translationUtils';
import { isProposalOpen } from 'tefps/RecoursesV2/utils/recourseUtils';
import Services from 'commons/services';
import { Base64 } from 'commons/helpers/Base64';
import { openNewAuthentifiedTab } from 'api/helpers';
import submitTags from 'tefps/RecoursesV2/utils/submitTags';

import './ProposalDetail.css';

const { _t, _tg } = window.loadTranslations(__filename);

const SubmitProposal = ({ newTags }: { newTags: string[] }): JSX.Element => {
  /* Immutable vars */
  const history = useHistory();
  const config = useCityConfig();
  const userInfo = useUserInfo();
  const recourse = useRecourse();
  const recourseConfiguration = useRecourseConfiguration();

  if (!recourse) {
    return <></>;
  }

  /* Service vars */
  const [newPrice] = useNewPrice();
  const [newReducedPrice] = useNewReducedPrice();
  const [proposalComment] = useProposalComment();
  const [decision] = useDecision();
  const [reason] = useReason();
  const setMessage = useSnackbar();

  /* State */
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState<boolean>(
    !recourse?.recourse.shouldSendLetters
  );
  const [letterEnabled, setLetterEnabled] = useState<boolean>(
    !!recourse?.recourse.shouldSendLetters
  );

  /* Conditional rendering vars */
  const disableSubmit =
    loading || !reason || (decision === 'ACCEPTED' && newPrice === null);

  const disableSubmitWithoutTags =
    disableSubmit || (recourseConfiguration?.tagsMandatory && !newTags.length);

  const canValidate =
    userInfo?.rights.includes('RAPO_ACCEPT') && !config?.recourseFullValidation;

  const emailNotificationOption =
    (recourse?.recourse.user.email &&
      recourse?.recourse.representative === null) ||
    recourse?.recourse.representative?.email;

  const letterNotificationOption =
    !config?.mailingPartnerProperties?.class.includes(
      'CvfmMailingPartnerProperties'
    ) &&
    ((recourse?.recourse.user.addressLocality &&
      recourse.recourse.representative === null) ||
      recourse?.recourse.representative?.addressLocality);

  /* Functions */
  const buildProposal = (): RecourseProposalRequestDTO => {
    return {
      agentComment: proposalComment,
      newFpsPrice: convertToCents(newPrice || 0), // send the price in cents
      newFpsReducedPrice: newReducedPrice
        ? convertToCents(newReducedPrice)
        : null,
      cityReason: reason || 'R_OTHERS',
      status: decision || 'REFUSED',
      waiting: false,
    };
  };

  const submitProposal = async (validate = false) => {
    if (decision && recourse) {
      /* We start by adding or removing tags */
      const status = await submitTags(
        newTags,
        recourse,
        setLoading,
        setMessage
      );
      if (!status) {
        return;
      }

      setLoading(true);
      const patches: Array<PatchObject<
        RecourseProposalRequestDTO | RecourseDecisionRequestDTO
      >> = [];
      /* Case when we are modifying the proposal */
      if (recourse && isProposalOpen(recourse.recourse)) {
        patches.push({
          op: 'add',
          path: `/proposals/${recourse.recourse.proposals.length - 1}/decision`,
          value: {
            accepted: false,
            emailEnabled,
            letterEnabled,
          } as RecourseDecisionRequestDTO,
        });
      }
      /* Now we can add the proposal */
      patches.push({
        op: 'add',
        path: '/proposals',
        value: buildProposal(),
      });
      /* Validating the proposal immediately */
      if (validate) {
        patches.push({
          op: 'add',
          path: `/proposals/${recourse.recourse.proposals.length}/decision`,
          value: {
            accepted: true,
            emailEnabled,
            letterEnabled,
          } as RecourseDecisionRequestDTO,
        });
      }
      try {
        const updatedRecourse = await patchRecourse(
          recourse.recourse.rapoId,
          patches
        );
        Services.recourse.applyRecourseChanges(updatedRecourse);
        setMessage(getRecourseUpdateMessage(decision, validate));
        history.goBack();
      } catch (e) {
        const error = e as ApiError;
        setMessage(
          validate
            ? _t('error.validation', { error: error.message })
            : _t('error.proposition', { error: error.message })
        );
      } finally {
        setLoading(false);
        setOpen(false);
      }
    }
  };

  const computeModalActions = () => {
    const buttons = [
      <BoButton
        label={_t('action.submit')}
        primary
        className="margin-x--xs"
        onClick={() => submitProposal()}
        disabled={loading}
      />,
      <BoButton
        label={_tg('action.cancel')}
        className="margin-x--xs"
        onClick={() => setOpen(false)}
        disabled={loading}
      />,
    ];

    if (canValidate) {
      buttons.unshift(
        <BoButton
          label={_t('action.submitAndValidate')}
          className="margin-x--xs"
          secondary
          onClick={() => submitProposal(true)}
          disabled={loading}
        />
      );
    }

    return buttons;
  };

  /* Refresh all service variables, so we come back to the initial state in every component */
  const refresh = async (recourseDTO: RecourseDTO) => {
    setLoading(true);
    await Services.recourse.init(recourseDTO.rapoId);
    setLoading(false);
  };

  const previewMail = () => {
    if (recourse && recourse.fps.recourseOrganization && reason) {
      try {
        const url = previewRapoAnswerWipUrl(
          recourse.recourse.rapoId,
          reason.toString(),
          recourse.fps.recourseOrganization.organizationId,
          Base64.encode(proposalComment)
        );

        openNewAuthentifiedTab(url);
      } catch (e) {
        const error = e as ApiError;
        setMessage(_t('error.preview'), { error: error.message });
      }
    }
  };

  return (
    <>
      {/* Case modifying a proposal */}
      {recourse && isProposalOpen(recourse.recourse) && (
        <FlatButton
          className="margin-x--xs"
          label={_t('action.cancelModification')}
          onClick={_e => refresh(recourse.recourse)}
        />
      )}
      <BoButton
        label={_t('action.preview')}
        backgroundColor="var(--bg-color--blue)"
        labelColor="var(--white)"
        onClick={previewMail}
        disabled={disableSubmit}
      />
      <BoButton
        label={_t('action.submit')}
        backgroundColor="var(--bg-color--cyan)"
        labelColor="var(--white)"
        onClick={() => setOpen(true)}
        disabled={disableSubmitWithoutTags}
        className="margin-x--xs"
      />

      <Dialog
        title={_tg('field.confirmation')}
        onRequestClose={() => setOpen(false)}
        modal
        open={open}
        autoScrollBodyContent
        actions={computeModalActions()}
      >
        <div className="column margin-y--l">
          {decision === 'ACCEPTED' && <p>{_t('message.accept')}</p>}
          {decision === 'REFUSED' && <p>{_t('message.reject')}</p>}
          {decision === 'INCORRECT' && <p>{_t('message.incorrect')}</p>}
          {canValidate && (
            <div className="text-warning">
              {_t('message.validationWarning')}
              {emailNotificationOption && (
                <TefpsToggle
                  label={_t('message.emailNotification')}
                  toggled={emailEnabled}
                  onToggle={() => setEmailEnabled(!emailEnabled)}
                />
              )}
              {letterNotificationOption && (
                <TefpsToggle
                  label={_t('message.letterNotification')}
                  toggled={letterEnabled}
                  onToggle={() => setLetterEnabled(!letterEnabled)}
                />
              )}
            </div>
          )}
        </div>
        {loading && (
          <div className="row">
            <CircularProgress />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default SubmitProposal;
